import { safeClassName, useObservable } from 'some-utils/npm/react'
import { unifiedSearchState } from 'state/unified-search'
import { toggleMobileMenu } from 'view/mobile-menu'

import * as svg from './svg'

import './TopMenuMobile.css'

export function TopMenuMobile() {
  const unifiedSearchVisible = useObservable(unifiedSearchState.visibleObs)

  return (
    <div
      className='TopMenu TopMenuMobile button'
      onClick={() => {
        if (unifiedSearchVisible) {
          unifiedSearchState.visibleObs.setFalse()
        } else {
          toggleMobileMenu()
        }
      }}
    >
      <div className={safeClassName('absolute-fill flex center', !unifiedSearchVisible && 'visible')}>
        <svg.Circle />
      </div>
      <div className={safeClassName('absolute-fill flex center', unifiedSearchVisible && 'visible')}>
        <svg.Cross style={{ transform: 'scale(75%)', stroke: 'var(--ui-color' }} />
      </div>
    </div>
  )
}
