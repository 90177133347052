export const shorthMonths = [
  {
    en: 'Jan',
    fr: 'Jan',
  },
  {
    en: 'Feb',
    fr: 'Fév',
  },
  {
    en: 'Mar',
    fr: 'Mar',
  },
  {
    en: 'Apr',
    fr: 'Avr',
  },
  {
    en: 'May',
    fr: 'Mai',
  },
  {
    en: 'Jun',
    fr: 'Juin',
  },
  {
    en: 'Jul',
    fr: 'Juil',
  },
  {
    en: 'Aug',
    fr: 'Août',
  },
  {
    en: 'Sep',
    fr: 'Sep',
  },
  {
    en: 'Oct',
    fr: 'Oct',
  },
  {
    en: 'Nov',
    fr: 'Nov',
  },
  {
    en: 'Dec',
    fr: 'Déc',
  },
]
