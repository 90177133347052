import ReactMarkdown from 'react-markdown'
import { range } from 'some-utils/iterators'
import { useEffects, useFetchText, } from 'some-utils/npm/react'
import { Animation } from 'some-utils/Animation'
import { handlePointer } from 'some-utils/dom'
import { navigate } from 'state/navigation'
import { BigCrossSvg } from 'assets/svg'
import doc from './DOC.md'
import './Doc.css'
import { Message } from 'some-utils/message'

const extractHTagText = (htag: HTMLElement) => {
  return htag.querySelector('span.text')?.innerHTML ?? htag.innerHTML
}

const toHTag = (str: string) => {
  return str
    .replace(/\W/g, '-')
    .replace(/-+/g, '-')
    .replace(/(^-)|(-$)/g, '')
    .toLowerCase()
}

const translatePath = (path: number[]) => {
  const roman = ['I', 'II', 'III', 'IV', 'V']
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const str = path.map((x, i) => {
    switch (i) {
      case 0: {
        return roman[x - 1]
      }
      case 2: {
        return alphabet[x - 1]
      }
      default: {
        return x.toString()
      }
    }
  })
    .map(x => `${x}.`)
    .join('')
  return str
}

const prefixHLinks = (element: HTMLElement) => {
  const htags = [...element.querySelectorAll('h2, h3, h4, h5, h6')] as HTMLElement[]
  const path = [0, 0, 0, 0, 0]
  for (let i = 0, max = htags.length; i < max; i++) {
    const x = Number.parseInt(htags[i].tagName.substring(1)) - 2
    path[x]++
    for (let y = x + 1; y < 6; y++) {
      path[y] = 0
    }
    const currentPath = path.slice(0, x + 1)
    const str = translatePath(currentPath)
    const htag = htags[i]
    const text = extractHTagText(htag)
    htag.innerHTML = `<span class="index">${str}</span>&nbsp;<span class="text">${text}</span>`
  }
}

const initHLinks = (element: HTMLElement) => {
  const htags = [...element.querySelectorAll('h1, h2, h3, h4, h5, h6')] as HTMLElement[]
  const tokens = htags.map(h => toHTag(extractHTagText(h)))

  let destroyed = false
  const onDestroy = new Set<() => void>()
  const destroy = () => {
    if (destroyed === false) {
      destroyed = true
      for (const cb of onDestroy) cb()
    }
  }

  const gotoToken = (token: string) => {
    const index = tokens.indexOf(token)
    if (index !== -1) {
      const htag = htags[index]
      const scrollTop = htag.offsetTop - 24
      const scrollingElement = document.querySelector<HTMLDivElement>('div.Doc')!
      let x = scrollingElement.scrollTop
      Animation.loopWithTarget(element, () => {
        if (destroyed) {
          return Animation.BREAK
        }
        const delta = (scrollTop - scrollingElement.scrollTop) / 10
        x += delta
        scrollingElement.scrollTop = x
        if (Math.abs(delta) < 1e-3) {
          scrollingElement.scrollTop = scrollTop
          return Animation.BREAK
        }
      })
    }
  }

  onDestroy.add(handlePointer(element, {
    onWheel: () => {
      Animation.loopCancelTarget(element)
    }
  }).destroy)

  const entries = [] as [HTMLElement, string][]
  for (const index of range(htags.length)) {
    const htag = htags[index]
    const token = tokens[index]
    htag.dataset.htag = token
    htag.onclick = () => {
      window.location.hash = token
      gotoToken(token)
    }
    entries.push([htag, token])
  }

  return {
    gotoToken,
    entries,
    destroy,
  }
}

const Summary = ({ data = '' }) => {
  const lines = data
    .split('\n')
    .map(s => s.trim())
    .filter(s => s.startsWith('#'))

  const title = lines.shift()!.replace(/^#*\s*/, '')

  const entries = [] as [indice: string, line: string][]
  const path = [0, 0, 0, 0, 0]
  for (const [index, line] of lines.entries()) {
    const [hashes] = line.match(/^#*/)!
    const x = hashes.length - 2
    path[x]++
    for (let y = x + 1; y < 6; y++) {
      path[y] = 0
    }
    const currentPath = path.slice(0, x + 1)
    const indice = translatePath(currentPath)
    entries[index] = [indice, line.replace(/^#*\s*/, '')]
  }

  const onUlClick = (index: number) => {
    const [, line] = entries[index]
    Message.send('DOC', 'HTAG', toHTag(line))
  }

  return (
    <div className='Summary'>
      <h1>{title}</h1>
      <ul>
        {entries.map(([indice, line], index) => (
          <li key={index} onClick={() => onUlClick(index)}>
            <span className='Indice'>{indice}</span>
            <span className='Line'>{line}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const Doc = () => {
  const data = useFetchText(doc)
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    document.title = 'OVVO - documentation'
    if (data) {
      const content = div.querySelector<HTMLDivElement>('.Content')!
      content.innerHTML = content.innerHTML.replace(/\[NEWLINE\]/g, '<br>')
      prefixHLinks(content)
      const hlink = initHLinks(content)
      yield hlink
      if (window.location.hash.length > 1) {
        const hash = window.location.hash.substring(1).toLowerCase()
        hlink.gotoToken(hash)
      }
      yield Message.on('DOC', 'HTAG', ({ props: hash }) => {
        hlink.gotoToken(hash)
      })
    }
  }, [data])

  return (
    <div ref={ref} className='Doc flex row'>
      {data === null
        ? 'loading...'
        : (
          <>
            <Summary data={data} />

            <div className='Content'>
              <ReactMarkdown>
                {data}
              </ReactMarkdown>
              <div style={{ height: '100px' }} />
            </div>
          </>
        )
      }
      <div className='Close button' onClick={() => navigate('/', { clearHash: true })}>
        <BigCrossSvg />
      </div>
    </div>
  )
}
