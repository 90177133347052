/* eslint-disable require-yield */

import { forwardRef, HTMLProps, Ref, useImperativeHandle } from 'react'

import { safeClassName, useLayoutEffects } from 'some-utils/npm/react'

import './HorizontalWrapper.css'

export function HorizontalWrapperWithRef(props: HTMLProps<HTMLDivElement>, outerRef: Ref<HTMLDivElement>) {
  const { className, children, ...rest } = props
  const { ref } = useLayoutEffects<HTMLDivElement>(function* (div) {
    let max = div.clientWidth
    let min = div.clientHeight

    const rotated = min > max
    if (rotated) {
      [min, max] = [max, min]
    }

    const child = div.firstElementChild as HTMLDivElement
    child.style.width = `${max}px`
    child.style.height = `${min}px`

    if (rotated) {
      child.style.top = `${max}px`
      child.style.left = `0`
      child.style.transform = `rotate(-90deg)`
      child.style.transformOrigin = `top left`
    }

    else {
      child.style.top = `0`
      child.style.left = `0`
      child.style.removeProperty('transform')
      child.style.removeProperty('transformOrigin')
    }
  }, [])

  useImperativeHandle(outerRef, () => ref.current!)

  return (
    <div
      ref={ref}
      {...rest}
      className={safeClassName('HorizontalWrapper', className)}
    >
      <div>
        {children}
      </div>
    </div>)
}

export const HorizontalWrapper = forwardRef(HorizontalWrapperWithRef)
