import { getSearch, setSearch } from 'some-utils/router'
import queryString from 'query-string'
import { positiveModulo } from 'some-utils/math'

export const setLightbox = (currentMediaId: number | null, mediaIds: number[] = []) => {
  if (currentMediaId === null) {
    return setSearch('')
  }
  const search = `lb=${[currentMediaId, ...mediaIds].join(',')}`
  setSearch(search)
}

export type LightboxData = {
  rawData: string
  
  currentIndex: number | null
  previousIndex: number | null
  nextIndex: number | null

  currentMediaId: number
  previousMediaId: number | null
  nextMediaId: number | null
  
  mediaIds: number[]
}

export const getLightboxData = (): LightboxData | null => {
  const data = queryString.parse(getSearch())

  if ('lb' in data) {
    const rawData = data.lb as string
    const [currentMediaId, ...mediaIds] = rawData
      .split(/,/)
      .map(x => parseInt(x))
    
    const currentIndex = mediaIds.length > 0 ? mediaIds.indexOf(currentMediaId) : null
    const previousIndex = currentIndex !== null ? positiveModulo(currentIndex - 1, mediaIds.length) : null
    const nextIndex = currentIndex !== null ? positiveModulo(currentIndex + 1, mediaIds.length) : null

    const previousMediaId = currentIndex !== null ? mediaIds[positiveModulo(currentIndex - 1, mediaIds.length)] : null
    const nextMediaId = currentIndex !== null ? mediaIds[positiveModulo(currentIndex + 1, mediaIds.length)] : null
    return { 
      rawData, 
      
      currentIndex, 
      previousIndex,
      nextIndex,

      currentMediaId, 
      previousMediaId, 
      nextMediaId,
      
      mediaIds,
    }
  }

  return null
}

export const getLightboxIndex = () => {
  const data = getLightboxData()
  if (data) {
    const { currentMediaId, mediaIds } = data
    return mediaIds.indexOf(currentMediaId)
  }
  return -1
}

export const setLightboxIndex = (index: number) => {
  const data = getLightboxData()
  if (data) {
    const { mediaIds } = data
    const newIndex = positiveModulo(index, mediaIds.length)
    setLightbox(mediaIds[newIndex], mediaIds)
  }
}

export const lightboxNext = () => setLightboxIndex(getLightboxIndex() + 1)
export const lightboxPrevious = () => setLightboxIndex(getLightboxIndex() - 1)
