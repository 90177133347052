import { gql } from '@apollo/client'

import { Locale } from 'config'
import { graphql } from 'config/graphql'
import { NewsCategory } from 'data/api'

/**
 * - [strapi filters](https://docs.strapi.io/dev-docs/api/graphql#filters)
 * - [graphql playground](http://46.226.105.1:1338/graphql)
 */
export const allNewsPreviews = ({
  newsCategory = null as NewsCategory | null,
  paginationLimit = 40,
  locale = 'en' as Locale,
  debug = false,
} = {}) => gql`
query {
  allNews(
    ${graphql.newsFilters(newsCategory, { debug })}
    pagination: { limit: ${paginationLimit} }
    locale: "${locale}"
    sort: "Date:desc"
  ) ${graphql.newsPreviewData}
}
`

export const allNewsIds = ({
  slug = '' as string,
  paginationLimit = 1,
  locale = 'en' as Locale,
} = {}) => gql`
query {
  allNews(
    filters: {
      and: [
        { Slug: { eq: "${slug}" } }
        { Visibility: { in: ["visible"] } }
      ]
    }
    pagination: { limit: ${paginationLimit} }
    locale: "${locale}"
  ) {
    data {
      id
    }
  }
}
`

/**
 * @deprecated Currently the "Contents" (which is dynamic) is not retrieved.
 */
export const oneNewsFullBySlug = ({
  slug = '',
  locale = 'en' as Locale,
} = {}) => gql`
query {
  allNews(
    filters: { Slug: { eq: "${slug}" } }
    pagination: { limit: 1 }
    locale: "${locale}"
  ) ${graphql.newsFullData}
}`