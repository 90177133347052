import { createRoot } from 'react-dom/client'
import { App } from './App'
import packageJson from '../package.json'
import './three-extensions'
import './index.css'

const { branch, version } = packageJson
console.log(`OVVO - ${branch}@  ${version}`)

document.title = ''

const div = document.getElementById('root')!
const root = createRoot(div)
root.render(<App />)

// Example of debug
// root.render(<MetaballRD />)

