import { useContext, useState } from 'react'
import ReactPlayer from 'react-player'

import { strapi } from 'config'
import { safeClassName, useEffects } from 'some-utils/npm/react'

import { allProjectsBySlug } from 'data'
import { useResponsive } from 'state/layout'
import { setLightbox } from 'state/lightbox/lightbox'
import { cursorClassName } from 'view/cursor'

import { Media } from '../../components/Media'
import { ScrollContext } from '../ScrollContext'
import { ContentError } from './ContentError'
import { MediaCaption } from './MediaCaption'
import { useHorizontalMargin, useLayoutCase } from './utils/misc'

import defaultLayout from './MediaSingle.defaultLayout.json'

import './css/MediaSingle.css'

const getAspect = (content: strapi.MediaSingle) => {
  if (content.aspect !== 'auto') {
    return content.aspect
  }
  if (content.media?.data?.attributes) {
    const { width, height } = content.media.data.attributes
    return `${width} / ${height}`
  }
  return null
}

const getCaption = (content: strapi.MediaSingle) => {
  if (!content.displayCaption) {
    return null
  }
  if (content.useAlternativeCaption) {
    return content.alternativeCaption ?? '(missing "alternative caption")'
  }
  return content.media.data?.attributes.caption
}

export const MediaSingle = ({
  content,
  useLightBox = false,
}: {
  content: strapi.MediaSingle
  useLightBox?: boolean
}) => {

  const { columnsCount } = useResponsive()

  const { columnStart, columnWidth } = useLayoutCase(content.layout?.columns, defaultLayout.columns)
  const style = useHorizontalMargin([columnStart, columnWidth])
  const aspectRatio = columnsCount === 1 && content.externalMedia === false
    ? '1'
    : getAspect(content)
  const caption = getCaption(content)

  const scrollContext = useContext(ScrollContext)
  const [disableVideoPlayer, setDisableVideoPlayer] = useState(true)
  useEffects(function* () {
    console.log({ scrollContext })
    yield scrollContext?.scrollObs?.onChange(() => {
      setDisableVideoPlayer(true)
    })
  }, [])

  if (columnsCount === 1) {
    style.marginLeft = '0'
    style.marginRight = '0'
    style.width = '100%'
    style.aspectRatio = aspectRatio ?? 'auto'
  }

  if (content.externalMedia === false && !content.media?.data) {
    const project = allProjectsBySlug.get(window.location.pathname.split('/').pop() ?? '')
    return (
      <ContentError
        content={content}
        message={`
          Media is null!
          Please specify a media from the backend or an external url.
          https://ovvo.studio/admin/content-manager/collectionType/api::project.project/${project?.id}
        `} />
    )
  }

  if (aspectRatio === null) {
    return (
      <ContentError
        content={content}
        message={`Cannot retrieve "aspect" on ${content.media.data?.attributes.name}`} />
    )
  }

  const media = (() => {
    if (content.media?.data) {
      return (
        <Media
          lightbox={useLightBox}
          media={content.media.data}
          onClick={useLightBox
            ? () => setLightbox(content.media.data.id)
            : undefined
          }
        />
      )
    }
    if (content.externalMedia) {
      return (
        <div
          className='fill'
          // NOTE: With "mouse" event here, it seems that there are no more bug with mobile (intempestive video opening during scroll). 
          onMouseDown={() => setDisableVideoPlayer(false)}
        >
          <ReactPlayer
            controls
            width='100%'
            height='100%'
            url={content.externalMediaUrl}
            playing={!disableVideoPlayer}
            style={disableVideoPlayer ? { pointerEvents: 'none' } : {}}
          />
        </div>
      )
    }
  })()

  return (
    <div
      className={safeClassName(
        `ProjectContent MediaSingle`,
        useLightBox && cursorClassName('Fullscreen'),
      )}
      style={{ ...style }}
    >
      <div
        className='Wrapper'
        style={{ aspectRatio }}
      >
        {media}
      </div>
      {caption && (
        <MediaCaption caption={caption} style={{ paddingTop: '12px' }} />
      )}
    </div>
  )
}