import React from 'react'
import './css/MediaCaption.css'

const Chip: React.FC = () => {
  const size = 24
  return (
    <svg width={size} height={size}>
      <circle cx={size / 2} cy={size / 2 + .5} r={3.5}></circle>
    </svg>
  )
}

export const MediaCaption: React.FC<{
  caption: string
} & React.HTMLAttributes<HTMLDivElement>> = ({
  caption,
  ...props
}) => {
  return (
    <div {...props} className='MediaCaption ui'>
      <Chip />
      <p>{caption}</p>
    </div>
  )
}
