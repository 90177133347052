import { CSSProperties } from 'react'

import { Animation } from 'some-utils/Animation'
import { useEffects } from 'some-utils/npm/react'

import './SolidFadeIn.css'

export function SolidFadeIn({
  theme = 'dark',
  color = theme === 'dark' ? '#16151B' : '#F5F5F5',
}: Partial<{
  color: string
  theme: 'light' | 'dark'
}>) {
  const { ref } = useEffects<HTMLDivElement>(function (div) {
    Animation
      .during({ duration: 0.5, delay: 0.5 }, anim => {
        div.style.opacity = (1 - anim.progress).toString()
      })
      .onComplete(() => {
        div.remove()
      })
  }, [])
  return (
    <div
      ref={ref}
      className='SolidFadeIn'
      style={{ '--color': color } as CSSProperties}
    />
  )
}
