import { RectangleParams, Point, PointParams, Rectangle } from 'some-utils/geom'
import { layoutObs } from './layout'

const convertStart = (
  columnCount: number, 
  columnStart: number,
) => {
  const correspondances:Record<number, number[]> = {
    5: [0, 1, 2, 3, 4],
    4: [0, 0, 1, 2, 3],
    3: [0, 0, 1, 1, 2],
    2: [0, 0, 1, 1, 1],
    1: [0, 0, 0, 0, 0],
  }
  if (columnCount in correspondances === false) {
    throw new Error(`mais non ${columnCount}`)
  }
  return correspondances[columnCount][columnStart]
}

const convertWidth = (
  columnCount: number, 
  contentWidth: number,
  start: number,
) => {
  const max = columnCount - start
  switch (columnCount) {
    case 1:
      return max
    case 2:
      return Math.min(contentWidth / (2 / 5), max)
    case 3:
      return Math.min(contentWidth / (3 / 5), max)
    case 4:
      return Math.min(contentWidth / (4 / 5), max)
    default:
      return Math.min(contentWidth, max)
  }
}

/**
 * Returns the actual start & width according to the actual number of columns 
 * (which may vary (from 5 to 1)).
 */
export const convertColumnStartAndContentWidth = (
  columnCount: number, 
  columnStart: number, 
  contentWidth: number,
) => {
  const start = convertStart(columnCount, columnStart)
  const width = convertWidth(columnCount, contentWidth, start)
  return [start, width]
}

export const pixelToThree = {

  point: (position: PointParams, receiver = { x: 0, y: 0 }) => {
    let { x, y } = Point.ensure(position)
    const { threeRatio, size } = layoutObs.value
    x += -size.width / 2
    y += -size.height / 2
    x *= threeRatio
    y *= -threeRatio // reverse Y axis
    receiver.x = x
    receiver.y = y
    return receiver
  },

  rectangle: (rectangle: RectangleParams, receiver = new Rectangle()) => {
    const { threeRatio, size } = layoutObs.value
    let { x, y, width, height } = Rectangle.ensure(rectangle)
    x += -size.width / 2
    y += -size.height / 2
    x *= threeRatio
    y *= -threeRatio // reverse Y axis
    width *= threeRatio
    height *= threeRatio
    receiver.setDimensions(x, y, width, height)
    return receiver
  },
}