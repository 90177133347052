import { clamp01, inverseLerp, lerp, round } from 'some-utils/math'
import { deepGet } from 'some-utils/object'
import { globalData } from 'data'
import defaultLayoutSettings from 'config/defaultLayoutSettings.json'

export const computeBaseFontSize = (width: number) => {
  const { LayoutSettings: layoutSettings } = globalData.attributes

  const lerpFromViewport = deepGet(layoutSettings, 'fontSize.lerp.from.viewportWidth')
    ?? defaultLayoutSettings.fontSize.lerp.from.viewportWidth
  const lerpToViewport = deepGet(layoutSettings, 'fontSize.lerp.to.viewportWidth')
    ?? defaultLayoutSettings.fontSize.lerp.to.viewportWidth
  const lerpFromFont = deepGet(layoutSettings, 'fontSize.lerp.from.fontSize')
    ?? defaultLayoutSettings.fontSize.lerp.from.fontSize
  const lerpToFont = deepGet(layoutSettings, 'fontSize.lerp.to.fontSize')
    ?? defaultLayoutSettings.fontSize.lerp.to.fontSize

  const ratio = inverseLerp(lerpFromViewport, lerpToViewport, width)
  return round(lerp(lerpFromFont, lerpToFont, clamp01(ratio)), .1)
}
