import { FacebookIcon, InstagramIcon, LinkedinIcon, VimeoIcon } from 'assets/svg'
import { globalData } from 'data'
import { IconWrapper } from './IconWrapper'

export const FacebookButton = () => (
  <IconWrapper href={globalData.attributes.SocialFacebookUrl}>
    <FacebookIcon />
  </IconWrapper>
)

export const LinkedinButton = () => (
  <IconWrapper href={globalData.attributes.SocialLinkedInUrl}>
    <LinkedinIcon />
  </IconWrapper>
)

export const VimeoButton = () => (
  <IconWrapper href={globalData.attributes.SocialVimeoUrl}>
    <VimeoIcon />
  </IconWrapper>
)

export const InstagramButton = () => (
  <IconWrapper href={globalData.attributes.SocialInstagramUrl}>
    <InstagramIcon />
  </IconWrapper>
)

