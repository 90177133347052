import { strapi } from 'config'
import { StrapiComponent, StrapiComponentType } from 'config/types/strapi'
import { ContentError } from 'view/project/contents/ContentError'
import { GridComposer } from 'view/project/contents/GridComposer'
import { MediaGallery } from 'view/project/contents/MediaGallery'
import { MediaSingle } from 'view/project/contents/MediaSingle'
import { SectionAnchor } from 'view/project/contents/SectionAnchor'
import { Spacer } from 'view/project/contents/Spacer'
import { Text } from 'view/project/contents/Text'

export function Content({ item }: {
  item: StrapiComponent
}) {
  switch (item.__component) {
    case StrapiComponentType.Spacer: return (
      <Spacer
        content={item as strapi.Spacer} />
    )
    case StrapiComponentType.SectionAnchor: return (
      <SectionAnchor
        content={item} />
    )
    case StrapiComponentType.Text: return (
      <Text
        content={item as strapi.Text} />
    )
    case StrapiComponentType.MediaGallery: return (
      <MediaGallery
        content={item as strapi.MediaGallery} />
    )
    case StrapiComponentType.MediaSingle: return (
      <MediaSingle
        content={item as strapi.MediaSingle} />
    )
    case StrapiComponentType.GridComposer: return (
      <GridComposer
        content={item as strapi.GridComposer} />
    )
    default: return (
      <ContentError
        content={item}
        message='Not implemented' />
    )
  }
}
