import React from 'react'
import { ObservableNumber, ObservableObject } from 'some-utils/observables'
import { safeClassName, useComplexEffects, useObservable } from 'some-utils/npm/react'
import { clampModulo, floor, middleModulo, positiveModulo } from 'some-utils/math'
import { Animation } from 'some-utils/Animation'
import { handleClick, handleDrag } from 'some-utils/dom/handle-events'
import { strapi } from 'config'
import { layoutObs } from 'state/layout'
import { setLightbox } from 'state/lightbox/lightbox'
import { Media } from '../../components/Media'
import { cursorClassName } from 'view/cursor'

type SliderFrameState = { 
  mediaIndex: number
  active: boolean
  highlighted: boolean
  previousOne: boolean
  nextOne: boolean
}

const SliderFrame: React.FC<{
  frameState: ObservableObject<SliderFrameState>
  frameIndex: number,
  medias: strapi.Media[]
} & React.HTMLProps<HTMLDivElement>> = ({
  frameState,
  frameIndex,
  medias,
  ...props
}) => {
  
  const { mediaIndex, active, highlighted, previousOne, nextOne } = useObservable(frameState)
  const media = medias[mediaIndex]
  return (
    <div
      className={safeClassName(
        `SliderFrame SliderFrame-${frameIndex}`,
        highlighted && cursorClassName('Fullscreen'),
        previousOne && cursorClassName('Previous'),
        nextOne && cursorClassName('Next'),
        { active, highlighted, previous: previousOne, next: nextOne },
      )}
      data-frame-index={frameIndex} // debug purpose
      data-media-index={mediaIndex} // debug purpose
      {...props}
    >
      <Media
        debug={true}
        play={active}
        media={media} 
        disabled={!active}
        lightbox={active}
      />
    </div>
  )
}



export const Slider: React.FC<{
  medias: strapi.Media[]
  onChange?: (info: { index: number, media: strapi.Media }) => void
}> = ({
  medias,
  onChange,
}) => {

  const ref = React.useRef<HTMLDivElement>(null)

  const outerProps = React.useRef({
    onChange,
  })

  const core = React.useMemo(() => {
    // This is a tradeoff. 5 frames means that 3 are visible, 2 are hidden (1 left, 1 right).
    // It is important to have at least one image hidden on both side, since this
    // allow pre-loading (the images are loaded when coming into the viewport).
    const FRAME_COUNT = 5
    const FRAME_OFFSET = -2
    const MIN_MARGIN = -.2
    const sliderIndex = new ObservableNumber(0)
    const mediaIndex = new ObservableNumber(0)
    const getScroll = (frameIndex: number) => {
      return clampModulo(frameIndex - sliderIndex.value, MIN_MARGIN, MIN_MARGIN + FRAME_COUNT) + FRAME_OFFSET
    }
    const getVirtualIndex = (frameIndex: number) => {
      // here is the tricky part, calculate the "virtual" index from the current slider position
      const offset = FRAME_COUNT + sliderIndex.value - frameIndex + MIN_MARGIN
      return frameIndex + floor(offset, FRAME_COUNT) + FRAME_OFFSET
    }
    const getFrameMediaIndex = (frameIndex: number) => {
      return positiveModulo(getVirtualIndex(frameIndex), medias.length)
    }
    const frameStates = Array.from({ length: FRAME_COUNT })
      .map((_, frameIndex) => {
        const mediaIndex = getFrameMediaIndex(frameIndex)
        return new ObservableObject<SliderFrameState>({ 
          mediaIndex, 
          active: false,
          highlighted: false,
          previousOne: false,
          nextOne: false,
        })
      })
    
    const update = () => {
      mediaIndex.value = positiveModulo(Math.round(sliderIndex.value), medias.length)
      
      for (const [frameIndex, frameState] of frameStates.entries()) {
        const deltaIndex = middleModulo((frameIndex + FRAME_OFFSET) - sliderIndex.value, FRAME_COUNT)
        const active = Math.abs(deltaIndex) < .1
        const highlighted = Math.abs(deltaIndex) < .8
        frameState.updateValue({ 
          mediaIndex: getFrameMediaIndex(frameIndex), 
          active,
          highlighted,
          previousOne: Math.round(deltaIndex) === -1,
          nextOne: Math.round(deltaIndex) === 1,
        })
      }
    }
    update()

    // Navigation
    const tweenSliderIndex = (value: number, duration = .4) => {
      Animation.tween(sliderIndex, { duration }, {
        to: { value },
        ease: 'cubic-bezier(.3, 0, .3, 1)',
      })
    }
    const previous = (duration = .4) => tweenSliderIndex(Math.round(sliderIndex.value - 1), duration) 
    const next = (duration = .4) => tweenSliderIndex(Math.round(sliderIndex.value + 1), duration) 
    const gotoNearest = (duration = .4) => tweenSliderIndex(Math.round(sliderIndex.value), duration) 

    return {
      FRAME_COUNT,
      MIN_MARGIN,
      sliderIndex,
      mediaIndex,
      next,
      previous,
      gotoNearest,
      getScroll,
      getVirtualIndex,
      frameStates,
      update,
    }
    
  }, [medias])

  useComplexEffects(function* () {
    const div = ref.current!

    const getFrameDivs = () => [...div.querySelectorAll('div.SliderFrame')] as HTMLDivElement[]
    const getFrameLeft = () => layoutObs.value.responsive.gridPadding.left
    const getFrameWidth = () => layoutObs.value.columnWidth * Math.min(5, layoutObs.value.responsive.columnsCount)
    const getFrameStep = () => getFrameWidth() + getFrameLeft() / 2
    yield layoutObs.onChange(() => {
      for (const frame of getFrameDivs()) {
        frame.style.width = `${getFrameWidth()}px`
        frame.style.height = '100%'
        frame.style.left = `${getFrameLeft()}px`
      }
    }, { execute: true })

    yield handleDrag(div, {
      onDrag: s => core.sliderIndex.value += -s.deltaX / getFrameStep(),
      onDragEnd: () => core.gotoNearest(),
    })

    for (const [frameIndex, frame] of getFrameDivs().entries()) {
      yield handleClick(frame, {
        onClick: () => {
          const delta = core.getVirtualIndex(frameIndex) - core.sliderIndex.value
          if (delta === 0) {
            const ids = medias.map(m => m.id)
            const currentId = ids[core.mediaIndex.value]
            setLightbox(currentId, ids)
          }
          else if (delta > 0) {
            core.next()
          }
          else if (delta < 0) {
            core.previous()
          }
        }
      })
    }

    yield core.sliderIndex.onChange(() => {
      core.update()
      const step = getFrameStep()
      for (const [frameIndex, frame] of getFrameDivs().entries()) {
        const x = step * core.getScroll(frameIndex)
        frame.style.transform = `translateX(${x}px)`
      }
    }, { execute: true })

    yield core.mediaIndex.onChange((index) => {
      outerProps.current.onChange?.({ index, media: medias[index] })
    })

  }, [])

  return (
    <div 
      ref={ref}
      className='Slider flex column center'
      // style={{ border: 'solid 20  px red', transform: 'scale(.33)', color: 'black', fontSize: '40px' }}
    >
      {core.frameStates.map((frameState, index) => (
        <SliderFrame
          key={index}
          frameIndex={index}
          frameState={frameState}
          medias={medias}
        />
      ))}
    </div>
  )
}
