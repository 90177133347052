import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { endpoints } from 'config'
import { Reel } from 'config/types/strapi'
import { currentLocale } from 'state/Locale'
import { NewsCategoryNames } from './api'

export type MainGraphqlResponse = {
  reels: Reel[]
  newsCategories: NewsCategoryNames
  tags: {
    id: number
    tagName: string
    projects: { id: number, slug: string }[]
  }[]
  projectsOnHomeSlugs: string[]
}

export const apolloClient = new ApolloClient({
  uri: endpoints.graphql(),
  cache: new InMemoryCache(),
})

const newsCategoriesRequest = async () => {
  const result = await apolloClient.query({
    query: gql`
      query {
        newsCategories(locale: "${currentLocale}") {
          data {
            id
            attributes {
              Name
              NewsCategory
            }
          }
        }
      }
    `,
  })

  const map = result.data.newsCategories.data.reduce((acc: any, category: any) => {
    acc[category.attributes.NewsCategory] = category.attributes.Name
    return acc
  }, {}) as NewsCategoryNames

  return map
}

export const mainGraphqlRequest = async (): Promise<MainGraphqlResponse> => {
  // http://46.226.105.1:1338/graphql
  const result = await apolloClient.query({
    query: gql`
      query {
        reels {
          data {
            id
            attributes {
              reelTitle
              videoUrl
              reelCover {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        tags {
          data {
            id
            attributes {
              tagName
              projects(pagination: { limit: 1000 }) {
                data {
                  id
                  attributes {
                    slug
                  }
                }
              }
            }
          }
        }
        projectsOnHome {
          data {
            attributes {
              ProjectOnHomeEntry(pagination: { limit: 1000 }) {
                project {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  })

  const newsCategories = await newsCategoriesRequest()

  const reels = result.data.reels.data

  const projectsOnHomeSlugs =
    result.data.projectsOnHome.data.attributes.ProjectOnHomeEntry
      .map((p: any) => p.project.data.attributes.slug) as string[]

  const tags =
    result.data.tags.data
      .map((tag: any) => {
        const id = Number.parseInt(tag.id)
        const { tagName, projects } = tag.attributes
        return {
          id,
          tagName,
          projects: projects.data.map((project: any) => {
            const id = Number.parseInt(project.id)
            const { slug } = project.attributes
            return { id, slug }
          }),
        }
      }) as MainGraphqlResponse['tags']

  return {
    reels,
    newsCategories,
    tags,
    projectsOnHomeSlugs,
  }
}
