import * as THREE from 'three'

import {
  cancelContinuousAnimation,
  requestContinuousAnimation,
} from 'some-utils/npm/@react-three'
import { remap } from 'some-utils/math'
import { Destroyable } from 'some-utils/observables'
import { areEquivalent } from 'some-utils/object'

import { MediaSettings, defaultMediaSettings, strapi } from 'config'
import { getHomeProjects, globalData } from 'data'
import { layoutObs, responsiveObs } from 'state/layout'
import { currentRouteProject, routeObs } from 'state/navigation'
import { ensureMediaSettings, getImageSize, getVideoSize } from 'utils'
import { releaseTexture, requireTexture } from '../texture-manager'
import { fragmentShader, vertexShader } from './shaders'

type PlaneArgs = Partial<{
  index: number
  color: string
  parent: THREE.Object3D
  camera: THREE.PerspectiveCamera
}>

export class MediaPlaneMesh extends THREE.Mesh<
  THREE.BufferGeometry,
  THREE.ShaderMaterial
> {
  static geometry = new THREE.PlaneGeometry(10, 10, 10, 10)
  static zMax = 0.75
  static zMin = -0.75

  static computeScale(width: number, height: number, settings: MediaSettings) {
    const aspect = width / height
    const { resizeMode, scale } = settings
    const { aspect: threeAspect } = layoutObs.value
    const w = threeAspect / aspect
    const h = 1
    const ratio = resizeMode === 'cover' ? Math.max(w, h) : Math.min(h, w)
    return new THREE.Vector3(scale * ratio * aspect, scale * ratio, scale)
  }

  static #count = 0
  readonly #id = MediaPlaneMesh.#count++

  props = {
    initialIndex: 0,
    index: 0,
    projectId: -1,
    hasFocus: false,
    requestContinuousAnimationId: -1,
    routeListener: null! as Destroyable,
    responsiveListener: null! as Destroyable,
    camera: null! as THREE.PerspectiveCamera | undefined,
    offsetPosition: new THREE.Vector3(),
    offsetRotation: new THREE.Euler(),
    offsetScale: new THREE.Vector3(),
  }

  state = {
    homeScroll: 0,
    projectScroll: 0,
    coverVisibility: 1,
  }

  getTexture() {
    return this.material.uniforms.uMap.value
  }

  setTexture(value: THREE.Texture) {
    return (this.material.uniforms.uMap.value = value)
  }

  isVideo() {
    return this.material.uniforms.uMap.value?.image instanceof HTMLVideoElement
  }

  getVideoFromTexture() {
    return this.material.uniforms.uMap.value?.image as HTMLVideoElement
  }

  constructor({ color = 'white', index = 0, parent, camera }: PlaneArgs = {}) {
    super(
      MediaPlaneMesh.geometry,
      new THREE.ShaderMaterial({
        transparent: true,
        vertexShader,
        fragmentShader,
        uniforms: {
          uMap: { value: new THREE.Texture() },
          uColor: { value: new THREE.Color(color) },
          uVisibility: { value: 1 },
        },
        depthWrite: false,
      })
    )

    this.props.initialIndex = index
    this.props.index = index
    this.props.camera = camera
    this.props.routeListener = routeObs.onChange(() => this.#onIndexChange())
    this.props.responsiveListener = responsiveObs.onChange(() =>
      this.#onIndexChange()
    )

    this.#onIndexChange()

    parent?.add(this)
  }

  // NOTE: destroy() should be bind to 'this'
  destroy = () => {
    const { material } = this
    material.dispose()
    const { requestContinuousAnimationId, routeListener, responsiveListener } =
      this.props
    cancelContinuousAnimation(requestContinuousAnimationId)
    routeListener.destroy()
    responsiveListener.destroy()
    this.removeFromParent()
    for (const key in this.props) {
      // @ts-ignore
      delete this.props[key]
    }
  }

  setIndex(value: number) {
    if (this.props.index === value) {
      return
    }
    this.props.index = value
    this.#onIndexChange()
  }

  async #onIndexChange() {
    const { index } = this.props

    const routeProject = currentRouteProject()
    const homeProjects = getHomeProjects()
    const homeSplash = routeProject === null && index === 0
    const homeProjectIndex = index - 1
    const homeProjectIndexIsValid =
      homeProjectIndex >= 0 && homeProjectIndex < homeProjects.length
    const project =
      routeProject ??
      (homeProjectIndexIsValid ? homeProjects[homeProjectIndex] : null)
    const ready = homeSplash || !!project

    this.props.projectId = project?.id ?? -1

    if (ready) {
      const media =
        (homeSplash
          ? globalData.attributes.HomeCoverMedia
          : project!.attributes.coverMedia
        )?.data ?? null

      const mediaSettings = ensureMediaSettings(
        homeSplash
          ? globalData.mediaSettings[responsiveObs.value.columnsCount]
          : project!.mediaSettings[responsiveObs.value.columnsCount]
      )

      this.setMedia(media, mediaSettings)
    }

    this.updateVisibility()
  }

  _media: strapi.Media | null = null
  _mediaSettings: MediaSettings = defaultMediaSettings
  async setMedia(media: strapi.Media | null, mediaSettings: MediaSettings) {
    if (
      this._media === media &&
      areEquivalent(this._mediaSettings, mediaSettings)
    ) {
      return
    }
    this._media = media
    this._mediaSettings = mediaSettings

    releaseTexture(this, this.getTexture())

    if (media === null) {
      this.visible = false
      return
    }

    const texture = requireTexture(this, media.attributes.url)
    this.setTexture(texture)
    const { width, height } = await (texture instanceof THREE.VideoTexture
      ? getVideoSize(texture.image)
      : getImageSize(texture.image))

    // HACK: This is a hack, and a fix:
    // "setMedia" can be invoked twice for a same instance, and request resolution
    // may not respect the order of invocations (...). If so, the previous texture
    // may have been released. So use "src" to detect obsolete calls.
    if (!texture.image?.src) {
      return
    }

    const scale = MediaPlaneMesh.computeScale(width, height, mediaSettings)

    const z = remap(
      -1,
      1,
      MediaPlaneMesh.zMin,
      MediaPlaneMesh.zMax,
      mediaSettings.zOffset
    )
    const cameraPosition =
      this.props.camera?.position ?? new THREE.Vector3(0, 0, 5)
    const distance1 = new THREE.Vector3(0, 0, 0).distanceTo(cameraPosition)
    const distance2 = new THREE.Vector3(0, 0, z).distanceTo(cameraPosition)
    scale.multiplyScalar(distance2 / distance1)

    const { offsetPosition, offsetRotation, offsetScale } = this.props
    offsetPosition.set(mediaSettings.xOffset, mediaSettings.yOffset, z)
    offsetRotation.set(0, 0, (mediaSettings.zRotation * Math.PI) / 180)
    offsetScale.copy(scale)

    this.position.copy(offsetPosition)
    this.rotation.copy(offsetRotation)
    this.scale.copy(offsetScale)

    this.visible = true
    this.material.needsUpdate = true
  }

  updateIndex() {
    const { homeScroll } = this.state
    let { index } = this.props
    const margin = 0.5

    // NOTE: Previous there was not a while loop here, but a incremental branch
    // that cannot handle more than +3 or -3 shift.
    while (true) {
      const signedDistance = homeScroll - index
      if (signedDistance > 1 + margin) {
        index += 3
      } else if (signedDistance < -(1 + margin)) {
        index -= 3
      } else {
        break
      }
    }
    this.setIndex(index)
  }

  updateVerticalPosition() {
    const { homeScroll, projectScroll } = this.state
    const relativeScroll = homeScroll - this.props.index
    this.position.copy(this.props.offsetPosition)
    this.position.y += relativeScroll * 6 + projectScroll * 3
  }

  updateVisibility() {
    const { homeScroll, projectScroll, coverVisibility } = this.state
    const relativeScroll = homeScroll - this.props.index
    const uVisibility =
      (1 - Math.abs(relativeScroll * 2) - Math.abs(projectScroll * 2)) *
      coverVisibility
    this.material.uniforms.uVisibility.value = uVisibility
    this.visible = coverVisibility > 0
  }

  // Complicated because "dev" proof (same video used multiple times).
  videoRefCounter = new WeakMap<HTMLVideoElement, number>()
  videoMustPlay = false
  #updateVideoState() {
    const { homeScroll } = this.state
    const { index } = this.props
    const distance = Math.abs(homeScroll - index)
    const video = this.getVideoFromTexture()
    if (distance < 0.3 && this.videoMustPlay === false) {
      this.videoMustPlay = true
      this.props.requestContinuousAnimationId = requestContinuousAnimation()
      const count = this.videoRefCounter.get(video) ?? 0
      this.videoRefCounter.set(video, count + 1)
      document.body.append(video)
      video.play()
    }
    if (distance > 0.5 && this.videoMustPlay) {
      this.videoMustPlay = false
      cancelContinuousAnimation(this.props.requestContinuousAnimationId)
      const count = this.videoRefCounter.get(video)!
      this.videoRefCounter.set(video, count - 1)
      if (count === 0) {
        video.pause()
        video.remove()
      }
    }
  }

  update(homeScroll: number, projectScroll: number, coverVisibility: number) {
    Object.assign(this.state, { homeScroll, projectScroll, coverVisibility })
    this.updateIndex()
    this.updateVerticalPosition()
    this.updateVisibility()
    if (this.isVideo()) {
      this.#updateVideoState()
    }
  }
}
