import { Locale } from 'config'
import { allProjectsBySlug, api, onDataInitialized } from 'data'
import { Observable } from 'some-utils/observables'
import { getHash, getSearch, setUrl } from 'some-utils/router'
import { unifiedSearchState } from 'state/unified-search'
import { Route, isNewsRoute, isStaticRoute, splitPathname } from './routes'

export const {
  locale: initialLocale,
  route: initialRoute,
  slug: initialSlug,
} = splitPathname()

export const routeObs = new Observable<Route>(initialRoute)
export const localeObs = new Observable<Locale>(initialLocale)
export const projectObs = new Observable<api.Project | null>(null)

onDataInitialized.add(() => {
  // Once the data has arrived, init with the current project (if so).
  const { slug } = splitPathname()
  projectObs.setValue(slug ? allProjectsBySlug.get(slug)! : null)
})

export const navigate = (
  arg: Route | api.Project,
  { clearHash = false } = {}
) => {
  const resolveArg = (): [Route, api.Project | null] => {
    if (isStaticRoute(arg)) {
      return [arg, null]
    }
    if (isNewsRoute(arg)) {
      return [arg as string, null]
    }
    {
      const project =
        typeof arg === 'string'
          ? allProjectsBySlug.get(arg.replace(/^\//, ''))!
          : arg
      return [`/${project.slug}`, project]
    }
  }
  const [route, project] = resolveArg()

  // Hide "unified search" before any navigation
  unifiedSearchState.visibleObs.setValue(false)

  // NOTE: Order is very, very important here.
  // make route & project changes
  routeObs.setValue(route, { ignoreCallbacks: true })
  projectObs.setValue(project, { ignoreCallbacks: true })

  // update pathname
  const pathname = `/${localeObs.value}${routeObs.value}?${getSearch()}#${clearHash ? '' : getHash()}`
  setUrl(pathname)

  // then invoke route & project callbacks (if "has changed“)
  routeObs.triggerChangeCallbacks()
  projectObs.triggerChangeCallbacks()
}

// F*** important. Don't forget to update navigation on pop state.
window.addEventListener(
  'popstate',
  event => {
    event.preventDefault()
    const { route } = splitPathname()
    navigate(route)
  },
  { capture: true }
)

export const setLocale = (value: Locale) => {
  // update pathname
  const pathname = `/${value}${routeObs.value}`
  setUrl(pathname)
  window.location.reload()
}
