import React from 'react'
import ReactMarkdown from 'react-markdown'
import { strapi } from 'config'
import { onFrame } from 'state/time'
import { Animation } from 'some-utils/Animation'
import { useEffects } from 'some-utils/npm/react'
import { parentQuerySelector } from 'some-utils/dom/utils'
import { Observable } from 'some-utils/observables'
import { lerp, solveCubicEasing } from 'some-utils/math'
import { layoutObs, useResponsive } from 'state/layout'
import { HorizontalPadding } from 'view/components'
import { MediaCaption } from './MediaCaption'
import { Slider } from './MediaGallery-Slider'
import './css/MediaGallery.css'

type Props = {
  content: strapi.MediaGallery
  scrollInnerHeight?: number
  scrollOuterMargin?: number
}

const Body = ({
  content,
  scrollInnerHeight = 300,
  scrollOuterMargin = 100,
}: Props) => {

  const { ref: wrapperRef } = useEffects<HTMLDivElement>(function* (wrapper) {
    
    const parent = wrapper.parentElement!
    const maskWrapper = parentQuerySelector(wrapper, '.MaskWrapper')!

    // NOTE:
    // When 'scroll' is between 0 & 1, the gallery is "focus".
    // When 'scroll' < 0, the gallery is coming
    // When 'scroll' > 1, the gallery has passed
    const getScrollInfo = () => {
      const top = parent.offsetTop - maskWrapper.scrollTop - scrollOuterMargin
      const scroll = -top / (scrollInnerHeight + 2 * scrollOuterMargin)
      const below = scroll < 0
      const above = scroll > 1
      const focus = (below || above) === false
      const shouldScroll = above || below
      return { top, scroll, below, above, focus, shouldScroll }
    }

    parent.style.setProperty('--scroll-inner-height', `${scrollInnerHeight}px`)

    // state
    const shouldScroll = new Observable(true)
    
    const state = (() => {
      const {
        top,
        scroll,
      } = getScrollInfo()
      return {
        top,
        scroll,
        t: 0,
        u: 0,
      }
    })()

    const onWheel = (e: WheelEvent) => {
      // console.log(wrapper.parentElement)
      // parent.dispatchEvent()
    }
    wrapper.addEventListener('wheel', onWheel)
    yield () => wrapper.removeEventListener('wheel', onWheel)

    yield shouldScroll.withValue(value => {
      Animation.tween(state, {
        duration:  value ? 0 : .2,
        delay: value ? 0 : .05,
      }, {
        to: { u: value ? 0 : 1 },
        // ease: 'cubic-bezier(0.4, 0, 0.3, 1)',
      })
    })

    const update = () => {
      state.t += (state.u - state.t) * .1
      const t1 = solveCubicEasing(0.4, 0, 0.3, 1, state.t)
      const unfocusY = lerp(0, scrollInnerHeight, state.scroll)
      const y = lerp(unfocusY, -state.top - scrollOuterMargin, t1)
      const { width } = layoutObs.value.size
      // const scaleMin = (width - layout.value.currentRule.gridPadding.totalHorizontal * 0.25) / width
      const scaleMin = (width - layoutObs.value.responsive.gridPadding.totalHorizontal * 1) / width
      wrapper.style.top = `${y.toFixed(1)}px`
      wrapper.style.transform = `scale(${lerp(scaleMin, 1, state.t)})`
    }
    const onScroll = () => {
      const info = getScrollInfo()
      state.top = info.top
      state.scroll = info.scroll
      shouldScroll.setValue(info.shouldScroll)
      parent.classList.toggle('above', info.above)
      parent.classList.toggle('below', info.below)
      parent.classList.toggle('focus', info.focus)
      update()
    }
    maskWrapper.addEventListener('scroll', onScroll)
    onScroll()
    yield () => maskWrapper.removeEventListener('scroll', onScroll)
    yield onFrame.add(update)

  }, [scrollInnerHeight])

  const { top, bottom } = useResponsive().gridPadding

  const [state, setState] = React.useState({
    index: 0,
    media: content.medias.data[0],
  })

  return (
    <>
      <div ref={wrapperRef} className='Wrapper' style={{ paddingTop: `${top}px`, paddingBottom: `${bottom}px` }}>
        {content.header && (
          <HorizontalPadding className='Header' columnWidth={4}>
            <div>
              <ReactMarkdown>{content.header}</ReactMarkdown>
            </div>
          </HorizontalPadding>
        )}
        <Slider 
          medias={content.medias.data}
          onChange={({ index, media }) => setState({ index, media })}
        />
        <HorizontalPadding className='Footer' columnWidth={4}>
          <MediaCaption caption={content.caption ?? 'missing "caption"'} />
          {state.media.attributes.caption && (
            <MediaCaption caption={state.media.attributes.caption} />
          )}
        </HorizontalPadding>
        <div className='Counter' style={{ top: `calc(100% - ${bottom / 2}px)`}}>
          {state.index + 1} / {content.medias.data.length}
        </div>
      </div>
    </>
  )
}

export const MediaGallery = (props: Props) => {

  const error = (() => {
    if (props.content.medias.data === null) {
      return 'Media are null!'
    }
    return null
  })()

  return (
    <div className='ProjectContent MediaGallery flex'>
      {error ? (
        <div className='Error expand flex column center padding-32'>
          {error}
        </div>
      ) : (
        <Body {...props} />
      )}
    </div>
  )
}


