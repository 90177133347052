
import { PermanentReels } from './reels.permanent'
import { RegularReels } from './reels.regular'

import './reels.css'

export const showreel = {
  Regular: RegularReels,
  Permanent: PermanentReels,
}
