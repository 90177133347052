import { Animation } from 'some-utils/Animation'
import { lerp } from 'some-utils/math'
import { useEffects } from 'some-utils/npm/react'
import { getRouteIs, routeObs } from 'state/navigation'
import { coverVisibilityObs } from 'state/scroll'

const computeVisibility = (route: string) => {
  const { home, directProject } = getRouteIs(route)
  return home || directProject ? 1 : 0
}

/**
 * CoverVisibilityHandler is responsible to tween coverVisibilityObs between 
 * 0 & 1, according to the current route.
 */
export const CoverVisibilityHandler = () => {
  useEffects(function* () {
    // Initialization:
    coverVisibilityObs.value = computeVisibility(routeObs.value)

    // Transition:
    yield routeObs.onChange(value => {
      const from = coverVisibilityObs.value
      const to = computeVisibility(value)
      if (from !== to) {
        const duration = 1.2
        Animation.duringWithTarget(coverVisibilityObs, { duration }, ({ progress }) => {
          const alpha = Animation.getMemoizedEase('inout4')(progress)
          coverVisibilityObs.value = lerp(from, to, alpha)
        })
      }
    })
  }, [])
  return null
}
