
import { useEffects } from 'some-utils/npm/react'
import { Animation } from 'some-utils/Animation'

import { useDefaultTheme } from 'AppContext'
import { globalData } from 'data'
import { navigate, staticRoutes } from 'state/navigation'

import settings from './Route404.settings.json'
import './Route404.css'

export const Route404 = () => {
  useDefaultTheme()

  const { ref } = useEffects<HTMLDivElement>(function* (div, state) {
    const bar = div.querySelector('.Bar') as HTMLDivElement
    const duration = globalData.attributes.NotFoundSettings.delayBeforeRedirection ?? settings.delayBeforeRedirection
    Animation
      .duringWithTarget(div, duration, ({ progress }) => {
        bar.style.transform = `scaleX(${(1 - progress).toFixed(3)})`
        return state.isMounted() ? undefined : Animation.BREAK
      })
      .onComplete(() => {
        if (state.isMounted()) {
          navigate(staticRoutes.home)
        }
      })
    yield null
  }, [])

  const [line1, line2 = ''] = globalData.attributes.NotFound.split('\n')
  return (
    <div ref={ref} className='Route404 absolute-fill flex column center'>
      <div>
        {line1}
      </div>
      <div className='Bar' />
      <div>
        {line2}
      </div>
    </div>
  )
}
