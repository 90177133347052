import { FC, HTMLProps, useEffect, useRef } from 'react'

import { safeClassName } from 'some-utils/npm/react'

import { server, strapi } from 'config'
import { globalData } from 'data'
import { HoverFrame } from 'view/components'

import './Media.css'

export const Video: FC<HTMLProps<HTMLVideoElement> & {
  play: boolean
}> = ({
  play,
  ...props
}) => {
    const ref = useRef<HTMLVideoElement>(null)
    useEffect(() => {
      // Force play / pause.
      // Why? Ask React or HTML.
      const video = ref.current!
      video.muted = play
      video.autoplay = play
      if (play && video.paused) {
        video.play()
      }
      if (!play && !video.paused) {
        video.pause()
      }
    })
    return (
      <video
        playsInline
        ref={ref}
        {...props}
      />
    )
  }

export function Media(props: Partial<{
  media: strapi.Media | null
  disabled: boolean
  lightbox: boolean
  className: string
  onClick: () => void
  play: boolean
  loop: boolean
  debug: boolean
}>) {
  const {
    media: mediaProp,
    disabled,
    lightbox,
    className,
    play = false,
    loop = false,
    // debug = false,
    ...rest
  } = props
  const media = mediaProp ?? globalData.attributes.CoverMediaFallback.data
  const url = server + media.attributes.url
  return (
    <div
      className={safeClassName('Media', className, { disabled })}
      {...rest}
    >
      {lightbox && (
        <HoverFrame />
      )}
      {/video/.test(media.attributes.mime)
        ? <Video src={url} play={play} loop={loop} />
        : <img key={url} src={url} alt={media.attributes.alternativeText} />
      }
    </div>
  )
}
