
export const Spacing = ({
  size = 8,
  expand = false,
}: Partial<{
  size: number | string
  expand: boolean
}>) => {
  size = typeof size === 'number' ? `${size}px` : size
  return (
    <div
      className="Spacing"
      style={{
        width: size,
        height: size,
        alignSelf: 'stretch',
        flexGrow: expand ? 1 : 0,
        flexShrink: 0,
        flexBasis: size,
        pointerEvents: 'none',
      }}
    />
  )
}

