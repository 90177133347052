import { handleEvent } from 'some-utils/dom'
import { Message } from 'some-utils/message'
import { useEffects } from 'some-utils/npm/react'
import { clearHash, getPathname, setPathname } from 'some-utils/router'

import { useAppContext } from 'AppContext'
import { development, server } from 'config'
import { parseNewsCategory } from 'data/api'
import { apolloClient } from 'data/graphql'
import { localeObs, routeObs, staticRoutes, useLocale } from 'state/navigation'

import { useNews } from './context'
import { allNewsIds, allNewsPreviews } from './graphql'

export function closeOpenedNews() {
  Message.send('NEWS', 'CLOSE_OPENED_NEWS')
}

export function useNewsController() {
  const { themeObs } = useAppContext()
  const news = useNews()
  const locale = useLocale()
  useEffects(function* (_, state) {
    // Update theme:
    themeObs.setValue('light')

    // On route change (leaving), reset the state:
    yield routeObs.onChange(route => {
      if (route !== staticRoutes.news) {
        // news.observables.newsCategory.setValue(null)
        news.observables.newsPreviews.setValue([])
        news.observables.openedNews.setValue(null)
        clearHash()
      }
    })

    news.observables.newsCategory.setValue(null)

    function updateFromLocation() {
      const basePath = `/${localeObs.value}${staticRoutes.news}`
      const slug = getPathname().slice(basePath.length + 1)
      const hash = window.location.hash.slice(1)

      if (!!slug) {
        apolloClient
          .query({ query: allNewsIds({ slug, locale }) })
          .then(async result => {
            const first = result.data.allNews.data[0]
            if (first) {
              const { id } = first
              const response = await window.fetch(`${server}/api/all-news/${id}?populate=*`)
              const data = await response.json()
              news.observables.openedNews.setValue(data.data)
            }
          })
      }

      if (!hash) {
        // Reset the state if no hash is present:
        news.observables.newsCategory.setValue(null)
        news.observables.openedNews.setValue(null)
      }

      else {
        // Initialize "news category" or "opened news" from hash:

        // Look for a news category first:
        const newsCategory = parseNewsCategory(hash)
        news.observables.newsCategory.setValue(newsCategory)
        if (newsCategory !== null) {
        }

        // If no news category was found, look for a news by id:
        if (newsCategory === null) {
          apolloClient
            .query({ query: allNewsIds({ slug: hash, locale }) })
            .then(async result => {
              const first = result.data.allNews.data[0]
              if (first) {
                // Load the news by id:
                news.actions.loadNews(first.id)
              }
              else {
                // Reset the state if no news was found:
                news.observables.newsCategory.setValue(null)
                news.observables.openedNews.setValue(null)
              }
            })
        }
      }
    }

    yield handleEvent(window, {
      popstate() {
        updateFromLocation()
      },
    })

    updateFromLocation()

    function updateLocation() {
      const openedNews = news.observables.openedNews.value
      const newsCategory = news.observables.newsCategory.value
      if (openedNews !== null) {
        setPathname(`/${localeObs.value}/${staticRoutes.news}/${openedNews.attributes.Slug}`)
      }
      else if (newsCategory !== null) {
        window.location.hash = newsCategory.toLowerCase()
      }
      else {
        // Remove hash if no news category or news is opened,
        // but only if the hash is not empty (to avoid pushing a new state on "previous" button click):
        if (window.location.hash.slice(1) !== '') {
          window.history.pushState(null, '', window.location.pathname)
        }
      }
    }

    // ...then update hash on change:
    yield news.observables.newsCategory.onChange(updateLocation)
    yield news.observables.openedNews.onChange(updateLocation)

    async function update() {
      const newsCategory = news.observables.newsCategory.value
      const result = await apolloClient.query({
        query: allNewsPreviews({
          newsCategory,
          locale,
          debug: development,
        }),
      })

      // If the component is unmounted, don't update the state
      if (state.isMounted() === false) return

      // If the news category has changed, don't update the state
      // (a new request will be made with the new category)
      if (news.observables.newsCategory.value !== newsCategory) return

      const entries = result.data.allNews.data
      news.observables.newsPreviews.setValue(entries)
    }

    yield news.observables.newsCategory.onChange(update)

    yield Message.on('NEWS', 'CLOSE_OPENED_NEWS', () => {
      news.observables.openedNews.setValue(null)
    })

    update()
  }, [])
}