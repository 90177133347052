const WILDCARD_WEIGHT = 1
const RANGE_WEIGHT = 2
const EXACT_WEIGHT = 3

const evaluateKey = (columnCount: number, key: string): [match: boolean, weight: number] => {
  if (key === '*') {
    return [true, WILDCARD_WEIGHT]
  }
  const range = key.split('-').map(v => Number.parseInt(v))
  if (range.length === 2) {
    const [min, max] = range
    if (columnCount >= min && columnCount <= max) {
      return [true, RANGE_WEIGHT]
    } else {
      return [false, 0]
    }
  }
  const [single] = range
  if (columnCount === single) {
    return [true, EXACT_WEIGHT]
  }
  return [false, 0]
}

type ColumnsJson<T> = {
  columns: Record<string, T>
}

export type LayoutJson<T> = T | ColumnsJson<T>

/**
 * Solve json that are concerned by the number of columns of the layout. 
 */
export function solveLayoutJson<T>(columnCount: number, json: LayoutJson<T>): T | undefined
export function solveLayoutJson<T>(columnCount: number, json: any, fallbackJson: LayoutJson<T>): T
export function solveLayoutJson<T>(
  columnCount: number,
  json: LayoutJson<T>,
  fallbackJson?: LayoutJson<T>
): T | undefined {
  if (!json || typeof json !== 'object' || 'columns' in json === false) {
    return json as T
  }
  let result: T | undefined = undefined
  let resultWeight = 0
  for (const [key, value] of Object.entries((json as ColumnsJson<T>).columns)) {
    const [match, weight] = evaluateKey(columnCount, key)
    if (match && weight > resultWeight) {
      result = value
      resultWeight = weight
    }
  }
  if (result === undefined && fallbackJson) {
    return solveLayoutJson(columnCount, fallbackJson)!
  }
  return result
}
