import { isIOSStandalone } from 'config'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { Message } from 'some-utils/message'
import { useEffects } from 'some-utils/npm/react'

export function ReelTopViewer() {
  const [state, setState] = useState({
    playing: false,
    url: 'https://vimeo.com/850973784',
  })

  const stateRef = useRef(state)
  stateRef.current = state
  function updateState(newState: Partial<typeof state>) {
    setState({ ...stateRef.current, ...newState })
  }

  const playerRef = useRef<ReactPlayer>(null)

  useEffects(function* () {
    yield Message.on('REEL', 'PRELOAD', m => {
      const url: string = m.props.url
      updateState({ url })
    })

    yield Message.on('REEL', 'PLAY', () => {
      function play() {
        updateState({ playing: true })
        playerRef.current?.seekTo(0)
      }

      if (stateRef.current.playing) {
        updateState({ playing: false })
        window.requestAnimationFrame(() => {
          play()
        })
      } else {
        play()
      }
    })
  }, [])

  useEffect(() => {
    const onFullscreenChanged = (event: any) => {
      const fullscreen: boolean = event.fullscreen
      if (fullscreen === false) {
        updateState({ playing: false })
      }
    }
    const player = playerRef.current?.getInternalPlayer()
    player?.on('fullscreenchange', onFullscreenChanged)
    return () => {
      player?.off('fullscreenchange', onFullscreenChanged)
    }
  })

  return (
    <div
      className='ReelTopViewer absolute-fill flex center'
      style={{
        // visibility: 'hidden',
        zIndex: 100,
        visibility:
          isIOSStandalone() ? 'hidden' :
            (state.playing ? 'visible' : 'hidden'),
        backgroundColor: 'black',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          color: 'white',
          padding: '0.5rem',
          cursor: 'pointer',
          fontSize: '1.5rem',
          zIndex: 1,
        }}
        onClick={() => updateState({ playing: false })}
      >
        ╳
      </div>
      <ReactPlayer
        ref={playerRef}
        playing={state.playing}
        controls
        width='100%'
        height='100%'
        url={state.url}
      />
    </div>
  )
}
