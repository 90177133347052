import { RefObject, useMemo } from 'react'
import { handlePointer } from 'some-utils/dom'
import { Padding, PaddingParams, Rectangle } from 'some-utils/geom'
import { safeClassName, useChildrenBounds, useEffects } from 'some-utils/npm/react'
import { HitArea } from './HitArea'
import './Scrollbar.css'

const scrollBarProgress = (
  totalSpace: number,
  bar: number,
  startPadding: number,
  endPadding: number,
  progress: number,
) => (
  startPadding + (totalSpace - bar - startPadding - endPadding) * progress
)

export interface ScrollbarPublicState {
  setProgress: (t: number) => void
  onRequestProgress: (callback: (t: number) => void) => (() => void)
}

export const Scrollbar = (props: Partial<{
  direction: 'horizontal' | 'vertical'
  stateRef: RefObject<ScrollbarPublicState>,
  padding: PaddingParams
  zIndex: number
}>) => {
  const {
    direction = 'vertical',
    padding = 16,
    stateRef: publicStateRef,
    zIndex = 1,
  } = props

  const privateState = useMemo(() => ({
    progress: 0,
    callbacks: [] as ((t: number) => void)[],
    bounds: new Rectangle(),
    barBounds: new Rectangle(),
  }), [])

  const ref = useChildrenBounds<HTMLDivElement>('createRef', ['.Bar'], ([bounds, barBounds]) => {
    privateState.bounds.copy(bounds)
    privateState.barBounds.copy(barBounds)
  })

  useEffects(function* () {
    const { left, right, top, bottom } = Padding.ensure(padding)

    const div = ref.current!
    const bar = div.querySelector('.Bar') as HTMLElement

    const publicState: ScrollbarPublicState = {
      setProgress: progress => {
        privateState.progress = progress
        if (direction === 'horizontal') {
          const x = scrollBarProgress(privateState.bounds.width, privateState.barBounds.width, left, right, progress)
          bar.style.top = '0px'
          bar.style.left = `${x.toFixed(1)}px`
        } else {
          const y = scrollBarProgress(privateState.bounds.height, privateState.barBounds.height, top, bottom, progress)
          bar.style.left = '0px'
          bar.style.top = `${y.toFixed(1)}px`
        }
      },
      onRequestProgress: callback => {
        privateState.callbacks.push(callback)
        const destroy = () => {
          const index = privateState.callbacks.indexOf(callback)
          if (index !== -1) {
            privateState.callbacks.splice(index, 1)
          }
        }
        return destroy
      },
    }

    if (publicStateRef) {
      // @ts-ignore
      publicStateRef.current = publicState
    }

    publicState.setProgress(0)

    yield handlePointer(bar, {
      passive: false,
      onDown: event => {
        event.preventDefault()
        div.classList.add('grabbed')
      },
      onUp: () => {
        div.classList.remove('grabbed')
      },
      onDragStop: () => {
        div.classList.remove('grabbed')
      },
      onDrag: info => {
        let { progress } = privateState
        switch (direction) {
          case 'horizontal': {
            progress += info.delta.x / (privateState.bounds.width - privateState.barBounds.width - left - right)
            break
          }
          default:
          case 'vertical': {
            progress += info.delta.y / (privateState.bounds.height - privateState.barBounds.height - top - bottom)
            break
          }
        }
        for (const callback of privateState.callbacks) {
          callback(progress)
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, padding, publicStateRef, privateState.barBounds.height, privateState.barBounds.width, privateState.bounds.height, privateState.bounds.width])

  return (
    <div ref={ref} className={safeClassName('Scrollbar', direction)} style={{ zIndex }}>
      <div className='Bar'>
        <HitArea offset={64} />
      </div>
    </div>
  )
}
