export const vertexShader = /* glsl */ `
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`

export const fragmentShader = /* glsl */ `
  varying vec2 vUv;
  uniform vec4 uColor;
  uniform float uBlank;
  uniform float uOffset;
  uniform float uAlpha;
  void main() {
    if (mod(vUv.y * 40.0 + uOffset, 1.0) > 1.0 - uBlank) discard;
    gl_FragColor = uColor;
    gl_FragColor.a *= uAlpha;
  }
`
