// NOTE: This is concurrent with Timer.ts

import { clamp01, hermite01 } from 'some-utils/math'
import CallbackStack from 'utils/__old__/CallbackStack'

let frame = 0
let appTime = 0
let appTimeOld = 0
let appDeltaTime = 0

let autoPause = false
let autoPauseDelay = 6
let autoPauseOutroDuration = 1
let autoPauseTime = autoPauseDelay
let autoPauseTimeScale = 1

let time = 0
let timeOld = 0
let deltaTime = 0
let timeScale = 1

const resetAutoPause = (delay = autoPauseDelay) => autoPauseTime = delay

const info = Object.seal({
  frame,

  appTime,
  appTimeOld,
  appDeltaTime,
  
  autoPause,
  autoPauseTimeScale,
  
  time,
  timeOld,
  deltaTime,
  timeScale,
})

const updateInfo = () => Object.assign(info, {
  frame,

  appTime,
  appTimeOld,
  appDeltaTime,
  
  autoPause,
  autoPauseTimeScale,
  
  time,
  timeOld,
  deltaTime,
  timeScale,
})

type TimeInfo = typeof info

const onAppFrame = new CallbackStack<[TimeInfo]>()
const onFrame = new CallbackStack<[TimeInfo]>()

const update = (ms: number) => {

  frame++

  appTimeOld = appTime
  appTime = ms / 1000
  appDeltaTime = appTime - appTimeOld

  autoPauseTime += -appDeltaTime
  autoPauseTimeScale = hermite01(clamp01(autoPauseTime / Math.min(autoPauseOutroDuration, autoPauseDelay)))
  autoPause = autoPauseTimeScale === 0

  timeOld = time
  deltaTime = appDeltaTime * autoPauseTimeScale * timeScale
  time = time + deltaTime

  updateInfo()

  onAppFrame.call(info)

  if (!autoPause) {
    onFrame.call(info)
  }
}

const loop = (ms: number) => {
  update(ms)
  window.requestAnimationFrame(loop)
}

window.requestAnimationFrame(loop)

const listener = () => resetAutoPause()
window.addEventListener('pointermove', listener, { capture: true })
window.addEventListener('pointerdown', listener, { capture: true })
window.addEventListener('keydown', listener, { capture: true })
window.addEventListener('wheel', listener, { capture: true })
window.addEventListener('popstate', listener, { capture: true })

export type {
  TimeInfo,
}

export {
  frame,

  appTime,
  appTimeOld,
  appDeltaTime,

  autoPause,
  autoPauseTimeScale,

  time,
  timeOld,
  deltaTime,
  timeScale,

  info,
  onAppFrame,
  onFrame,

  resetAutoPause,
}