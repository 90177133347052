import { HTMLAttributes } from 'react'
import { useResponsive } from 'state/layout'
import { LayoutJson, solveLayoutJson } from 'utils/solveLayoutJson'
import './LayoutDiv.css'

export const LayoutDiv = ({
  layout,
  className = '',
  style = {}, 
  ...props
}: {
  layout: [any, LayoutJson<{ columnWidth: number} >]
} & HTMLAttributes<HTMLDivElement>) => {
  const { columnsCount } = useResponsive()
  const { columnWidth } = solveLayoutJson(columnsCount, ...layout)
  Object.assign(style, { '--layout-column-width': columnWidth })
  return (
    <div 
      {...props} 
      style={style}
      className={`LayoutDiv ${className}`}
    />
  )
}
