import copyToClipboard from 'copy-to-clipboard'
import { useState } from 'react'

import { Animation } from 'some-utils/Animation'
import { handleKeyboard, handlePointer, isParentOf } from 'some-utils/dom'
import { safeClassName, useEffects, useObservable } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'

import { server } from 'config'
import { getFilteredSortedCompanyInfos, globalData } from 'data'
import { useResponsive } from 'state/layout'
import { hideOvvoFooter, ovvoFooterOpeningObs } from 'state/ovvo-footer'
import * as UI from 'state/ui'
import { openSnackbar } from 'view/snackbar'
import { SvgWrapper } from './SvgWrapper'

import './OvvoFooter.css'

const visibilityObs = new ObservableNumber(ovvoFooterOpeningObs.value ? 1 : 0)
ovvoFooterOpeningObs.onChange(open => {
  if (open) {
    Animation.tween(visibilityObs, .5, {
      to: { value: 1 },
      ease: 'cubic-bezier(.2, 1, .5, 1)',
    })
  }
  else {
    Animation.tween(visibilityObs, .4, {
      to: { value: 0 },
      ease: 'cubic-bezier(.5, 0, .9, .75)',
      // ease: 'cubic-bezier(.3, 0, .2, 1)',
    })
  }
})

const Companies = ({
  selectedIndex = 0,
  onChangeIndexRequest = (id: number) => { },
}) => {
  return (
    <div className='Companies flex row center gutter-64 align-stretch text-center'>
      {getFilteredSortedCompanyInfos().map((company, index) => {
        return (
          <div
            key={index}
            className={safeClassName('Company button cursor-pointer flex column', {
              'ui-highlight disabled': index === selectedIndex,
              'ui-dim': index !== selectedIndex,
            })}
            onClick={() => onChangeIndexRequest(index)}
          >
            <div className='Location flex column gutter-8'>
              {company.attributes.companyDisplayLocation.split('\n').map((str, index) => (
                <span key={index}>{str}</span>
              ))}
            </div>
            <div className='space expand' />
            <SvgWrapper url={server + company.attributes.footerCompanyImage.data?.attributes.url} />
            {/* <LocationIcon type={company.attributes.companyIconId} /> */}
            <h3>{company.attributes.companyDisplayName}</h3>
          </div>
        )
      })}
    </div>
  )
}

const Lines = ({
  text = '',
  copyToClipboard: copyToClipboardProp = false,
}) => {
  const onClick = copyToClipboardProp ? (() => {
    copyToClipboard(text)
    openSnackbar(globalData.attributes.CopyToClipboard, 2)
  }) : undefined
  return (
    <div
      className={safeClassName('flex column center', copyToClipboardProp && 'button')}
      onClick={onClick}
    >
      {text.split(/\n/).map((line, index) => (
        <span key={index}>{line}</span>
      ))}
    </div>
  )
}

const Contact = ({ selectedIndex = 0 }) => {

  const company = getFilteredSortedCompanyInfos()[selectedIndex]
  return (
    <div className='Contact flex column'>

      <div className='Info flex row center gutter-48 text-center'>
        <Lines text={company.attributes.companyName} />
        <Lines text={company.attributes.companyAddress} />
        <Lines text={company.attributes.companyRegistration} />
        <Lines text={company.attributes.companyPhones} />
        <Lines copyToClipboard text={company.attributes.companyEmails} />
      </div>

      <div className='space-64' />

      <div className='flex row center'>
        <button
          className='button ui ui-uppercase ui-link ui-dim cursor-pointer link'
          onClick={() => {
            copyToClipboard(company.info())
            openSnackbar(globalData.attributes.CopyToClipboard, 2)
          }}>
          {'> Copier'}
        </button>
        <div className='space-64' />
        <a className='button ui ui-uppercase ui-link cursor-pointer' href={company.attributes.companyMap} target='blank'>
          {'> Google Maps'}
        </a>
      </div>
    </div>
  )
}

const Footer = () => {
  // force HUD opening
  UI.useSetHudOpeningTrue()

  const { gridPadding } = useResponsive()

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    const inner = div.querySelector('.Inner') as HTMLDivElement
    const background = div.querySelector('.Background') as HTMLDivElement
    yield visibilityObs.onChange(value => {
      background.style.opacity = value.toFixed(2)
      inner.style.opacity = value.toFixed(2)
      inner.style.transform = `translateY(${((1 - value) * 50).toFixed(1)}%)`
    })

    yield handlePointer(document.documentElement, {
      onDown: event => {
        const shouldHideOvvoFooter = (
          isParentOf(div, event.target) === false &&
          (event.target as HTMLElement).classList.contains('ovvo-footer-button') === false)
        if (shouldHideOvvoFooter) {
          hideOvvoFooter()
        }
      },
    })

    yield handleKeyboard({
      onDown: [['Escape', hideOvvoFooter]],
    })

  }, [])

  const [companyIndex, setCompanyIndex] = useState(0)

  return (
    <div ref={ref} className='OvvoFooter absolute-fill dark-theme'>
      <div className='Background absolute-fill' onClick={hideOvvoFooter} />
      <div className='Inner' style={{ paddingBottom: `${gridPadding.bottom / 2 - 8}px` }}>
        <Companies
          selectedIndex={companyIndex}
          onChangeIndexRequest={index => setCompanyIndex(index)} />
        <Contact
          selectedIndex={companyIndex} />
      </div>
    </div>
  )
}

export const OvvoFooter = () => {
  const visibility = useObservable(visibilityObs)
  return (visibility === 0
    ? null
    : <Footer />
  )
}