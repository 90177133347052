import { useContext } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { handleBounds } from 'some-utils/dom/bounds'
import { useEffects } from 'some-utils/npm/react'
import { RouteStateContext } from 'some-utils/npm/react/router'
import { ObservableNumber } from 'some-utils/observables'

import { useAppContext } from 'AppContext'
import { staticRoutes } from 'config'
import { getHomeProjects, globalData } from 'data'
import { useResponsive } from 'state/layout'
import { navigate } from 'state/navigation'
import { bindHomeScroll, homeScrollObs } from 'state/scroll'
import { hudOpening, ovvoLogoOpening } from 'state/ui'
import { DocumentTitle } from 'utils/document-title'
import { ScrollInvitation } from 'view/components'
import { ProjectSplash } from 'view/home/ProjectSplash'

import { autoScroll } from './autoScroll'
import { AutoScrollBar } from './AutoScrollBar'

import './Home.css'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function HomeSplashOld() {
  return (
    <section
      className='HomeSplash fullframe flex column center'
      style={{
        color: 'var(--ui-color)',
        padding: useResponsive().gridPadding.toCSS(),
      }}>
      <h1 className='Title'>
        {globalData.attributes.HomeCoverTitle}
      </h1>
      <h2 className='Subtitle'>
      </h2>
    </section>
  )
}

function ResponsiveTitle() {
  const { ref } = useEffects<HTMLHeadingElement>(function (h1) {
    const p = h1.querySelector('p') as HTMLParagraphElement
    handleBounds(p, bounds => {
      const ratio = p.offsetWidth / p.scrollWidth
      p.style.transformOrigin = 'bottom left'
      p.style.transform = `scale(${ratio})`
    })
  }, [])

  return (
    <h1 ref={ref} className='Title'>
      <ReactMarkdown>{globalData.attributes.HomeCoverTitleRich}</ReactMarkdown>
    </h1>
  )
}

function HomeSplash() {
  const { mobile } = useResponsive()
  return (
    <section
      className='HomeSplash fullframe flex column'
      style={{
        color: 'var(--ui-color)',
        padding: useResponsive().gridPadding.toCSS(),
      }}
    >
      <div>
        <ResponsiveTitle />

        <div style={{ height: mobile ? '2em' : '3em' }} />

        <div className='BigButtons'>
          <button
            onClick={() => navigate(staticRoutes.news, { clearHash: true })}
          >
            {globalData.attributes.MenuNameNews ?? 'News'}
          </button>

          <button
            onClick={() => navigate(staticRoutes.searchProjects, { clearHash: true })}
          >
            {globalData.attributes.MenuNameProject ?? 'Projects'}
          </button>

          <button
            onClick={() => navigate(staticRoutes.reels, { clearHash: true })}
          >
            {globalData.attributes.MenuNameReels ?? 'Reels'}
          </button>
        </div>
      </div>
    </section>
  )
}

export const Home = () => {
  const { statusObs } = useContext(RouteStateContext)
  const { themeObs } = useAppContext()
  const { ref } = useEffects<HTMLDivElement>(function* (div) {

    themeObs.setValue('dark')

    const hudOpeningChild = hudOpening.createChild(true)
    yield hudOpeningChild

    const ovvoLogoOpeningChild = ovvoLogoOpening.createChild(false)
    yield ovvoLogoOpeningChild

    const homeInfluenceObs = new ObservableNumber(1)
    yield statusObs.onChange(status => {
      homeInfluenceObs.setValue(status === 'visible' ? 1 : 0)
    })

    yield homeScrollObs.withValue(scroll => {
      div.scrollTop = scroll * div.offsetHeight
      hudOpeningChild.setValue(scroll < .5)
      ovvoLogoOpeningChild.setValue(scroll < .5)
    })

    yield homeScrollObs.withStepValue(1, stepValue => {
      if (stepValue > 0) {
        const project = getHomeProjects()[stepValue - 1]
        if (!project) {
          debugger
        }
        themeObs.setValue(project.theme())
      }
    })

    yield* bindHomeScroll(div, homeInfluenceObs)

    yield* autoScroll()

  }, [])

  return (
    <div
      ref={ref}
      className='Home absolute-fill'
    >
      <DocumentTitle noPrefix title={globalData.attributes.HomeTabTitle ?? 'OVVO Studio'} />
      <main>
        <HomeSplash />
        {getHomeProjects().map((project, index) => (
          <ProjectSplash key={index} project={project} />
        ))}
      </main>
      <ScrollInvitation
        scrollObs={homeScrollObs}
        scrollThreshold={.01}
      />
      <AutoScrollBar />
    </div>
  )
}



