import { useMemo } from 'react'
import { useRefComplexEffects } from 'some-utils/npm/react'
import { Observable, ObservableNumber } from 'some-utils/observables'
import { getItemIndexesFromPool } from 'some-utils/misc/pooling'
import { clamp01, easings, lerp } from 'some-utils/math'
import { api } from 'data'
import { layoutObs } from 'state/layout'
import { Cover } from './Cover'
import './SliderPool.css'

const limited = (x: number, max: number) => x * max / (x + max)
const limitedClamp = (x: number, min: number, max: number, margin: number) => {
  if (x < min) {
    const d = min - x
    return min - limited(d, margin)
  }
  if (x > max) {
    const d = x - max
    return max + limited(d, margin)
  }
  return x
}

const slideCount = 5

/**
 * Create a small pool of children (up to 5) to avoid to render too many ones.
 * The pool optimization technique is directly based on React optimization.
 * When the position shifts over a certain amount, the children a re-created. 
 * Over 5 children, one child is destroyed (unmount), one other is created (mount).
 * 3 are re-used as they are.
 */
export const SliderPool = ({
  position = new ObservableNumber(0),
  projects = [] as api.Project[],
}) => {

  const projectObservables = useMemo(() => (
    [...getItemIndexesFromPool(projects.length, slideCount, 0)]
      .map(projectIndex => new Observable(projects[projectIndex]))
  ), [projects])

  const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {

    const children = [...div.children] as HTMLDivElement[]

    const update = () => {
      const roundPosition = Math.round(position.value)
      const limitedPosition = limitedClamp(position.value, 0, projects.length - 1, .75)
      const newIndexes = [...getItemIndexesFromPool(projects.length, slideCount, roundPosition)]
      for (const [index, projectIndex] of newIndexes.entries()) {
        projectObservables[index].setValue(projects[projectIndex])
        const child = children[index]
        const delta = projectIndex - limitedPosition
        const opacity = easings.inout3(clamp01(1 - Math.abs(delta)))
        const x = easings.signedOut2(delta) * 300
        const scale = lerp(.9, 1, easings.out4(clamp01(1 - Math.abs(delta))))
        child.classList.toggle('pointer-disabled', Math.abs(delta) > .1)
        child.style.transform = `translateX(${x}px) scale(${scale.toFixed(3)})`
        child.style.opacity = opacity.toFixed(2)
      }
    }

    yield position.withValue(update)
    yield layoutObs.onChange(update)

  }, [projects])

  return (
    <div ref={ref} className='SliderPool expand'>
      {projectObservables.map((project, index) => (
        <Cover
          key={index}
          projectObservable={project} />
      ))}
    </div>
  )
}
