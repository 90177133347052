import { sRGBEncoding, Texture, TextureEncoding, TextureLoader, Wrapping } from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'

const textureLoader = new TextureLoader()
const exrLoader = new EXRLoader()

const cache = new Map<string, Texture>()
const createEntry = (key: string, options: TextureOptions, texture: Texture) => {
  applyTextureOptions(texture, options)
  cache.set(key, texture)
  return texture
}

type TextureOptions = Partial<{
  wrap: Wrapping
  wrapS: Wrapping
  wrapT: Wrapping
  repeat: number
  repeatU: number
  repeatV: number
  encoding: TextureEncoding
}>

const applyTextureOptions = (texture: Texture, {
  wrap,
  wrapS = wrap ?? texture.wrapS,
  wrapT = wrap ?? texture.wrapT,
  repeat,
  repeatU = repeat ?? texture.repeat.x,
  repeatV = repeat ?? texture.repeat.y,
  encoding = sRGBEncoding,
}: TextureOptions = {}) => {
  texture.wrapS = wrapS
  texture.wrapT = wrapT
  texture.repeat.set(repeatU, repeatV)
  texture.encoding = encoding
}

export const getTexture = (
  url: string, 
  options: TextureOptions = {},
  onLoad?: (texture: Texture) => void,
) => {
  const key = url + Object.keys(options)
    .sort((a, b) => a < b ? -1 : 1)
    .map(key => `${key}:${options[key as keyof TextureOptions]}`)
    .join('')
  if (cache.has(key)) {
    return cache.get(key)!
  }
  const _onLoad = (texture: Texture) => {
    applyTextureOptions(texture, options)
    onLoad?.(texture)
  }
  if (url.endsWith('.exr')) {
    return createEntry(key, options, exrLoader.load(url, _onLoad))
  }
  return createEntry(key, options, textureLoader.load(url, _onLoad))
}
