import React from 'react'
import { api } from 'data'
import { navigate } from 'state/navigation'
import { ProjectCover } from 'view/project/ProjectCover'

export const ProjectSplash: React.FC<{
  project: api.Project
}> = ({
  project,
}) => {
  return (
    <section className='ProjectSplash fullframe'>
      <ProjectCover
        hideDescription
        onTap={() => navigate(project)}
        project={project} />
    </section>
  )
}
