import { useRef } from 'react'

import { StrapiComponent } from 'config/types/strapi'
import { newsCategoriesNames } from 'data'
import { NewsFull } from 'data/api'
import { useLocale } from 'state/navigation'
import { Scrollbar, ScrollbarPublicState, Spacing } from 'view/components'
import { Media } from 'view/components/Media'

import { Content } from './Content'
import { shorthMonths } from './shorthMonths'

import { useAppContext } from 'AppContext'
import { useEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { SolidFadeIn } from './SolidFadeIn'

import './full-page.css'

function NewsCover({
  news,
}: {
  news: NewsFull
}) {
  const theme = news.attributes.CoverTheme ?? 'light'
  const [year, month, day] = news.attributes.Date.split('-')
  return (
    <div className={`Cover flex column grid-padding ${theme}-theme`}>
      <div className='absolute-fill'>
        <Media
          play
          loop
          media={news.attributes.Cover.data ?? news.attributes.Thumbnail.data} />
      </div>

      <div className='Wrapper flex column'>
        <div className='flex column'>
          <div className='NewsCategory uppercase'>
            {newsCategoriesNames[news.attributes.NewsCategory]}
          </div>
          <div className='Date'>
            {day} {shorthMonths[Number.parseFloat(month) - 1][useLocale()].toUpperCase()} {year.slice(-2)}
          </div>
        </div>

        <div style={{ flex: '0 1 4em' }} />

        <div className='flex column'>
          <h1 className='Title uppercase title-shadow'>
            {news.attributes.Title}
          </h1>
          <div className='Subtitle'>
            {news.attributes.Subtitle}
          </div>
          <div style={{ flex: '0 1 2em' }} />
          <p className='Description'>
            {news.attributes.Description}
          </p>
        </div>
      </div>
    </div>
  )
}

function interpose<T = any>(arr: T[], sep: (index: number) => T): T[] {
  return arr.reduce((acc, item, index) => {
    if (index !== 0) {
      acc.push(sep(index - 1))
    }
    acc.push(item)
    return acc
  }, [] as T[])
}

function Contents({
  contents,
}: {
  contents: StrapiComponent[]
}) {
  const nodes = interpose(
    contents
      .filter(item => item.visibility && item.visibility !== 'visible' ? false : true)
      .map((item, index) => <Content key={index} item={item} />),
    index => <Spacing size={8} key={`sep-${index}`} />)

  return (
    <div className='flex column'>
      <div style={{ height: '12em' }} />
      {nodes}
      <div style={{ height: '12em' }} />
    </div>
  )
}

export function NewsFullPage({
  news,
}: {
  news: NewsFull
}) {
  const { themeObs } = useAppContext()
  const scrollbarState = useRef<ScrollbarPublicState>(null)

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    const scrollObs = new ObservableNumber(0)
    const scrollProgressObs = new ObservableNumber(0)

    function update() {
      scrollObs.setValue(wrapper.scrollTop)
      scrollProgressObs.setValue(wrapper.scrollTop / (wrapper.scrollHeight - wrapper.clientHeight))
    }

    const wrapper = div.querySelector('.Wrapper') as HTMLElement
    wrapper.onscroll = () => {
      update()
    }

    // Theme: "ménager la chèvre et le chou"
    // - on first 100px of scroll, use the theme of the cover
    // - after that, use the light theme
    const previous = themeObs.value
    yield scrollObs.withValue(scroll => {
      const threshold = div.clientHeight * .33
      if (scroll < threshold) {
        const theme = news.attributes.CoverTheme ?? 'light'
        themeObs.setValue(theme)
      } else {
        themeObs.setValue('light')
      }
    })
    yield () => themeObs.setValue(previous)

    yield scrollProgressObs.withValue(progress => {
      scrollbarState.current?.setProgress(progress)
    })

    yield scrollbarState.current?.onRequestProgress(progress => {
      wrapper.scrollTop = progress * (wrapper.scrollHeight - wrapper.clientHeight)
      update()
    })

  }, [])

  return (
    <div ref={ref} className='NewsFullPage'>
      <Scrollbar stateRef={scrollbarState} />
      <div className='Wrapper absolute-fill'>
        <SolidFadeIn theme={news.attributes.CoverTheme ?? 'dark'} />
        <NewsCover news={news} />
        {news.attributes.Contents?.length > 0 && (
          <Contents
            contents={news.attributes.Contents} />
        )}
      </div>
    </div>
  )
}
