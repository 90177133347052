import { Value, PropertyMeta, BasicValue } from './property'

export enum DetailedPropertyDeclarationStatus {
  Valid,
  InvalidBadConstructor,
  InvalidPrimitive
}

export const getDetailedPropertyDeclarationStatus = (value: any) => {
  if (typeof value !== 'object') {
    return DetailedPropertyDeclarationStatus.InvalidPrimitive
  }
  if (value.constructor !== Object) {
    return DetailedPropertyDeclarationStatus.InvalidBadConstructor
  }
  return DetailedPropertyDeclarationStatus.Valid
}

export const isDetailedPropertyDeclaration = (value: any): value is DetailedPropertyDeclaration => {
  return getDetailedPropertyDeclarationStatus(value) === DetailedPropertyDeclarationStatus.Valid
}

export type DetailedPropertyDeclaration = { value: Value}  & PropertyMeta

export type PropertyDeclaration = DetailedPropertyDeclaration | BasicValue
