/**
 * SVG factory. Create svg element in an easy way.
 */
export const svgf = (target: string | SVGElement, {
  className,
  parent,
  innerHTML,
  ...props
}: Partial<{
  parent: SVGElement | null
  className: string
  innerHTML: string
}> & Record<string, any>) => {
  const element = typeof target === 'string'
    ? document.createElementNS('http://www.w3.org/2000/svg', target)
    : target
  if (parent !== undefined) {
    if (parent !== null) {
      parent.append(element)
    } else {
      element.remove()
    }
  }
  if (innerHTML !== undefined) {
    element.innerHTML = innerHTML
  }
  if (className !== undefined) {
    for (const token of className.trim().split(/\s+/)) {
      element.classList.add(token)
    }
  }
  for (const key in props) {
    element.setAttributeNS(null, key, props[key])
  }
  return element
}
