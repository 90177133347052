import React from 'react'
import { RootState, useThree } from '@react-three/fiber'
import * as Time from './Time'

export const useTime = (cb: (time: Time.TimeInfo) => void) => {
  React.useEffect(() => {
    return Time.onFrame.add(time => {
      cb(time)
    }).destroy
  })
}

export const useTimeAndThree = (cb: (time: Time.TimeInfo, three: RootState) => void) => {
  const three = useThree()
  React.useEffect(() => {
    return Time.onFrame.add(() => {
      cb(Time.info, three)
    }).destroy
  })
}
