import { useDefaultTheme } from 'AppContext'
import { getFilteredSortedCompanyInfos, globalData, studiosPage } from 'data'
import { useMemo } from 'react'
import { useEffects } from 'some-utils/npm/react'
import { layoutObs } from 'state/layout'
import { DocumentTitle } from 'utils/document-title'
import { Markdown, ScrollInvitation } from 'view/components'
import { handleScroll } from '../../scroll/handleScroll'
import { CompanySectionJune23 } from './CompanySectionJune23'
import { LayoutDiv } from './LayoutDiv'
import './Studios.css'
import './Studios.mobile.css'
import defaultDescriptionLayout from './defaultDescriptionLayout.json'
import { playVideo } from './gl/material'
import { scrollObs } from './scroll'

const Intro = () => {
  return (
    <div className='Intro fullframe grid-padding flex column center'>
      <LayoutDiv className='flex column' layout={[studiosPage.attributes.descriptionLayout, defaultDescriptionLayout]}>
        <h1>
          <Markdown content={studiosPage.attributes.title} />
        </h1>
        <div className='Spacing' />
        <Markdown content={studiosPage.attributes.description} />
      </LayoutDiv>
    </div>
  )
}

export const Studios = () => {
  useDefaultTheme()
  const scrollHandler = useMemo(() => handleScroll(scrollObs), [])
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    yield layoutObs.withValue(({ baseFontSize }) => {
      div.style.fontSize = `${baseFontSize}rem`
    })
    yield scrollHandler.bind(div)
    yield playVideo(studiosPage.attributes.BackgroundVideoUrl)
  }, [])
  return (
    <div className='Studios absolute-fill'>
      <DocumentTitle title={globalData.attributes.MenuNameStudio} />
      <div ref={ref} className='absolute-fill'>
        <Intro />
        {/* {getFilteredSortedCompanyInfos().map((info, index) => (
          <CompanySection
            key={index}
            info={info}
            reversedLayout={index % 2 === 1}
          />
        ))} */}
        {getFilteredSortedCompanyInfos().map((info, index) => (
          <CompanySectionJune23
            key={index}
            info={info}
          />
        ))}
      </div>
      <ScrollInvitation
        scrollObs={scrollObs}
        label={studiosPage.attributes.scrollButton}
      />
    </div>
  )
}