import { useMemo } from 'react'
import { handleEvent, handleKeyboard, handlePointer } from 'some-utils/dom'
import { clamp, round } from 'some-utils/math'
import { useRefComplexEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { ValueFormat } from '../types'
import { formatValue } from './utils'

export const NumberInput = ({
  initialValue = 0,
  step = 1,
  min = -Infinity,
  max = Infinity,
  integer = false,
  format = (integer ? 'Integer' : 'F1') as ValueFormat,
  stepped = false,
  onChange = (value: number) => {},
  // debug = false,
}) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValueObs = useMemo(() => new ObservableNumber(initialValue), [])
  initialValueObs.setValue(initialValue)

  const ref = useRefComplexEffects<HTMLInputElement>(function* (input) {

    const getStep = (event: KeyboardEvent | PointerEvent | TouchEvent) => {
      return step * (event.shiftKey ? 10 : 1) * (event.altKey ? .1 : 1)
    }

    let inner = initialValue
    let outer = inner

    const updateOuter = () => {
      const previous = outer
      outer = inner
      if (stepped) {
        outer = round(outer, step)
      }
      if (integer) {
        outer = Math.round(outer)
        outer = clamp(outer, min, max)
      }
      const hasChanged = previous !== outer
      if (hasChanged) {
        input.value = formatValue(outer, format)
        onChange(outer)
      }
    }

    // Update from initial value changes. 
    // It is based on observed changes between a captured initial value and the 
    // fresh one received. Quite tricky.
    // Hmm...
    yield initialValueObs.withValue(value => {
      inner = value
      outer = value
      input.value = formatValue(outer, format)
    })

    const shiftInner = (delta: number) => {
      inner += delta
      inner = clamp(inner, min, max)
      updateOuter()
    }

    const updateInner = (inputValue: string) => {
      inner = Number.parseFloat(inputValue)
      if (Number.isNaN(inner)) {
        inner = initialValue
      }
      if (integer) {
        inner = Math.round(inner)
      }
      inner = clamp(inner, min, max)
      updateOuter()
    }

    yield handleKeyboard({
      element: input,
      onDown: [
        [['ArrowDown', 'ArrowUp'], { priority: 1000 }, info => {
          info.event.preventDefault()
          info.capture()
          const direction = info.event.code === 'ArrowUp' ? 1 : -1
          shiftInner(getStep(info.event) * direction)
        }],
        [['Escape', 'Enter'], () => {
          input.blur()
          input.disabled = true
          updateInner(input.value)
        }]
      ],
    })

    yield handlePointer(input, {
      onTap: () => {
        input.disabled = false
        input.focus()
      },
      dragDamping: 1,
      dragDistanceThreshold: 1,
      onHorizontalDrag: info => {
        const delta = info.delta.x / 10 * getStep(info.moveEvent)
        shiftInner(delta)
        input.blur()
        document.getSelection()?.empty()
      },
    })

    yield handleEvent(input, {
      input: event => {
        const str = (event.target as HTMLInputElement).value
        updateInner(str)
      }
    })

  }, [step, min, max])

  return (
    <input
      className='NumberInput expand'
      ref={ref}
      type="text"
      disabled
    />
  )
}
