import { newsCategoriesNames } from 'data'
import { NewsCategory } from 'data/api'
import { handlePointer } from 'some-utils/dom'
import { useEffects } from 'some-utils/npm/react'
import { Observable } from 'some-utils/observables'
import { useNews } from './context'

export function NewsHeader() {
  const news = useNews()

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    const hoveredObs = new Observable<NewsCategory | null>(null)
    const children = [...div.children as unknown as HTMLDivElement[]]
    for (const child of children) {
      yield handlePointer(child, {
        onEnter: () => {
          hoveredObs.setValue(child.dataset.newsCategory as NewsCategory)
        },
        onLeave: () => {
          hoveredObs.setValue(null)
        }
      })
    }

    function update() {
      const hovered = hoveredObs.value
      const newsCategory = news.observables.newsCategory.value
      for (const child of children) {
        const dim = hovered
          ? child.dataset.newsCategory !== hovered
          : (news.observables.newsCategory.value
            ? child.dataset.newsCategory !== newsCategory
            : false)

        child.classList.toggle('dim', dim)
      }
    }

    yield hoveredObs.onChange(() => {
      update()
    })

    yield news.observables.newsCategory.onChange(() => {
      update()
    })

    update()
  }, [])

  return (
    <div ref={ref} className='NewsHeader flex row center ui ui-uppercase' style={{ gap: '3em' }}>
      {Object.entries(newsCategoriesNames).map(([key, value]) => (
        <div
          key={key}
          data-news-category={key}
          className='button'
          onClick={() => {
            if (news.observables.newsCategory.value === key) {
              news.observables.newsCategory.setValue(null)
            } else {
              news.observables.newsCategory.setValue(key as NewsCategory)
            }
          }}
        >
          {value}
        </div>
      ))}
    </div>
  )
}
