import { defaultMediaSettings } from 'config'
import { Media } from 'config/types/strapi'
import * as Data from 'data'
import { getHomeProjects } from 'data'
import { MetaballSettings } from 'scroll'
import { LazyRecord } from 'some-utils/collections'

type ScrollSettings = {
  isHome: boolean
  media: Media | null
  metadata: any
  mediaSettings: any
  metaballSettings: Partial<MetaballSettings>
  rawAtt: any
}

const coverSettings = new LazyRecord<number, ScrollSettings>(index => {
  if (index === 0) {
    const att = Data.globalData.attributes
    const media = att.HomeCoverMedia.data
    const mediaSettings = att.CoverSettings?.media ?? {}
    const metaballSettings = (att.CoverSettings?.metaball ?? {}) as Partial<MetaballSettings>
    metaballSettings.envMapUrl = att.HomeCoverMetaballsEnv.data?.attributes.url ?? ''
    const metadata = { isHome: true, id: -1, slug: '(home)', ...att.CoverSettings?.metadata }
    return { isHome: true, media, mediaSettings, metaballSettings, rawAtt: att as any, metadata }
  }
  else {
    const projects = getHomeProjects()
    const projectIndex = Math.min(index - 1, projects.length - 1)
    const project = projects[projectIndex]
    const att = project.attributes
    const media = att.coverMedia.data
    const mediaSettings = att.coverSettings?.media ?? defaultMediaSettings
    const metaballSettings = (att.coverSettings?.metaball ?? {}) as Partial<MetaballSettings>
    const metadata = { isHome: false, ...att.coverSettings?.metadata }
    return { isHome: false, media, mediaSettings, metaballSettings, rawAtt: att as any, metadata }
  }
})

export const getScrollSettings = (index: number) => coverSettings.get(index)
