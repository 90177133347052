import { SectionOptions, SectionParams, Property, PropertyDeclaration, PropsMeta, Section, getValueType, SectionBundle, DetailedPropertyDeclaration, isDetailedPropertyDeclaration } from '../types'
import { createProperty, extractProperties } from './properties'

export const sections = new Map<string, Section>()
export let storeDirtyTimestamp = window.performance.now()

export const createSectionBundle = <T extends Record<string, PropertyDeclaration>>(
  params: SectionParams,
  props: T,
  options: SectionOptions<T> = {},
): SectionBundle<T> => {

  const {
    propsMeta = {} as PropsMeta<T>,
    valueMapper = [],
  } = options

  const properties = new Map<string, Property>()

  for (const key in props) {
    const source = props[key]
    const prop = (isDetailedPropertyDeclaration(source) ? source : { value: source }) as DetailedPropertyDeclaration
    const meta = propsMeta[key]
    const mapper = valueMapper.find(mapper => mapper.condition(prop.value, key))
    const property = createProperty(key, {  ...meta, ...prop }, mapper)
    properties.set(key, property)
  }

  const onChangeCallbacks = new Set<() => void>()
  const onChange = (callback: () => void) => {
    onChangeCallbacks.add(callback)
    const destroy = () => {
      onChangeCallbacks.delete(callback)
    }
    return { destroy }
  }
  const onChangeCallbacksCall = () => {
    for (const callback of onChangeCallbacks) {
      callback()
    }
  }

  storeDirtyTimestamp = window.performance.now()

  const bundle: SectionBundle<T> = {
    getProperty: key => properties.get(key)!.extractValue(),
    getProperties: () => extractProperties(properties),
    updateProperties: newProperties => {
      for (const key in newProperties) {
        const property = properties.get(key)

        if (!property) {
          console.warn(`Unknown property "${key}"`)
          continue
        }

        const value = newProperties[key]
        const type = getValueType(value)

        if (property.type !== type) {
          console.warn(`Value type error for "${key}". "${property.type}" vs "${type}(${value.constructor.name})"`)
          continue
        }

        property.updateValue(value)
      }
    },
    onChange,
    get properties() { return bundle.getProperties() },
  }

  const section: Section<T> = {
    bundle,
    params,
    properties,
    onUserChange: onChangeCallbacksCall,
  }

  sections.set(params.name!, section)

  return bundle
}
