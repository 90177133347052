import { hermite01, inout2, inout3, inout4, inout5 } from 'some-utils/math'
import { EaseFunction } from './types'

export const easeOptions: Record<string, EaseFunction> = {
  linear: x => x,
  smooth: hermite01,
  inout2,
  inout3,
  inout4,
  inout5,
  out2: x => 1 - (x = 1 - x) * x,
  out3: x => 1 - (x = 1 - x) * x * x,
  out4: x => 1 - (x = 1 - x) * x * x * x,
  out5: x => 1 - (x = 1 - x) * x * x * x * x,
}
