import { FC, useRef, useState, useEffect } from 'react'
import { CursorType, getCursorType, CursorComponents } from './cursor-types'
import { cursorSize, cursorStyle } from './Cursor.css'

export const Cursor: FC = () => {

  const ref = useRef<HTMLDivElement>(null)

  const [cursor, setCursor] = useState<CursorType>('Native')

  useEffect(() => {

    const svg = ref.current!

    const styleCursorNone = document.createElement('style')
    styleCursorNone.innerHTML = /* css */`
      body * {
        cursor: none !important;
      }
    `

    const updateCursor = (type: CursorType) => {
      setCursor(type)
      if (type === 'Native')
        styleCursorNone.remove()
      else
        document.head.append(styleCursorNone)
    }

    let currentElement = null as HTMLElement | null
    let onFrameId = -1
    const onFrame = () => {
      onFrameId = window.requestAnimationFrame(onFrame)
      if (currentElement && currentElement.isConnected === false) {
        currentElement = null
        updateCursor('Native')
      }
    }
    onFrameId = window.requestAnimationFrame(onFrame)

    const onPointerMove = (event: PointerEvent) => {
      const x = event.x - cursorSize / 2
      const y = event.y - cursorSize / 2
      svg.style.left = `${x.toFixed(1)}px`
      svg.style.top = `${y.toFixed(1)}px`
      currentElement = event.target as HTMLElement
      updateCursor(getCursorType(event.target as HTMLElement))
    }

    const onPointerLeave = () => {
      updateCursor('Native')
    }

    const style = document.createElement('style')
    style.innerHTML = cursorStyle
    document.head.append(style)

    document.addEventListener('pointermove', onPointerMove)
    document.addEventListener('pointerleave', onPointerLeave)
    
    return () => {
      window.cancelAnimationFrame(onFrameId)
      style.remove()
      document.removeEventListener('pointermove', onPointerMove)
      document.removeEventListener('pointerleave', onPointerLeave)
    }

  }, [])

  const CursorComponent = CursorComponents[cursor]

  return (
    <div
      ref={ref}
      className='Cursor'
    >
      {CursorComponent && (
        <CursorComponent />
      )}
    </div>
  )
}
