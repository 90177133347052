import { useRef } from 'react'

import { Animation, easings } from 'some-utils/Animation'
import { handlePointer, handleWindow } from 'some-utils/dom'
import { lerp } from 'some-utils/math'
import { Message } from 'some-utils/message'
import { mapWithSeparator, timer, useEffects } from 'some-utils/npm/react'
import { navigate, projectObs } from 'state/navigation'

import { useAppContext } from 'AppContext'
import { strapi } from 'config'
import { api, getHomeProjects } from 'data'
import { layoutObs, useResponsive } from 'state/layout'
import { homeScrollObs, projectScrollHeightObs, projectScrollObs } from 'state/scroll'
import { DocumentTitle } from 'utils/document-title'
import { ScrollInvitation, Scrollbar, ScrollbarPublicState, Spacing } from 'view/components'

import { MaskWrapper } from './MaskWrapper'
import { ProgressionLadder } from './ProgressionLadder'
import { ProjectCover } from './ProjectCover'
import { ScrollContext } from './ScrollContext'
import { ContentError } from './contents/ContentError'
import { GridComposer } from './contents/GridComposer'
import { MediaGallery } from './contents/MediaGallery'
import { MediaSingle } from './contents/MediaSingle'
import { SectionAnchor } from './contents/SectionAnchor'
import { Spacer } from './contents/Spacer'
import { Text } from './contents/Text'

import './css/Project.css'

const initHomeScroll = () => {
  const projects = getHomeProjects()
  const slug = document.location.pathname.slice(1)
  const projectIndex = projects.findIndex(project => project.slug === slug)
  if (projectIndex !== -1) {
    homeScrollObs.setValue(projectIndex + 1)
  }
}

const getContent = (item: strapi.StrapiComponent, index: number) => {
  switch (item.__component) {
    case strapi.StrapiComponentType.Spacer: return (
      <Spacer key={index}
        content={item as strapi.Spacer} />
    )
    case strapi.StrapiComponentType.SectionAnchor: return (
      <SectionAnchor key={index}
        content={item} />
    )
    case strapi.StrapiComponentType.Text: return (
      <Text key={index}
        content={item as strapi.Text} />
    )
    case strapi.StrapiComponentType.MediaGallery: return (
      <MediaGallery key={index}
        content={item as strapi.MediaGallery} />
    )
    case strapi.StrapiComponentType.MediaSingle: return (
      <MediaSingle key={index}
        content={item as strapi.MediaSingle} />
    )
    case strapi.StrapiComponentType.GridComposer: return (
      <GridComposer key={index}
        content={item as strapi.GridComposer} />
    )
    default: return (
      <ContentError
        key={index}
        content={item}
        message='Not implemented' />
    )
  }
}

const onChildrenResize = (
  children: Element[],
  callback: () => void,
) => {
  const ro = new ResizeObserver(() => {
    callback()
  })
  for (const child of children) {
    ro.observe(child)
  }
  const destroy = () => {
    for (const child of children) {
      ro.unobserve(child)
    }
    ro.disconnect()
  }
  return { destroy }
}

export const Project = ({
  project,
  useProjectLadder = false,
}: {
  project: api.Project
  useProjectLadder?: boolean
}) => {
  const contents = project.attributes.contents.filter(item => {
    return item.visibility && item.visibility !== 'visible' ? false : true
  })

  const nodes = mapWithSeparator(
    contents,
    getContent,
    index => <Spacing size={8} key={`sep-${index}`} />,
  )

  const { themeObs } = useAppContext()
  const { gridPadding, columnsCount } = useResponsive()
  const scrollbarState = useRef<ScrollbarPublicState>(null)
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    initHomeScroll()

    themeObs.setValue(project.theme())

    yield scrollbarState.current?.onRequestProgress(t => {
      projectScrollObs.value = t * projectScrollObs.getMax()
    })

    // Update layout according to the scroll:
    yield projectScrollObs.onChange(value => {
      const height = projectScrollHeightObs.value
      div.scrollTop = value * height
      scrollbarState.current?.setProgress(value / projectScrollObs.getMax())
    })

    const updateSize = () => {
      const height = div.offsetHeight
      projectScrollHeightObs.setValue(height)
      const max = (div.scrollHeight - height) / height
      projectScrollObs.setMinMax(0, max)
    }

    // Fix the children resize bug:
    yield onChildrenResize(
      [...div.firstElementChild!.children],
      () => updateSize())

    yield handleWindow({
      executeOnResizeImmediately: true,
      onResize: () => updateSize(),
    })

    // React to the user input:
    let velocityDecay = 0, velocity = 0

    yield handlePointer(div, {
      passive: false,
      onWheel: event => {
        event.preventDefault()
        const height = projectScrollHeightObs.value
        projectScrollObs.value += event.deltaY / height * .33
      },

      onDown: () => {
        velocity = 0
      },
      onVerticalDrag: info => {
        if (info.downEvent.pointerType === 'touch') {
          velocityDecay = .95
          velocity = -info.delta.y
          info.moveEvent.preventDefault()
          document.getSelection()?.empty()
        }
      },
    })

    yield timer.onFrame(() => {
      const height = projectScrollHeightObs.value
      projectScrollObs.value += velocity / height
      velocity *= velocityDecay
    })

    yield layoutObs.withValue(({ baseFontSize }) => {
      div.style.fontSize = `${baseFontSize}rem`
    })

    const rollback = (onComplete?: () => void) => {
      const tween = {
        to: 0,
        from: projectScrollObs.value,
      }
      const duration = .35 + Math.abs(tween.to - tween.from) * .15
      Animation.duringWithTarget('NAVIGATION_SCROLL', { duration }, ({ progress, complete }) => {
        const alpha = easings.inout(progress, 5, .25)
        projectScrollObs.value = lerp(tween.from, tween.to, alpha)
        if (complete) {
          onComplete?.()
        }
      })
    }

    yield Message.on('APP', 'LOGO_TAP', ({ props }) => {
      props.catchMessage()
      rollback(() => navigate('/'))
    })

    yield projectObs.onChange(project => {
      if (project == null) {
        // ensure to rollback
        rollback()
      }
    })

  }, [])

  return (
    <ScrollContext.Provider value={{ scrollObs: projectScrollObs }}>
      <DocumentTitle title={project.slug} />

      <MaskWrapper ref={ref}>
        <main className='Project'>
          <>
            <ProjectCover project={project} />
            {nodes}
            <Spacing size={gridPadding.bottom} />
          </>
        </main>
      </MaskWrapper>
      {useProjectLadder && (
        <ProgressionLadder projectRef={ref} />
      )}
      {columnsCount > 1 && (
        <Scrollbar
          direction='vertical'
          padding={{ vertical: 16 }}
          stateRef={scrollbarState}
        />
      )}
      <ScrollInvitation
        scrollObs={projectScrollObs}
        scrollThreshold={.01}
      />
    </ScrollContext.Provider>
  )
}

