import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { useMemo } from 'react'
import { createPortal, useFrame, useThree } from '@react-three/fiber'
import { useResponsiveCamera } from '../camera'
import { useRotator } from './useRotator'
import { usePostprocessing } from './postprocessing'

export const World = ({ children }: any) => {
  const scene = useMemo(() => new THREE.Scene(), [])
  const camera = useResponsiveCamera()
  const { gl: renderer } = useThree()

  useRotator(camera)

  const composer = useMemo(() => {
    const composer = new EffectComposer(renderer)
    composer.addPass(new RenderPass(scene, camera))
    return composer
  }, [camera, renderer, scene])

  usePostprocessing(composer)

  const priority = 0
  useFrame(() => {
    composer.render()
  }, priority)

  return createPortal(children, scene) as JSX.Element
}
