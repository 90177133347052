import { useMemo } from 'react'
import { Animation } from 'some-utils/Animation'
import { useEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { staticRoutes } from 'config'
import { routeObs } from 'state/navigation'
import { Part1 } from './Part1'
import { Part2 } from './Part2'
import { Part3 } from './Part3'
import { material } from './material'

export const PageStudiosOvvoVideo = () => {
  const alphaObs = useMemo(() => new ObservableNumber(1), [])

  useEffects(function* () {
    yield alphaObs
    yield alphaObs.withValue(alpha => {
      material.opacity = alpha
    })

    yield routeObs.withValue(route => {
      const value = route === staticRoutes.studio ? 1 : 0
      const duration = routeObs.hasChanged ? 1 : 0
      Animation.tween(alphaObs, { duration, delay: 0, immediate: true }, {
        to: { value },
        ease: 'inout3',
      })
    })
  }, [])

  return (
    <>
      <Part1 alphaObs={alphaObs} />
      <Part2 alphaObs={alphaObs} />
      <Part3 alphaObs={alphaObs} />
    </>
  )
}
