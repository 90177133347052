export const defaultTheme = 'dark'

export type Theme = typeof defaultTheme | 'light'

export const asTheme = (value: any): Theme => {
  switch (typeof value) {
    case 'string': {
      return value.toLowerCase() === 'light' ? 'light' : defaultTheme
    }
    default: {
      return defaultTheme
    }
  }
}
