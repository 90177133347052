import { useRef, useState } from 'react'

import { handlePointer } from 'some-utils/dom'
import { safeClassName, useEffects, useLayoutEffects } from 'some-utils/npm/react'

import { isIOSStandalone } from 'config'
import { globalData, reels } from 'data'
import { HorizontalWrapper } from 'view/components'

import { useReelState } from './useReelState'

import { Message } from 'some-utils/message'
import './MobileReel.css'

export function MobileReel() {
  const iosStandalone = isIOSStandalone()
  const state = useReelState()
  const [playing, setPlaying] = useState(false)
  const playingRef = useRef(playing)
  playingRef.current = playing

  useEffects(function () {
    const url = reels[state.reelIndex].videoUrl()
    Message.send('REEL', 'PRELOAD', { url })
  }, [state.reelIndex])

  const { ref } = useLayoutEffects<HTMLDivElement>(function* (div) {
    const playButton = div.querySelector('.PlayButton') as HTMLDivElement
    yield handlePointer(playButton, {
      passive: false,
      onTap: () => {
        Message.send('REEL', 'PLAY')
      },
    })
  }, [])

  return (
    <div ref={ref} className='MobileReel absolute-fill'>
      <HorizontalWrapper className='absolute-fill'>
        {reels.map((reel, index) => (
          <img
            key={index}
            className={safeClassName(
              'Cover absolute-fill',
              index === state.reelIndex && 'active'
            )}
            alt='video cover'
            src={reel.cover().url()}
          />
        ))}
      </HorizontalWrapper>

      <div className='UI absolute-fill'>
        <div className='Spacing' />

        <h1>
          {globalData.attributes.MenuNameReels}
        </h1>

        <div className='Spacing' />

        <ul>
          {reels.map((reel, index) => (
            <li key={index}>
              <span
                className={`button ${state.reelIndex === index ? 'active' : 'ui-dim'}`}
                onClick={() => {
                  state.set({
                    reelIndex: index,
                    videoIsReady: false,
                  })
                }}
              >
                {reel.title()}
              </span>
            </li>
          ))}
        </ul>

        <div style={{ flex: '1' }} />

        <div
          className='PlayButton'
          onClick={() => state.set({})}
        >
          <svg width="63" height="72" viewBox="0 0 63 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62 34.268C63.3333 35.0378 63.3333 36.9622 62 37.7321L3.49999 71.507C2.16666 72.2768 0.499997 71.3146 0.499997 69.775L0.5 2.225C0.5 0.685401 2.16667 -0.276846 3.5 0.492954L62 34.268Z" fill="#D9D9D9" fillOpacity="0.8" />
          </svg>
        </div>

        <div className='Spacing' />

        <div className='OrientationIcon'>
          <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="32" stroke="white" />
            <rect x="34.5" y="15.5" width="15" height="32" transform="rotate(90 34.5 15.5)" stroke="white" strokeDasharray="2 2" />
          </svg>
        </div>

        <div className='Spacing' />
      </div>
    </div>
  )
}
