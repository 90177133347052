export const vertexShader = /* glsl */ `

  precision mediump float;
  precision mediump int;

  attribute vec4 color;

  varying vec3 vPosition;
  varying vec4 vColor;
  varying vec2 vUv;

  void main()	{
    vPosition = position;
    vColor = color;
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
`
