import { location } from 'some-utils/router'

import { isLocale, Locale, staticRoutes } from 'config'
import { allProjectsBySlug } from 'data'

const routeValues = Object.values(staticRoutes)

export { staticRoutes }
export type StaticRoute = typeof staticRoutes[keyof typeof staticRoutes]
export const isStaticRoute = (value: any): value is StaticRoute => typeof value === 'string' && routeValues.includes(value as StaticRoute)
export const isProjectRoute = (value: any): boolean => allProjectsBySlug.has(value.replace(/^\//, ''))
export const isNewsRoute = (value: any): boolean => typeof value === 'string' && value.startsWith(staticRoutes.news) && (value.length === staticRoutes.news.length || value[staticRoutes.news.length] === '/')

export type Route = StaticRoute | string

export const isRouteOk = (value: any): boolean => (
  typeof value === 'string'
  && (isStaticRoute(value) || isProjectRoute(value) || isNewsRoute(value))
)

export const is404 = (value: any): boolean => {
  return !isRouteOk(value)
}

export const splitPathname = () => {
  const tokens = location.pathname.value.split('/').filter(v => !!v)
  const localeIndex = tokens.findIndex(token => isLocale(token))
  const locale: Locale = localeIndex !== -1
    ? tokens.splice(localeIndex, 1)[0] as Locale
    : 'en'
  const slugIndex = tokens.findIndex(token => allProjectsBySlug.has(token))
  const slug = slugIndex !== -1
    ? tokens[slugIndex]
    : null
  const route = `/${tokens.join('/')}`
  return {
    locale,
    slug,
    route,
  }
}
