import { jsonLocalStorage } from 'some-utils/dom/storage/jsonLocalStorage'
import { ObservableBoolean } from 'some-utils/observables'

const key = 'LiveInspector.hidden'

export const hiddenObs = new ObservableBoolean(jsonLocalStorage.getItem(key, true))

hiddenObs.onChange(() => {
  jsonLocalStorage.setItem(key, hiddenObs.value)
})

export const hideInspector = () => hiddenObs.setTrue()
export const showInspector = () => hiddenObs.setFalse()
export const toggleInspector = () => hiddenObs.toggle()
