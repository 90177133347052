import { useMemo } from 'react'
import { Color, ColorRepresentation } from 'three'

import { clamp, clamp01, inout } from 'some-utils/math'
import { timer, useEffects } from 'some-utils/npm/react'

import { getHomeProjects, globalData } from 'data'
import { Project } from 'data/api'
import { isHome, isProject } from 'app-utils/routing'
import { projectObs, routeObs } from 'state/navigation'
import { homeScrollObs, projectScrollObs } from 'state/scroll'

// <CascadingBackground/> based ont cascading props is weak and should not be used here.
// export const CascadingBackground = () => {
//   // Background color
//   const backgroundColor = useMemo(() => new THREE.Color(), [])
//   useEffects(function* () {
//     const prop = cascadingProps.getProp<THREE.Color>('metaball.backgroundColor')
//     backgroundColor.copy(prop.value)
//     yield prop.onChange(value => {
//       const ease = (x: number) => inout(x, 5, 1 / 5)
//       Animation.tween(backgroundColor, 0.5, { to: value, ease })
//     })
//   }, [])
//   return (
//     <primitive attach='background' object={backgroundColor} />
//   )
// }

const getGlobalBackgroundColor = (): ColorRepresentation => {
  return (
    globalData.attributes.CoverSettings?.metaball.backgroundColor
    ?? '#f0f' // Error color
  )
}

const getProjectBackgroundColor = (project: Project): ColorRepresentation => {
  return (
    project.attributes.coverSettings?.metaball?.backgroundColor
    ?? getGlobalBackgroundColor()
  )
}

const getProjectFadeoutBackgroundColor = (project: Project): ColorRepresentation => {
  return (
    project.attributes.MetaballFadeoutSettings?.backgroundColor
    ?? project.attributes.coverSettings?.metaball?.backgroundColor
    ?? getGlobalBackgroundColor()
  )
}

const getHomeColor = (index: number): ColorRepresentation => {
  if (index === 0) {
    return getGlobalBackgroundColor()
  } else {
    const projects = getHomeProjects()
    const projectIndex = clamp(index - 1, 0, projects.length - 1)
    return getProjectBackgroundColor(projects[projectIndex])
  }
}

export const DynamicBackground = () => {
  const backgroundColor = useMemo(() => new Color(0, 0, 0), [])

  useEffects(function* () {
    const colorX = new Color(getGlobalBackgroundColor())
    const color1 = new Color()
    const color2 = new Color()

    yield routeObs.onChange(route => {
      if (isHome(route)) {
        // Set the background color when entering home.
        const color = getHomeColor(Math.round(homeScrollObs.value))
        colorX.set(color)
      }
    })

    yield homeScrollObs.onChange(homeScroll => {
      color1.set(getHomeColor(Math.floor(homeScroll)))
      color2.set(getHomeColor(Math.ceil(homeScroll)))
      colorX.lerpColors(color1, color2, inout(homeScroll % 1, 7, .5))
    }, { execute: isHome() })

    yield projectScrollObs.onChange(projectScroll => {
      const project = projectObs.value
        ?? projectObs.valueOld! // Why that fallback? Because current project may be null (going back to home). In that case use the previous value.
      color1.set(getProjectBackgroundColor(project))
      color2.set(getProjectFadeoutBackgroundColor(project))
      colorX.lerpColors(color1, color2, inout(clamp01(projectScroll), 2, .5))
    }, { execute: isProject() })

    yield timer.onFrame(({ deltaTime }) => {
      backgroundColor.lerp(colorX, .1 ** deltaTime)
    })
  }, [])

  return (
    <primitive attach='background' object={backgroundColor} />
  )
}
