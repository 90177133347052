import * as THREE from 'three'
import { layoutObs, pixelToThree } from 'state/layout'
import { useHUDGroup } from 'view/gl/passes'
import { ObservableNumber } from 'some-utils/observables'
import { inverseLerp, lerp } from 'some-utils/math'
import { dashMaterial, dotMaterial } from './material'

const dotEase = (t: number) => {
  const minThreshold = .33 // below it's zero
  t = inverseLerp(minThreshold, 1, t)
  return t ** 12
}

const progressionInterpolation = (
  index: number,
  count: number,
  position: number,
) => {
  if (index < position) {
    const t = (index + 1) / (position + 1)
    return t - 1
  } else {
    const t = (index - position) / (count - position)
    return t
  }
}

const planeGeometry = new THREE.PlaneGeometry()

export const ProgressBar = ({
  count = 10,
  position = new ObservableNumber(0),
  useDot = false,
}) => {

  useHUDGroup(function* ({ group, add }) {

    group.name = 'ProgressBar'
    group.renderOrder = 10

    const lineVert = add(new THREE.Mesh(planeGeometry, dashMaterial))

    const marks = Array.from({ length: count }).map(() => {
      const material = (useDot ? dotMaterial : dashMaterial).clone()
      return add(new THREE.Mesh(planeGeometry, material))
    })

    const update = () => {
      const {
        size,
        threeRatio,
        responsive: { gridPadding },
      } = layoutObs.value

      const margin = 150
      const width = (size.width - gridPadding.totalHorizontal - margin * 2) * threeRatio

      pixelToThree.point({
        x: gridPadding.left + margin,
        y: size.height - gridPadding.bottom / 2,
      }, group.position)

      lineVert.scale.x = 1 * threeRatio
      lineVert.scale.y = 20 * threeRatio
      lineVert.position.x = width / 2
      lineVert.position.y = -20 * threeRatio
      lineVert.visible = useDot

      const markSpacing = 8 * threeRatio
      const markSize = useDot
        ? new THREE.Vector3(5 * threeRatio, 5 * threeRatio, 0)
        : new THREE.Vector3(1 * threeRatio, 8 * threeRatio, 0)
      const lerpOpacity = useDot
        ? (time: number) => lerp(1, .25, time)
        : (time: number) => lerp(1, .5, time)
      for (const [index, mark] of marks.entries()) {
        const progression = progressionInterpolation(index, count, position.value)
        const time = inverseLerp(0, .1, Math.abs(progression)) ** .25
        const scale = lerp(1.5, 1, time)
        mark.scale.set(markSize.x * scale, markSize.y * scale, 0)
        mark.material.uniforms.uOpacity.value = lerpOpacity(time)

        const start = index * markSpacing
        const middle = width / 2
        const end = index * markSpacing + width - (markSpacing * (count - 1))
        mark.position.x = progression < 0
          ? lerp(start, middle, dotEase(1 + progression))
          : lerp(middle, end, 1 - dotEase(1 - progression))
      }
    }

    yield layoutObs.onChange(update)
    yield position.withValue(update)

  }, [count, position])

  return null
}
