import { useState } from 'react'
import { handleEvent } from 'some-utils/dom'
import { safeClassName, useEffects } from 'some-utils/npm/react'
import { useSearch } from 'some-utils/npm/react/router'
import { setSearch } from 'some-utils/router'
import { useResponsive } from 'state/layout'
import { unifiedSearchState } from 'state/unified-search'
import { mobileMenuIsOpenObs } from 'view/mobile-menu'
import './SearchInput.css'

export const SearchInput = () => {

  const { gridPadding, columnsCount } = useResponsive()

  // NOTE: There is a particular case for 3-columns layout.
  const top = columnsCount === 3
    ? gridPadding.top + 100
    : gridPadding.top
  const style = {
    '--top': `${top}px`,
  } as React.CSSProperties

  const search = decodeURI(useSearch())
  const [focused, setFocused] = useState(false)

  const filled = search.length > 0

  const { ref: inputRef } = useEffects<HTMLInputElement>(function* (input) {
    function focus() {
      input.focus()
      setFocused(true)
    }

    function blur() {
      input.blur()
      setFocused(false)
    }

    focus()

    yield unifiedSearchState.visibleObs.onTrue(blur)
    yield mobileMenuIsOpenObs.onTrue(blur)

    yield handleEvent(document.documentElement, {
      mouseup: () => {
        if (unifiedSearchState.visibleObs.value) return
        if (mobileMenuIsOpenObs.value) return

        const end = input.value.length
        input.setSelectionRange(end, end)
        input.focus()
        setFocused(true)
      },
    })
  }, [])

  return (
    <div
      className={safeClassName('SearchInput flex row center ui', { focused, filled })}
      style={style}>

      {/* <NewSearch className='SearchSvg button' /> */}
      {/* <div className='Line' /> */}

      <input
        ref={inputRef}
        autoFocus
        // placeholder={globalData.attributes.SearchInputPlaceholder}
        type="text"
        style={{ textAlign: 'center' }}
        value={search}
        onChange={event => setSearch((event.target as HTMLInputElement).value)}
        onBlur={() => setFocused(false)}
      />

      {/* {search.length > 0 && (
        <ClearDoubleArrowSvg
          className='ClearDoubleArrowSvg button ui-dim'
          style={{ marginLeft: '8px' }}
          onClick={() => {
            clearSearch()
            inputRef.current?.focus()
          }}
        />
      )} */}

    </div>
  )
}
