import { Property, Value, getValueType, ExtractedProperties, ValueMapper, DetailedPropertyDeclaration } from '../types'

const defaultMapper: ValueMapper = {
  condition: () => true,
  transform: value => value,
  restore: value => value,
}

export const createProperty = (key: string, source: DetailedPropertyDeclaration, mapper: ValueMapper = defaultMapper): Property => {

  const {
    value: declaredValue, 
    type: declaredType, ...meta
  } = source

  const inferredType = getValueType(declaredValue)

  if (declaredType && inferredType !== declaredType) {
    throw new Error(`Property Error. Cannot create property "${key}". Declared type (${declaredType}) does not match inferredType (${inferredType}).`)
  }

  const type = declaredType ?? inferredType

  // private value
  let value = declaredValue

  const property: Property = {
    ...meta,
    key,
    mapper,
    type,
    get value() { return value },
    getLiveInspectorValue: () => value,
    setLiveInspectorValue: newValue => value = newValue,
    extractValue: () => mapper.restore(value, declaredValue),
    updateValue: source => value = mapper.transform(source),
  }

  return property
}

export const extractProperties = <T>(properties: Map<string, Property>) => {
  const result = {} as Record<string, Value>
  for (const property of properties.values()) {
    result[property.key] = property.extractValue()
  }
  return result as ExtractedProperties<T>
}
