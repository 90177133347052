import { useEffects } from 'some-utils/npm/react'
import { ObservableBoolean } from 'some-utils/observables'
import { handlePointer } from 'some-utils/dom'
import { allProjects } from 'data'
import './ProjectHint.css'

export const ProjectHint = () => {
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    const visibleObs = new ObservableBoolean(false)
    visibleObs.withValue(value => {
      div.classList.toggle('hidden', value === false)
    })

    yield handlePointer(document.documentElement, {
      onMove: event => {
        div.style.left = `${event.clientX}px`
        div.style.top = `${event.clientY - 40}px`
        const target = event.target as HTMLElement
        if ('projectId' in target.dataset) {
          const id = Number.parseInt(target.dataset.projectId!)
          const project = allProjects.get(id)!
          div.querySelector('.Title')!.innerHTML = project.title()
          div.querySelector('.Subtitle')!.innerHTML = project.subtitle()
          visibleObs.setValue(true)
        } else {
          visibleObs.setValue(false)
        }
      },
    })
  }, [])

  return (
    <div ref={ref} className='ProjectHint hidden'>
      <div className='Title'>Some title</div>
      <div className='Subtitle'>Some subtittitititle</div>
    </div>
  )
}
