
// This must be synced with the routes in the server
export const staticRoutes = {
  home: '/',
  searchProjects: '/projects',
  searchProjectsPixel: '/projects/pixel',
  reels: '/reels',
  news: '/news',
  studio: '/studio',
  doc: '/doc',
  dev: '/dev',
} as const
