import { useState } from 'react'

import { timer, useEffects, useObservable } from 'some-utils/npm/react'

import { QUALITY_DEGRADATION_MAX, useAppContext } from 'AppContext'

import './QualityDebug.css'

const canvasInfo = (canvas: HTMLCanvasElement): string => {
  const ratio = canvas.width / window.innerWidth
  return `${canvas.width} x ${canvas.height} @${ratio.toFixed(2)}`
}

const getInfos = (fps: number = 0, canvas: HTMLCanvasElement | null = null, paused = false, extra = ''): string[] => {
  return [
    `window(${window.innerWidth} x ${window.innerHeight} x${window.devicePixelRatio}dpr)`,
    `canvas(${canvas ? canvasInfo(canvas) : ''})`,
    `${fps.toFixed()}fps${paused ? ' (paused)' : ''}`,
    extra
  ]
}

const Content = ({ onRequestClose = () => { } }) => {
  const { averageFpsObs, qualityDegradationLevelObs, qualityInfoObs } = useAppContext()

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    let canvas: HTMLCanvasElement | null = null
    const infoDiv = div.querySelector('.Info') as HTMLDivElement
    yield timer.onChange(() => {
      canvas ||= document.querySelector('canvas[data-engine^="three.js"]')
      const infos = getInfos(
        averageFpsObs.value,
        canvas,
        timer.isFreezed(),
        qualityInfoObs.value,
      )
      for (const [index, info] of infos.entries()) {
        infoDiv.children[index].innerHTML = info
      }
    })
  }, [])

  const level = useObservable(qualityDegradationLevelObs)
  return (
    <div ref={ref} className='QualityDebug'>
      <div className='Close' onClick={onRequestClose} />
      <div className='Info'>
        {getInfos().map((_, index) => (
          <div key={index} />
        ))}
      </div>
      <div style={{ marginTop: '8px' }}>
        <label>quality degradation level:</label>
        <select
          name="degradation"
          value={level}
          onChange={event => {
            const value = Number.parseFloat(event.currentTarget.value)
            qualityDegradationLevelObs.setValue(value)
          }}
        >
          {Array.from({ length: QUALITY_DEGRADATION_MAX + 1 }).map((_, index) => (
            <option key={index}>{index}</option>
          ))}
        </select>
      </div>
    </div>
  )
}


export const QualityDebug = ({ visible: propVisible = false }) => {
  const [visible, setVisible] = useState(propVisible)
  return (visible
    ? <Content onRequestClose={() => setVisible(false)} />
    : null
  )
}
