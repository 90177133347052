import { isNewsRoute, navigate, staticRoutes, useProject, useRoute } from 'state/navigation'
import { closeOpenedNews } from 'view/news/controller'
import './Breadcrumb.css'

export const Breadcrumb = () => {
  const project = useProject()

  const route = useRoute()

  if (isNewsRoute(route)) {
    return (
      <div
        className='Breadcrumb flex column center button'
        onClick={() => {
          navigate(staticRoutes.news, { clearHash: true })
          closeOpenedNews()
        }}
      >
        {`/ ${staticRoutes.news.slice(1)}`}
      </div>
    )
  }

  const breadcrumb = null
    ?? (route === staticRoutes.reels ? route.slice(1) : null)
    ?? (route === staticRoutes.news ? route.slice(1) : null)
    ?? project?.attributes.breadcrumb
    ?? project?.slug

  return !breadcrumb ? null : (
    <div className='Breadcrumb flex column center'>
      {`/ ${breadcrumb}`}
    </div>
  )
}
