const globalize = (props:Record<string, any>, {
  hook,
}: {
  hook?: () => void,
} = {}) => {

  const global = {}
  for (const key in props) {
    Object.defineProperty(global, key, {
      configurable: true,
      get: () => {
        hook?.()
        return props[key]
      }
    })
  }

  Object.assign(window, { global, ...props })
}

export default globalize
