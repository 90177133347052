import { HTMLAttributes } from 'react'
import { safeClassName } from 'some-utils/npm/react'
import { clearHash, clearSearch, getHash, setHash } from 'some-utils/router'
import { useHash } from 'some-utils/npm/react/router'
import { navigate, splitPathname, staticRoutes } from 'state/navigation'
import { api } from 'data'
import { ClearDoubleArrowSvg } from 'assets/svg'
import './Tags.css'

const toggleHashTag = (tagName: string) => {
  const hashTokens = getHash().split(',').filter(value => !!value)
  const index = hashTokens.indexOf(tagName)
  if (index === -1) {
    hashTokens.push(tagName)
  }
  else {
    hashTokens.splice(index, 1)
  }
  setHash(hashTokens.join(','))
  let { route } = splitPathname()
  if (route.includes(staticRoutes.searchProjects) === false) {
    // redirect only if the current route is not part of search project routes.
    navigate(staticRoutes.searchProjects)
  }
}

type TagProps = {
  tag: api.Tag,
  className?: string,
  clearSearchOnClick?: boolean
}

export const Tag = ({
  tag,
  className,
  clearSearchOnClick = true,
}: TagProps) => {
  return (
    <li
      className={safeClassName('Tag button cursor-pointer', className)}
      onClick={() => {
        if (clearSearchOnClick) {
          clearHash()
          clearSearch()
        }
        toggleHashTag(tag.kebabName())
      }}
    >
      #{tag.kebabName()}
    </li>
  )
}

type TagsProps = {
  tags: api.Tag[]
  className?: string
  selectable?: boolean
  useClearButton?: boolean,
  clearSearchOnClick?: boolean,
} & HTMLAttributes<HTMLUListElement>

export const Tags = ({
  tags,
  className,
  selectable,
  // Shandor update Apr 2023:
  useClearButton = false,
  clearSearchOnClick = false,
  ...props
}: TagsProps) => {
  const hashTags = useHash().split(',').filter(value => !!value)
  return (
    <ul {...props} className={safeClassName('Tags ui flex row tight', className)}>
      {tags.map((tag, index) => {
        const selected = selectable && hashTags.includes(tag.kebabName())
        return (
          <Tag
            clearSearchOnClick={clearSearchOnClick}
            key={index}
            tag={tag}
            className={safeClassName({ selectable, selected })}
          />
        )
      })}
      {useClearButton && selectable && hashTags.length > 0 && (
        <ClearDoubleArrowSvg
          width='1.5em'
          height='1.5em'
          className='ClearButton button ui-dim'
          onClick={() => clearHash()}
        />
      )}
    </ul>
  )
}
