import { onDataInitialized, getHomeProjects, globalData } from 'data'
import { CascadingProps } from 'utils/CascadingProps'
import { ObservableNumber, ObservableObject } from 'some-utils/observables'
import { clamp01, inverseLerp, lerp } from 'some-utils/math'
import { Animation } from 'some-utils/Animation'
import { clamp } from 'three/src/math/MathUtils'
import { location } from 'some-utils/router'
import { currentRouteIsHome, currentRouteIsProject, currentProjectHomeIndex, projectObs } from 'state/navigation'
import { defaultScrollSettings } from 'scroll'
import { safeSettings } from 'data/utils'
import { development } from 'config'
import { loadFromLocalStorage, saveToLocalStorage } from './localstorage'

const fromStorage = loadFromLocalStorage()

export const homeScrollObs = new ObservableNumber(development ? fromStorage.homeScroll : 0)
export const projectScrollObs = new ObservableNumber(0)
export const projectScrollHeightObs = new ObservableNumber(0)
export const coverVisibilityObs = new ObservableNumber(1)

export const projectScrollTo = (value: number) => {
  value = projectScrollObs.clamp(value)
  const startValue = projectScrollObs.value
  const duration = .5
  Animation.duringWithTarget(projectScrollObs, duration, ({ progress }) => {
    const alpha = Animation.getMemoizedEase('inout(3, .4)')(progress)
    projectScrollObs.setValue(lerp(startValue, value, alpha))
  })
}

export const projectScrollToPixel = (value: number) => {
  const height = projectScrollHeightObs.value
  projectScrollTo(value / height)
}

homeScrollObs.onStepChange(.1, () => saveToLocalStorage(Math.round(homeScrollObs.value), projectScrollObs.value))
projectScrollObs.onStepChange(.1, () => saveToLocalStorage(Math.round(homeScrollObs.value), projectScrollObs.value))

// homeScrollTransition is an helper that helps to mix discrete values.
export const homeScrollTransition = new ObservableObject({
  startIndex: 0,
  endIndex: 1,
  progression: 0,
})

homeScrollObs.withValue(value => {
  const margin = .2
  const startIndex = Math.floor(value)
  const progression = inverseLerp(margin, 1 - margin, value - startIndex)
  homeScrollTransition.updateValue({
    startIndex,
    endIndex: startIndex + 1,
    progression,
  })
})

let destination = NaN
Animation.loop(({ deltaTime }) => {
  const project = projectObs.value
  if (project) {
    // Goto the project (if on home)
    const index = getHomeProjects().indexOf(project)
    if (index !== -1) {
      const alpha = 1 - .00001 ** deltaTime
      homeScrollObs.lerp(index + 1, alpha)
    }
  } else if (Number.isFinite(destination)) {
    const d = destination - homeScrollObs.value
    const threshold = 1 / window.innerHeight
    if (Math.abs(d) > threshold) {
      const x = homeScrollObs.value + d * clamp01(10 * deltaTime)
      homeScrollObs.setValue(x)
    } else {
      homeScrollObs.setValue(destination)
      destination = NaN
    }
  }
})

export const homeScrollTo = (index: number) => {
  destination = clamp(index, 0, getHomeProjects().length)
}

export const cascadingProps = new CascadingProps()

const updateCascadingProps = () => {
  const index = Math.round(homeScrollObs.value)
  const route = index < 1 ? 'home.splash' : `home.project${index - 1}`
  cascadingProps.enter(route)
}

cascadingProps.addRules('*', safeSettings(defaultScrollSettings))

homeScrollObs.onStepChange(.01, updateCascadingProps)

// add rules defined in CMS (strapi)
onDataInitialized.add(() => {
  // Home Splash
  cascadingProps.addRules('home.splash', safeSettings(globalData.attributes.CoverSettings, `global scroll settings`))

  // Projects
  for (const [index, project] of getHomeProjects().entries()) {
    const settings = project.attributes.coverSettings ?? {}
    cascadingProps.addRules(`home.project${index}`, safeSettings(settings, `project: #${project.id} (${project.title()})`))
  }

  // Scroll
  homeScrollObs.setMinMax(0, getHomeProjects().length)
  if (currentRouteIsProject()) {
    homeScrollObs.value = 1 + currentProjectHomeIndex()
  }

  // Cascading props
  location.pathname.withValue(() => {
    // Important! Revert projectScroll when going home.
    if (currentRouteIsHome()) {
      updateCascadingProps()
      Animation.tween(projectScrollObs, .5, {
        to: { value: 0 },
      })
    }

    if (currentRouteIsProject()) {
      cascadingProps.enter(`home.project${currentProjectHomeIndex()}`)
    }
  })
})
