
/**
 * CallbackStack accepts a definition for the callback arguments. This is handy:
 * 
 *     const s = new CallbackStack<[{ t: number, dt: number }]>()
 *     s.add(({ t, dt }) => console.log(t, dt))
 *     s.call({ t: 1, dt: 1 / 60 })
 */
export class CallbackStack<T extends any[] = never[]> {
  #callbacks:Set<(...args: T) => void> = new Set()

  add(callback:(...args: T) => void) {
    this.#callbacks.add(callback)
    const destroy = () => { this.#callbacks.delete(callback) }
    return { destroy }
  }

  addAndExecute(callback:(...args: T) => void,...args: T) {
    callback.apply(null, args)
    return this.add(callback)
  }

  call(...args: T) {
    for (const callback of this.#callbacks) {
      callback.apply(null, args)
    }
  }

  clear() {
    this.#callbacks.clear()
  }
}

export default CallbackStack
