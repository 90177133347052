import { RefObject, useRef } from 'react'
import { Animation } from 'some-utils/Animation'
import { aRange } from 'some-utils/iterators'
import { lerp } from 'some-utils/math'
import { useEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { responsiveObs, useResponsive } from 'state/layout'
import { projectScrollObs, projectScrollHeightObs, projectScrollToPixel } from 'state/scroll'
import { svgf } from 'utils'
import './ProgressionLadder.css'

export const createProgressionLadderHandler = (scrollingElement: HTMLElement) => {
  const anchors = [...scrollingElement.querySelectorAll('.Project > *')]
    .filter(div => div.classList.contains('SectionAnchor')) as HTMLElement[]
  const contents = [
      scrollingElement.querySelector('.ProjectCover') as HTMLElement,
    ...anchors,
  ]
  const computeLadderIndex = () => {
    const height = projectScrollHeightObs.value
    const safetyMargin = 10
    const threshold = projectScrollObs.value * height + responsiveObs.value.gridPadding.top + safetyMargin
    let index = 0
    for (const content of contents) {
      const x = content.offsetTop
      if (x > threshold) {
        break
      }
      index++
    }
    return index - 1
  }
  return {
    contents,
    computeLadderIndex,
    get count () { return contents.length },
  }
}

export const ProgressionLadder = ({
  projectRef,
  spacing = 16,
}: {
  projectRef: RefObject<HTMLElement>,
} & Partial<{
  spacing: number
}>) => {

  const svgRef = useRef<SVGSVGElement>(null)
  const { gridPadding } = useResponsive()
  useEffects(function* () {
    const handler = createProgressionLadderHandler(projectRef.current!)
    const { count } = handler
    const computeY = (index: number) => (index - (count - 1) / 2) * spacing
    const height = (count + 1) * spacing
    const width = gridPadding.right
    const svg = svgRef.current!
    const state = {
      /** 
       * `clickTimestamp` is for preventing auto positionning after a click.
       * The `yObs` value is automatically updated when the project scroll change. 
       * But this is slow, too slow on a click (user feedback). So, after a click
       * update immediately `yObs` and prevent `projectScroll` from doing the same.
       */
      clickTimestamp: -1,
      clickIsRecent: () => Date.now() - state.clickTimestamp < 1000,
    }
    svgf(svg, {
      innerHTML: '',
      height,
      viewBox: `${-width / 2} ${-height / 2} ${width} ${height}`,
    })
    for (const index of aRange(count)) {
      const y = computeY(index)
      svgf('line', {
        parent: svg,
        stroke: 'white',
        x1: '-3',
        x2: '3',
        opacity: '.333',
        y1: y.toFixed(),
        y2: y.toFixed(),
      })
      const smallCircle = svgf('circle', {
        parent: svg,
        fill: 'white',
        cy: y.toFixed(),
        r: 3,
        opacity: 0,
      })
      const button = svgf('rect', {
        parent: svg,
        className: 'button',
        opacity: 0,
        // fill: '#f006',
        x: -spacing,
        y: (y - spacing / 2).toFixed(),
        width: 2 * spacing,
        height: spacing,
      })
      const hoverEnter = () => {
        Animation.duringWithTarget(smallCircle, .1, ({ progress }) => {
          smallCircle.setAttributeNS(null, 'opacity', progress.toFixed(2))
        })
      }
      const hoverLeave = () => {
        Animation.duringWithTarget(smallCircle, .1, ({ progress }) => {
          smallCircle.setAttributeNS(null, 'opacity', (1 - progress).toFixed(2))
        })
      }
      button.onmouseenter = () => {
        hoverEnter()
      }
      button.onmouseleave = () => {
        hoverLeave()
      }
      button.onclick = () => {
        const x = handler.contents[index].offsetTop - responsiveObs.value.gridPadding.top
        projectScrollToPixel(x)
        yObs.setValue(computeY(index))
        state.clickTimestamp = Date.now()
      }
    }
    const whiteCircle = svgf('circle', { parent: svg, fill: 'white', r: 4 })
    const yObs = new ObservableNumber(computeY(handler.computeLadderIndex()))
    let y = yObs.value
    const update = () => {
      whiteCircle.setAttributeNS(null, 'cy', y.toFixed(1))
      svg.style.transform = `translateY(${-y.toFixed(1)}px)`
    }
    yield projectScrollObs.withStepValue(1e-5, () => {
      if (state.clickIsRecent() === false) {
        const index = handler.computeLadderIndex()
        yObs.setValue(computeY(index))
      }
    })
    yield yObs.onChange(value => {
      const startY = y
      Animation.duringWithTarget(yObs, .25, ({ progress }) => {
        const alpha = Animation.getMemoizedEase('out3')(progress)
        y = lerp(startY, value, alpha)
        update()
      })
    })
    update()
  }, [projectRef.current, gridPadding.right])

  return (
    <div className='ProgressionLadder flex center' style={{ width: `${gridPadding.right}px` }}>
      <svg ref={svgRef}></svg>
    </div>
  )
}
