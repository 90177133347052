import { forwardRef } from 'react'
import { Animation, mEase } from 'some-utils/Animation'
import { useEffects } from 'some-utils/npm/react'
import { Observable } from 'some-utils/observables'
import './ScrollInvitation.css'

type Props = Partial<{
  label: string
  scrollObs: Observable<number>
  /** The scroll threshold, when the value is exceeded, the component will fade out. */
  scrollThreshold: number
}>

export const ScrollInvitation = forwardRef<HTMLDivElement, Props>(({
  label,
  scrollObs,
  scrollThreshold = 10,
}: Props, outerRef) => {
  const { ref } = useEffects<HTMLDivElement>(function*(div) {
    // Ref transmission:
    if (outerRef) {
      if (typeof outerRef === 'function') {
        outerRef(div)
      } else {
        outerRef.current = div
      }
    }

    // Animation:
    if (scrollObs) {
      yield scrollObs.onVerify({
        execute: true,
        verify: scroll => scroll < scrollThreshold,
        onEnter: () => {
          Animation.duringWithTarget(div, [.75, .25], ({ progress }) => {
            div.style.transform = `translateY(${((1 - mEase('out3')(progress)) * 20).toFixed(1)}px)`
            div.style.opacity = mEase('inout3')(progress).toFixed(2)
          })
        },
        onLeave: () => {
          Animation.duringWithTarget(div, .25, ({ progress }) => {
            div.style.transform = `translateY(${(mEase('in3')(progress) * -50).toFixed(1)}px)`
            div.style.opacity =  (1 - mEase('inout3')(progress)).toFixed(2)
          })
        },
      })
    }
  }, [])
  
  return (
    <div ref={ref} className='ScrollInvitation flex column center ui' style={{ opacity: '0' }}>
      {label && (
        <div className='Label'>
          {label}
        </div>
      )}
      <svg width="83" height="29" viewBox="0 0 83 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L41.5 28L82 1" stroke="currentColor" strokeOpacity="0.5" />
      </svg>
    </div>
  )
})
