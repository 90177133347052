import { useMemo, useState } from 'react'
import * as THREE from 'three'
import { MarchingCubes } from 'three/examples/jsm/objects/MarchingCubes'

import { ObservableNumber } from 'some-utils/observables'
import { timer, useEffects } from 'some-utils/npm/react'

import { useLiveInspectorBundle } from 'live-inspector'

import { defaultScrollSettings, development, THREE_SCENE_HEIGHT } from 'config'
import { globalData } from 'data'
import { useAppContext } from 'AppContext'
import { projectObs } from 'state/navigation'
import { MetaballSettings } from 'scroll'
import { homeScrollObs, getScrollSettings, projectScrollObs } from 'state/scroll'
import { getEnvironment, renderEnvironment } from './environment'
import { applyMetaballMaterialSettings } from './utils'
import { metaballTween } from './tween'
import { computeMetaballResolution } from './quality'

const createDebugDisplay = () => {
  const div = document.createElement('div')
  const style = document.createElement('style')
  style.innerHTML = /* css */`
    #debug-display {
      position: fixed;
      right: 0;
      padding: 32px;
      min-width: 300px;
      min-height: 200px;
      background-color: #3009;
      color: white;
      white-space: pre;
      font-family: 'Fira Code', monospace;
      font-size: 12px;
    }
  `
  div.id = 'debug-display'
  document.body.append(div)
  document.head.append(style)
  const destroy = () => {
    div.remove()
    style.remove()
  }
  return {
    div,
    destroy,
  }
}

export const Metaball = () => {

  // const initialeResolution = useResponsive().mobile ? 40 : 80
  const initialeResolution = 80

  const bundle = useLiveInspectorBundle({
    name: 'Metaball',
    collapsed: true,
  }, {
    ...defaultScrollSettings.metaball,
    resolution: initialeResolution,
    // position: {
    //   value: new THREE.Vector3(1, 2, 3),
    //   min: new THREE.Vector3(-3, -2, 1),
    //   max: new THREE.Vector3(10, 20, 30),
    //   debug: true,
    // },
  }, {
    valueMapper: [
      {
        condition: value => value instanceof THREE.Vector3,
        transform: value => {
          const { x, y, z } = value as THREE.Vector3
          return { x, y, z }
        },
        restore: value => {
          const { x, y, z } = value
          return new THREE.Vector3(x, y, z)
        }
      },
    ],
    propsMeta: {
      numOfBlobs:
        { integer: true, min: 0 },
      resolution:
        { integer: true, min: 10, max: 100 },
      randomSeed:
        { integer: true, step: 10000000 },
      subtractBase:
        { min: -20, max: 80, step: .5 },
      deviation:
        { min: -1, max: 1, step: .05, format: 'F3' },
      metalness:
        { min: 0, max: 1, step: .1, format: 'F3' },
      roughness:
        { min: 0, max: 1, step: .1, format: 'F3' },
      reflectivity:
        { min: 0, max: 1, step: .1, format: 'F3' },
      clearcoat:
        { min: 0, max: 1, step: .1, format: 'F3' },
      clearcoatRoughness:
        { min: 0, max: 1, step: .1, format: 'F3' },
      envMapIntensity:
        { min: 0, max: 1, step: .1, format: 'F3' },
      emissiveIntensity:
        { min: 0, max: 1, step: .1, format: 'F3' },
      sheen:
        { min: 0, max: 1, step: .1, format: 'F3' },
      sheenRoughness:
        { min: 0, max: 1, step: .1, format: 'F3' },
      opacity:
        { min: 0, max: 1, step: .1, format: 'F3' },
    },
  })



  const material = useMemo(() => new THREE.MeshPhysicalMaterial({
    color: '#033',
    envMap: getEnvironment(),
    envMapIntensity: 1,
    reflectivity: 0,
    roughness: 1,
    metalness: 1,
    clearcoat: 1,
    // transmission: 1,
    clearcoatRoughness: 0,
    emissive: '#000',
    emissiveIntensity: 0,
    sheen: 1,
    sheenColor: new THREE.Color('#0f0'),
    sheenRoughness: .5,
    opacity: 1,
    transparent: true,
  }), [])

  // Resolution change need to repaint the whole component. So useState for that.
  const [resolution, setResolution] = useState(initialeResolution)
  useEffects(function* () {
    yield bundle.onChange(() => {
      setResolution(bundle.getProperty('resolution'))
    })
  }, [resolution])

  const { qualityDegradationLevelObs } = useAppContext()

  const marchingCubes = useMemo(() => {
    const enableUvs = false // weird artifacts on high-res
    const enableColors = false // colors are interpreted as displacement?
    const marchingCubes = new MarchingCubes(computeMetaballResolution(resolution, qualityDegradationLevelObs.value), material, enableUvs, enableColors, 100000)
    marchingCubes.scale.setScalar(THREE_SCENE_HEIGHT * 0.7)
    // Typescript can't see the "update" function.
    // https://github.com/mrdoob/three.js/blob/master/examples/jsm/objects/MarchingCubes.js#L817
    return marchingCubes as MarchingCubes & { update: () => void }
  }, [material, qualityDegradationLevelObs.value, resolution])

  const { ref } = useEffects<THREE.Group>(function* (group) {

    const settings = bundle.getProperties()
    settings.randomSeed = globalData.attributes.CoverSettings.metaball?.randomSeed ?? settings.randomSeed

    const marchingCubesGroup = new THREE.Group()
    marchingCubesGroup.add(marchingCubes)

    // NOTE: Actually unused. Background is automatically updated via the "cascading props" object.
    // const background = new THREE.Mesh(new THREE.BoxGeometry(100, 100, 100), new THREE.MeshBasicMaterial({
    //   color: 'black',
    // }))
    // marchingCubesGroup.add(background)

    group.add(marchingCubesGroup)

    applyMetaballMaterialSettings(material, marchingCubesGroup, settings)

    marchingCubes.onBeforeRender = renderer => renderEnvironment(renderer)

    yield () => material.dispose()
    yield () => group.clear()

    const currentIndexObs = new ObservableNumber(0)
    const progressionObs = new ObservableNumber(0)

    const getInfo = (): string => {
      return [
        'Metaball',
        `homeScroll: ${homeScrollObs.value.toFixed(3)}`,
        `home.current: ${currentIndexObs.value} /${(getScrollSettings(currentIndexObs.value)).metadata.slug}`,
        `home.next: ${currentIndexObs.value + 1} /${(getScrollSettings(currentIndexObs.value + 1)).metadata.slug}`,
        `home.progression: ${progressionObs.value.toFixed(3)}`
      ].join('\n')
    }

    const debug = false
    if (development && debug) {
      const debugDisplay = createDebugDisplay()
      yield debugDisplay
      yield timer.onFrame(() => {
        debugDisplay.div.innerHTML = getInfo()
      })
    }
    
    yield homeScrollObs.withStepValue(1e-4, value => {
      const currentIndex = Math.floor(value)
      const progression = value % 1
      currentIndexObs.setValue(currentIndex)
      progressionObs.setValue(progression)
      metaballTween.scrollProgressionObs.setValue(progression)
      
      // Update the "current" & "next" state on each tiny scroll moves (but not
      // at each frame to allow live inspector usage).
      if (metaballTween.isCurrentlyAnimated() === false) {
        metaballTween.updateCurrentAndNextState(
          getScrollSettings(currentIndexObs.value).metaballSettings,
          getScrollSettings(currentIndexObs.value + 1).metaballSettings)
      }
    })

    yield projectScrollObs.onStepChange(1e-4, value => {
      metaballTween.fadeoutProgressionObs.setValue(value)
    })

    // live-inspector: 
    // From time to time, update the live inspector.
    yield timer.onFrame({ skip: 20 }, () => {
      // Be sure to NOT interpolate randomSeed and numOfBlobs.
      const { randomSeed, numOfBlobs } = metaballTween.currentState
      const props = { ...metaballTween.tweenState, randomSeed, numOfBlobs }
      bundle.updateProperties(props)
    })
    yield currentIndexObs

    yield projectObs.withValue(project => {
      if (project) {
        // Entering a project. Setup the metaball with the right settings.
        const metaballSettings = project.rawData.attributes.coverSettings?.metaball
        metaballTween.updateCurrentAndNextState(metaballSettings, {})
      } else {
        // Leaving a projet (going home?). Update the metaball from the scroll.
        metaballTween.toStateAnimation(getScrollSettings(currentIndexObs.value).metaballSettings)
      }
    })

    // live-inspector: 
    // When the user changes props, apply them to the current state.
    yield bundle.onChange(() => {
      const settings = bundle.getProperties() as Partial<MetaballSettings>
      // Remove useless properties:
      delete settings.envMapUrl
      Object.assign(metaballTween.currentState, settings)
    })

    yield qualityDegradationLevelObs.withValue(level => {
      marchingCubes.init(computeMetaballResolution(resolution, level))
    })

    // The big update:
    yield timer.onChange(({ deltaTime }) => {
      if (projectObs.value !== null) {
        progressionObs.value += (0 - progressionObs.value) * .5
      }

      // 1. Update the "tween" state.
      metaballTween.update(deltaTime)
      // 2. Apply material props.
      applyMetaballMaterialSettings(material, marchingCubesGroup, metaballTween.tweenState)
      // 3. Update the metaball "balls" (blobs).
      marchingCubes.reset()
      for (const blob of metaballTween.blobs) {
        marchingCubes.addBall(blob.x, blob.y, blob.z, blob.strength, blob.subtract)
      }

      marchingCubes.update()
    })

  }, [resolution])

  return (
    <group ref={ref} />
  )
}