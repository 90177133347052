import { Property, ValueFormat } from '../types'

export const castNumber = (x: any) => {
  return typeof x === 'number' ? x : null
}

export const assertNumber = (x: any) => {
  if (typeof x !== 'number') {
    throw new Error(`This is not a number! Plz fix something somewhere.`)
  }
  return x
}

export const formatValue = (value: number, format: ValueFormat) => {
  if (format.startsWith('F')) {
    return value.toFixed(Number.parseInt(format.substring(1)))
  }
  if (format === 'Int') {
    return value.toFixed(0)
  }
  throw new Error(`Invalid 'ValueFormat': ${format}`)
}

export const getNumberInputProps = (property: Property, subKey = '') => {
  const {
    value,
    min,
    max,
    step = 1,
    stepped = false,
    integer = false,
    format = integer ? 'Int' : 'F1',
    debug = false,
  } = property
  try {
    return {
      initialValue: assertNumber(subKey ? value[subKey] : value),
      min: castNumber(subKey ? min?.[subKey] : min) ?? -Infinity,
      max: castNumber(subKey ? max?.[subKey] : max) ?? Infinity,
      integer,
      step,
      stepped,
      format,
      debug,
    }
  } 
  catch (error) {
    console.log(property, subKey)
    throw error
  }
}
