import { useState } from 'react'

export function useReelState() {
  const [reelIndex, setReelIndex] = useState(0)
  const [videoIsReady, setVideoIsReady] = useState(false)
  const set = ({ reelIndex: _reelIndex = reelIndex, videoIsReady: _videoIsReady = videoIsReady }) => {
    setReelIndex(_reelIndex)
    setVideoIsReady(_videoIsReady)
  }
  return { reelIndex, videoIsReady, set }
}
