
/**
 * Usage: 
 * ```
 * matchColumnCount({
 *   '*': '100px',    // Wildcard: low-priority
 *   '3-4': '50px',   // Range: mid-priority
 *   '1': '0',        // Number: high-priority
 * }, columnsCount),
 * ```
 */
export function matchColumnCount<T>(options: Record<string, T>, columnCount: number): T {
  enum KeyType {
    WildCard = 0,
    Range = 1,
    Number = 2
  }

  const getKeyType = (key: string): KeyType => {
    if (key === '*') {
      return KeyType.WildCard
    }
    if (/^\d-\d$/.test(key)) {
      return KeyType.Range
    }
    if (/^\d$/.test(key)) {
      return KeyType.Number
    }
    throw new Error(`Invalid key! ${key}`)
  }

  const keyMatch = (key: string, keyType: KeyType, columnCount: number): boolean => {
    if (keyType === KeyType.WildCard)
      return true
    if (keyType === KeyType.Range) {
      const [min, max] = key.split('-').map(s => Number.parseInt(s))
      return columnCount >= min && columnCount <= max
    }
    if (keyType === KeyType.Number) {
      const n = Number.parseInt(key)
      return columnCount === n
    }
    throw new Error(`Invalid keyType! ${keyType}`)
  }

  const entries = Object
    .entries(options)
    .map(([key, value]) => {
      const keyType = getKeyType(key)
      return { key, keyType, value }
    })
    .sort((a, b) => {
      return b.keyType - a.keyType
    })
  for (const { key, keyType, value } of entries) {
    if (keyMatch(key, keyType, columnCount)) {
      return value
    }
  }
  
  throw new Error(`Invalid "options", no solution for columnCount = ${columnCount}!`)
}
