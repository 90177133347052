
import { useEffects, useObservable } from 'some-utils/npm/react'

import { useAppContext } from 'AppContext'
import { development, staticRoutes } from 'config'
import { globalData, newsCategoriesNames } from 'data'
import { localeObs, navigate } from 'state/navigation'
import { resetUnifiedSearch, searchUnified, unifiedSearchState } from 'state/unified-search'

import { useResponsive } from 'state/layout'
import { MobileResults } from './MobileResults'

import './UnifiedSearch.css'

function EntriesColumn({
  title = 'A title',
  entries = [],
  noEntriesPlaceholder = '...',
}: Partial<{
  title: string
  entries: [title: string, description: string, url: string][]
  noEntriesPlaceholder: string
}>) {
  return (
    <div className='EntriesColumn flex-1 flex column overflow-hidden'>
      <div className='ColumnTitle uppercase ui ui-dim'>{title}</div>
      <div className='Results flex column'>
        {entries.length > 0
          ? entries.map(([title, description, url], index) => (
            <div
              key={index}
              className='Result ui ui-uppercase flex row'
              onClick={() => {
                navigate(url)
              }}
            >
              <div className='ResultTitle'>{title.replace(/\s+/, ' ')}</div>
              <div className='ResultDescription'>{description.replace(/\s+/, ' ')}</div>
            </div>
          ))
          : (
            <div className='NoResult ui ui-uppercase'>{noEntriesPlaceholder}</div>
          )}
      </div>
    </div>
  )
}

function Main() {
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    yield unifiedSearchState.visibleObs.withValue(visible => {
      if (visible) {
        div.querySelector('input')?.focus()
      }
    })
    yield unifiedSearchState.inputObs.onChange(input => {
      if (input.length === 0) {
        resetUnifiedSearch()
      }
      else if (input.length >= 3) {
        searchUnified(input, { locale: localeObs.value, debug: development })
      }
    })
  }, [])

  const { mobile } = useResponsive()
  const searchResult = useObservable(unifiedSearchState.resultObs)
  const noResultsPlaceholder = useObservable(unifiedSearchState.inputObs).length === 0 ? '...' : (globalData.attributes.SearchNoResult ?? '...')
  return (
    <div ref={ref} className='Main absolute-fill grid-padding flex column'>
      <div className='Top flex column'>
        <input
          type='text'
          onChange={event => unifiedSearchState.inputObs.setValue(event.target.value)}
          value={useObservable(unifiedSearchState.inputObs)}
        />
      </div>
      {mobile ? (
        <MobileResults searchResult={searchResult} />
      ) : (
        <div className='Bottom flex row overflow-hidden'>
          <EntriesColumn
            title={globalData.attributes.MenuNameProject}
            entries={searchResult.projects.map(project => {
              const { coverTitle, coverSubtitle, searchCategory, slug } = project.data.attributes
              return [coverTitle, searchCategory ?? coverSubtitle, slug]
            })}
            noEntriesPlaceholder={noResultsPlaceholder}
          />
          <EntriesColumn
            title={globalData.attributes.MenuNameNews}
            entries={searchResult.allNews.map(news => {
              const { Title, NewsCategory, Slug } = news.data.attributes
              return [Title, newsCategoriesNames[NewsCategory], `${staticRoutes.news}/${Slug}`]
            })}
            noEntriesPlaceholder={noResultsPlaceholder}
          />
        </div>
      )}
    </div>
  )
}

export function UnifiedSearch() {
  const app = useAppContext()

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    let previousTheme = app.themeObs.value
    yield unifiedSearchState.visibleObs.withValue(visible => {
      div.classList.toggle('visible', visible)
      if (visible) {
        previousTheme = app.themeObs.value
        app.themeObs.setValue('light')
      } else {
        app.themeObs.setValue(previousTheme)
      }
    })
  }, [])

  return (
    <div ref={ref} className='UnifiedSearch absolute-fill'>
      <Main />
    </div>
  )
}