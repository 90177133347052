import React from 'react'
import { strapi } from 'config'
import { Spacing } from 'view/components'
import { useResponsive } from 'state/layout'
import { solveLayoutJson } from 'utils/solveLayoutJson'

export const Spacer: React.FC<{
  content: strapi.Spacer
}> = ({
  content,
}) => {
  const { columnsCount } = useResponsive()
  return (
    <div className='ProjectContent Spacer'>
      <Spacing size={solveLayoutJson<number>(columnsCount, content.size) ?? 0} />
    </div>
  )
}


