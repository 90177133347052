import { CSSProperties } from 'react'
import { range } from 'some-utils/iterators'

const urlGrid = (url: string) => {
  const m = url.match(/\d+x\d+/)
  if (m) {
    const [width, height] = m[0].split('x').map(str => Number.parseInt(str))
    return { width, height }
  }
  return null
}

const safeRatio = 1.05
export const getBackgroundOptions = (url: string) => {
  const grid = urlGrid(url)
  const options: CSSProperties[] = []
  if (grid === null) {
    // Simple:
    options.push({
      backgroundImage: `url(${url})`,
    })
  } else {
    // Grid:
    const { width, height } = grid
    const count = width * height
    for (const index of range(count)) {
      const x = index % width
      const y = Math.floor(index / width)
      const rw = 1 - 1 / width / safeRatio
      const rh = 1 - 1 / height / safeRatio
      const left = (x + (safeRatio - 1) / 2 / safeRatio) / width / rw
      const top = (y + (safeRatio - 1) / 2 / safeRatio) / height / rh
       options.push({
        backgroundImage: `url(${url})`,
        backgroundSize: `${(width * 100 * safeRatio).toFixed(1)}% ${(height * 100 * safeRatio).toFixed(1)}%`,
        backgroundPosition: `${(left * 100).toFixed(1)}% ${(top * 100).toFixed(1)}%`
      })
    }
  }
  return options
}
