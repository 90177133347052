import { globalData, onDataInitialized } from 'data'
import { useObservable } from 'some-utils/npm/react'
import { Observable } from 'some-utils/observables'
import { ArraySolver, useArraySolverImmutableChild } from 'some-utils/solvers'

export const uiSettings = {
  "color": {
    "--ui-default": "#e5e7ebcc",
    "--ui-default-max": "#e5e7ebff",
    "--ui-default-dim": "#e5e7eb50",
    "--ui-highlight": "#fffd50cc",
    "--ui-highlight-max": "#fffd50ff",
    "--ui-highlight-dim": "#fffd5050",
    "--ui-link": "#76EAE9cc",
    "--ui-link-max": "#76EAE9ff",
    "--ui-link-dim": "#76EAE950",
    "--ui-tag": "#da5f64cc",
    "--ui-tag-max": "#da5f64ff",
    "--ui-tag-dim": "#da5f645"
  }
}

// Load css variables from Strapi.
onDataInitialized.add(() => {
  const {
    UiCssVariables,
    UiCssVariablesLightTheme = '',
  } = globalData.attributes

  const cssVariablesEntries = UiCssVariables
    .split('\n')
    .filter(str => /\w/.test(str))
    .map(str => {
      const [key, value] = str.split(/\s*:\s*/)
      return [key.trim(), value.replace(/;/, '').trim()] as [string, string]
    })

  const cssVariables = Object.fromEntries(cssVariablesEntries)
  for (const key in cssVariables) {
    uiSettings.color[key as keyof typeof uiSettings.color] = cssVariables[key]
  }

  const style = document.createElement('style')
  style.id = 'ui-css-variables'
  style.innerHTML = /* css */`
    .dark-theme {
      --ui-color: var(--ui-default);
      --ui-color-max: var(--ui-default-max);
      --ui-color-dim: var(--ui-default-dim);
      color: var(--ui-color);
      ${UiCssVariables}
    }
    .light-theme {
      --ui-color: var(--ui-default);
      --ui-color-max: var(--ui-default-max);
      --ui-color-dim: var(--ui-default-dim);
      color: var(--ui-color);
      ${UiCssVariablesLightTheme}
    }
  `
  document.head.append(style)
})

// This should be removed:
export const primaryColor = new Observable('#9298A0')
export const secondaryColor = new Observable('#fffd50')
export const backgroundColor = new Observable('#111')
export const hudOpen = new Observable(false)

export const hudOpening = new ArraySolver(false, children => children.some(c => c.value))
export const hudOpeningHoverObs = hudOpening.createChild(false)

export const ovvoLogoOpening = new ArraySolver(false, children => children.some(c => c.value))

export const useSetHudOpeningTrue = () => {
  // force HUD opening
  useArraySolverImmutableChild(hudOpening, true)
}

export function usePrimaryColorWithAlpha(alpha: number) {
  const color = useObservable(primaryColor)
  return color + Math.round(0xff * alpha).toString(16).padStart(2, '0')
}