import React, { SVGProps, useMemo } from 'react'
import { handleKeyboard, handlePointer } from 'some-utils/dom'
import { pointerInfo } from 'some-utils/dom/pointer'
import { Point } from 'some-utils/geom'
import { aRange } from 'some-utils/iterators'
import { Message } from 'some-utils/message'
import { timer, useEffects, useObservable } from 'some-utils/npm/react'
import { ObservableObject } from 'some-utils/observables'
import { useLayout, useResponsive } from 'state/layout'



const VLine = ({ x, ...props }: { x: number } & SVGProps<SVGLineElement>) => (
  <line x1={x} x2={x} y1={0} y2='100%' {...props} />
)
const HLine = ({ y, ...props }: { y: number } & SVGProps<SVGLineElement>) => (
  <line x1={0} x2='100%' y1={y} y2={y} {...props} />
)

// export let shift = false
// window.addEventListener('keydown', event => {
//   if (event.key === 'Shift') {
//     shift = true
//   }
// })
// window.addEventListener('keyup', event => {
//   if (event.key === 'Shift') {
//     shift = false
//   }
// })

class GridCursor extends Point {
  visible = true
  constructor(x = 0, y = 0, visible = true) {
    super(x, y)
    this.visible = visible
  }
}

const Inner = () => {

  const state = useMemo(() => ({
    cursor1Obs: new ObservableObject(new GridCursor(window.innerWidth / 2, window.innerHeight / 2)),
    cursor2Obs: new ObservableObject(new GridCursor(window.innerWidth / 2, window.innerHeight / 2, false)),
  }), [])

  useEffects(function* () {
    const app = document.querySelector('.App') as HTMLDivElement
    yield pointerInfo.isDown.onChange(value => {
      app.style.userSelect = value ? 'none' : ''
    })
    yield timer.onFrame(() => {
      if (pointerInfo.isDown.value) {
        const { x, y } = pointerInfo.position.value
        state.cursor2Obs.updateValue({ x, y })
      }
    })
    yield handlePointer(window, {
      onTap: () => {
        state.cursor2Obs.updateValue({ visible: !state.cursor2Obs.value.visible })
      },
    })
  }, [])

  const layout = useLayout()
  const rule = useResponsive()
  const { gridPadding } = rule
  const cursor1 = useObservable(state.cursor1Obs)
  const cursor2 = useObservable(state.cursor2Obs)

  return (
    <svg
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: 'var(--vh)',
        zIndex: 10,
        pointerEvents: 'none',
      }}
      fill='none'
      stroke='#f695ff66'
    >
      <HLine y={gridPadding.top} />
      <VLine x={layout.size.width - gridPadding.right} />
      <HLine y={layout.size.height - gridPadding.bottom} />
      <VLine x={gridPadding.left} />

      <HLine opacity={.4} y={gridPadding.top / 2} />
      <VLine opacity={.4} x={layout.size.width - gridPadding.right / 2} />
      <HLine opacity={.4} y={layout.size.height - gridPadding.bottom / 2} />
      <VLine opacity={.4} x={gridPadding.left / 2} />

      <VLine x={cursor1.x} />
      <HLine y={cursor1.y} />
      {cursor2.visible && (
        <>
          <VLine x={cursor2.x} />
          <HLine y={cursor2.y} />
        </>
      )}
      {aRange(rule.columnsCount - 1).map(index => {
        const x = gridPadding.left + layout.columnWidth * (index + 1)
        return (
          <line
            key={index}
            x1={x}
            x2={x}
            y1={gridPadding.top}
            y2={layout.size.height - gridPadding.bottom}
            opacity={.4}
          />
        )
      })}
    </svg>
  )
}

export const DebugGrid = () => {
  const [visible, setVisible] = React.useState(false)
  useEffects(function* () {
    yield Message.on('APP', 'TOGGLE-DEBUG-GRID', ({ props = {} }) => {
      setVisible(props.toggle ?? !visible)
    })
    yield handleKeyboard({
      onDown: [
        ['LetterG', () => {
          // NOTE: Toggle the debug grid only when nothing is focused (eg: no text input).
          if (document.activeElement === document.body) {
            setVisible(!visible)
          }
        }],
      ],
    })
  }, [visible])

  return visible ? <Inner /> : null
}

Object.assign(window, {
  toggleDebugGrid: (toggle?: boolean) => Message.send('APP', 'TOGGLE-DEBUG-GRID', { toggle }),
})