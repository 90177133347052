import { globalData } from 'data'
import { LerpMetaballMode, lerpMetaballSettings } from './utils'
import { defaultMetaballSettings, MetaballSettings } from 'scroll'
import { ObservableNumber } from 'some-utils/observables'
import { lerp, PRNG, sin02 } from 'some-utils/math'
import { deepClone } from 'some-utils/object'
import { Animation } from 'some-utils/Animation'
import { coverVisibilityObs } from 'state/scroll'
import { projectObs } from 'state/navigation'
import { requireTexture } from '../texture-manager'
import { getOutThenGetIn } from './getOutThenGetIn'
import { updateEnviromnent } from './environment'
import defaultJson from './default.json'
import defaultFadeoutJson from './default-fadeout.json'

const ignoredKeys = ['randomSeed', 'numOfBlobs', 'envMapUrl']
// const allKeys = Object.keys(defaultJson)
// const allFadeoutKeys = Object.keys(defaultFadeoutJson)
// const allKeysNotInFadeout = allKeys.filter(k => (allFadeoutKeys as any[]).includes(k) === false)
// const fadeoutIgnoredKeys = [...new Set([...ignoredKeys, ...allKeysNotInFadeout])]

const createState = () => deepClone(defaultMetaballSettings) as MetaballSettings

/** The "current" state means the one that is currently displayed, or fading out. */
const currentState = createState()
/** The "next" state obviously is the one that is coming (or is still completely hidden). */
const nextState = createState()
/** 
 * The "tween" state is a mix of the two state above.
 * NOTE: The tween state is used essentially to lerp "graphic" values (such as colors, 
 * intensity, and some geometrical values as deviation etc.). The main geometrical 
 * values, `randomSeed` & `numOfBlobs` are interpolated in a separate way from blob
 * to blob (cf lerpBlobs)
 */
const tweenState = createState()

const timeObs = new ObservableNumber(0)
const scrollProgressionObs = new ObservableNumber(0)
const fadeoutProgressionObs = new ObservableNumber(0)

/**
 * Returns *cached-by-project* fadeout settings.
 * 
 * The logic is the following, for the entries, use values from:
 * - default-fadeout.json (hard coded)
 * - override with values from the back-end "global" settings (if they exist).
 * - override with values from the back-end "per project" settings (if they exist).
 */
const getCurrentMetaballFadeoutSettings = (() => {
  const metaballFadeoutSettingsCache = new Map<string, typeof defaultJson>()
  const defaultSettings = {
    ...defaultFadeoutJson,
    ...globalData.attributes.MetaballFadeoutSettings,
  }
  return () => {
    const project = projectObs.value
    if (project === null) {
      return defaultSettings
    }
    const { slug } = project
    const settings = metaballFadeoutSettingsCache.get(slug)
    if (settings !== undefined) {
      return settings
    } else {
      const settings = {
        ...defaultSettings,
        ...project.attributes.MetaballFadeoutSettings,
      }
      metaballFadeoutSettingsCache.set(slug, settings)
      return settings
    }
  }
})()

type Blob = {
  x: number
  y: number
  z: number
  strength: number
  subtract: number
}
const currentBlobs: Blob[] = []
const nextBlobs: Blob[] = []
const blobs: Blob[] = []

/**
 * Push the blob ONLY if strength > 0 (otherwise it has no effect, so skip it).
 */
const pushBlob = (blob: Blob) => {
  if (blob.strength > 0) {
    blobs.push(blob)
  }
}

// NOTE: Using multiple PRNG to not break the design (based on the seed).
const prng1 = new PRNG()
const prng2 = new PRNG()

const applyRotation = (x: number, y: number, centerX: number, centerY: number, cos: number, sin: number) => {
  const x1 = x - centerX
  const y1 = y - centerY
  const x2 = x1 * cos + y1 * -sin
  const y2 = x1 * sin + y1 * cos
  return [x2 + centerX, y2 + centerY]
}

/**
 * Update the given blobs array, according to a "state" (the current or the next one).
 * @param time The time value, for continuous (time based) animation .
 * @param scrollProgression A number [0, 1] that represents the "local" scroll progression.
 */
const updateBlobs = (time: number, scrollProgression: number, visibility: number, state: MetaballSettings, blobs: Blob[]) => {
  let {
    randomSeed,
    numOfBlobs,
    subtractBase,
    noiseMovementAmplitudeXY,
    noiseMovementAmplitudeZ,
    deviation,
    deviationPower,
    aspect,
    deviationScaleZ,
  } = state
  deviation += lerp(.5, 0, visibility)
  prng1.reset(randomSeed)
  prng2.reset(randomSeed)
  blobs.length = numOfBlobs
  const pSin02 = sin02(scrollProgression) // goes from 0 to 1 then 0 (sinus).
  const angle = state.rotationZ * Math.PI / 180
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)
  for (let i = 0; i < numOfBlobs; i++) {
    const subtract = subtractBase + prng1.range(8, 12) + 4 * sin02(scrollProgression)
    const strengthBase = 0.5 - 0.2 * sin02(scrollProgression)
    const xBase = prng1.around({ from: 0.5, deviation: deviation * Math.sqrt(aspect), power: deviationPower })
    const yBase = prng1.around({ from: 0.5, deviation: deviation / Math.sqrt(aspect), power: deviationPower })
    const zBase = prng1.around({ from: 0.5, deviation: deviation * deviationScaleZ, power: deviationPower })
    const strengthTimeDelta = .05 * sin02(time * prng2.range(1, 2) * .5 + prng2.float() * 10)
    const xTimeDelta = lerp(.005, .05, pSin02) * sin02(time * prng2.range(.5, 1) + prng2.float()) * noiseMovementAmplitudeXY
    const yTimeDelta = lerp(.005, .05, pSin02) * sin02(time * prng2.range(.5, 1) + prng2.float()) * noiseMovementAmplitudeXY
    const zTimeDelta = lerp(.005, .05, pSin02) * sin02(time * prng2.range(.5, 1) + prng2.float()) * noiseMovementAmplitudeZ
    const yScrollDelta = prng1.chance(.6) ? getOutThenGetIn(scrollProgression) * prng2.range(1, 2) : 0
    const xLocal = xBase + xTimeDelta
    const yLocal = yBase + yTimeDelta
    const [x, y] = applyRotation(xLocal, yLocal, .5, .5, cos, sin)
    blobs[i] = {
      x: x,
      y: y + yScrollDelta,
      z: zBase + zTimeDelta,
      strength: (strengthBase + strengthTimeDelta) * visibility,
      subtract,
    }
  }
}

const updateCurrentAndNextState = (currentStateProps: Partial<MetaballSettings>, nextStateProps: Partial<MetaballSettings>) => {
  // NOTE: Important here to apply the default metaball settings before updating 
  // with the current & next changes, otherwise, since changes are partial, some
  // props may leak from one state to another.
  Object.assign(currentState, defaultMetaballSettings, currentStateProps)
  Object.assign(nextState, defaultMetaballSettings, nextStateProps)
}

const isCurrentlyAnimated = () => Animation.hasTween(tweenState)

const toStateAnimation = (nextStateValue: Partial<MetaballSettings>, duration = 1) => {
  Object.assign(nextState, nextStateValue)
  Animation.duringWithTarget(tweenState, duration, ({ progress, deltaTime, complete }) => {
    const alpha = Animation.getMemoizedEase('inout3')(progress)
    lerpMetaballSettings(currentState, nextState, alpha, LerpMetaballMode.UseDefaultValues, {
      receiver: tweenState,
      ignoredKeys,
    })

    lerpBlobs(deltaTime, alpha)

    if (complete) {
      Object.assign(currentState, nextStateValue)
    }
  })
}

/**
 * Update the metaball:
 * - Interpolate the metaball settings (property lerp)
 * - Interpolate the blobs (from "current" to "next")
 */
const update = (deltaTime: number) => {

  if (isCurrentlyAnimated()) {
    return
  }

  const scrollProgression = scrollProgressionObs.value

  // 1. Update the "material" properties. 
  // Blend "current" & "next" state.
  lerpMetaballSettings(currentState, nextState, scrollProgression, LerpMetaballMode.UseDefaultValues, {
    receiver: tweenState,
    ignoredKeys,
  })
  // Restore the random seed from the current state (live inspector usage).
  tweenState.randomSeed = currentState.randomSeed

  // Blend with the "fadeout" state.
  const fadeout = getCurrentMetaballFadeoutSettings()
  lerpMetaballSettings(tweenState, fadeout, fadeoutProgressionObs.value, LerpMetaballMode.SkipUndefinedKeys, {
    receiver: tweenState,

    // NOTE2: Actually, with the new concept of "fadeout" settings per project we can ignore the ignored keys.
    // // NOTE: Once, Shandor use a fadeout json with some geometry properties (eg: 
    // // "globalScale"), it resulted in a weird "fadeout" animation. Fadeout keys
    // // must only concern visual properties.
    // ignoredKeys: fadeoutIgnoredKeys,
  })

  // 2. Update the "metaball" balls (blobs).
  lerpBlobs(deltaTime, scrollProgression)
}

/**
 * This is quite tricky since blobs may appear & disappear from one state to the
 * other (because "numOfBlobs" may vary).
 */
const lerpBlobs = (deltaTime: number, progression: number) => {

  const timeScale = tweenState.timeScale
  timeObs.value += deltaTime * timeScale * 0.25

  // 2.1. Update the current state of blobs
  updateBlobs(timeObs.value, scrollProgressionObs.value, coverVisibilityObs.value, tweenState, currentBlobs)
  updateBlobs(timeObs.value, scrollProgressionObs.value, coverVisibilityObs.value, nextState, nextBlobs)

  // 2.2. Interpolate the blobs
  const numOfBlobs = progression > .0001
    ? Math.max(tweenState.numOfBlobs, nextState.numOfBlobs)
    : tweenState.numOfBlobs
  blobs.length = 0
  for (let i = 0; i < numOfBlobs; i++) {
    const current = currentBlobs[i]
    const next = nextBlobs[i]
    const state = (
      i >= tweenState.numOfBlobs ? 'entering' :
        i >= nextState.numOfBlobs ? 'leaving' :
          'tweening')

    // NOTE: The strength is scaled according to the particular case of each blob (entering, leaving, etc...)
    switch (state) {
      case 'leaving': {
        // The blob does not exists on the next state. 
        // Take values from the current blob & scale down the strength.
        let { x, y, z, strength, subtract } = currentBlobs[i]
        strength *= 1 - progression
        pushBlob({ x, y, z, strength, subtract })
        break
      }

      case 'entering': {
        // The blob does not exists on the current state. 
        // Take values from the next blob & scale up the strength.
        let { x, y, z, strength, subtract } = nextBlobs[i]
        strength *= progression
        pushBlob({ x, y, z, strength, subtract })
        break
      }

      case 'tweening': {
        // The blob exists in the current and the next state.
        // Lerp the values.
        const x = lerp(current.x, next.x, progression)
        const y = lerp(current.y, next.y, progression)
        const z = lerp(current.z, next.z, progression)
        const strength = lerp(current.strength, next.strength, progression)
        const subtract = lerp(current.subtract, next.subtract, progression)
        pushBlob({ x, y, z, strength, subtract })
        break
      }
    }
  }

  lerpEnvironment(progression)
}

const lerpEnvironment = (progression: number) => {
  const url1 = currentState.envMapUrl
  const url2 = nextState.envMapUrl
  const map1 = url1 ? requireTexture(tweenState, url1) : null
  const map2 = url2 ? requireTexture(tweenState, url2) : null
  updateEnviromnent(map1, map2, progression)
}

export const metaballTween = {
  currentState,
  nextState,
  tweenState,
  scrollProgressionObs,
  fadeoutProgressionObs,
  update,
  updateCurrentAndNextState,
  isCurrentlyAnimated,
  toStateAnimation,
  blobs,
  get info() {
    return `Metaball p: ${scrollProgressionObs.value.toFixed(2)} ${blobs.length} blobs sheen: ${tweenState.sheenColor}`
  },
}

Object.assign(window, { metaballTween })
