import { Observable } from 'some-utils/observables'
import { FloatVariable } from 'some-utils/variables'

type AxisState = 'static' | 'acceleration' | 'deceleration'

enum DeltaMode {
  PIXEL = 0,
  LINE = 1,
  PAGE = 2
}

type SwipeInfo = {
  direction: -1 | 1
}

const defaultOptions = {
  capture: true,
  /** Time to wait between 2 swipe (in seconds, defaults to 1) */
  intervalMin: 1,
  deltaThreshold: 8,
}

type Options = Partial<typeof defaultOptions & {
  onVerticalSwipe: (info: SwipeInfo) => void
}>

export const handleSwipe = (element: HTMLElement, options: Options) => {
  const {
    capture,
    intervalMin,
    deltaThreshold,
    onVerticalSwipe,
  } = { ...defaultOptions, ...options }

  const VAR_SIZE = 16
  const yDeltaVar = new FloatVariable(0, { size: VAR_SIZE })
  const yAbsDeltaVar = new FloatVariable(0, { size: VAR_SIZE, derivativeCount: 1 })

  const state = {
    lastSwipeTimeStamp: -1,
  }

  const yStateObs = new Observable<AxisState>('static')
  yStateObs.onChange(ySlowingDown => {
    if (ySlowingDown === 'deceleration') {
      const dt = (Date.now() - state.lastSwipeTimeStamp) / 1e3
      if (dt > intervalMin) {
        state.lastSwipeTimeStamp = Date.now()
        onVerticalSwipe?.({ direction: yDeltaVar.average > 0 ? 1 : -1 })
      }
    }
  })

  const onWheel = (event: WheelEvent) => {
    const deltaY = event.deltaY * (event.deltaMode === DeltaMode.LINE ? 16 : 1)
    yDeltaVar.setNewValue(deltaY)
    yAbsDeltaVar.setNewValue(Math.abs(deltaY))

    const state: AxisState =
      yAbsDeltaVar.computeAverage({ cut: 2 }) < deltaThreshold
        ? 'static'
        : yAbsDeltaVar.derivative!.computeAverage({ cut: 2 }) < 0
          ? 'deceleration'
          : 'acceleration'
    yStateObs.setValue(state)
  }
  element.addEventListener('wheel', onWheel, { capture, passive: false })

  const destroy = () => {
    element.removeEventListener('wheel', onWheel, { capture })
  }
  return { destroy }
}
