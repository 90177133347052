import React from 'react'
import { safeClassName } from 'some-utils/npm/react'

export const ContentCenter: React.FC<{
  childAspect: number
  sizeMode: 'cover' | 'contain'
  childKey: any
} & React.HTMLProps<HTMLDivElement>> = ({
  childAspect, sizeMode, childKey, children, className,
}) => {
    const ref = React.useRef<HTMLDivElement>(null)

    React.useLayoutEffect(() => {
      const div = ref.current!
      const child = div.firstElementChild as HTMLElement | null
      if (child) {
        const { width, height } = div.getBoundingClientRect()
        const currentAspect = width / height
        const childProps = {
          width,
          height,
        }
        if (sizeMode === 'contain') {
          if (currentAspect < childAspect) {
            childProps.height = width / childAspect
          }
          else {
            childProps.width = height * childAspect
          }
        }
        else {
          throw new Error(`sizeMode "cover" not implemented!`)
        }
        child.style.width = `${childProps.width}px`
        child.style.height = `${childProps.height}px`
        const marginLeft = (width - childProps.width) / 2
        const marginTop = (height - childProps.height) / 2
        child.style.marginBottom =
          child.style.marginTop = marginTop ? `${marginTop}px` : ''
        child.style.marginRight =
          child.style.marginLeft = marginLeft ? `${marginLeft}px` : ''
      }
    }, [childAspect, sizeMode, childKey])

    return (
      <div
        ref={ref}
        className={safeClassName('ContentCenter', className)}
      >
        {children}
      </div>
    )
  }
