import { useMemo, useState } from 'react'
import { Point } from 'some-utils/geom'
import { handleKeyboard, handlePointer, handleTimer } from 'some-utils/dom'
import { useEffects, useLocalStorageState, useObservable } from 'some-utils/npm/react'
import { sections, storeDirtyTimestamp, hiddenObs, hideInspector, toggleInspector } from '../store'
import { Section } from './Section'
import { CloseSvg } from './svg'
import './LiveInspector.css'

const MoveableLiveInspector = () => {

  const margin = 8
  const position = useMemo(() => new Point(), [])
  const [savedPosition, setSavedPosition] = useLocalStorageState('LiveInspector.Position', { x: 0, y: 0 })
  position.copy(savedPosition)

  const { ref } = useEffects<HTMLDivElement>(function* (div) {

    const update = () => {
      const rect = div.getBoundingClientRect()
      const min = new Point(margin, margin)
      const max = new Point(window.innerWidth, window.innerHeight).subtract(min).subtract([rect.width, rect.height])
      position.clamp(min, max)
      const { x, y } = position
      div.style.transform = `translate(${x}px, ${y}px)`
    }
    update()

    yield handlePointer(div.querySelector('h1')!, {
      passive: false,
      onDown: event => event.preventDefault(),
      onDrag: info => {
        position.add(info.delta)
        setSavedPosition(position)
        update()
      },
    })

    // NOTE: Why do i need to use a addEventListener for the "pointerdown" event
    // and not a simple "onClick" attribute...well i dunno.
    yield handlePointer(div.querySelector('.Close') as HTMLElement, {
      onDown: hideInspector,
    })

  }, [])

  return (
    <div ref={ref} className='LiveInspector flex column gutter-8'>
      <div className='flex row align-center'>
        <h1 className='expand'>Live Inspector</h1>
        <div className='Close button'>
          <CloseSvg/>
        </div>
      </div>
      <p>
        {'[Shift + Ctrl + Space] -> Toggle'}
      </p>
      {[...sections.entries()].map(([key, section]) => (
        <Section key={key} section={section} />
      ))}
    </div>
  )
}

export const LiveInspector = () => {

  const [timestamp, repaint] = useState(storeDirtyTimestamp)
  const hidden = useObservable(hiddenObs)

  useEffects(function* () {

    yield handleTimer({
      interval: [100, () => {
        if (storeDirtyTimestamp > timestamp) {
          repaint(storeDirtyTimestamp)
        }
      }]
    })

    yield handleKeyboard({
      onDown: [
        ['Space', info => {
          const { shiftKey, altKey, ctrlKey } = info.event
          if ((shiftKey && altKey) || (shiftKey && ctrlKey)) {
            toggleInspector()
          }
        }]
      ],
    })

  }, [timestamp])

  if (hidden) {
    return null
  }

  return (
    <MoveableLiveInspector />
  )
}
