import { useEffect } from 'react'

const defaultTitle = 'OVVO Studio'

export const withPrefix = (title: string) => {
  return title.length === 0 
    ? defaultTitle
    : `OVVO - ${title[0].toUpperCase()}${title.slice(1).toLowerCase()}`
}

export const documentTitle = (title: string) => {
  document.title = withPrefix(title)
}

export const DocumentTitle = ({ title = defaultTitle, noPrefix = false }) => {
  useEffect(() => {
    const previous = document.title
    document.title = noPrefix ? title : withPrefix(title)
    return () => {
      document.title = previous
    }
  }, [title, noPrefix])
  return null
}
