import { CSSProperties } from 'react'
import { safeClassName } from 'some-utils/npm/react'
import './NavArrow.css'
import { useResponsive } from 'state/layout'

const ThinArrowSvg = ({
  width = 160,
  arrowSize = 24,
}) => {
  const height = arrowSize + 2
  const viewBox = `0 0 ${width} ${height}`

  const x = width - 1
  const h2 = height / 2
  const a2 = arrowSize / 2
  const d = `
    M0,${h2} 
    H${x}
    M${x - a2} ${h2 - a2} 
    L${x} ${h2}
    L${x - a2} ${h2 + a2}`

  return (
    <svg
      style={{ '--withdrawal': `-${arrowSize}px` } as CSSProperties}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d={d} stroke="#D9D9D9" />
    </svg>
  )
}

const NarrowSvg = ({
  width = 40,
  extraWidth = 100,
  height = 200,
  arrowSize = 24,
}) => {
  const { columnsCount } = useResponsive()
  const viewBox = `0 0 ${width} ${height}`

  if (columnsCount === 1) {
    const ratio = 0.66
    width *= ratio
    height *= ratio
  }

  const d = `
    M0,0
    L${width},${height / 2}
    L0,${height}
  `

  return (
    <svg
      style={{ '--withdrawal': `-${arrowSize}px` } as CSSProperties}
      width={width + extraWidth}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d={d} stroke="#D9D9D9" />
    </svg>
  )
}

export const NavArrow = ({
  type,
  enabled = true,
  useNarrowSvg = true,
  areaWidth = '20%',
  paddingTop = 0,
  paddingBottom = 0,
  ...props
}: {
  type: 'next' | 'previous'
  enabled?: boolean
  useNarrowSvg?: boolean
  areaWidth?: string
  paddingTop?: number,
  paddingBottom?: number,
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { columnsCount } = useResponsive()
  return (
    <div {...props}
      className={safeClassName('NavArrow button', type, { disabled: !enabled })}
      style={{ width: areaWidth, paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px` }}
    >
      <div className='fill'>
        {useNarrowSvg
          ? <NarrowSvg {...columnsCount <= 3 ? { width: 20, height: 100, extraWidth: 80 } : {} } />
          : <ThinArrowSvg width={200} />
        }
      </div>
    </div>
  )
}
