import { CSSProperties, useMemo, useState } from 'react'
import { aRange } from 'some-utils/iterators'
import { safeClassName, useBounds, usePointerType } from 'some-utils/npm/react'
import { PRNG } from 'some-utils/math'
import { api, allProjects, globalData } from 'data'
import { responsiveObs } from 'state/layout'
import { navigate } from 'state/navigation'
import { getBackgroundOptions } from 'utils/background-options'
import { ProjectHint } from '../components/ProjectHint'
import { findTheBestGrid, GridInfo } from './find-the-best-grid'
import './PixelView.css'
import { safeFallback } from 'utils/safeFallback'

type CellInfo = {
  project: api.Project
  cellStyle: CSSProperties
  thumbnailStyle: CSSProperties
  dim: boolean
}

const getDisabledStyle = (() => {
  let options: CSSProperties[] | null = null
  let index = 0
  return () => {
    if (!options) {
      const url = globalData.pixelViewDisabledImage().url()
      options = getBackgroundOptions(url)
      PRNG.shuffle(options)
    }
    const item = options[index]
    index = (index + 1) % options.length
    return item
  }
})()

const Cell = ({
  info,
}: {
  info: CellInfo
}) => {
  const { dim, project, cellStyle, thumbnailStyle } = info
  const disabledStyle = useMemo(getDisabledStyle, [])
  return (
    <div
      className={safeClassName('Cell button flex', { dim })}
      data-project-id={project.id}
      style={cellStyle}
      onClick={() => navigate(project)}>
      <div className='Thumbnail absolute-fill'>
        <div className='Image absolute-fill' style={thumbnailStyle} />
        <div className='Frame absolute-fill' />
      </div>
      <div className='Disabled absolute-fill' style={disabledStyle} />
    </div>
  )
}

const getProjectStyleOptions = (project: api.Project) => {
  const options: CSSProperties[] = []
  for (const media of project.searchPixelMedias()) {
    options.push(...getBackgroundOptions(media.url()))
  }
  return PRNG.shuffle(options)
}

const Cells = ({
  gridInfo,
  filteredProjects,
}: {
  gridInfo: GridInfo
  filteredProjects: api.Project[],
}) => {
  const {
    cellCount,
    gutter,
    cellWidth,
    cellHeight,
    col,
  } = gridInfo

  const cellsInfo = useMemo(() => {
    const projects = [...allProjects.values()]
    const randomCellIndexes = PRNG.shuffle(aRange(cellCount))
    const cellsInfo = aRange(cellCount).map((index): CellInfo => {
      const x = index % col
      const y = Math.floor(index / col)
      const left = x * (cellWidth + gutter)
      const top = y * (cellHeight + gutter)
      return {
        project: null! as api.Project,
        cellStyle: {
          width: `${cellWidth.toFixed(1)}px`,
          height: `${cellHeight.toFixed(1)}px`,
          left: `${left.toFixed(1)}px`,
          top: `${top.toFixed(1)}px`,
        },
        thumbnailStyle: {},
        dim: false,
      }
    })

    let cellIndex = 0
    const projectsCount = projects.length
    for (const project of projects) {
      const start = Math.round(cellIndex)
      cellIndex += cellCount / projectsCount
      const end = Math.round(cellIndex)
      const projectCellIndexes = randomCellIndexes.slice(start, end)

      const projectStyleOptions = getProjectStyleOptions(project)

      for (const index of projectCellIndexes) {
        cellsInfo[index].project = project
        cellsInfo[index].dim = filteredProjects.includes(project) === false
        cellsInfo[index].thumbnailStyle = projectStyleOptions[index % projectStyleOptions.length]
      }
    }

    return cellsInfo

  }, [cellCount, col, cellWidth, gutter, cellHeight, filteredProjects])

  return (
    <>
      {cellsInfo.map((info, index) => {
        return (
          <Cell key={index} info={info} />
        )
      })}
    </>
  )
}

const BestGrid = ({
  projects,
}: {
  projects: api.Project[]
}) => {
  const [gridInfo, setGridInfo] = useState<GridInfo | null>(null)
  const ref = useBounds<HTMLDivElement>((bounds, div) => {
    const { columnsCount } = responsiveObs.value
    const gutter = 2

    const { width, height } = bounds

    const desiredCellCount = safeFallback(globalData.attributes.LayoutSettings?.pixelView?.desiredCellCount?.columns, {
      1: 30,
      3: 75,
      4: 100,
      5: 150,
    })[columnsCount]

    setGridInfo(findTheBestGrid({
      width,
      height,
      gutter,
      desiredCellCount,
    }))

  }, {})

  return (
    <div ref={ref} className='BestGrid expand flex row' data-grid-info={gridInfo?.info}>
      {gridInfo && (
        <Cells
          filteredProjects={projects}
          gridInfo={gridInfo} />
      )}
    </div>
  )
}

export const PixelView = ({
  projects,
}: {
  projects: api.Project[]
}) => {
  return (
    <div className='PixelView absolute-fill flex'>
      <div className='expand flex overflow-hidden'>
        <BestGrid projects={projects} />
        {usePointerType() === 'mouse' && (
          <ProjectHint />
        )}
      </div>
    </div>
  )
} 
