import lodash from 'lodash'

import { development, endpoints, strapi } from 'config'
import { api } from 'data'
import { localeObs } from 'state/navigation'
import { initGlobalDataFallback } from './api'
import { allProjects, allProjectsBySlug, allTags, companyInfos, globalData, newsCategoriesNames, reels, studiosPage } from './data'
import { MainGraphqlResponse, mainGraphqlRequest } from './graphql'
import { onDataInitialized } from './onDataInitialized'

const consolidateTags = (graphqlTags: MainGraphqlResponse['tags']) => {
  allTags.clear()

  for (const project of allProjects.values()) {
    project.tags.length = 0
  }
  for (const tag of allTags.values()) {
    tag.projects.length = 0
  }

  for (const graphqlTag of graphqlTags) {
    // NOTE: This is a legacy project, and too many changes have been made on the
    // data graph to keep things clear here. There are actually some non-sense
    // code following: 
    // the `projects` array is stored twice:
    // - in the `attributes` object through the constructor (`new api.Tag...`)
    // - in the `projects` property through the method `api.linkProjectTag`
    // Why?
    // That is a question I can't answer anymore. But it is working. So...
    const projects = [...allProjects.values()]
      .filter(project => graphqlTag.projects.some(p => p.slug === project.slug))

    const tag = new api.Tag({
      id: graphqlTag.id,
      attributes: {
        tagName: graphqlTag.tagName,
        kebabName: lodash.kebabCase(graphqlTag.tagName),
        projects,
      }
    })

    for (const project of projects) {
      api.linkProjectTag(project, tag)
    }

    allTags.set(tag.attributes.tagName, tag)
  }

  // Previous code when tags were retrieved from the projects data structure and 
  // not from the graphql request:
  //
  // for (const project of allProjects.values()) {
  //   for (const tag of project.attributes.tags.data) {
  //     const tagIsNew = allTags.has(tag.attributes.tagName) === false
  //     if (tagIsNew) {
  //       const tagProjects = [...allProjects.values()].filter(project => project.attributes.tags.data.some(tag2 => tag2.id === tag.id))
  //       const uniqueTag = new api.Tag({
  //         id: tag.id,
  //         attributes: {
  //           ...tag.attributes,
  //           projects: tagProjects,
  //         },
  //       })
  //       for (const project of tagProjects) {
  //         api.linkProjectTag(project, uniqueTag)
  //       }

  //       allTags.set(tag.attributes.tagName, uniqueTag)
  //     }
  //   }
  // }
}

const addMetadataToCoverSettings = () => {
  for (const project of allProjects.values()) {
    const metadata = {
      id: project.id,
      slug: project.slug,
      title: project.title(),
    }
    project.attributes.coverSettings.metadata = metadata
  }
}

const fetchJson = async (url: string) => {
  const response = await fetch(url)
  return await response.json()
}

export let initialized = false
let projectsOnHome = [] as api.Project[]
export const dataInit = async () => {
  if (initialized) {
    return
  }

  {
    // GLOBAL:
    const json = await fetchJson(endpoints.globalSettings(localeObs.value))
    globalData.setData(json.data)
    initGlobalDataFallback(globalData) // init api
  }

  {
    // STUDIO:
    const json = await fetchJson(endpoints.studiosPage(localeObs.value))
    studiosPage.setData(json.data)
  }

  {
    // PROJECT:
    const json = await fetchJson(endpoints.projects(localeObs.value))
    const rawData = json.data as strapi.Project[]
    allProjects.clear()
    allProjectsBySlug.clear()
    for (const projectData of rawData) {
      const project = new api.Project(projectData)
      allProjects.set(project.id, project)
      allProjectsBySlug.set(project.slug, project)
    }
  }

  {
    // COMPANIES:
    const json = await fetchJson(endpoints.companyInfos(localeObs.value))
    const rawData = json.data as strapi.CompanyInfo[]
    companyInfos.length = 0
    companyInfos.push(...rawData.map(data => new api.CompanyInfo(data)))
  }

  // PROJECT ON HOME:
  const graphqlResponse = await mainGraphqlRequest()
  projectsOnHome = graphqlResponse.projectsOnHomeSlugs
    .map(slug => {
      const project = allProjectsBySlug.get(slug)
      if (!project) {
        console.warn(`WARN, there is no project with "${slug}" as slug (not in current locale (${localeObs.value})?).`)
        return null
      }
      if (project.isVisible() === false) {
        return null
      }
      return project
    })
    .filter(project => !!project) as api.Project[]

  Object.assign(newsCategoriesNames, graphqlResponse.newsCategories)

  consolidateTags(graphqlResponse.tags)
  addMetadataToCoverSettings()

  reels.length = 0
  reels.push(...graphqlResponse.reels.map(data => new api.Reel(data)))

  if (development) {
    console.log(`All projects:\n${[...allProjectsBySlug.values()].map(p => '  ' + p.slug).join('\n')}`)
    console.log(`Projects on home:\n${projectsOnHome.map(p => '  ' + p.slug).join('\n')}`)
  }

  initialized = true

  onDataInitialized.call()
}

export const getHomeProjects = () => projectsOnHome

type ProjectPredicate = (project: api.Project) => boolean

type ProjectPredicateArg =
  | ProjectPredicate
  | number

const resolveProjectPredicateArg = (arg: ProjectPredicateArg): ProjectPredicate => {
  if (typeof arg === 'number') {
    return project => project.id === arg
  }
  if (typeof arg === 'function') {
    return arg
  }
  throw new Error(`You're not using Typescript!`)
}

export const findProject = (predicate: ProjectPredicateArg) => {
  const resolvedPredicate = resolveProjectPredicateArg(predicate)
  for (const project of allProjects.values()) {
    if (resolvedPredicate(project)) {
      return project
    }
  }
  return null
}

export const findProjects = function* (predicate: ProjectPredicateArg) {
  const resolvedPredicate = resolveProjectPredicateArg(predicate)
  for (const project of allProjects.values()) {
    if (resolvedPredicate(project)) {
      yield project
    }
  }
}
