
export enum Quality {
  MAX,
  GOOD,
  NORMAL,
  LOW,
  VERY_LOW,
}

export const quality = Quality.MAX

export const withQuality = <T>(options: Partial<Record<Quality | 'default', T>>) => {
  return options[quality] ?? options.default
}
