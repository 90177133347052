import { clamp } from 'some-utils/math'
import { appTimer, timer, useEffects } from 'some-utils/npm/react'
import { FloatVariable } from 'some-utils/variables'

import { QUALITY_DEGRADATION_MAX, useAppContext } from 'AppContext'
import { development } from 'config'
import { computeRequiredCycleCount } from './computeRequiredCycleCount'
import { fpsThresholds } from './fpsThresholds'

const FRAME_COUNT = 40 // base for a fps average
const FRAME_CUT = 4 // Ignored frame for the average (N best & worst frames are ignored)

export const QualityAdaptater = () => {
  const {
    averageFpsObs,
    qualityDegradationLevelObs: levelObs,
    qualityInfoObs: infoObs,
  } = useAppContext()

  useEffects(function* () {
    const levelHistory = new FloatVariable(0, { size: 32 })
    
    if (development) {
      levelObs.logOnChange('quality degradation level')
    }
    
    let count = 0
    const state = {
      desiredLevel: -1,
      cycleCount: 0,
      lastChangeTime: -1,
    }

    yield levelObs.onChange(level => {
      state.lastChangeTime = appTimer.time
      levelHistory.setNewValue(level)
    })
    
    // DEGRADING THE QUALITY:
    const degradeQuality = (averageFps: number) => {
      const step = averageFps / fpsThresholds.low[levelObs.value] < .5 ? 2 : 1
      levelObs.increment(step)
    }
    
    // RESTORING THE QUALITY:
    const restoreQuality = () => {
      const desiredLevel = clamp(levelObs.value - 1, 0, QUALITY_DEGRADATION_MAX)
      const requiredCycleCount = computeRequiredCycleCount(levelHistory, desiredLevel, QUALITY_DEGRADATION_MAX)
      if (levelObs.value !== desiredLevel) {
        if (state.desiredLevel !== desiredLevel) {
          state.cycleCount = 0
        }
        state.desiredLevel = desiredLevel
        state.cycleCount++
        if (state.cycleCount >= requiredCycleCount.cycleCount) {
          state.cycleCount = 0
          levelObs.setValue(state.desiredLevel)
          state.desiredLevel = -1
        }
        infoObs.setValue(`desiredLevel: ${desiredLevel}, cycles: ${state.cycleCount}/${requiredCycleCount.cycleCount}, ping-pong: ${requiredCycleCount.pingPong}`)
      }
    }
    
    const dtVariable = new FloatVariable(0, { size: FRAME_COUNT })
    yield appTimer.onFrame(({ time, deltaTime }) => {
      // Skip when the global "time-scaled" timer is paused:
      if (timer.isFreezed()) {
        return
      }

      if (time - state.lastChangeTime < 2) {
        infoObs.setValue('waiting...')
        return
      }

      if (count < FRAME_COUNT - 1) {
        dtVariable.setNewValue(deltaTime)
        count++
      } else {
        const averageDt = dtVariable.computeAverage({ cut: FRAME_CUT })
        const averageFps = 1 / averageDt
        const level = levelObs.value
        averageFpsObs.setValue(averageFps)
        
        if (averageFps < fpsThresholds.low[level]) {
          degradeQuality(averageFps)
        } else if (averageFps > fpsThresholds.high[level]) {
          restoreQuality()
        } else {
          infoObs.setValue(`desiredLevel: ${levelObs.value} (ok)`)
        }

        count = 0
      }
    })

  }, [])

  return null
}


