import { ReactNode } from 'react'

import { Animation } from 'some-utils/Animation'
import { handlePointer } from 'some-utils/dom'
import { safeClassName, useEffects } from 'some-utils/npm/react'

import { api, globalData } from 'data'
import { layoutObs, useResponsive } from 'state/layout'
import { hudOpening } from 'state/ui'
import { matchColumnCount } from 'utils/matchColumnCount'
import { Tags } from 'view/components'
import { useHorizontalPadding, useLayoutCase } from './contents/utils/misc'

import defaultLayoutSettings from 'config/defaultLayoutSettings.json'

import './ProjectCover.css'

/**
 * <TagWrapper /> is for auto-hiding content (tags) when the HUD is open.
 */
const TagWrapper = ({ children }: { children: ReactNode }) => {
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    yield hudOpening.withValue(isOpen => {
      Animation.duringWithTarget(div, .5, ({ progress }) => {
        div.style.opacity = (isOpen ? 1 - progress : progress).toFixed(2)
      })
    })
  }, [])

  return (
    <div ref={ref}>
      {children}
    </div>
  )
}

type Props = {
  project: api.Project
  onTap?: () => void
  hideDescription?: boolean
  showTags?: boolean
}

export const ProjectCover = ({
  project,
  onTap,
  hideDescription = false,
  showTags = true,
}: Props) => {
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    const title = div.querySelector('.Title')!
    const bottomWrapper = div.querySelector('.BottomWrapper')!
    const onOver = () => {
      title.classList.add('hover')
      bottomWrapper.classList.add('hover')
    }
    const onOut = () => {
      title.classList.remove('hover')
      bottomWrapper.classList.remove('hover')
    }
    yield handlePointer(title, {
      onOver,
      onOut,
      onTap,
    })
    yield handlePointer(bottomWrapper, {
      onOver,
      onOut,
      onTap,
    })

    layoutObs.withValue(({ baseFontSize }) => {
      div.style.fontSize = `${baseFontSize}rem`
    })
  }, [])

  const { columnStart, columnWidth } = useLayoutCase(
    globalData.attributes.LayoutSettings?.projectCover?.columns
    ?? defaultLayoutSettings.projectCover.columns)
  const horizontalPadding = useHorizontalPadding([columnStart, columnWidth])
  const { columnsCount, gridPadding } = useResponsive()

  return (
    <div
      ref={ref}
      className='ProjectCover'
    >
      <div className='flex column'>
        <div
          className='Top flex column justify-end'
          style={{ flex: '1', justifyContent: 'flex-end', ...horizontalPadding }}
        >
          <h1
            className={safeClassName('Title', onTap && 'button')}
            dangerouslySetInnerHTML={{ __html: project.title().replace('\n', '<br>') }}
          />
        </div>

        <div
          className='Bottom'
          style={{
            flex: '1',
            ...horizontalPadding,
            paddingBottom: `${gridPadding.bottom / 2}px`,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div className={safeClassName('BottomWrapper flex column', onTap && 'button')}>
            <h2
              className='Subtitle'
              dangerouslySetInnerHTML={{ __html: project.subtitle().replace('\n', '<br>') }}
            />
            {hideDescription === false && (
              <>
                <div className='Spacing' />
                <p className='ProjectCoverDescription'>{project.attributes.coverDescription}</p>
              </>
            )}
          </div>

          <div style={{ flex: '1' }} />

          {showTags && (
            <TagWrapper>
              <Tags
                clearSearchOnClick
                tags={project.tags}
                style={{
                  padding: matchColumnCount({
                    '1': '0 0 32px .5em',
                    '*': '0',
                  }, columnsCount),
                  transform: matchColumnCount({
                    '1': '',
                    '*': 'translateY(50%)',
                  }, columnsCount),
                }}
              />
            </TagWrapper>
          )}
        </div>
      </div>
    </div>
  )
}
