/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import * as THREE from 'three'
import { devicePixelRatio } from 'config'
import { cascadingProps } from 'state/scroll/scroll'
import { Canvas, useThree } from '@react-three/fiber'
import { inout } from 'some-utils/math'
import { timer, useEffects, useObservable } from 'some-utils/npm/react'
import { Animation } from 'some-utils/Animation'
import { GridPlane } from './GridPlane/top-down'
import { ScrollPoints } from './ScrollPoints'
import { WavyBackground } from './wavy-background'
import * as passes from './passes'
import { Metaball } from './metaball'
import { MediaPlane } from './media-plane'
import { CoverVisibilityHandler } from './CoverVisibilityHandler'
import { PageStudiosOvvoVideo } from 'view/studios/gl/PageStudiosOvvoVideo'
import { useAppContext } from 'AppContext'
import { DynamicBackground } from './background'

const Content: React.FC = () => {
  return (
    <>
      <DynamicBackground />
      <ambientLight intensity={.1} />
      <directionalLight intensity={1} />
      <Metaball />
      <MediaPlane />
      {/* <rd.CubemapDynamic /> */}
      {/* <rd.Dissolve /> */}
    </>
  )
}

const RenderFrame = () => {
  const three = useThree()
  useEffects(function*(){
    yield timer.onFrame(() => {
      three.invalidate()
    })
  }, [])
  return null
}

export const Scene3D: React.FC = () => {

  const { qualityDegradationLevelObs } = useAppContext()
  const level = useObservable(qualityDegradationLevelObs)
  const finalDPR = devicePixelRatio / (1.33 ** level)

  return (
    <div id="canvas-container">
      <Canvas
        frameloop="demand"
        dpr={finalDPR}
        // dpr={.66}
        // linear
        // flat
        gl={{
          // precision: 'highp',
          precision: 'mediump',
          outputColorSpace: 'srgb-linear',
          toneMapping: THREE.LinearToneMapping,
        }}
      >
        <RenderFrame />
        <CoverVisibilityHandler />

        <passes.World>
          <Content />
        </passes.World>

        <passes.HUD>
          <GridPlane />
          <ScrollPoints />
          <WavyBackground />
          <PageStudiosOvvoVideo />
        </passes.HUD>
      </Canvas>
    </div>
  )
}

