import * as THREE from 'three'
import { server } from 'config'
import { isImageUrl, isVideoUrl } from 'utils'

export type TextureArgs = Partial<{
  mapping: THREE.Mapping
  wrap: THREE.Wrapping
  wrapS: THREE.Wrapping
  wrapT: THREE.Wrapping
  magFilter: THREE.MagnificationTextureFilter
  minFilter: THREE.TextureFilter
  format: THREE.PixelFormat
  type: THREE.TextureDataType
  anisotropy: number
  colorSpace: THREE.ColorSpace
}>

export const serializeTextureArgs = ({
  mapping,
  wrap,
  wrapS = wrap,
  wrapT = wrap,
  magFilter,
  minFilter,
  format,
  type,
  anisotropy,
}: TextureArgs) => {
  return `m:${mapping ?? ''},ws:${wrapS ?? ''},wt:${wrapT ?? ''},mg:${magFilter ?? ''},mn:${minFilter ?? ''},f:${format ?? ''},t:${type ?? ''},a:${anisotropy ?? ''}`
}

const safeUrl = (url: string) => {
  if (url.startsWith('/')) {
    return server + url
  }
  return url
}

const createImageTexture = (url: string, {
  mapping = THREE.UVMapping,
  wrap = THREE.RepeatWrapping,
  wrapS = wrap,
  wrapT = wrap,
  magFilter = THREE.LinearFilter,
  minFilter = THREE.LinearMipMapLinearFilter,
  format = THREE.RGBAFormat,
  type = THREE.UnsignedByteType,
  anisotropy = 4,
  colorSpace = THREE.LinearSRGBColorSpace,
}: TextureArgs) => {

  const image = document.createElement('img')
  image.crossOrigin = 'anonymous'
  image.src = safeUrl(url)

  const texture = new THREE.Texture(image, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy, colorSpace)
  image.addEventListener('load', () => {
    texture.needsUpdate = true
  }, { once: true })

  return texture
}

const createVideoTexture = (url: string, {
  mapping,
  wrap,
  wrapS = wrap,
  wrapT = wrap,
  magFilter,
  minFilter,
  format,
  type,
  anisotropy,
}: TextureArgs) => {

  const video = document.createElement('video')
  video.crossOrigin = 'anonymous'
  video.style.display = 'none'
  video.muted = true
  video.autoplay = true
  video.loop = true
  video.src = safeUrl(url)
  video.playsInline = true
  video.onloadedmetadata = () => video.play()

  const texture = new THREE.VideoTexture(video, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy)
  texture.colorSpace = THREE.LinearSRGBColorSpace

  return texture
}

export const createTexture = (url: string, textureArgs: TextureArgs) => {

  if (isVideoUrl(url)) {
    return createVideoTexture(url, textureArgs)
  }
  
  if (isImageUrl(url)) {
    return createImageTexture(url, textureArgs)
  }
  
  throw new Error(`What's this media johnny? ${url}`)
}
