import { safeClassName, useObservable } from 'some-utils/npm/react'

import { unifiedSearchState } from 'state/unified-search'

import * as svg from './svg'

import './SearchCorner.css'

export const SearchCorner = () => {

  const visible = useObservable(unifiedSearchState.visibleObs)
  return (
    <div
      className='SearchCorner button'
      onClick={() => {
        unifiedSearchState.visibleObs.toggle()
      }}
    >
      <div className={safeClassName('absolute-fill flex row center', { visible })}>
        <svg.Cross />
      </div>
      <div className={safeClassName('absolute-fill flex row center', { visible: !visible })}>
        <svg.Search />
      </div>
    </div>
  )
}
