import { ReactNode } from 'react'
import { useEffects } from 'some-utils/npm/react'
import { handleBounds } from 'some-utils/dom/bounds'

import './AspectContainer.css'

export const AspectContainer = ({ children, aspect = 1 }: { aspect?: number; children: ReactNode }) => {
  const { ref } = useEffects<HTMLDivElement>(function (div) {
    const child = div.children[0] as HTMLElement
    handleBounds(div, bounds => {
      const aspectParent = bounds.width / bounds.height
      if (aspectParent > aspect) {
        child.style.width = `${100 * aspect / aspectParent}%`
        child.style.height = '100%'
      } else {
        child.style.width = '100%'
        child.style.height = `${100 * aspectParent / aspect}%`
      }
    })
  }, [])

  return (
    <div className='AspectContainer' ref={ref}>
      {children}
    </div>
  )
}
