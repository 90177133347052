import defaultScrollSettings from 'scroll/defaultScrollSettings.json'

export type MediaSettings = {
  scale: number
  resizeMode: 'cover' | 'contain'
  xOffset: number
  yOffset: number
  zOffset: number
  zRotation: number
}

export { defaultScrollSettings }
export const defaultMediaSettings = defaultScrollSettings.media as MediaSettings
