import { handlePointer } from 'some-utils/dom'
import { clamp } from 'some-utils/math'
import { ObservableNumber } from 'some-utils/observables'
import { FloatVariable } from 'some-utils/variables'

/**
 * NOTE: `handleScroll` is a new way (02/2023) to handle the scroll in an abstract 
 * manner.
 */
export const handleScroll = (scrollObs: ObservableNumber) => {
  
  const onDestroySet = new Set<() => void>()

  let _element = null as HTMLElement | null
  const computeMax = () => _element ? Math.max(0, _element.scrollHeight - _element.offsetHeight) : 0
  
  let mounted = true
  let velocity = 0
  let velocityDecay = .8
  const scrollAverage = new FloatVariable(0, { size: 10 })

  let lastWheelEventTimestamp = -1
  const onWheel = (event: WheelEvent): void => {
    event.preventDefault()
    const deltaTime = (event.timeStamp - lastWheelEventTimestamp) / 1e3
    // Skip invalid frames!
    if (deltaTime === 0) {
      return
    }
    lastWheelEventTimestamp = event.timeStamp
    velocityDecay = .8
    velocity = event.deltaY / deltaTime * .2
  }

  const onFrame = () => {
    if (mounted) {
      window.requestAnimationFrame(onFrame)
    }
    scrollAverage.newValue = clamp(scrollAverage.value + velocity * 1 / 60, 0, computeMax())
    scrollObs.value = scrollAverage.average
    velocity *= velocityDecay
  }

  const bind = (element: HTMLElement) => {
    if (_element !== null) {
      throw new Error('Element already exist. Bind cannot be called twice.')
    }
    _element = element
    _element.style.overflow = 'hidden'

    const initialScroll = clamp(_element.scrollTop, 0, computeMax())
    scrollAverage.fill(initialScroll)
    scrollObs.setValue(initialScroll)

    element.addEventListener('wheel', onWheel, { passive: false })
    onDestroySet.add(() => {
      element.removeEventListener('wheel', onWheel)
    })

    onDestroySet.add(
      handlePointer(element, {
        dragDistanceThreshold: 20,
        onDown: () => {
          velocity = 0
        },
        onVerticalDrag: info => {
          if (info.downEvent.pointerType === 'touch') {
            velocityDecay = .95
            velocity = -info.delta.y * 60
            info.moveEvent.preventDefault()
            document.getSelection()?.empty()
          }
        },
      }).destroy
    )

    onDestroySet.add(
      scrollObs.onStepChange(.5, scroll => {
        element.scrollTop = scroll
      }).destroy
    )

    window.requestAnimationFrame(onFrame)
    return handler
  }

  const destroy = () => {
    mounted = false
    for (const callback of onDestroySet) {
      callback()
    }
  }

  const handler = {
    bind,
    destroy,
  }

  return handler
}
