import { createRoot } from 'react-dom/client'
import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { sections, createSectionBundle } from './store'
import { PropertyDeclaration, SectionParams, SectionOptions } from './types'
import { useForceUpdate } from './utils'
import { LiveInspector } from './view/LiveInspector'
import './index.css'

const div = document.createElement('div')
div.id = 'live-inspector-root'
document.body.append(div)

const root = createRoot(div)
root.render(
  <React.StrictMode>
    <LiveInspector />
  </React.StrictMode>
)

export const useLiveInspectorBundle = <T extends Record<string, PropertyDeclaration>>(
  params: SectionParams,
  props: T,
  options: SectionOptions<T> = {},
) => {

  const { name } = params
  const safeName = useMemo(() => name ?? `inspector-${sections.size}`, [name])

  const bundle = useMemo(() => {
    return createSectionBundle({ ...params, name: safeName }, props, options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safeName])

  return bundle
}

export const useLiveInspector = <T extends Record<string, PropertyDeclaration>>(
  params: SectionParams,
  props: T,
  options: SectionOptions<T> = {},
) => {

  const forceUpdate = useForceUpdate()
  const bundle = useLiveInspectorBundle(params, props, options)
  useEffect(() => {
    const { destroy } = bundle.onChange(forceUpdate)
    return destroy
  })

  return bundle.getProperties()
}






// export const Test = () => {
//   class Foo {
//     str: string
//     constructor(str = 'yo') {
//       this.str = str
//     }
//     set(str: string) {
//       this.str = str
//     }
//   }
//   const lol = useLiveInspector({}, {
//     foo: 3,
//     lol: '2',
//     qux: false,
//     bar: {
//       value: true
//     },
//     q: {
//       min: 0,
//       max: 10,
//       value: 4,
//     },
//     mdr: {
//       value: new Foo(),
//     },
//     ptdr: {
//       value: new Foo(),
//     }
//   }, {
//     propsMeta: {
//       foo: {
//         min: 0,
//       },
//     },
//     valueMapper: [
//       {
//         condition: value => value instanceof Foo,
//         transform: (value: Foo) => value.toString(),
//         restore: (value: string, source: Foo) => source.set(value),
//       },
//     ],
//   })

//   return lol
// }