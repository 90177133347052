import { hermite01 } from 'some-utils/math'
import { EaseFunction, TransitionType } from './types'

export const DEFAULT_EASE: EaseFunction = hermite01

export const DEFAULT_TRANSITION: TransitionType = {
  duration: 1,
  delay: 0,
  ease: DEFAULT_EASE,
}
