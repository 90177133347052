import { CSSProperties, ForwardedRef, forwardRef } from 'react'
import { safeClassName } from 'some-utils/npm/react'
import './IconWrapper.css'

type Props = {
  className?: string
  style?: CSSProperties
  onClick?: () => void
  children: JSX.Element
  href?: string
}

const innerIconWrapper = (props: Props, ref: ForwardedRef<HTMLElement>) => {
  const { style, href, children, onClick } = props
  const className = safeClassName('IconWrapper button cursor-pointer', props.className)
  return props.href
    ? <a ref={ref as any} className={className} style={style} onPointerUp={() => window.open(href)} href={href} target='_blank' rel='noreferrer'>{children}</a>
    : <div ref={ref as any} className={className} style={style} onClick={onClick}>{children}</div>
}

export const IconWrapper = forwardRef<HTMLElement, Props>(innerIconWrapper)
