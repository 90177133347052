import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass'
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { timer, useEffects } from 'some-utils/npm/react'
import { handleWindow } from 'some-utils/dom'
import { deepLerp } from 'some-utils/math'
import { deepAssign, deepClone } from 'some-utils/object'
import { ObservableNumber } from 'some-utils/observables'
import { homeScrollObs } from 'state/scroll'
import { projectObs } from 'state/navigation'
// import { NoiseShader } from './noise'
import { getHomeProjects, globalData } from 'data'
import defaultSettings from './postprocessing-defaultSettings.json'
import { Animation } from 'some-utils/Animation'
import { useAppContext } from 'AppContext'

export const usePostprocessing = (composer: EffectComposer) => {
  const { qualityDegradationLevelObs } = useAppContext()
  useEffects(function* () {
    const info = {
      statusLines: [] as string[]
    }

    const homeProjects = getHomeProjects()
    const homeIndexObs = new ObservableNumber(0, { min: 0, max: homeProjects.length - 1 })
    const progressionObs = new ObservableNumber(0, { min: 0, max: 1 })
    yield homeScrollObs.onStepChange(0.001, homeScroll => {
      const index = Math.min(Math.floor(homeScroll), homeProjects.length - 1)
      homeIndexObs.setValue(index)
      const progression = homeScroll - index
      progressionObs.setValue(progression)
    })

    /**
     * 0: Global home
     * 1: Projects[0]
     * 2: Projects[1]
     * ...
     * n: Projects[n - 1]
     */
    const settings0 = deepClone(defaultSettings)
    const settings1 = deepClone(defaultSettings)
    const settingsX = deepClone(defaultSettings)

    const updateHomeSettings = () => {
      const homeIndex = homeIndexObs.value
      const s0 = homeIndex === 0
        ? globalData.attributes.CoverSettings.postprocessing
        : homeProjects[homeIndex - 1].attributes.coverSettings.postprocessing
      const s1 = homeProjects[homeIndex].attributes.coverSettings.postprocessing
      deepAssign(settings0, defaultSettings, s0)
      deepAssign(settings1, defaultSettings, s1)
    }

    const lerpSettings = () => {
      deepLerp(
        settings0,
        settings1,
        settingsX,
        progressionObs.value)
    }

    yield homeIndexObs.withValue(homeIndex => {
      const isHome = projectObs.value === null
      if (isHome) {
        if (homeIndex !== -1) {
          updateHomeSettings()
        }
      }
    })

    yield homeScrollObs.withStepValue(0.001, () =>
      lerpSettings())

    yield timer.onFrame({ skip: 10 }, () => {
      info.statusLines[0] = `i:${homeIndexObs.value} p:${progressionObs.value.toFixed(3)}`
      info.statusLines[1] = `${settings0.debugName} (${progressionObs.value.toFixed(2)}) ${settings1.debugName}`
      info.statusLines[2] = `[${settings0.bloom.strength.toFixed(1)} < ${settingsX.bloom.strength.toFixed(1)} > ${settings1.bloom.strength.toFixed(1)}]`
    })

    // Handle transitions: home <-> project
    yield projectObs.withValue(project => {
      if (project) {
        // Entering a project, make sure to restore the project settings:
        deepAssign(settings0, settingsX)
        deepAssign(settings1, defaultSettings, project.attributes.coverSettings.postprocessing)
        homeIndexObs.setValue(-1)
        progressionObs.setValue(0)
        Animation.tween(progressionObs, .3, {
          to: { value: 1 },
          ease: 'cubic-bezier(.3, 0, .7, 1)',
          onChange: () =>
            lerpSettings(),
        })
      } else {
        // Leaving a project, going home? Anyway restore the global settings:
        const homeScrollIndex = Math.round(homeScrollObs.value)
        const destinationSettings = homeScrollIndex === 0
          ? globalData.attributes.CoverSettings.postprocessing
          : getHomeProjects()[homeScrollIndex - 1].attributes.coverSettings.postprocessing
        deepAssign(settings0, settingsX)
        deepAssign(settings1, defaultSettings, destinationSettings)
        progressionObs.setValue(0)
        Animation.tween(progressionObs, .3, {
          to: { value: 1 },
          ease: 'cubic-bezier(.3, 0, .7, 1)',
          onChange: () =>
            lerpSettings(),
          onComplete: () => {
            homeIndexObs.setValue(0)
            progressionObs.setValue(0)
            updateHomeSettings()
          },
        })
      }
    })

    {
      // Bloom
      const strength = .3
      const radius = .5
      const threshold = .9
      const bloom = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), strength, radius, threshold)
      composer.addPass(bloom)
      yield handleWindow({
        onResize: () => {
          bloom.resolution.set(window.innerWidth, window.innerHeight)
        },
      })
      yield timer.onFrame(() => {
        bloom.strength = settingsX.bloom.strength
        bloom.radius = settingsX.bloom.radius
        bloom.threshold = settingsX.bloom.threshold
      })
      yield qualityDegradationLevelObs.withValue(level => {
        bloom.enabled = level <= 2
      })

    }

    // NOISE is too much consuming resources (??? windows only???)
    // {
    //   // Noise
    //   const noise = new ShaderPass(NoiseShader)
    //   composer.addPass(noise)
    //   yield timer.onFrame(({ deltaTime }) => {
    //     noise.uniforms.uTime.value += deltaTime
    //     noise.uniforms.uAmount.value = settingsX.noise.amount
    //     noise.uniforms.uNoiseSize.value = 1 / settingsX.noise.size
    //   })
    //   console.log(noise)
    // }

    composer.addPass(new OutputPass())

  }, [composer])
}
