import {
  CompanyInfo,
  GlobalSettings,
  NewsCategoryNames,
  Project,
  Reel,
  StudiosPage,
  Tag,
} from './api'

export const reels = [] as Reel[]
export const newsCategoriesNames = {} as NewsCategoryNames
export const allProjects = new Map<number, Project>()
export const allProjectsBySlug = new Map<string, Project>()
export const companyInfos: CompanyInfo[] = []
export const getFilteredSortedCompanyInfos = () => {
  return companyInfos
    .filter(a => a.attributes.hidden !== true)
    .sort((a, b) => a.attributes.Order - b.attributes.Order)
}
export const globalData = new GlobalSettings()
export const studiosPage = new StudiosPage()
export const allTags = new Map<string, Tag>()
export const getTags = () => [...allTags.values()]
export const mapTag = (tag: Tag) => allTags.get(tag.attributes.tagName)!
