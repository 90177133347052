import { SVGAttributes, SVGProps } from 'react'
import { safeClassName } from 'some-utils/npm/react'

const DEFAULT = {
  SIZE: 32,
  RADIUS: 3.5,
}

export const Circle = ({
  size = DEFAULT.SIZE,
  ...props
}: {
  size?: number
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={size}
      height={size}
      fill='var(--ui-color)'
      {...props}
    >
      <circle cx={size / 2} cy={size / 2} r={DEFAULT.RADIUS} />
    </svg>
  )
}


export const DoubleCircle = ({
  size = DEFAULT.SIZE,
  mode = 'horizontal',
  ...props
}: {
  size?: number
  mode?: 'vertical' | 'horizontal' | 'diagonal' | 'anti-diagonal'
} & SVGProps<SVGSVGElement>) => {

  const offset = 0.18

  const [x1, y1, x2, y2] = (() => {
    switch (mode) {
      case 'horizontal': return [
        size * (0.5 - offset), size * 0.5,
        size * (0.5 + offset), size * 0.5,
      ]
      case 'vertical': return [
        size * 0.5, size * (0.5 - offset),
        size * 0.5, size * (0.5 + offset),
      ]
      case 'diagonal': return [
        size * (0.5 - offset * 0.5), size * (0.5 + offset),
        size * (0.5 + offset * 0.5), size * (0.5 - offset),
      ]
      case 'anti-diagonal': return [
        size * (0.5 - offset * 0.5), size * (0.5 - offset),
        size * (0.5 + offset * 0.5), size * (0.5 + offset),
      ]
    }
  })()

  return (
    <svg
      width={size}
      height={size}
      fill='var(--ui-color)'
      {...props}
    >
      <circle cx={x1} cy={y1} r={DEFAULT.RADIUS} />
      <circle cx={x2} cy={y2} r={DEFAULT.RADIUS} />
    </svg>
  )
}

export const Search = (props: SVGAttributes<SVGElement>) => {
  const {
    className,
    ...rest
  } = props
  return (
    <svg className={safeClassName('Search', className)} width="17" height="20" viewBox="0 0 17 20" fill="red" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4418 7.84309C13.4418 10.8752 10.9638 13.3333 7.90694 13.3333C4.85013 13.3333 2.37208 10.8752 2.37208 7.84309C2.37208 4.81096 4.85013 2.35293 7.90694 2.35293C10.9638 2.35293 13.4418 4.81096 13.4418 7.84309ZM11.3637 14.8989C10.3195 15.4032 9.14652 15.6862 7.90694 15.6862C3.54006 15.6862 0 12.1747 0 7.84309C0 3.51147 3.54006 0 7.90694 0C12.2738 0 15.8139 3.51147 15.8139 7.84309C15.8139 10.0983 14.8543 12.1312 13.318 13.562L16.7627 18.1176C17.1557 18.6373 17.0496 19.3748 16.5255 19.7646C16.0015 20.1545 15.2581 20.0492 14.8651 19.5294L11.3637 14.8989Z" />
    </svg>
  )
}

export const Cross = (props: SVGAttributes<SVGElement>) => {
  const {
    className,
    ...rest
  } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={safeClassName('Cross', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="red" strokeWidth="2" strokeLinecap="round"
      {...rest}>
      <path d="M1 1L23 23M23 1L1 23" />
    </svg>
  )
}