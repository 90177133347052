import { ensureProp, isValueProp, isValueType } from './ensure'
import { getSelector, safePath, selectorMatchPath } from './selector'
import { PropDeclaration, PropsDeclaration, PropType, RulesBundle, TransitionType, ValueType } from './types'
import * as Animation from '../__old__/Animation'
import { deepEntries } from 'some-utils/object'

type Rule = {
  selector: string[]
  props: Record<string, PropType>
}

/**
 * From an collection of rules, return the matching rules sorted by their score.
 */
const getMatchingRules = (rules: Iterable<Rule>, path: string) => {
  type Match = { rule: Rule, score: number }
  const matchingRules = [] as Match[]
  const p = safePath(path)
  for (const rule of rules) {
    const { score } = selectorMatchPath(rule.selector, p)
    if (score > 0) {
      matchingRules.push({ rule, score })
    }
  }
  return (
    matchingRules
      .sort((A, B) => B.score - A.score)
      .map(item => item.rule)
  )
}

const addRules = (rules: Set<Rule>, bundle: RulesBundle) => {
  for (const key in bundle) {
    const selector = getSelector(key)
    const propsDeclaration = bundle[key]
    const props = {} as Record<string, PropType>
    let keyCount = 0
    for (const key2 in propsDeclaration) {
      const value = propsDeclaration[key2]
      if (isValueType(value) || isValueProp(value)) {
        props[key2] = ensureProp(value as PropDeclaration)
        keyCount++
      } else {
        for (const prop of deepEntries(value)) {
          props[`${key2}.${prop.path}`] = ensureProp(prop.value)
          keyCount++
        }
      }
    }
    // Skip empty declarations.
    if (keyCount > 0) {
      rules.add({
        selector,
        props,
      })
    }
  }
}

class CachedRuleValues {
  rules: Rule[] = []
  cache = new Map<string, PropType>()
  clear() {
    this.rules = []
    this.cache.clear()
  }
  setRules(rules: Rule[]) {
    this.rules = rules
    this.cache.clear()
  }
  get(key: string) {
    const value = this.cache.get(key)
    if (value !== undefined) {
      return value
    }

    for (const rule of this.rules) {
      if (key in rule.props) {
        const value = rule.props[key]
        this.cache.set(key, value)
        return value
      }
    }
  }
  getTransition(key: string) {
    for (const rule of this.rules) {
      if (key in rule.props) {
        const { transition } = rule.props[key]
        if (transition) {
          return transition
        }
      }
    }
  }
}

const propsCallback = new WeakMap<Prop, Set<(value: any) => void>>()

class Prop<T = any> {
  #owner: CascadingProps
  get owner() { return this.#owner }
  
  #key: string
  get key() { return this.#key }

  #value: T
  get value() { return this.#value }
  set value(value: T) { this.setValue(value) }

  constructor(owner: CascadingProps, key: string, value: T) {
    this.#owner = owner
    this.#key = key
    this.#value = value
  }

  onChange(callback: (value: T) => void) {
    const create = () => {
      const set = new Set<(value: any) => void>()
      propsCallback.set(this, set)
      return set
    }
    const set = propsCallback.get(this) ?? create()
    set.add(callback)
    const destroy = () => { set.delete(callback) }
    return { destroy }
  }

  withValue(callback: (value: T) => void) {
    const listener = this.onChange(callback)
    callback(this.#value)
    return listener
  }

  setValue(value: T) {
    if (!areEquivalent(this.#value, value)) {
      this.#value = value
      const callbacks = propsCallback.get(this)
      if (callbacks) {
        for (const callback of callbacks) {
          callback(value)
        }
      }
    }
  }
}

const areEquivalent = (source: any, clone: any) => {
  if (source === clone) {
    return true
  }
  if (typeof source !== 'object' || typeof clone !== 'object') {
    return false
  }
  for (const key in source) {
    if (source[key] !== clone[key]) {
      return false
    }
  }
  return true
}

const handleTransition = (prop: Prop, value: ValueType, transition: TransitionType | undefined) => {

  if (!transition) {

    // Step transition
    prop.setValue(value)

  } else {

    // Transition based on Animation.ts
    const { duration, delay, ease } = transition
    const from = prop.value
    const to = value as any
    Animation.tween(prop, { duration, delay }, {
      from: { value: from },
      to: { value: to },
      ease,
    })
  }
}

/**
 * CascadingProps allow to declare multiple props relative to a selector. 
 * Selector are a kind of CSS selector (eg: "home...section2"). 
 * When they match a route, the according props are loaded. 
 * 
 * cf README.md
 */
export class CascadingProps {

  currentPath = ''
  previousPath = ''
  
  allRules = new Set<Rule>()
  #cachedValue = new CachedRuleValues()

  #props = new Map<string, Prop>()

  addRules(path: string, props: PropsDeclaration): CascadingProps
  addRules(rules: RulesBundle): CascadingProps
  addRules(arg1: any, arg2?: any) {
    const rules = arg2 ? { [arg1]: arg2 } : arg1
    addRules(this.allRules, rules)
    this.#cachedValue.setRules(getMatchingRules(this.allRules, this.currentPath))
    return this
  }

  getProp<T = any>(key: string, defaultValue?: T) {
    const prop = this.#props.get(key)
    if (!prop) {
      const value = this.#cachedValue.get(key)?.value ?? defaultValue
      if (value === undefined) {
        // TEMP
        throw new Error(`No value for ${key}`)
      }
      const prop = new Prop(this, key, value)
      this.#props.set(key, prop)
      return prop as Prop<T>
    }
    return prop as Prop<T>
  }
  
  /**
   * "path" must be "xxx(.xxx(.xxx))"
   */
  enter(path: string) {

    if (/^[\w-]+(\.[\w-]+)*$/.test(path) === false) {
      throw new Error(`Invalid path: "${path}"`)
    }

    if (this.currentPath !== path) {
      this.previousPath = this.currentPath
      this.currentPath = path

      // refresh current cache
      const newRules = getMatchingRules(this.allRules, this.currentPath)
      this.#cachedValue.setRules(newRules)

      for (const prop of this.#props.values()) {
        const newValue = this.#cachedValue.get(prop.key)
        if (newValue && newValue.value !== undefined) {
          const transition = this.#cachedValue.getTransition(prop.key)
          handleTransition(prop, newValue.value, transition)
        }
      }

      return true
    }

    return false
  }
}

