import { api } from 'data'
import { safeClassName, useEffects, useObservable } from 'some-utils/npm/react'
import { Observable } from 'some-utils/observables'
import { navigate } from 'state/navigation'
import { useResponsive } from 'state/layout'
import { Media } from 'view/components/Media'
import './Cover.css'

type CoverProps = {
  projectObservable: Observable<api.Project>
  style?: React.CSSProperties
}

export const Cover = ({
  projectObservable,
  style,
}: CoverProps) => {
  const { columnsCount } = useResponsive()

  const project = useObservable(projectObservable)

  // Auto-hide paragraph feature:
  const { ref } = useEffects<HTMLDivElement>(function (div) {
    const textsElement = div.querySelector('.Texts') as HTMLDivElement
    const paragraphElement = div.querySelector('.Texts p') as HTMLDivElement
    paragraphElement.style.removeProperty('display')
    const contentHeight = [...textsElement.children].reduce((sum, element) => sum + element.clientHeight, 0)
    const showParagraph = columnsCount > 1 && textsElement.clientHeight >= contentHeight
    if (showParagraph) {
      paragraphElement.style.removeProperty('display')
    } else {
      paragraphElement.style.setProperty('display', 'none')
    }
  }, [columnsCount, project])

  return (
    <div
      ref={ref}
      className='Cover absolute-fill flex row center'
      style={style}
    >
      <div className={safeClassName(`CoverWrapper flex`, columnsCount > 1 ? 'row' : 'column')}>
        <div
          className='Texts flex column justify-center button'
          onClick={() => navigate(project)}
        >
          <h1 className='Title flex column'>
            {project.searchTitle()}
          </h1>
          <h2 className='Subtitle'>
            {project.searchSubtitle()}
          </h2>
          <p>
            {project.searchDescription()}
          </p>
        </div>

        <Media media={project.searchMedia()} play />
      </div>

    </div>
  )
}
