import { Observable, ObservableBoolean } from 'some-utils/observables'

import { Theme, defaultLocale, server } from 'config'
import { Media, StrapiItem } from 'config/types/strapi'
import { NewsCategory } from 'data/api'

type Result<T> = {
  score: number
  data: StrapiItem<T>
}

type NewsResult = Result<{
  locale: string
  NewsCategory: NewsCategory
  Slug: string
  Title: string
  Subtitle: string
  Description: string
  Thumbnail: { data: Media }
  CoverTheme: Theme
  Cover: { data: null | Media }
}>

type ProjectResult = Result<{
  slug: string
  coverTitle: string
  coverSubtitle: string
  coverDescription: string
  Theme: Theme
  searchCategory: null | string
  searchMediaAlternative: { data: null | Media }
}>

export type UnifiedSearchResult = {
  allNews: NewsResult[]
  projects: ProjectResult[]
}

export const unifiedSearchState = {
  inputObs: new Observable(''),
  visibleObs: new ObservableBoolean(false),
  resultObs: new Observable<UnifiedSearchResult>({ allNews: [], projects: [] }),
}

export function resetUnifiedSearch() {
  unifiedSearchState.inputObs.setValue('')
  unifiedSearchState.resultObs.setValue({ allNews: [], projects: [] })
}

export async function searchUnified(input: string, {
  locale = defaultLocale,
  debug = false,
}): Promise<UnifiedSearchResult> {
  const url = new URL(`${server}/${locale}/unified-search`, window.location.origin)
  url.searchParams.set('input', input)
  if (debug) {
    url.searchParams.set('debug', '1')
  }
  const response = await window.fetch(url.href)
  const data = await response.json()
  unifiedSearchState.resultObs.setValue(data)
  return data
}