import { Color, TextureLoader, Vector4 } from 'three'

import { globalData } from 'data'
import { staticRoutes } from 'state/navigation'

import noiseMap from './noise.png'

export const defaultUniforms = {
  uTime: { value: 0 },
  uZoom: { value: 1.2 },
  uShadowIntensity: { value: 1 },
  uSize: { value: new Vector4(window.innerWidth, window.innerHeight, window.innerWidth / window.innerHeight) },
  uParam1: { value: new Vector4() },
  /** Transform (scaleX, scaleY, translateX, translateY) */
  uTransform1: { value: new Vector4(2.5, 2.5, -0.8, 0.95) },
  uShadow: { value: new Vector4(5, 0, 0.3, 0) },
  /** Offset between 2 layers. (offsetX, offsetY, noise offsetX, noise offsetY) */
  uOffset1: { value: new Vector4(1, 0.2, 1, 2) },
  uColor: { value: new Color('#524c56') },
  uNoiseMap: { value: new TextureLoader().load(noiseMap) },
  uGrainIntensity: { value: 0.05 },
  uAliasThreshold: { value: 5 },
}

type UniformKey = keyof typeof defaultUniforms

type PartialUniforms = Partial<Record<UniformKey, { value: Partial<(typeof defaultUniforms)[UniformKey]['value']>} >>

export const getUniformDestination = (route: string): PartialUniforms => {
  const n1 = 0
  const n2 = 0
  const n3 = 0
  const get = (position: number, y = 0) => ({
    uTransform1: {
      value: {
        z: n1 + -0.8 + position * -1,
        w: 0.95 + y,
      }
    },
    uOffset1: {
      value: {
        x: n2 + 1 + position * -0.2,
        w: n3 + 2 + position * 0.2,
      },
    },
  })
  const json = globalData.attributes.WavyBackgroundSettings
  switch (route) {
    case staticRoutes.searchProjects:
    case staticRoutes.searchProjectsPixel: {
      const { shadowIntensity = 1, position } = json?.searchProjects ?? {}
      const { x = 0, y = -0.1 } = position ?? {}
      return {
        uShadowIntensity: { value: shadowIntensity },
        ...get(x, y),
      }
    }
    case staticRoutes.reels: {
      const { shadowIntensity = 1, position } = json?.showreel ?? {}
      const { x = 1, y = -0.1 } = position ?? {}
      return {
        uShadowIntensity: { value: shadowIntensity },
        ...get(x, y),
      }
    }
    case staticRoutes.studio: {
      const { shadowIntensity = 1, position } = json?.studios ?? {}
      const { x = 2, y = 0.2 } = position ?? {}
      return {
        uShadowIntensity: { value: shadowIntensity },
        ...get(x, y),
      }
    }
    default:
      return {}
  }
}
