import { asTheme, asVisibility, defaultMediaSettings, development, LayoutColumn, MediaSettings, server, strapi, Theme, Visibility } from 'config'
import lodash from 'lodash'
import metaballDefaultJson from 'view/gl/metaball/default.json'
import { resolveColumnLayoutDeclaration } from './resolveColumnLayoutDeclaration'
import { applyFallback, max } from './utils'

const asImageMedia = (media?: strapi.Media | null) => {
  if (!media) {
    return null
  }
  const { url } = media.attributes
  if (/\.(jpe?g|png)$/.test(url) === false) {
    return null
  }
  return media
}

const arrayFallback = <T>(...arrays: (T[] | (() => T[]))[]) => {
  for (let array of arrays) {
    if (typeof array === 'function') {
      array = array()
    }
    if (array) {
      array = array.filter(item => item !== null && item !== undefined)
      if (array.length > 0) {
        return array as NonNullable<T>[]
      }
    }
  }
  return null
}

class Data<T extends {
  id: number
  attributes: Record<string, any>
}> {
  rawData!: T
  get id() { return this.rawData.id }
  get attributes(): T['attributes'] { return this.rawData.attributes }
  constructor(rawData?: T) {
    if (rawData) {
      this.setData(rawData)
    } else {
      this.setData({ id: -1, attributes: {} } as T)
    }
  }
  setData(rawData: T) {
    this.rawData = Object.freeze(rawData)
  }
}

export class Project extends Data<strapi.Project> {

  mediaSettings: LayoutColumn<MediaSettings>

  constructor(rawData: strapi.Project) {
    super(rawData)
    this.consolidateScrollSettings()
    this.mediaSettings = resolveColumnLayoutDeclaration(this.rawData.attributes.coverSettings.media, defaultMediaSettings)
  }

  /**
   * "coverSettings" from backend may not exists, may have holes, so here, the 
   * purpose is to create the required values (just one, for the time being: 
   * 'coverSettings.metaball.envMapUrl').
   */
  private consolidateScrollSettings() {
    const { rawData } = this
    if (!rawData.attributes.coverSettings) {
      rawData.attributes.coverSettings = {}
    }
    if (!rawData.attributes.coverSettings.metaball) {
      rawData.attributes.coverSettings.metaball = {}
    }
    const envMapUrl = (
      rawData.attributes.coverMetaballsEnv?.data?.attributes.url
      ?? rawData.attributes.coverMedia?.data?.attributes.url
      ?? '')
    rawData.attributes.coverSettings.metaball.envMapUrl = envMapUrl

    rawData.attributes.coverSettings.metaball = {
      ...metaballDefaultJson,
      ...rawData.attributes.coverSettings.metaball,
    }
  }

  // Expose the most common attributes
  get slug() { return this.attributes.slug }

  tags = [] as Tag[]

  theme(): Theme { return asTheme(this.attributes.Theme) }
  visibility(): Visibility { return asVisibility(this.attributes.Visibility) }
  title() { return this.attributes.coverTitle ?? '' }
  subtitle() { return this.attributes.coverSubtitle ?? '' }
  searchTitle() { return applyFallback(this.attributes.searchTitleAlternative, max(this.attributes.coverTitle, 100)) }
  searchSubtitle() { return applyFallback(this.attributes.searchSubtitleAlternative, max(this.attributes.coverSubtitle, 100)) }
  searchDescription() { return applyFallback(this.attributes.searchDescriptionAlternative, max(this.attributes.coverDescription, 300)) }
  searchMedia() {
    return new Media(
      this.attributes.searchMediaAlternative?.data
      ?? this.attributes.coverMedia?.data
      ?? globalData.value.attributes.CoverMediaFallback.data
    )
  }
  searchPixelMedias() {
    const array = arrayFallback(
      this.attributes.searchMediaPixelThumbnails.data
        ?.map(media => asImageMedia(media))
        ?.filter(media => media),
      [asImageMedia(this.attributes.searchMediaAlternative?.data)],
      [asImageMedia(this.attributes.coverMedia?.data)],
      [globalData.value.attributes.CoverMediaFallback.data])!
    return array.map(data => new Media(data))
  }
  date() { return new Date(this.attributes.date) }
  dateYear() { return this.attributes.date.slice(0, 4) }
  dateMonth() { return this.attributes.date.slice(5, 7) }

  isVisible(): boolean {
    const visibility = this.visibility()
    return visibility === 'visible' || (development && visibility === 'debug')
  }

  /**
   * Returns true if all the tags names are associated to the current project.
   * NOTE: If the given list is empty, the method returns true.
   */
  containsTags(names: string[]) {
    if (names.length === 0) {
      return true
    }
    const projectTagNames = this.tags.map(tag => tag.kebabName())
    return names.every(name => projectTagNames.includes(name))
  }

  matchSearch(searchStr: string) {
    if (!searchStr) {
      return true
    }
    const re = new RegExp(searchStr, 'gi')
    return [
      this.attributes.coverTitle,
      this.attributes.coverSubtitle,
      this.attributes.coverDescription,
      this.attributes.searchTitleAlternative,
      this.attributes.searchSubtitleAlternative,
      this.attributes.searchDescriptionAlternative,
    ].some(str => re.test(str))
  }
}

export class Tag extends Data<strapi.Tag> {
  projects = [] as Project[];
  kebabName() { return lodash.kebabCase(this.attributes.tagName) }
}

export class CompanyInfo extends Data<strapi.CompanyInfo> {
  info() {
    return [
      this.attributes.companyName,
      this.attributes.companyAddress,
      this.attributes.companyRegistration,
      this.attributes.companyPhones,
      this.attributes.companyEmails,
    ].join('\n\n')
  }
}


export class Media extends Data<strapi.Media> {
  url() { return `${server}${this.attributes.url}` }
}

export class GlobalSettings extends Data<strapi.GlobalSettings> {
  mediaSettings: LayoutColumn<MediaSettings> = null!

  override setData(rawData: strapi.GlobalSettings) {
    super.setData(rawData)
    if (rawData.id !== -1) {
      this.mediaSettings = resolveColumnLayoutDeclaration(rawData.attributes.CoverSettings.media, defaultMediaSettings)
    }
  }

  pixelViewDisabledImage() {
    const data = this.attributes.PixelViewDisabledImage?.data ?? this.attributes.CoverMediaFallback.data
    return new Media(data)
  }
}

export class StudiosPage extends Data<strapi.StudiosPage> {

}

export const linkProjectTag = (project: Project, tag: Tag) => {
  project.tags.push(tag)
  tag.projects.push(project)
}

const globalData = { value: null! as GlobalSettings }
export const initGlobalDataFallback = (globalDataValue: GlobalSettings) => globalData.value = globalDataValue

export class Reel extends Data<strapi.Reel> {
  videoUrl() { return this.attributes.videoUrl }
  title() { return this.attributes.reelTitle }
  cover() { return new Media(this.attributes.reelCover.data ?? globalData.value.attributes.CoverMediaFallback.data) }
}

export * from './api.news'
