import * as theatre from '@theatre/core'
import { Animation } from 'some-utils/Animation'
import { useEffects } from 'some-utils/npm/react'
import { handlePointer } from 'some-utils/dom'
import { navigate } from 'state/navigation'
import { useResponsive } from 'state/layout'
import { dispatchScrollToEvent } from 'state/scroll'
import { closeMobileMenu } from 'view/mobile-menu/state'
import { ReactComponent as OvvoLogoAnimSvg } from './ovvo-logo-anim.svg'
import anim from './anim.json'
import './OvvoLogo.css'

// Init anim once:
const project = theatre.getProject('HTML Animation Tutorial', { state: anim })
const sheet = project.sheet('OVVO')
const obj = sheet.object('deploy', {
  open: theatre.types.number(1, { range: [0, 1] }),
  opacity: theatre.types.number(1, { range: [0, 1] }),
})

export const OvvoLogo = ({
  centered = false,
}) => {
  const {
    gridPadding: { left, top },
  } = useResponsive()

  const onClick = () => {
    dispatchScrollToEvent({ destination: 0 })
    closeMobileMenu()
    navigate('/')
  }

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    const g = div.querySelector('svg g') as SVGGElement
    const baseline = div.querySelector('.Baseline') as HTMLDivElement
    const [, , sl1, as2, sl2, o2] = g.children as unknown as SVGGElement[]
    const openDelta = 86
    const updateGTransform = (open = 0) => {
      const x = centered 
        ? -77 - open * openDelta / 2 
        : -77 - openDelta / 2
      g.setAttributeNS(null, 'transform', `translate(${x.toFixed(1)} -111.75)`)
    }
    updateGTransform(0)
    
    // NOTE: Theatre returns Unsubscribe function! So it's compatible with 
    // "useEffects()" destroyable concept.
    yield obj.onValuesChange(({ open, opacity }) => {
      updateGTransform(open)
      as2.style.transform = `translateX(${open * 52}px)`
      o2.style.transform = `translateX(${open * openDelta}px)`
      sl1.style.opacity = opacity.toString()
      sl2.style.opacity = opacity.toString()
    })
    yield handlePointer(div, {
      onOver: () => {
        sheet.sequence.play({ range: [0, 1], rate: 1.6 })
        Animation.duringWithTarget(baseline, { duration: .5, delay: .2 }, ({ progress }) => {
          baseline.style.opacity = progress.toFixed(2)
        })
      },
      onOut: () => {
        sheet.sequence.play({ direction: 'reverse', rate: 1.6 })
        Animation.duringWithTarget(baseline, { duration: .3, delay: .2 }, ({ progress }) => {
          baseline.style.opacity = (1 - progress).toFixed(2)
        })
      },
    })
  }, [centered])

  const padding = 16

  return (
    <div
      ref={ref}
      className='OvvoLogo button cursor-pointer flex row center'
      onPointerUp={onClick}
      style={{
        padding: `${padding}px`,
        left: `${left}px`,
        top: `${top / 2}px`,
        transform: `translate(${-124 / 2}px, -50%)`,
      }}
    >
      <OvvoLogoAnimSvg style={{ marginLeft: centered ? '0' : '16px'}} />
      <div className='Baseline ui-dim' style={{ opacity: 0 }}>live art studios</div>
    </div>
  )
}
