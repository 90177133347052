import React from 'react'

import { handlePointer, isParentOf } from 'some-utils/dom'
import { useEffects } from 'some-utils/npm/react'

import { useResponsive } from 'state/layout'
import * as UI from 'state/ui'
import { OvvoLogo2023 } from 'view/components'

import {
  BottomLeft,
  BottomRight,
  Breadcrumb,
  MobilePermanentSearch,
  OvvoFooter,
  TopMenu
} from './components'

import './HUD.css'

export const DASH_SPACING = 24

export const HUD = () => {

  const ref = React.useRef<HTMLDivElement>(null)
  const { mobile } = useResponsive()

  useEffects(function* () {
    yield handlePointer(document.body, {
      onDown: event => {
        if (isParentOf(ref.current, event.target) === false) {
          UI.hudOpeningHoverObs.setValueWithDelay(false, 0)
        }
      },
    })
  }, [])

  return (
    <>
      {/* 
        NOTE: The logo is outside from the HUD div, to enable the css 
        "mix-blend-mode: screen". 
      */}
      <OvvoLogo2023 />

      <div ref={ref} className='HUD ui click-through'>
        <OvvoFooter />
        <TopMenu />

        {mobile ? (
          <MobilePermanentSearch />
        ) : (
          <>
            <Breadcrumb />
            <BottomLeft />
            <BottomRight />
          </>
        )}
      </div>
    </>
  )
}

