import { ObservableBoolean } from 'some-utils/observables'

export const mobileMenuIsOpenObs = new ObservableBoolean(false)
// mobileMenuIsOpenObs.setValue(window.localStorage.getItem('mobile-menu-opening') === 'open')
mobileMenuIsOpenObs.onChange(open => {
  window.localStorage.setItem('mobile-menu-opening', open ? 'open' : 'close')
})

export const openMobileMenu = () => mobileMenuIsOpenObs.setTrue()
export const closeMobileMenu = () => mobileMenuIsOpenObs.setFalse()
export const toggleMobileMenu = () => mobileMenuIsOpenObs.toggle()
