import { Animation } from 'some-utils/Animation'
import { clamp, easings } from 'some-utils/math'
import { useRefComplexEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { ArraySolver } from 'some-utils/solvers'
import './SliderIndex.css'

export const SliderIndex = ({
  position = new ObservableNumber(0),
  count = 10,
}) => {

  const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {

    const span = div.querySelector('span.index') as HTMLElement

    // The "round" index
    const index = new ObservableNumber(0)

    // Complex because of a neat mix of two opacity values
    const finalOpacity = new ArraySolver(1, children => children.reduce((r, c) => r * c.value, 1))
    const opacity1 = finalOpacity.createChild(1)
    const opacity2 = finalOpacity.createChild(1)
    finalOpacity.onChange(value => {
        span.style.opacity = value.toFixed(2)
    })
    
    yield position.withValue(() => {
      const outDistance = Math.abs(position.value - clamp(Math.round(position.value), 0, count - 1))
      opacity1.value = easings.out3(1 - outDistance * 4)
      index.value = clamp(Math.round(position.value), 0, count - 1)
    })
    
    yield index.withValue(() => {
      span.innerHTML = (index.value + 1).toString()
    })

    yield index.onChange(() => {
      opacity2.value = 0
      Animation.tween(opacity2, { duration: 1, delay: .25 }, {
        to: { value: 1 },
      })
    })

  }, [position, count])

  return (
    <div ref={ref} className='SliderIndex ui flex row center fit-line-height'>
      <span style={{ flex: '1 0 0' }}/>
      <span className='index' />
      <span style={{ flex: '0 0 25%' }}/>
    </div>
  )
}
