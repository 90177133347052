import React from 'react'
import './HoverFrame.css'

export const HoverFrame: React.FC = () => {
  const [visible, setVisible] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const div = ref.current!
    const onPointerOver = () => setVisible(true)
    const onPointerOut = () => setVisible(false)
    const parent = div.parentElement!
    parent.addEventListener('pointerover', onPointerOver)
    parent.addEventListener('pointerout', onPointerOut)
    return () => {
      parent.removeEventListener('pointerover', onPointerOver)
      parent.removeEventListener('pointerout', onPointerOut)
    }
  }, [])
  return (
    <div
      ref={ref}
      className='HoverFrame' 
      style={{ opacity: visible ? '1' : '0' }}
    />
  )
}