import { clamp01, positiveModulo } from 'some-utils/math'
import { Message } from 'some-utils/message'
import { timer } from 'some-utils/npm/react'
import { Destroyable, ObservableNumber } from 'some-utils/observables'

import { development } from 'config'
import { globalData } from 'data'
import { homeScrollObs } from 'state/scroll'
import { unifiedSearchState } from 'state/unified-search'
import { safeFallback } from 'utils/safeFallback'

import settings from './autoScroll.settings.json'

type Params = Partial<typeof settings & {
  onActiveWait: (info: { progress: number }) => void
}>

export function* autoScroll(params: Params = {}): Generator<(() => void) | Destroyable> {
  const {
    silentWaitDuration,
    activeWaitDuration,
    postActiveWaitDuration,
    onActiveWait,
  } = {
    ...safeFallback(globalData.attributes.AutoScrollSettings, settings),
    ...params,
    ...(development ? { silentWaitDuration: 1000 } : {}) as typeof settings,
  }

  const waitObs = new ObservableNumber(0, { min: 0, max: silentWaitDuration + activeWaitDuration + postActiveWaitDuration })
  const activeWaitObs = new ObservableNumber(0, { min: 0, max: 1 })
  const state = {
    lastAutoScrollTime: 0,
    lastInteractionFrame: 0,
  }
  const onInteraction = () => {
    state.lastInteractionFrame = timer.frame
    waitObs.setValue(0)
  }
  document.documentElement.addEventListener('pointermove', onInteraction, { capture: true })
  document.documentElement.addEventListener('touchstart', onInteraction, { capture: true })
  yield () => {
    document.documentElement.removeEventListener('pointermove', onInteraction, { capture: true })
    document.documentElement.removeEventListener('touchstart', onInteraction, { capture: true })
  }
  yield homeScrollObs.onChange(() => {
    waitObs.setValue(0)
  })

  const autoScroll = () => {
    const value = positiveModulo(Math.round(homeScrollObs.value) + 1, homeScrollObs.getMax() + 1)
    state.lastAutoScrollTime = timer.time
    Message.send('HOME_SCROLL', 'JUMP', { value })
  }

  yield waitObs.onChange(value => {
    const activeWaitProgress = clamp01((value - silentWaitDuration) / activeWaitDuration)
    activeWaitObs.setValue(activeWaitProgress)
  })

  yield activeWaitObs.onChange(value => {
    Message.send('HOME_AUTO_SCROLL', 'ACTIVE_WAIT', { value })
    onActiveWait?.({ progress: value })
  })

  yield waitObs.onVerify({
    verify: value => value === waitObs.getMax(),
    onEnter: autoScroll,
  })

  yield timer.onFrame(({ time, frame, deltaTime }) => {
    if (time - state.lastAutoScrollTime > 2) {
      if (state.lastInteractionFrame < frame) {
        if (unifiedSearchState.visibleObs.value) {
          // Rewind
          waitObs.setValue(0)
        } else {
          // Progress
          waitObs.increment(deltaTime)
        }
      }
    }
  })
}
