import * as THREE from 'three'
import { useMemo } from 'react'
import { createPortal, useFrame, useThree } from '@react-three/fiber'
import { useResponsiveCamera } from '../camera'
import { Observable } from 'some-utils/observables'
import { ComplexEffectsDependencyList, Destroyable, useComplexEffects } from 'some-utils/npm/react'

const sceneObs = new Observable(new THREE.Scene())

export const HUD = (({ children }: any) => {
  const scene = sceneObs.value
  const camera = useResponsiveCamera()
  const { gl: renderer } = useThree()

  const priority = 10
  useFrame(() => {
    renderer.autoClear = false
    renderer.clearDepth()
    renderer.render(scene, camera)
  }, priority)
  return createPortal(children, scene) as JSX.Element
})

type GroupBundle = {
  group: THREE.Group,
  scene: THREE.Scene,
  add: <T extends THREE.Object3D>(child: T) => T,
}

/**
 * This is a small helper to create a group in the HUD scene. 
 * The group is automatically added and removed from the scene (on mount / unmount).
 */
export const useHUDGroup = (
  callback: (arg: GroupBundle) => Generator<Destroyable>,
  deps: ComplexEffectsDependencyList = 'always-recalculate',
) => {
  const bundle = useMemo<GroupBundle>(() => {
    const group = new THREE.Group()
    return {
      group,
      scene: sceneObs.value,
      add: child => {
        group.add(child)
        return child
      }
    } 
  }, [])
  useComplexEffects(function* () {
    sceneObs.value.add(bundle.group)
    yield () => sceneObs.value.remove(bundle.group)
    yield () => bundle.group.clear()
    yield* callback(bundle)
  }, deps)
  return bundle
}
