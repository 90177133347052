import { FloatVariable } from 'some-utils/variables'

/**
 * Quite complex heuristic:
 * - If the new level is higher, returns 1 because we want to be responsive on higher level (degrading the quality).
 * - If the new level is lower, then check the history:
 *   - If the previous-previous value was NOT lower than the current value (it
 *   means that the quality is restored ex: 4 -> 3 -> 2 (request)), then returns
 *   a simple cycle count based the quality (the lower the most expensive).
 *   - If the previous-previous value was lower, then we might encounter a "ping-pong"
 *   situation where the value is always going back and forth between two values
 *   ex: 0 -> 1 -> 0 -> 1 -> 0 (request), so in order to prevent that we count the
 *   number of times it has happened and returns an exponential cycle cost based
 *   on that.
 */
export const computeRequiredCycleCount = (levelHistory: FloatVariable, desiredLevel: number, levelMax = 4): { cycleCount: number, pingPong: number } => {
  if (desiredLevel > levelHistory.value) {
    // Only one cycle is required if going up (degrading the quality).
    return { cycleCount: 1, pingPong: 0 }
  }
  if (desiredLevel < levelHistory.value) {
    const level1 = levelHistory.getValue(1)
    const restoringPreviousValue = desiredLevel === level1
    if (restoringPreviousValue === false) {
      return {
        cycleCount: (levelMax - desiredLevel) * 2,
        pingPong: 0,
      }
    } else {
      let pingPong = 0
      // history ex: 1, 0, 1, 0, 1, 2, 3
      for (let step = 0; step < levelHistory.size; step++) {
        const level0 = levelHistory.getValue(step * 2 + 0)
        const level1 = levelHistory.getValue(step * 2 + 1)
        const sameDirection = level0 === level1 + 1
        if (sameDirection) {
          // If the previous step was in the "same direction", we increase the 
          // cost to going down (restoring the quality)
          pingPong++
        } else {
          break
        }
      }
      return {
        cycleCount: 4 * (2 ** Math.max(0, pingPong - 1)),
        pingPong,
      }
    }
  }
  return {
    cycleCount: Infinity,
    pingPong: 0,
  }
}

// const test = () => {
//   let history: FloatVariable = new FloatVariable(0)
//   const log = (cycleCount: number) => {
//     const str = [...history.getArray()].join(', ')
//     console.log(`${str}\ncycle count: ${cycleCount}`)
//   }

//   history = new FloatVariable(0, { size: 8 })
//   history.setNewValue(4)
//   history.setNewValue(3)
//   history.setNewValue(2)
//   history.setNewValue(1)
//   log(computeRequiredCycleCount(history, 0))

//   history = new FloatVariable(0, { size: 8 })
//   history.setNewValue(4)
//   history.setNewValue(3)
//   history.setNewValue(2)
//   history.setNewValue(1)
//   history.setNewValue(4)
//   log(computeRequiredCycleCount(history, 3))

//   history = new FloatVariable(0, { size: 8 })
//   for (let i = 0; i < 4; i++) {
//     history.setNewValue(i % 2)
//   }
//   log(computeRequiredCycleCount(history, 0))

//   history = new FloatVariable(0, { size: 8 })
//   for (let i = 0; i < 8; i++) {
//     history.setNewValue(i % 2)
//   }
//   log(computeRequiredCycleCount(history, 0))
// }

// test()
