import { time } from 'some-utils/npm/@react-three'
import * as THREE from 'three'

const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(512, {
  minFilter: THREE.LinearMipmapLinearFilter,
  generateMipmaps: true,
})

const cubeCamera = new THREE.CubeCamera(.1, 1000, cubeRenderTarget)

const scene = new THREE.Scene()

const sky = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.RawShaderMaterial({
  side: THREE.BackSide,
  vertexShader: /* glsl */`
    precision mediump float;
    precision mediump int;

    uniform mat4 modelViewMatrix; // optional
    uniform mat4 projectionMatrix; // optional

    attribute vec3 position;
    attribute vec2 uv;
    attribute vec4 color;

    varying vec3 vPosition;
    varying vec2 vUv;
    varying vec4 vColor;

    void main()	{

      vPosition = position;
      vUv = uv;
      vColor = color;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: /* glsl */`
    precision mediump float;
    varying vec2 vUv;
    uniform float uMapMix;
    uniform sampler2D uMap1, uMap2;
    void main() {
      vec4 px1 = texture2D(uMap1, vUv);
      vec4 px2 = texture2D(uMap2, vUv);
      gl_FragColor = mix(px1, px2, uMapMix);
    }
  `,
  uniforms: {
    uMap1: { value: new THREE.Texture() },
    uMap2: { value: new THREE.Texture() },
    uMapMix: { value: 0 },
  },
}))
scene.add(sky)
time.onChange(({ time }) => {
  sky.rotation.y = time * .1
})

export const getEnvironment = () => cubeRenderTarget.texture
export const renderEnvironment = (renderer: THREE.WebGLRenderer) => {
  cubeRenderTarget.clear(renderer, true, true, true)
  cubeCamera.update(renderer, scene)
}
export const updateEnviromnent = (map1: THREE.Texture | null, map2: THREE.Texture | null, mix: number) => {
  sky.material.uniforms.uMap1.value = map1
  sky.material.uniforms.uMap2.value = map2
  sky.material.uniforms.uMapMix.value = mix
}