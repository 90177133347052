import lodash from 'lodash'
import * as THREE from 'three'
import * as Data from 'data'
import * as Router from 'some-utils/router'
import * as Time from 'state/time'
import * as UI from 'state/ui'
import * as scroll from 'state/scroll'
import * as Layout from 'state/layout'
import * as Navigation from 'state/navigation'
import * as UtilsMisc from 'utils/__old__/misc'
import * as Animation from 'some-utils/Animation'
import * as MathUtils from 'some-utils/math'
import * as GeomUtils from 'some-utils/geom'
import * as ObjectUtils from 'some-utils/object'
import * as DomUtils from 'some-utils/dom/utils'
import * as iterators from 'some-utils/iterators'
import * as variables from 'some-utils/variables'
import * as misc from 'some-utils/misc'
import { pointerInfo } from 'some-utils/dom/pointer'
import { CascadingProps } from 'utils/CascadingProps'
import globalize from 'utils/__old__/globalize'

const cheat = {}

export const cheatExpose = (props: any) => Object.assign(cheat, props)

globalize({
  lodash,
  THREE,
  Data,
  Time,
  UI,
  scroll,
  Router,
  Layout,
  Navigation,
  UtilsMisc,
  Animation,
  MathUtils,
  GeomUtils,
  ObjectUtils,
  DomUtils,
  variables,
  iterators,
  misc,
  CascadingProps,
  pointerInfo,
  cheat,
}, {
  hook: () => Time.resetAutoPause(),
})
