import { Media, StrapiComponent, StrapiItem } from 'config/types/strapi'

export const NewsCategories = [
  'EVENT',
  'SPOTLIGHT',
  'BLOG',
] as const

export type NewsCategory = typeof NewsCategories[number]

export function parseNewsCategory(str: string): NewsCategory | null {
  str.toLowerCase()
  return NewsCategories.find(c => c.toLowerCase() === str) ?? null
}

export type NewsCategoryNames = Record<NewsCategory, string>

export type NewsPreview = StrapiItem<{
  NewsCategory: NewsCategory
  Date: string
  Slug: string
  Title: string
  Subtitle: string
  Description: string
  CoverTheme: 'dark' | 'light'
  Thumbnail: {
    data: Media
  }
}>

export type NewsFull = StrapiItem<NewsPreview['attributes'] & {
  Cover: {
    data: Media
  }
  Contents: StrapiComponent[]
}>
