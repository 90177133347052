import { useResponsive } from 'state/layout'
import { safeClassName, useObservable } from 'some-utils/npm/react'
import { DivSwitch } from 'some-utils/npm/react/components'

import * as UI from 'state/ui'
import { ovvoFooterOpeningObs } from 'state/ovvo-footer'
import { FacebookButton, InstagramButton, LinkedinButton, VimeoButton, Spacing } from 'view/components'
import { Circle } from './svg'
import { DASH_SPACING } from '../HUD'

const BottomRightContent = () => (
  <div className='flex row center' style={{ transform: 'translateX(-10px)' }}>
    <div className='Dash' />
    <Spacing size={DASH_SPACING} />
    <FacebookButton />
    <LinkedinButton />
    <VimeoButton />
    <InstagramButton />
  </div>
)

export const BottomRight = () => {
  const { right, bottom } = useResponsive().gridPadding
  const darkTheme = useObservable(ovvoFooterOpeningObs) && 'dark-theme'
  return (
    <div
    className={safeClassName('BottomMenu right', darkTheme)}
      style={{
        right: `${right}px`,
        bottom: `${bottom / 2}px`,
        transform: `translate(16px, 50%)`,
      }}
      onPointerEnter={() => UI.hudOpeningHoverObs.setValueWithDelay(true, 0)}
      onPointerLeave={() => UI.hudOpeningHoverObs.setValueWithDelay(false, 2)}
    >

      <DivSwitch
        index={useObservable(UI.hudOpening) ? 0 : 1}
        items={[
          BottomRightContent,
          Circle,
        ]} />

    </div>
  )
}
