import { globalData, newsCategoriesNames } from 'data'
import { navigate, staticRoutes } from 'state/navigation'
import { UnifiedSearchResult } from 'state/unified-search'
import { Scrollbar } from 'view/components'
import { LargeScroller, useBindScrollStates } from 'view/components/LargeScroller'
import { Media } from 'view/components/Media'

import './MobileResults.css'

function MobileEntry({
  project,
  news,
}: {
  project?: UnifiedSearchResult['projects'][0]
  news?: UnifiedSearchResult['allNews'][0]
}) {
  const isProject = project !== undefined
  const isNews = news !== undefined
  const title =
    isProject ? project?.data.attributes.coverTitle :
      isNews ? news?.data.attributes.Title :
        '╳╳╳'
  const description =
    isProject ? project?.data.attributes.coverSubtitle :
      isNews ? newsCategoriesNames[news.data.attributes.NewsCategory] :
        '╳╳╳'
  const media =
    project?.data.attributes.searchMediaAlternative.data
    ?? news?.data.attributes.Cover.data
    ?? news?.data.attributes.Thumbnail.data

  const theme = project?.data.attributes.Theme ?? news?.data.attributes.CoverTheme ?? 'dark'
  const label =
    isProject ? globalData.attributes.MenuNameProject :
      isNews ? globalData.attributes.MenuNameNews :
        ''
  return (
    <div
      className='MobileEntry flex column'
      onClick={() => {
        if (isProject) {
          navigate(project.data.attributes.slug)
        }
        else {
          navigate(`${staticRoutes.news}/${news!.data.attributes.Slug}`)
        }
      }}
    >
      <div className={`Image flex column ${theme}-theme`}>
        {media && (
          <Media className='absolute-fill' media={media} />
        )}
        <p className='ui ui-bigger ui-uppercase'>
          {label}
        </p>
      </div>
      <div className='Texts'>
        <div className='ui ui-bigger ui-uppercase UnifiedSearchTitle'>{title}</div>
        <div className='ui ui-bigger ui-uppercase UnifiedSearchDescription'>{description}</div>
      </div>
    </div>
  )
}

export function MobileResults({
  searchResult,
}: {
  searchResult: UnifiedSearchResult
}) {
  const scroll = useBindScrollStates()
  return (
    <>
      <Scrollbar
        direction='vertical'
        padding={{ vertical: 16 }}
        stateRef={scroll.refs.scrollbar}
        zIndex={10}
      />

      <div className='Bottom MobileResults flex-1 flex column'>
        {(searchResult.projects.length === 0 && searchResult.allNews.length === 0) && (
          <div className='absolute-fill overflow-hidden'>
            {Array.from({ length: 3 }).map((_, i) => (
              <MobileEntry key={`spacer-${i}`} />
            ))}
          </div>
        )}

        <LargeScroller stateRef={scroll.refs.scroller}>
          {searchResult.projects.map(project => (
            <MobileEntry
              key={`project-${project.data.id}`}
              project={project} />
          ))}
          {searchResult.allNews.map(news => (
            <MobileEntry
              key={`project-${news.data.id}`}
              news={news} />
          ))}
        </LargeScroller>
      </div>
    </>
  )
}
