import { useMemo, useState } from 'react'
import { clamp, clampModulo } from 'some-utils/math'
import { handleKeyboard, handlePointer, handleWindow } from 'some-utils/dom'
import { useEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { Animation } from 'some-utils/Animation'
import { api } from 'data'
import { responsiveObs, useResponsive } from 'state/layout'
import { NavArrow, SliderIndex, SliderPool } from '../components'
import { ProgressBar } from '../gl/ProgressBar'
import './SliderView.css'

export const SliderView = ({
  projects,
  // Shandor update Apr 2023:
  useSliderIndex = false,
  useProgressBar = true,
}: {
  projects: api.Project[]
  useSliderIndex?: boolean
  useProgressBar?: boolean
}) => {
  const { columnsCount } = useResponsive()

  const {
    index,
    position,
  } = useMemo(() => ({
    index: new ObservableNumber(0),
    position: new ObservableNumber(0),
  }), [])

  const setIndex = ({
    index: value = index.value,
    delta = 0,
  }) => {
    value += delta
    value = clampModulo(value, 0, projects.length)
    // Tween the position
    Animation.tween(position, .7, {
      to: { value: value },
      ease: 'out5',
    })
    if (value !== index.value) {
      index.value = value
    }
  }

  const [[
    arrowWidth,
    arrowPaddingTop,
    arrowPaddingBottom,
  ], setArrowDimensions] =
    useState<[string, number, number]>(['20%', 0, 0])

  const { ref } = useEffects<HTMLDivElement>(function* (div) {

    // Don't forget to rewind.
    setIndex({ index: 0 })

    yield handleKeyboard({
      onDown: [
        ['ArrowLeft', () => setIndex({ delta: -1 })],
        ['ArrowRight', () => setIndex({ delta: 1 })],
      ],
    })

    yield handlePointer(div.parentElement!, {
      onHorizontalDragStart: () => {
        document.documentElement.style.setProperty('user-select', 'none')
      },
      onHorizontalDragStop: info => {
        document.documentElement.style.removeProperty('user-select')
        const dx = -info.delta.x / window.innerWidth
        const velocityJump = clamp(dx * 20, -1, 1)
        const index = clamp(Math.round(position.value + velocityJump), 0, projects.length - 1)
        setIndex({ index })
      },
      onHorizontalDrag: info => {
        const boost = responsiveObs.value.mobile ? 1 : 3
        const dx = -info.delta.x / window.innerWidth
        position.value += dx * boost
        Animation.cancelTween(position)
      },
      onWheelFrame: ({ velocity, velocityOld, deltaTime }) => {
        const threshold = 1000
        if (Math.abs(velocity.x) < threshold) {
          if (Math.abs(velocityOld.x) >= threshold) {
            const velocityJump = clamp(velocity.x * 0.25, -1, 1) // helps to anticipate the user desire
            const index = clamp(Math.round(position.value + velocityJump), 0, projects.length - 1)
            setIndex({ index })
          }
          return
        }

        const width = document.documentElement.offsetWidth
        position.value += velocity.x * deltaTime / width
        Animation.cancelTween(position)
      },
    })

    // Compute arrow dimensions (padding & width):
    yield handleWindow({
      executeOnResizeImmediately: true,
      onResize: size => {
        const { top, bottom } = div.getBoundingClientRect()
        const wrapper = div.querySelector('.CoverWrapper')
        if (wrapper) {
          const wrapperWidth = wrapper.clientWidth
          const width = `${Math.ceil((size.width - wrapperWidth) / 2)}px`
          setArrowDimensions([width, top, size.height - bottom])
        }
      },
    })

  }, [projects])
  return (
    <>
      <div ref={ref} className='SliderView absolute-fill flex column center'>
        {projects.length > 0 ? (
          <>
            <SliderPool
              position={position}
              projects={projects}
            />

            {useSliderIndex && (
              <SliderIndex
                position={position}
                count={projects.length}
              />
            )}
          </>
        ) : (
          <div>Pas de résultat...</div>
        )}
      </div>

      <NavArrow
        type='previous'
        areaWidth={arrowWidth}
        paddingTop={arrowPaddingTop}
        paddingBottom={arrowPaddingBottom}
        enabled={projects.length > 1}
        onClick={() => setIndex({ delta: -1 })}
      />

      <NavArrow
        type='next'
        areaWidth={arrowWidth}
        paddingTop={arrowPaddingTop}
        paddingBottom={arrowPaddingBottom}
        enabled={projects.length > 1}
        onClick={() => setIndex({ delta: 1 })}
      />

      {columnsCount > 1 && (
        <>
          {useProgressBar && (
            <ProgressBar
              count={projects.length}
              position={position}
            />
          )}
        </>
      )}
    </>
  )
}
