import { Locale } from 'config'
import { handlePointer } from 'some-utils/dom'
import { safeClassName, useEffects } from 'some-utils/npm/react'
import { setLocale, useLocale } from 'state/navigation'
import { IconWrapper } from 'view/components'

import './LocaleButton.css'

type Props = Partial<{
  en: boolean
  fr: boolean
  locale: Locale
}>

export const LocaleButton = ({
  fr = false,
  en = !fr,
  locale = en ? 'en' : 'fr',
}: Props) => {
  const currentLocale = useLocale()
  const isCurrent = currentLocale === locale
  const { ref } = useEffects<HTMLElement>(function* (element) {
    yield handlePointer(element, {
      onTap: () => setLocale(locale),
    })
  }, [locale])
  return (
    <IconWrapper
      ref={ref}
      className={safeClassName('LocaleButton', { 'ui-dim': !isCurrent, 'underline': isCurrent })}
      style={{
        pointerEvents: currentLocale === locale ? 'none' : 'all',
      }}
    >
      <span>
        {locale.toUpperCase()}
      </span>
    </IconWrapper>
  )
}
