import { useResponsive } from 'state/layout'

export const SectionAnchor = ({ content = null as any }) => {
  const { gridPadding: { left, right } } = useResponsive()
  const inset = 8
  const debug = false
  return (
    <div className='ProjectContent SectionAnchor' style={{ height: '1px', padding: `0 ${right + inset}px 0 ${left + inset}px` }} >
      {debug && (
        <div className='fill' style={{ backgroundColor: 'white', opacity: .1 }}/>
      )}
    </div>
  )
}


