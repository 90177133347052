import { LayoutColumn } from '../../column-layout'
import { Media } from './items'

export enum StrapiComponentType {
  Text = 'project-contents.text',
  Spacer = 'project-contents.spacer',
  MediaSingle = 'project-contents.media-single',
  MediaGallery = 'project-contents.media-gallery',
  GridComposer = 'project-contents.grid-composer',
  SectionAnchor = 'project-contents.section-anchor',
}

export type VisibilityType = 'visible' | 'hidden'

export type BlockLayout = {
  columnStart: number | string // Allowing string for absolute value eg: "0.5em"
  columnWidth: number
}

export type BlockLayoutTuple = [
  columnStart: number | string, // Allowing string for absolute value eg: "0.5em"
  columnWidth: number,
]

export type ColumnCase = Record<string, BlockLayout>

export type GridLayout = {
  global?: {
    vertical?: {
      gutter?: string
    }
  }
  entries: GridComposerEntry[]
}

export const defaultColumnCase: LayoutColumn<BlockLayout> = {
  1: { columnStart: 0, columnWidth: 1 },
  3: { columnStart: 0, columnWidth: 3 },
  4: { columnStart: 0, columnWidth: 4 },
  5: { columnStart: 0, columnWidth: 5 },
}

export interface StrapiComponent {
  id: number
  __component: string
  visibility?: VisibilityType
}

export interface Text extends StrapiComponent {
  text: string
  layout?: { columns: ColumnCase }
}

export const isText = (value: any): value is Text => (
  !!value
  && 'id' in value
  && 'text' in value
)

export interface Spacer extends StrapiComponent {
  size: number
}

export interface MediaSingle extends StrapiComponent {
  media: { data: Media }
  layout?: { columns: ColumnCase }
  externalMedia: boolean
  externalMediaUrl: string
  aspect: string

  // caption
  displayCaption: boolean
  useAlternativeCaption: boolean
  alternativeCaption: string
}

export interface MediaGallery extends StrapiComponent {
  header: string
  caption: string
  aspect: string
  medias: { data: Media[] }
}

export interface GridComposerEntry {
  selector: string
  zIndex: number
  horizontal?: {
    zIndex?: number
    justifyContent?: string
  }
  vertical?: {
    priority: number
  }
  columns?: ColumnCase
}

export interface GridComposer extends StrapiComponent {
  gridLayout: GridLayout
  mediaSingles: MediaSingle[]
  texts: Text[]
}
