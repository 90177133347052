import React from 'react'
import * as THREE from 'three'
import { layoutObs } from 'state/layout'
import { useComplexEffects } from 'some-utils/npm/react'
import { THREE_SCENE_HEIGHT } from 'config'

export const useResponsiveCamera = () => {

  const camera = React.useMemo(() => {
    const camera = new THREE.PerspectiveCamera(90, layoutObs.value.aspect)
    camera.name = 'responsive camera'
    camera.position.set(0, 0, THREE_SCENE_HEIGHT / 2)
    return camera
  }, [])

  useComplexEffects(function* () {
    yield layoutObs.onChange(() => {
      camera.aspect = layoutObs.value.aspect
      camera.updateProjectionMatrix()
    })
  }, [camera])

  return camera
}
