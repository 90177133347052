import * as THREE from 'three'
import { useEffects } from 'some-utils/npm/react'
import { coverVisibilityObs, homeScrollObs, projectScrollObs } from 'state/scroll'
import { aRange } from 'some-utils/iterators'
import { useThree } from '@react-three/fiber'
import { MediaPlaneMesh } from './MediaPlaneMesh'

export const MediaPlane = () => {

  const camera = useThree().camera as THREE.PerspectiveCamera

  const { ref } = useEffects<THREE.Group>(function* (group) {

    // Creating the 3 planes.
    const planes = aRange(3).map(index => new MediaPlaneMesh({
      index,
      camera,
      parent: group,
    }))

    // Ensure their destruction on unmount (remove from group, dispose texture, video etc.).
    yield* planes

    // Update planes on scroll.
    const update = () => {
      for (const plane of planes) {
        plane.update(homeScrollObs.value, projectScrollObs.value, coverVisibilityObs.value)
      }
      // console.log([homeScrollObs.value, projectScrollObs.value, coverVisibilityObs.value, ...planes.map(p => p.material.uniforms.uVisibility.value)].map(v => v.toFixed(2)).join(' '))
    }
    yield homeScrollObs.onChange(update)
    yield projectScrollObs.onChange(update)
    yield coverVisibilityObs.onChange(update)
    update()

  }, [])

  return (
    <group
      ref={ref}
      name='MediaPlane'
    />
  )
}
