import React from 'react'
import * as THREE from 'three'
import { layoutObs, responsiveObs } from 'state/layout'
import * as ui from 'state/ui'
import { PlaneGeometry } from 'three'
import { inout, inverseLerp, lerp } from 'some-utils/math'
import { useEffects } from 'some-utils/npm/react'
import { parseColorWithAlpha } from 'utils'
import { solveLayoutJson } from 'utils/solveLayoutJson'
import { globalData } from 'data'
import { getProps } from './props'
import { fragmentShader, vertexShader } from './shaders'

const lineThickness = 1


export const GridPlane: React.FC = () => {

  const groupRef = React.useRef<THREE.Group>(null)

  useEffects(function*() {
    const group = groupRef.current!
    yield () => group.clear()

    const { opening, opacity } = yield* getProps()

    const defaultGridOpacity = .5
    let gridOpacity = defaultGridOpacity
    responsiveObs.withValue(({ columnsCount }) => {
      gridOpacity = solveLayoutJson(columnsCount, globalData.attributes.GridOpacity, defaultGridOpacity)
    })
    const getAlpha = () => {
      const t = inout(opening.value, 3, .33)
      return lerp(0, gridOpacity, t) * opacity.value
    }

    const geometry = new PlaneGeometry(1, 1, 1, 1)

    const meshes = Array.from({ length: 6 }).map((_, index) => {
    
      const material = new THREE.ShaderMaterial({
        transparent: true,
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        uniforms: {
          uColor: { value: parseColorWithAlpha(ui.uiSettings.color['--ui-default']) },
          uBlank: { value: 0 },
          uOffset: { value: index / 6 },
          uAlpha: { value: getAlpha() },
        },
      })
            
      const mesh = new THREE.Mesh(geometry, material)
      group.add(mesh)
      return mesh
    })

    yield ui.primaryColor.onChange(value => {
      for (const { material } of meshes) {
        (material.uniforms.uColor.value as THREE.Color).set(value)
      }
    })
  
    const updateAlpha = () => {
      const alpha = getAlpha()
      for (const { material } of meshes) {
        material.uniforms.uAlpha.value = alpha
      }
    }

    yield opening.onChange(updateAlpha)
    yield opacity.onChange(updateAlpha)

    const paint = () => {
      const { size, threeSize, threeRatio, responsive } = layoutObs.value
      const { gridPadding } = responsive
      const w = threeSize.width - threeRatio * gridPadding.totalHorizontal
      const h = threeSize.height - threeRatio * gridPadding.totalVertical
      const middleY = (gridPadding.top + (size.height - gridPadding.bottom)) / 2 * threeRatio
      const y = threeSize.height / 2 - middleY
      const max = responsive.columnsCount + 1
      for (const [index, mesh] of meshes.entries()) {
        const visible = index < max
        mesh.visible = visible
        if (visible) {
          mesh.position.x = w * (-0.5 + index / (max - 1))
          mesh.scale.x = lineThickness * threeRatio

          const off = index * .07
          const t = inverseLerp(off + .0, off + .3, opening.value)
          const opn = index === 0 ? 1 : inout(t, 2, .2)
          mesh.position.y = y + h * (1 - opn) / 2
          mesh.scale.y = h * opn
        }
      }
    }

    yield layoutObs.onChange(paint)
    yield opening.onChange(paint)

    paint()

  }, [])

  return (
    <group ref={groupRef}/>
  )
} 
