
export const cursorSize = 64

export const cursorStyle = /* css */`

.Cursor {
  pointer-events: none;
  position: fixed;
  width: ${cursorSize}px;
  height: ${cursorSize}px;
  z-index: 1000;
  /* color: var(--ui-secondary-color); */
}

`
