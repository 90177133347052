import { handlePointer } from 'some-utils/dom'
import { safeClassName, useEffects, useObservable } from 'some-utils/npm/react'
import { DivSwitch } from 'some-utils/npm/react/components'

import { FullscreenIcon, SearchIcon13 } from 'assets/svg'
import { globalData } from 'data'
import { useResponsive } from 'state/layout'
import { navigate, staticRoutes, useLocale, useRouteIs } from 'state/navigation'
import * as UI from 'state/ui'
import { Spacing } from 'view/components'
import { closeOpenedNews } from 'view/news/controller'

import * as svg from './svg'

import { SearchCorner } from './SearchCorner'
import './TopMenu.css'
import { TopMenuMobile } from './TopMenuMobile'

const ButtonSearchProject = ({ showSearchIcon = false }) => {
  const routeIs = useRouteIs()
  const highlight = routeIs.searchProjects
  const { ref } = useEffects<HTMLAnchorElement>(function* (span) {
    yield handlePointer(span, {
      onTap: () => {
        navigate(staticRoutes.searchProjectsPixel, { clearHash: true })
      },
    })
  }, [])
  return (
    <a
      ref={ref}
      href={`/${useLocale()}${staticRoutes.searchProjectsPixel}`}
      className={safeClassName('flex row button', highlight ? 'ui-highlight' : 'ui-dim')}
      onClick={event => event.preventDefault()}
    >
      {showSearchIcon && (
        <>
          <SearchIcon13 />
          <Spacing size={8} />
        </>
      )}
      {globalData.attributes.MenuNameProject ?? '{PROJECTS}'}
    </a>
  )
}

const ButtonNews = () => {
  const routeIs = useRouteIs()
  const { ref } = useEffects<HTMLAnchorElement>(function* (span) {
    yield handlePointer(span, {
      onTap: () => {
        navigate(staticRoutes.news, { clearHash: true })
        closeOpenedNews()
      },
    })
  }, [])
  return (
    <a
      ref={ref}
      className={safeClassName('ui-dim button', routeIs.news ? 'ui-highlight' : 'ui-dim')}
      target='_blank'
      rel='noreferrer'
      href={`/${useLocale()}${staticRoutes.news}`}
      onClick={event => event.preventDefault()}
    >
      {globalData.attributes.MenuNameNews ?? '{NEWS}'}
    </a>
  )
}

const ButtonShowreel = () => {
  const routeIs = useRouteIs()
  const highlight = routeIs.showreel
  const { ref } = useEffects<HTMLAnchorElement>(function* (span) {
    yield handlePointer(span, {
      onTap: () => {
        navigate(staticRoutes.reels)
      },
    })
  }, [])
  return (
    <a
      ref={ref}
      href={`/${useLocale()}${staticRoutes.reels}`}
      className={safeClassName('flex row button', highlight ? 'ui-highlight' : 'ui-dim')}
      onClick={event => event.preventDefault()}
    >
      {globalData.attributes.MenuNameReels}
    </a>
  )
}

const ButtonStudios = () => {
  const routeIs = useRouteIs()
  const highlight = routeIs.studio
  const { ref } = useEffects<HTMLAnchorElement>(function* (anchor) {
    yield handlePointer(anchor, {
      onTap: () => {
        navigate(staticRoutes.studio)
      },
    })
  }, [])
  return (
    <a
      ref={ref}
      href={`/${useLocale()}${staticRoutes.studio}`}
      className={safeClassName('flex row button', highlight ? 'ui-highlight' : 'ui-dim')}
      onClick={event => event.preventDefault()}
    >
      {globalData.attributes.MenuNameStudio}
    </a>
  )
}

const Buttons = ({
  showSearchIcon = false,
  showNews = true,
  showShowreel = true,
  showFullscreenIcon = false,
}) => {
  return (
    <div className='flex row center' style={{ transform: 'translateX(3px)' }}>

      {showNews && (
        <>
          <ButtonNews />
          <Spacing size={24} />
        </>
      )}

      <ButtonSearchProject showSearchIcon={showSearchIcon} />
      <Spacing size={24} />

      {showShowreel && (
        <>
          <ButtonShowreel />
          <Spacing size={24} />
        </>
      )}

      <ButtonStudios />

      {showFullscreenIcon && (
        <span
          className='button ui-dim'
          style={{ paddingLeft: '8px', paddingRight: '4px' }}
          onClick={() => {
            if (document.fullscreenElement) {
              document.exitFullscreen()
            } else {
              document.documentElement.requestFullscreen()
            }
          }}>
          <FullscreenIcon />
        </span>
      )}

      <Spacing size={24} />
    </div>
  )
}

/**
 * This is a mess, originally there were as many icons as buttons (it was "double
 * circle"), but now there are only one icon, which was a simple circle before 
 * and now it's a magnifying glass.
 */
export const Icon = ({
  showBlog = false,
  showShowreel = true,
  // Shandor update Apr 2023:
  useDoubleCircle = false,
}) => {
  return useDoubleCircle
    ? (
      <div className='flex row center' style={{ transform: 'translateX(10px)' }}>
        <svg.DoubleCircle mode='diagonal' style={{ fill: 'var(--ui-highlight)' }} />
        <Spacing size={32} />
        {showBlog && (
          <>
            <svg.DoubleCircle mode='vertical' />
            <Spacing size={32} />
          </>
        )}
        {showShowreel && (
          <>
            <svg.DoubleCircle mode='anti-diagonal' />
            <Spacing size={32} />
          </>
        )}
        <svg.DoubleCircle mode='horizontal' />
      </div>
    )
    : (
      <div className='flex row center' style={{ transform: 'translateX(16px)' }}>
        <svg.Search style={{ fill: 'var(--ui-color)' }} />
      </div>
    )
}

const None = (props: any) => null

const TopMenuDesktop = () => {
  const { gridPadding: { top, right } } = useResponsive()

  return (
    <div
      className='TopMenu ui ui-uppercase'
      style={{
        right: `${right}px`,
        top: `${top / 2}px`,
        transform: `translate(0, -50%)`,
      }}
      // NOTE: On Windows "enter" & "leave" events may be sync (same frame), since then, the two invocations were in conflicts.
      onPointerEnter={() => UI.hudOpeningHoverObs.setValueWithDelay(true, 0)}
      onPointerLeave={() => UI.hudOpeningHoverObs.setValueWithDelay(false, 2)}
    >
      <DivSwitch
        index={useObservable(UI.hudOpening) ? 0 : 1}
        items={[
          Buttons,
          None,
        ]} />
      <SearchCorner />
    </div>
  )
}

export const TopMenu = () => {
  return (useResponsive().mobile
    ? <TopMenuMobile />
    : <TopMenuDesktop />
  )
}
