import { DEFAULT_EASE, DEFAULT_TRANSITION } from './defaults'
import { easeOptions } from './ease'
import { EaseFunction, PropDeclaration, PropType, TransitionDeclaration, TransitionType, ValueType } from './types'

export const isValueType = (value: any): boolean => {
  const type = typeof value
  if (type === 'number') return true
  if (type === 'string') return true
  if (type === 'boolean') return true
  if (type === 'object' && value.constructor !== Object && value.constructor !== Array) return true
  if (Array.isArray(value) && value.every(v => isValueType(v))) return true
  return false
}

export const isValueProp = (obj: any) => !!obj && typeof obj === 'object' && 'value' in obj

export const ensureEase = (ease: EaseFunction | string): EaseFunction => {
  if (ease === 'default') {
    return DEFAULT_EASE
  }
  if (typeof ease === 'string') {
    if (ease in easeOptions) {
      return easeOptions[ease]
    }
    throw new Error(`Unhandled ease function: "${ease}"`)
  }
  return ease
}

export const ensureTransition = (transition: TransitionDeclaration): TransitionType => {
  const wrap = (duration: number, ease?: EaseFunction | string, delay?: number): TransitionType => ({
    duration,
    ease: ensureEase(ease ?? 'default'),
    delay: delay ?? 0,
  })

  if (transition === 'default') {
    return DEFAULT_TRANSITION
  }

  if (Array.isArray(transition)) {
    const [duration, ease, delay] = transition
    return wrap(duration, ease, delay)
  }

  const {
    duration,
    ease,
    delay,
  } = transition

  return wrap(duration, ease, delay)
}

export const ensureProp = (prop: PropDeclaration): PropType => {
  const wrap = (value: ValueType, transition?: TransitionDeclaration): PropType => {
    if (transition !== undefined) {
      return { value, transition: ensureTransition(transition) }
    }
    return { value }
  }

  if (Array.isArray(prop)) {
    return wrap(prop)
  }

  if (isValueProp(prop)) {
    const { value, transition } = prop as any
    return wrap(value, transition)
  }

  return wrap(prop as ValueType)
}

