import { staticRoutes } from 'config/staticRoutes'
import { allProjectsBySlug, getHomeProjects } from 'data'
import { useObservable } from 'some-utils/npm/react'
import { localeObs, projectObs, routeObs } from './navigation'
import { isNewsRoute, splitPathname } from './routes'

export const getRouteIs = (route: string) => {
  return {
    get home() {
      return route === staticRoutes.home
    },
    get news() {
      return isNewsRoute(route)
    },
    get searchProjects() {
      return route.startsWith(staticRoutes.searchProjects)
    },
    get showreel() {
      return route === staticRoutes.reels
    },
    get directProject() {
      return allProjectsBySlug.has(route.substring(1))
    },
    get studio() {
      return route === staticRoutes.studio
    }
  }
}

export const currentRouteIsHome = () => {
  return routeObs.value === staticRoutes.home
}

export const currentRouteIsProject = () => {
  const { slug } = splitPathname()
  return slug !== null && allProjectsBySlug.has(slug)
}

export const currentRouteProject = () => {
  const { slug } = splitPathname()
  return slug ? allProjectsBySlug.get(slug) ?? null : null
}

export const currentProjectHomeIndex = () => {
  const { slug } = splitPathname()
  return getHomeProjects().findIndex(project => project.slug === slug)
}

export const currentRouteIsSearchProject = () => {
  const { route } = splitPathname()
  return route.includes(staticRoutes.searchProjects)
}

export const useLocale = () => useObservable(localeObs)
export const useProject = () => useObservable(projectObs)
