import React from 'react'
import { Quality, withQuality } from 'config/quality'
import { lerp, solveCubicEasing } from 'some-utils/math'
import { useLayout, useResponsive } from 'state/layout'
import './css/MaskWrapper.css'

function* smoothGradient(
  positionIn: number, 
  positionOut: number, 
  alphaIn: number, 
  alphaOut: number, 
  steps = 60,
  cubicAnchors: [number, number, number, number] = [0.5, 0, 0.5, 1],
) {
  for (let i = 0; i < steps; i++) {
    const t = i / (steps - 1)
    const position = lerp(positionIn, positionOut, t)
    const alpha = lerp(alphaIn, alphaOut, solveCubicEasing(...cubicAnchors, t))
    const color = `#ffffff` + Math.round(alpha * 0xff).toString(16).padStart(2, '0')
    yield `${color} ${position.toFixed(1)}px`
  }
}

export const useSmoothGradient = () => {
  const { size: { height } } = useLayout()
  const { gridPadding: { top, bottom } } = useResponsive()
  const mt = top * 2 / 3
  const mb = bottom * 2 / 3
  
  const stepsCount = withQuality({
    [Quality.MAX]: 90,
    [Quality.GOOD]: 30,
    [Quality.NORMAL]: 20,
    default: 2,
  })!
  
  const steps = [
    ...smoothGradient(top - mt, top, 0, 1, stepsCount, [.8, 0, .8, 1]),
    ...smoothGradient(height - bottom, height - bottom + mb, 1, .1, stepsCount, [.3, 0, .3, 1]),
  ]
  
  return `linear-gradient(\n  ${steps.join(',\n  ')})`
}

export const useStopGradient = () => {
  const { gridPadding: { top } } = useResponsive()
  return `linear-gradient(#000c ${top}px, black ${top}px)`
}

export const MaskWrapper = React.forwardRef<
  HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {
    smooth?: boolean
  }>(({ 
    children,
    smooth = false,
  }, ref) => {
  
  // No more mask...
  // const smoothGradient = useSmoothGradient()
  // const stopGradient = useStopGradient()
  // const maskImage = smooth ? smoothGradient : stopGradient

  return (
    <div
      ref={ref}
      className="MaskWrapper"
      // style={{ maskImage, WebkitMaskImage: maskImage }}
    >
      {children}
    </div>
  )
})
