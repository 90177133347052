import { HTMLAttributes } from 'react'

import { Animation } from 'some-utils/Animation'
import { handlePointer } from 'some-utils/dom'
import { Message } from 'some-utils/message'
import { useEffects } from 'some-utils/npm/react'

import { ReactComponent as OvvoLogo2023SVG } from 'assets/svg/ovvo-logo-2023.svg'
import { responsiveObs } from 'state/layout'
import { navigate } from 'state/navigation'
import { ovvoLogoOpening } from 'state/ui'
import { closeMobileMenu } from 'view/mobile-menu'
import { theatreObject, theatreSheet, updateSvg } from './theatre'
import { computeAspectRatio } from './utils'

import './OvvoLogo2023.css'

const PADDING = 16
const HEIGHT = 16

export const OvvoLogo2023 = (props: HTMLAttributes<HTMLDivElement>) => {
  const { ref } = useEffects<SVGSVGElement>(function* (svg) {
    const ovvoLogoOpeningChild = ovvoLogoOpening.createChild(false)

    const div = svg.parentElement as HTMLDivElement
    const aspectRatio = computeAspectRatio(svg)
    svg.style.width = `${HEIGHT * aspectRatio}px`
    svg.style.height = `${HEIGHT}px`
    for (const child of svg.querySelectorAll('[fill]')) {
      child.setAttributeNS(null, 'fill', 'var(--mask-color)')
    }
    yield responsiveObs.withValue(({ gridPadding, columnsCount }) => {
      const d = HEIGHT / 2
      const leftShift = columnsCount > 1
        ? HEIGHT * 1.5 // border line is in the middle of "OVVO" 
        : 3 // border line is on the border of "OVVO"
      div.style.marginLeft = `${gridPadding.left - PADDING - d - leftShift}px`
      div.style.marginTop = `${gridPadding.top / 2 - PADDING - d - 2}px`
      div.style.padding = `${PADDING}px`
    })
    yield theatreObject.onValuesChange(object => {
      updateSvg(object, svg)
    })
    const sequenceDuration = 2.75
    const playback = {
      _position: 0,
      get position() { return playback._position }, 
      set position(value) {
        playback._position = value
        theatreSheet.sequence.position = sequenceDuration * value
      }, 
    }
    yield handlePointer(div, {
      onOver: () => {
        ovvoLogoOpeningChild.setValue(true)
      },
      onOut: () => {
        ovvoLogoOpeningChild.setValue(false)
      },
      onTap: () => {
        let caught = false
        const catchMessage = () => caught = true
        Message.send('APP', 'LOGO_TAP', { catchMessage })
        closeMobileMenu()
        if (caught === false) {
          navigate('/')
        }
      },
    })

    yield ovvoLogoOpening.onChange(value => {
      if (value) {
        Animation.tween(playback, 1.6, {
          to: { position: 1 },
        })
      }
      else {
        Animation.tween(playback, 1.6, {
          to: { position: 0 },
        })
      }
    })
  }, [])

  return (
    <div {...props} className='OvvoLogo2023 button'>
      <OvvoLogo2023SVG
        ref={ref}
        style={{ mixBlendMode: 'screen' }}
        fill='white' />
    </div>
  )
}
