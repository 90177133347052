import React from 'react'
import * as THREE from 'three'
import * as Data from 'data'
import * as ui from 'state/ui'
import { Animation } from 'some-utils/Animation'
import { homeScrollObs } from 'state/scroll/scroll'
import { THREE_SCENE_HEIGHT } from 'config'
import { layoutObs } from 'state/layout'
import { Observable } from 'some-utils/observables'
import { clamp01, hermite01, lerp } from 'some-utils/math'
import { useEffects } from 'some-utils/npm/react'
import { parseColorWithAlpha } from 'utils'
import { currentRouteIsHome, routeObs } from 'state/navigation'
import { useAppContext } from 'AppContext'

const vertexShader = /* glsl */`
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`
const fragmentShader = /* glsl */`
  varying vec2 vUv;
  uniform vec4 uColor;
  void main() {
    float d = length(vUv - 0.5) * 2.0;
    float a = 1.0 - smoothstep(0.9, 1.0, d);
    gl_FragColor = uColor;
    // gl_FragColor.a *= a;
  }
`

export const ScrollPoints: React.FC = () => {

  const groupRef = React.useRef<THREE.Group>(null)
  const { themeObs } = useAppContext()

  useEffects(function* () {
    const group = groupRef.current!
    yield () => group.clear()

    const getRadiusScalar = () => currentRouteIsHome() ? 1 : 0
    const radiusScalar = new Observable(getRadiusScalar())
    yield radiusScalar
    yield routeObs.onChange(() => {
      const valueStart = radiusScalar.value
      const valueEnd = getRadiusScalar()
      const duration = 0.5
      const delay = currentRouteIsHome() ? 1 : 0
      Animation.duringWithTarget(radiusScalar, { duration, delay }, ({ progress }) => {
        if (radiusScalar.destroyed) {
          return Animation.BREAK
        }
        radiusScalar.setValue(lerp(valueStart, valueEnd, progress))
      })
    })

    const planeGeometry = new THREE.PlaneGeometry(1, .2)
    const uniforms = {
      uColor: { value: parseColorWithAlpha(ui.uiSettings.color['--ui-default']) },
    }
    const material = new THREE.ShaderMaterial({
      transparent: true,
      vertexShader,
      fragmentShader,
      uniforms,
    })

    yield themeObs.withValue(() => {
      const appElement = document.querySelector('.App') as HTMLDivElement
      const color = window.getComputedStyle(appElement).getPropertyValue('--ui-default')
      for (const [index, value] of parseColorWithAlpha(color).entries()) {
        uniforms.uColor.value[index] = value
      }
    })

    yield ui.primaryColor.onChange(value => {
      (material.uniforms.uColor.value as THREE.Color).set(value)
    })

    const points = Data.getHomeProjects().map(() => {
      const point = new THREE.Mesh(planeGeometry, material)
      group.add(point)
      return point
    })

    // const line = new THREE.Mesh(
    //   planeGeometry,
    //   material,
    // )
    // group.add(line)

    const position = () => {
      group.visible = radiusScalar.value > 0
      
      const { size, responsive, aspect, threeRatio } = layoutObs.value
      const { gridPadding } = responsive
      const w = THREE_SCENE_HEIGHT * aspect * (1 - gridPadding.totalHorizontal / size.width)
      const h = THREE_SCENE_HEIGHT * (1 - gridPadding.totalVertical / size.height)
      group.position.x = -0.5 * w

      const pointSize = 10 * threeRatio * radiusScalar.value
      const margin = 24 * threeRatio
      const gap = 0.1
      const top = h / 2 - margin
      const middle = 0
      const bottom = -(h / 2) + margin

      const middleY = (gridPadding.top + (size.height - gridPadding.bottom)) / 2 * threeRatio
      const y = size.height * threeRatio / 2 - middleY
      // line.scale.x = 10 * threeRatio * radiusScalar.value
      // line.scale.y = 1 * threeRatio
      // line.position.y = middle

      for (const [index, point] of points.entries()) {
        const d = (homeScrollObs.value - 1) - index
        if (d < 0) {
          const t = hermite01(clamp01(1 + d))
          point.position.y = y + lerp(bottom + gap * (points.length - index - 1), middle, t)
          point.scale.setScalar(pointSize * lerp(0.5, 1, t))
        } else {
          const t = hermite01(clamp01(d))
          point.position.y = y + lerp(middle, top - gap * index, t)
          point.scale.setScalar(pointSize * lerp(1, 0.5, t))
        }
      }
    }

    yield radiusScalar.onChange(position)
    yield homeScrollObs.onStepChange(1 / 200, position)
    yield layoutObs.onChange(position)
    position()

  }, [])

  return (
    <group ref={groupRef}/>
  )
}
