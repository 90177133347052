import React from 'react'
import { ReactComponent as Fullscreen } from './svg/Cursor-Fullscreen.svg'
import { ReactComponent as Close } from './svg/Cursor-Close.svg'
import { ReactComponent as Next } from './svg/Cursor-Next.svg'
import { ReactComponent as Previous } from './svg/Cursor-Previous.svg'

const Native: React.FC = () => null

export const CursorComponents = {
  Native,
  Fullscreen,
  Close,
  Next,
  Previous,
  None: null,
}

export type CursorType = keyof typeof CursorComponents

export const cursorClassName = (type: CursorType) => `CursorType CursorType-${type}`

export const getCursorType = (element: HTMLElement | null): CursorType => {

  while (element) {
    if (element.tagName === 'IFRAME') {
      return 'None'
    }
    if (element.classList.contains('CursorType')) {
      for (const className of element.classList) {
        if (className.startsWith('CursorType-'))
          return className.slice('CursorType-'.length) as CursorType
      }
    }
    element = element.parentElement
  }

  return 'Native'
}
