import { CSSProperties } from 'react'

import { strapi } from 'config'
import { Markdown } from 'view/components'

import { useHorizontalPadding, useLayoutCase } from './utils/misc'

import defaultLayout from './Text.defaultLayout.json'

import './css/Text.css'

export const Text = ({
  content,
  style,
}: {
  content: strapi.Text
  style?: CSSProperties
}) => {

  const { columnStart, columnWidth } = useLayoutCase(content.layout?.columns, defaultLayout.columns)
  const padding = useHorizontalPadding([columnStart, columnWidth])

  return (
    <div
      className='ProjectContent Text flex column'
      style={{ ...padding, ...style }}
      data-column-start={columnStart}
      data-column-width={columnWidth}>
      <div>
        <Markdown content={content.text} />
      </div>
    </div>
  )
}


