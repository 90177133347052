import { useEffects } from 'some-utils/npm/react'

type Props = {
  url: string
}

/**
 * Loads and display an SVG image.
 */
export const SvgWrapper = ({ url }: Props) => {
  const { ref } = useEffects<HTMLDivElement>(function (div, state) {
    window.fetch(url).then(response => {
      if (response.ok) {
        response.text().then(str => {
          if (state.isMounted()) {
            div.innerHTML = str
          }
        })
      }
    })
  }, [])
  return (
    <div ref={ref} className='SvgWrapper' />
  )
}
