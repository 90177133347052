export const SOME_WHERE = '...'
export const BOUND = '::'

export const safePath = (path: string | string[]) => Array.isArray(path) ? path : path.split('.')

export const getSelector = (str: string) => {
  if (str.startsWith('#')) {
    str = str.slice(1)
  }

  const rootBound = str.startsWith(BOUND)
  if (rootBound) {
    str = str.slice(BOUND.length)
  }

  const tailBound = str.endsWith(BOUND)
  if (tailBound) {
    str = str.slice(0, -BOUND.length)
  }

  const path = [] as string[]

  if (rootBound === false) {
    path.push(SOME_WHERE)
  }

  const tokens = str.matchAll(/[\w-*]+/g)
  let token = '#'
  let index = 0

  const first = tokens.next().value as RegExpMatchArray
  token = first[0]
  index = first.index!
  path.push(token)

  for (const match of tokens) {
    const separator = str.slice(index + token.length, match.index)
    const currentToken = match[0]
    const currentIndex = match.index!

    if (separator === '...') {
      path.push(SOME_WHERE)
    }

    path.push(currentToken)

    token = currentToken
    index = currentIndex
  }

  if (tailBound === false) {
    path.push(SOME_WHERE)
  }

  return path
}

export const safeSelector = (selector: string | string[]) => (
  typeof selector === 'string' ? getSelector(selector) : selector
)

/**
 * This is tricky.
 *
 * For such path, pattern and match:
 *
 *     abbbccdde
 *     /bb.*d/
 *     -bbbccdd-
 *
 * This is the mask:
 *
 *     -X-X---X-
 *
 * The fisrt token position (rootIndex) is trivial, but the other not so much.
 * Here we are using "lastIndexOf" because RegExp is "greedy" by default.
 * So any token position execpt the first one is always the last one.
 */
const getMatchIndexMask = (matchStr: string, chars: string[], rootIndex: number, pathLength: number) => {
  const mask = new Array(pathLength).fill(false)
  mask[rootIndex] = true
  const charsCount = chars.length
  let currentIndex = matchStr.length
  for (let i = 1; i < charsCount; i++) {
    const char = chars[charsCount - i]
    currentIndex = matchStr.lastIndexOf(char, currentIndex - 1)
    mask[rootIndex + currentIndex] = true
  }
  return mask
}

/**
 * Last matches contribute more than first ones to the score.
 *
 * That is to say that the following:
 *
 *      ---XX
 *
 * is considered to be closer to the path than:
 *
 *      X---X
 *
 */
const computeScore = (mask: boolean[]) => {
  const max = mask.length
  let score = 0
  for (let i = 0; i < max; i++) {
    if (mask[i]) {
      score += 1 << i
    }
  }
  return score
}

export const selectorMatchPath = (selector: string[], path: string[]) => {

  const DEFAULT_SCORE = 1

  const map = new Map<string, string>()
  const getChar = (str: string) => {
    const value = map.get(str)
    if (value) {
      return value
    }
    const char = String.fromCharCode(97 + map.size)
    map.set(str, char)
    return char
  }
  const p = path.map(str => getChar(str)).join('')
  const root = selector[0] === SOME_WHERE
  const tail = selector[selector.length - 1] === SOME_WHERE
  const chars = [] as string[] // collection of "char", that is to say "token" only (not "dots""), for score computation
  let pattern = selector.slice(root ? 1 : 0, selector.length - (tail ? 1 : 0)).map(str => {
    if (str === SOME_WHERE) {
      return '.*'
    }
    if (str === '*') {
      return '.'
    }
    const char = getChar(str)
    chars.push(char)
    return char
  }).join('')

  if (pattern === '.') {
    return {
      ok: true,
      matchRoot: true,
      matchTail: true,
      match: '1'.padEnd(path.length, '0'),
      score: DEFAULT_SCORE,
    }
  }

  pattern = (root ? '' : '^') + pattern + (tail ? '' : '$')
  const match = p.match(new RegExp(pattern))

  if (match) {
    const matchStr = match[0]
    const root = match.index!
    const tail = match.index! + matchStr.length

    const mask = getMatchIndexMask(matchStr, chars, root, path.length)
    const score = computeScore(mask) + DEFAULT_SCORE // shift by "DEFAULT_SCORE"

    return {
      ok: true,
      matchRoot: root === 0,
      matchTail: tail === path.length,
      match: mask.map(i => i ? '1' : '0').join(''),
      score,
    }
  }

  return {
    ok: false,
    matchRoot: false,
    matchTail: false,
    match: '0'.repeat(path.length),
    score: 0,
  }
}
