import { strapi } from 'config'
import './css/Error.css'

export const ContentError = (props: {
  content: strapi.StrapiComponent
  message: string
}) => {
  const {
    content,
    message,
  } = props
  return (
    <div className='ProjectContent ContentError flex column'>
      <div>
        Error ({content.__component?.split('.').pop() ?? JSON.stringify(content)}):
      </div>
      {message.split('\n').filter(str => !!str).map((str, index) => {
        str = str.trim()
        return (
          <div key={index} className={`line-${index}`}>
            {str.startsWith('http')
              ? <a href={str}>{str}</a>
              : str
            }
          </div>
        )
      })}
    </div>
  )
}