import { Padding } from 'some-utils/geom'

export const orientations = ['landscape', 'portrait'] as const
export type Orientation = typeof orientations[number]

export const hudAlignments = ['center', 'inside'] as const
export type HudAlignment = typeof hudAlignments[number]

export const getOrientation = (width: number, height: number): Orientation => width >= height ? 'landscape' : 'portrait'

export type OrientationRecord<T> = Record<Orientation, T>
export const isOrientationRecord = <T>(value: any): value is OrientationRecord<T> => !!value && typeof value === 'object' && 'landscape' in value
export const solveOrientation = <T>(value: T | OrientationRecord<T>): OrientationRecord<T> => {
  return isOrientationRecord(value)
    ? value
    : { landscape: value, portrait: value }
}

export interface Size { 
  width: number, 
  height: number,
} 

export interface ResponsiveBase {
  name: string
  max: number | Size
  columnCount: ColumnCount | OrientationRecord<ColumnCount>
  gridPadding: Padding | OrientationRecord<Padding>
  hudAlignment: HudAlignment
}

export type ColumnCount = 1 | 3 | 4 | 5

export interface ResponsiveRule {
  screenName: string
  tokens: string[]
  min: Size
  max: Size
  mobile: boolean
  orientation: Orientation
  columnsCount: ColumnCount
  gridPadding: Padding
  hudAlignment: HudAlignment
}
