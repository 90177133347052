import { handleFrame, handleKeyboard, handlePointer } from 'some-utils/dom'
import { useObservable, useRefComplexEffects } from 'some-utils/npm/react'
import { ObservableObject } from 'some-utils/observables'
import './Snackbar.css'

export type SnackbarState = {
  message: string
  duration: number
  timeIn: number
  timeOut: number
}

const snackbar = new ObservableObject<SnackbarState | null>(null)

export const openSnackbar = (message: string, duration: number) => {
  const timeIn = Date.now() / 1e3
  snackbar.setValue({
    message,
    duration,
    timeIn,
    timeOut: timeIn + duration
  })
}

export const closeSnackbar = () => {
  if (snackbar.value) {
    const now = Date.now() / 1e3
    snackbar.updateValue({ timeOut: now })
  }
}

const Inner = ({ state }: { state: SnackbarState }) => {

  const { message } = state
  
  const ref = useRefComplexEffects<HTMLDivElement>(function*(div) {

    yield handleFrame(() => {
      if (snackbar.value) {
        const now = Date.now() / 1e3
        const { timeOut } = snackbar.value
        div.classList.toggle('out', now > timeOut)
        if (now > timeOut + 1)
          snackbar.setValue(null)
      }
    })

    yield handlePointer(document.documentElement, {
      onTap: () => closeSnackbar(),
    })

    yield handleKeyboard({
      onDown: [
        ['Escape', closeSnackbar],
      ]
    })

  }, [])

  return (
    <div ref={ref} className='Snackbar out'>
      {message}
    </div>
  )
}

export const Snackbar = () => {
  const state = useObservable(snackbar)
  return state && <Inner state={state}/>
}
