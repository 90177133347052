import { useEffects } from 'some-utils/npm/react'
import { Message } from 'some-utils/message'
import './AutoScrollBar.css'

export const AutoScrollBar = () => {
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    yield Message.on('HOME_AUTO_SCROLL', 'ACTIVE_WAIT', ({ props }) => {
      const value = props.value as number
      div.classList.toggle('visible', value > 0)
      if (value > 0) {
        div.style.transform = `scaleX(${value.toFixed(3)})`
      }
    })
  }, [])
  return (
    <div ref={ref} className='AutoScrollBar' />
  )
}
