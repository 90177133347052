import { ReactNode, RefObject, useMemo, useRef } from 'react'

import { clamp01 } from 'some-utils/math'
import { useEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'

import { ScrollbarPublicState } from './Scrollbar'

import './LargeScroller.css'

function createState() {
  const state = {
    scrollObs: new ObservableNumber(0, { min: 0, max: Infinity }),
    scrollProgressObs: new ObservableNumber(0, { min: 0, max: 1 }),
    setProgress: (delta: number) => state,
  }
  return state
}

export type LargeScrollerPublicState = ReturnType<typeof createState>

export function LargeScroller({
  stateRef,
  children,
}: Partial<{
  stateRef: RefObject<LargeScrollerPublicState>
  children: ReactNode
}>) {
  const state = useMemo(createState, [])

  const { ref } = useEffects<HTMLDivElement>(function (div) {
    const shadow = div.querySelector('.Shadow') as HTMLElement
    const scrollableContent = div.querySelector('.ScrollableContent') as HTMLElement
    if (stateRef) {
      // @ts-ignore
      stateRef.current = state
    }
    function update() {
      const max = scrollableContent.scrollHeight - scrollableContent.clientHeight
      state.scrollObs.setMax(max)
      state.scrollObs.value = scrollableContent.scrollTop
      state.scrollProgressObs.value = clamp01(state.scrollObs.value / max)
      shadow.style.opacity = (.2 * clamp01(state.scrollObs.value / 100)).toFixed(2)
    }
    state.setProgress = (delta: number) => {
      const h = scrollableContent.scrollHeight - scrollableContent.clientHeight
      scrollableContent.scrollTop = h * delta
      update()
      return state
    }
    scrollableContent.onscroll = () => {
      update()
    }
    update()
  }, [])

  return (
    <div ref={ref} className='LargeScroller'>
      <div className='Shadow' style={{ visibility: 'hidden' }} />
      <div className='WhiteGradient' style={{ visibility: 'hidden' }} />
      <div className='ScrollableContent'>
        {children}
      </div>
    </div>
  )
}

/**
 * Bind the scrollbar and the scroller together.
 */
export function useBindScrollStates() {
  const scrollbarStateRef = useRef<ScrollbarPublicState>(null)
  const scrollerStateRef = useRef<LargeScrollerPublicState>(null)

  useEffects<HTMLDivElement>(function* () {
    const scrollbar = scrollbarStateRef.current!
    const scroller = scrollerStateRef.current!

    yield scrollbar.onRequestProgress(t => {
      scroller.setProgress(t)
    })
    yield scroller.scrollProgressObs.onChange(progress => {
      scrollbar.setProgress(progress)
    })
  }, [])

  return {
    refs: {
      scrollbar: scrollbarStateRef,
      scroller: scrollerStateRef,
    },
  }
}

