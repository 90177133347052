import * as THREE from 'three'
import { createTexture, serializeTextureArgs, TextureArgs } from './create'

let ownerCounter = 0
const ownerMap = new Map<any, number>()
const createOwnerId = (owner: any) => {
  const id = ownerCounter++
  ownerMap.set(owner, id)
  return id
}
const getOwnerId = (owner: any) => {
  return ownerMap.get(owner) ?? createOwnerId(owner)
}

type TextureBundle = {
  owners: Set<number>
  texture: THREE.Texture | THREE.VideoTexture
}

const bundles = new Map<string, TextureBundle>()

const createBundle = (url: string, key: string, textureArgs: TextureArgs) => {
  const bundle = {
    owners: new Set<number>(),
    texture: createTexture(url, textureArgs),
  }
  bundles.set(key, bundle)
  return bundle
}

export const requireTexture = (owner: any, url: string, textureArgs: TextureArgs = {}) => {
  const key = `${url},${serializeTextureArgs(textureArgs)}`
  const bundle = bundles.get(key) ?? createBundle(url, key, textureArgs)
  const id = getOwnerId(owner)
  bundle.owners.add(id)
  return bundle.texture
}

export const releaseTexture = (owner: any, texture: THREE.Texture | null) => {
  if (texture) {
    for (const [key, bundle] of bundles) {
      if (bundle.texture === texture) {
        const id = getOwnerId(owner)
        bundle.owners.delete(id)
        if (bundle.owners.size === 0) {
          bundle.texture.image = null
          bundle.texture.dispose()
          bundles.delete(key)
          // console.log(`texture ${key} has been released`)
        }
      }
    }
  }
}

export const getTextureInfo = () => {
  const ownerCount = ownerMap.size
  const textureCount = bundles.size
  const details = [...bundles.entries()].map(([key, bundle]) => `(${bundle.owners.size}) ${key}`).join('\n')
  return { ownerCount, textureCount, details }
}

Object.assign(window, {
  getTextureInfo,
  requireTexture,
  releaseTexture,
})
