import { QUALITY_DEGRADATION_MAX } from 'AppContext'
import { development } from 'config'
import { inverseLerp, lerp } from 'some-utils/math'

export const fpsThresholds = (() => {
  const createArray = (mapIndex: (index: number) => number) => Array
    .from({ length: QUALITY_DEGRADATION_MAX + 1 })
    .map((_, index) => Math.round(mapIndex(index)))

  const high = createArray(
    index => lerp(40, 18, inverseLerp(1, QUALITY_DEGRADATION_MAX, index)))

  const low = createArray(
    index => lerp(30, 15, inverseLerp(0, QUALITY_DEGRADATION_MAX - 1, index)))

  high[0] = Infinity
  low[QUALITY_DEGRADATION_MAX] = 0

  const toString = (): string => {
    const format = (n: number): string =>
      n === Infinity || n === 0
        ? '  '
        : n.toFixed()
    const thresholds = Array
      .from({ length: QUALITY_DEGRADATION_MAX + 1 })
      .map((_, index) => {
        const max = format(high[index])
        const min = format(low[index])
        return `  q${index} { ${max} : ${min} }`
      })
      .join('\n')
    return `"quality degradation" FPS thresholds\n${thresholds}`
  }

  return { high, low, toString }
})()

if (development) {
  console.log(fpsThresholds.toString())
}
