export const locales = [
  'fr',
  'en',
]

/**
 * @obsolete
 * @deprecated Use `useLocale` instead
 */
export let currentLocale = locales[0]

// TODO:
// enable localStorage
