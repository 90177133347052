import * as THREE from 'three'
import copy from 'copy-to-clipboard'

const matrix1 = new THREE.Matrix4()
const matrix2 = new THREE.Matrix4()
const euler = new THREE.Euler()

/**
 * Small wrapper to handle orbit-like rotation.
 * How does it work ? 
 * By cancelling the matrix auto update. Beware!
 */
export class Rotator {

  // tweenable props
  phi = 0
  theta = 0
  panX = 0
  panY = 0
  zScalar = 1

  #target!: THREE.Object3D
  get target() { return this.#target }

  constructor(target: THREE.Object3D) {
    this.setTarget(target)
    this.update()
  }
  
  setTarget(value: THREE.Object3D) {
    this.#target = value
    this.#target.matrixAutoUpdate = false
  }
  
  update() {
    const { DEG2RAD } = THREE.MathUtils
    const { phi, theta, panX, panY, zScalar } = this

    euler.set(-theta * DEG2RAD, phi * DEG2RAD, 0, 'YZX')
    matrix1.makeRotationFromEuler(euler)

    const { x, y, z } = this.#target.position
    matrix2.makeTranslation(-panX + x, -panY + y, z * zScalar)
    this.#target.matrix.multiplyMatrices(matrix1, matrix2)
    this.#target.matrixWorldNeedsUpdate = true
  }
  
  toArray() {
    const { phi, theta, panX, panY } = this
    return [phi, theta, panX, panY]
  }

  fromArray([phi = 0, theta = 0, panX = 0, panY = 0]: number[]) {
    this.phi = phi 
    this.theta = theta 
    this.panX = panX 
    this.panY = panY
  }

  toJsonString(copyToClipboard = true) {
    const { phi, theta, panX, panY } = this
    const str = `[${phi.toFixed(1)}, ${theta.toFixed(1)}, ${panX.toFixed(2)}, ${panY.toFixed(2)}]`
    if (copyToClipboard) {
      copy(str)
    }
    return str
  }
}