import ReactPlayer from 'react-player'

import { safeClassName, useEffects } from 'some-utils/npm/react'

import { useAppContext } from 'AppContext'
import { reels } from 'data'
import { useResponsive } from 'state/layout'
import { ovvoLogoOpening } from 'state/ui'
import { Spacing } from 'view/components'
import { AspectContainer } from 'view/components/AspectContainer'
import { MobileReel } from './MobileReel'
import { useReelState } from './useReelState'

function WideReel() {
  const { gridPadding } = useResponsive()
  const state = useReelState()

  return (
    <div
      className='absolute-fill'
      style={{ padding: gridPadding.toCSS() }}
    >
      <div className='Menu flex row center' style={{ gap: '2em', pointerEvents: state.videoIsReady ? 'unset' : 'none' }}>
        {reels.map((reel, index) => (
          <div key={index} className='Reel ui uppercase'>
            <span
              className={`button ui ${state.reelIndex === index ? 'active' : 'ui-dim'}`}
              onClick={() => {
                state.set({
                  reelIndex: index,
                  videoIsReady: false,
                })
              }}
            >
              {reel.title()}
            </span>
          </div>
        ))}
      </div>

      <Spacing size='2em' />

      <AspectContainer aspect={2.3864}>
        <div className='Wrapper'>
          <ReactPlayer
            controls
            width='100%'
            height='100%'
            url={reels[state.reelIndex].videoUrl()}
            onReady={() => {
              state.set({ videoIsReady: true })
            }}
          />
          {reels.map((reel, index) => (
            <img
              key={index}
              className={safeClassName(
                'Cover absolute-fill',
                index === state.reelIndex && 'active',
                state.videoIsReady && 'player-is-ready',
              )}
              alt='video cover'
              src={reel.cover().url()}
            />
          ))}
        </div>
      </AspectContainer>
    </div>
  )
}

/**
 * Contrary to the <PermamentShowreel/> component this one is very regular, and 
 * mounts and unmounts normally.
 */
export function RegularReels() {
  const app = useAppContext()
  const { mobile } = useResponsive()

  useEffects(function* () {
    // Update theme:
    app.themeObs.setValue('dark')

    // Deploying the ovvo logo
    const ovvoLogoOpeningChild = ovvoLogoOpening.createChild(false)
    yield ovvoLogoOpeningChild
    ovvoLogoOpeningChild.setValue(true)
  }, [])

  return (
    <div
      className='Showreel permanent absolute-fill'
    >
      {mobile
        ? <MobileReel />
        : <WideReel />
      }
    </div>
  )
}
