import { Rectangle } from 'some-utils/geom'
import { ObservableObject } from 'some-utils/observables'

export const measures = {
  viewport: new ObservableObject(new Rectangle(0, 0, window.innerWidth, window.innerHeight)),
  projectsSearch: {
    scroll: {
      bounds: new ObservableObject({ wrapper: new Rectangle(), item: new Rectangle() }),
      position: new ObservableObject({ pixel: { x: 0, y: 0 }, progress: { x: 0, y: 0 } }),
    },    
  },
}

// hm... this is the beginning of a duplicate of state/layout. But this is way much cleaner.
window.addEventListener('resize', () => {
  measures.viewport.updateValue({
    width: window.innerWidth,
    height: window.innerHeight,
  })
})
