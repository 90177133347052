import copyToClipboard from 'copy-to-clipboard'
import { handleTimer } from 'some-utils/dom'
import { safeClassName, useComplexEffects, useLocalStorageState } from 'some-utils/npm/react'
import * as types from '../types'
import { useForceUpdate } from '../utils'
import { Property } from './Property'
import './Section.css'

const Properties = ({
  section,
}: {
  section: types.Section
}) => {

  // Interval updates design.
  // For sake of simplicity.
  const forceUpdate = useForceUpdate()
  useComplexEffects(function* () {
    yield handleTimer({
      interval: [200, forceUpdate],
    })
  }, [])

  const result = [...section.properties.values()]
    .filter(property => property.hidden !== true)
    .sort((A, B) => (B.priority ?? 0) - (A.priority ?? 0))
    .map(property => (
      <Property
        key={property.key}
        property={property}
        onChange={value => {
          property.setLiveInspectorValue(value)
          section.onUserChange()
        }}
      />
    ))

  return (
    <>
      {result}
    </>
  )
}

const JsonLayer = ({
  section,
  requestClose,
}: {
  section: types.Section
  requestClose: () => void
}) => {

  const jsonMaxDecimals = 2
  const json = JSON.stringify(section.bundle.getProperties(), null, '  ')
    .replaceAll(/\d+\.\d+/g, (m) => {
      const [i, d] = m.split('.')
      return `${i}.${d.slice(0, jsonMaxDecimals)}`
    })

  return (
    <div className='JsonLayer absolute-fill flex column gutter-8'>
      <div className='JsonContainer expand'>
        {json}
      </div>
      <button
        className='border'
        onClick={() => {
          copyToClipboard(json)
          requestClose()
        }}>
        Copy & close
      </button>
    </div>

  )
}

export const Section = ({
  section,
}: {
  section: types.Section
}) => {

  const [state, setState] = useLocalStorageState(section.params.name!, { collapsed: false, showJson: false })
  const updateState = (partialState: Partial<typeof state>) => setState({ ...state, ...partialState })
  const { collapsed, showJson } = state

  return (
    <div className={safeClassName('Section flex column', { collapsed })}>

      <h2 className='button' onClick={() => updateState({ collapsed: !collapsed })}>
        {section.params.name}
        {collapsed && ' (...)'}
      </h2>

      {collapsed === false && (
        <>
          <button className='JsonButton border' onClick={() => updateState({ showJson: !showJson })}>
            {showJson ? 'Hide JSON' : 'Show JSON'}
          </button>
          <div className='flex column' style={{ minHeight: showJson ? '100px' : 'unset' }}>
            <Properties section={section} />
            {showJson && (
              <JsonLayer section={section} requestClose={() => updateState({ showJson: false })} />
            )}
          </div>
        </>
      )}

    </div>
  )
}
