import * as THREE from 'three'
import { useEffect, useRef } from 'react'
import { time } from 'some-utils/npm/@react-three'
import { useComplexEffects } from 'some-utils/npm/react'
import { handlePointer } from 'some-utils/dom'
import { Rotator } from 'utils/three/Rotator'
import { useLiveInspector } from 'live-inspector'

enum PointerButton {
  LEFT = 0,
  RIGHT = 2
}
export const useRotator = (camera: THREE.PerspectiveCamera) => {

  const fromInspector = useRef({
    orbit: false,
  })

  fromInspector.current = useLiveInspector(
    { name: 'Camera' }, fromInspector.current)

  useEffect(() => {
    const updateHome = () => {
      const { orbit } = fromInspector.current
      const app = document.querySelector('.App') as HTMLElement
      app.style.pointerEvents = orbit ? 'none' : ''
      app.style.userSelect = orbit ? 'none' : ''
    }
    setTimeout(updateHome, 0)
  })

  useComplexEffects(function* () {

    const rotator = new Rotator(camera)

    const app = document.querySelector('.App') as HTMLElement
    yield handlePointer(app, {
      passive: false,
      onContextMenu: event => {
        event.preventDefault()
      },
      onDrag: info => {
        const { orbit } = fromInspector.current
        if (orbit) {
          if (info.downEvent.button === PointerButton.LEFT) {
            rotator.phi += -info.delta.x * .5
            rotator.theta += info.delta.y * .5
          }
          else {
            rotator.panX += info.delta.x * .1
            rotator.panY += -info.delta.y * .1
          }
        }
      },
    })

    const onWheel = (event: WheelEvent) => {
      const { orbit } = fromInspector.current
      if (orbit) {
        event.stopPropagation()
        rotator.zScalar *= 1 + event.deltaY / 1000
      }
    }
    window.addEventListener('wheel', onWheel, { capture: true, passive: false })
    yield () => {
      window.removeEventListener('wheel', onWheel, { capture: true })
    }

    yield time.onChange(() => {
      rotator.update()
    })

  }, [])
}
