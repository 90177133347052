import * as THREE from 'three'
import { defaultMetaballSettings, MetaballSettings } from 'scroll'

const color1 = new THREE.Color()
const color2 = new THREE.Color()
const color3 = new THREE.Color()
export const lerpProperty = (value1: any, value2: any, t: number, key: string, { clamp = true } = {}) => {
  if (clamp) {
    t = t < 0 ? 0 : t > 1 ? 1 : t
  }
  if (typeof value1 === 'string') {
    value1 = color1.set(value1)
  }
  if (typeof value2 === 'string') {
    value2 = color2.set(value2)
  }
  const type = typeof value1
  if (typeof value2 !== type) {
    throw new Error(`Interpolate error (k: "${key}"). Type are differents: ${value1}(${type}), ${value2}(${typeof value2})`)
  }
  if (type === 'number') {
    return value1 + (value2 - value1) * t
  }
  if (type === 'boolean') {
    return t < .5 ? value1 : value2
  }
  else if (type === 'object') {
    if (value1 instanceof THREE.Color) {
      return `#${color3.lerpColors(value1, value2, t).getHexString()}`
    }
  }
  throw new Error(`Interpolate error (k: "${key}"). Values: ${value1} & ${value2}`)
}

export enum LerpMetaballMode {
  UseDefaultValues,
  SkipUndefinedKeys,
}

export const lerpMetaballSettings = (source: any, destination: any, t: number, mode: LerpMetaballMode, {
  receiver,
  ignoredKeys,
}: {
  receiver?: MetaballSettings
  ignoredKeys?: string[]
}) => {

  const result = receiver ?? { ...defaultMetaballSettings } as any

  for (const key in result) {
    
    if (ignoredKeys?.includes(key)) {
      continue
    }

    // Skip 'private' key.
    if (key.startsWith('_')) {
      continue
    }

    // Skip 'non-overided' prop.
    if (mode === LerpMetaballMode.SkipUndefinedKeys) {
      if (key in destination === false) {
        continue
      }
    }

    const value1 = source[key]
    const value2 = destination[key]

    if (value1 !== undefined || value2 !== undefined) {
      const defaultValue = (defaultMetaballSettings as any)[key]
      result[key] = lerpProperty(value1 ?? defaultValue, value2 ?? defaultValue, t, key)
    }
  }

  return result as MetaballSettings
}

export const applyMetaballMaterialSettings = (
  material: THREE.MeshPhysicalMaterial, 
  group: THREE.Object3D, 
  settings: MetaballSettings,
) => {
  material.color.set(settings.color)
  material.emissive.set(settings.emissive)
  material.sheenColor.set(settings.sheenColor)
  
  material.emissiveIntensity = settings.emissiveIntensity
  material.metalness = settings.metalness
  material.roughness = settings.roughness
  material.flatShading = settings.flatShading
  material.clearcoat = settings.clearcoat
  material.clearcoatRoughness = settings.clearcoatRoughness
  material.envMapIntensity = settings.envMapIntensity
  material.reflectivity = settings.reflectivity
  material.sheen = settings.sheen
  material.sheenRoughness = settings.sheenRoughness
  material.opacity = settings.opacity

  group.position.set(0, 0, settings.positionZ)
  // NOTE: Rotation is now handled directly via ball position (tween.js)
  // group.rotation.set(0, 0, radian(settings.rotationZ))
  group.scale.set(settings.globalScale, settings.globalScale, settings.globalScale * settings.scaleZ)
}
