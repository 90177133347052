import globalize from 'utils/__old__/globalize'
import { Observable } from 'some-utils/observables'
import { useEffects, useObservable } from 'some-utils/npm/react'
import { THREE_SCENE_HEIGHT } from 'config'
import { getCurrentRule, responsiveRules } from './responsive-rules'
import { onDataInitialized } from 'data'
import { computeBaseFontSize } from './computeBaseFontSize'

export let columnWidth = NaN

export const responsiveObs = new Observable(responsiveRules[0])

export const layoutObs = new Observable({
  size: { width: 0, height: 0 },
  fullSize: { width: 0, height: 0 },
  /** Size of the "three" viewport. Homothetic to `window`. */
  threeSize: { width: 0, height: 0 },
  threeRatio: 1,
  aspect: 1,
  pixelRatio: 1,
  responsive: responsiveObs.value,
  baseFontSize: 1,
  columnWidth,
})

globalize({ layoutObs, rule: responsiveObs })

export const compute = () => {
  const {
    innerWidth: width,
    innerHeight: height,
    devicePixelRatio: pixelRatio,
  } = window
  responsiveObs.setValue(getCurrentRule(width, height))

  if (responsiveObs.hasChanged) {
    for (const token of responsiveObs.valueOld.tokens) {
      document.documentElement.classList.remove(token)
    }
    for (const token of responsiveObs.value.tokens) {
      document.documentElement.classList.add(token)
    }
    document.documentElement.dataset.columnsCount = responsiveObs.value.columnsCount.toString()
    const { columnsCount } = responsiveObs.value
    for (const x of [1, 3, 4, 5]) {
      document.documentElement.classList.toggle(`column-${x}`, x === columnsCount)
    }
    console.log(`${responsiveObs.value.screenName} ${width}x${height} ${responsiveObs.value.columnsCount} columns`)
  }

  columnWidth = (width - responsiveObs.value.gridPadding.left - responsiveObs.value.gridPadding.right) / responsiveObs.value.columnsCount

  const baseFontSize = computeBaseFontSize(width)

  document.documentElement.style.setProperty('--column-width', `${columnWidth.toFixed(1)}px`)

  const aspect = width / height

  layoutObs.setValue({
    aspect,
    size: { width, height },
    fullSize: { width: width * pixelRatio, height: height * pixelRatio },
    threeSize: { width: THREE_SCENE_HEIGHT * aspect, height: THREE_SCENE_HEIGHT },
    threeRatio: THREE_SCENE_HEIGHT / height,
    pixelRatio,
    responsive: responsiveObs.value,
    baseFontSize,
    columnWidth,
  })
}

compute()
onDataInitialized.add(compute)

window.addEventListener('resize', () => {
  compute()
})

/**
 * `currentRule` changes when some screen threshold are passed (kind of css media queries).
 * 
 * Happens once in a while.
 */
export const useResponsive = () => useObservable(responsiveObs)

/**
 * `layout` changes as soon as the window is resized.
 * 
 * May happen on every frame.
 */
export const useLayout = () => useObservable(layoutObs)

export const useGlobalStyle = () => {
  useEffects(function* () {
    const globalStyle = document.createElement('style')
    globalStyle.id = 'layout-style'
    
    // mount / unmount:
    document.head.append(globalStyle)
    yield () => globalStyle.remove()

    // update:
    yield responsiveObs.withValue(rule => {
      globalStyle.innerHTML = `
        .App {
          --grid-padding-top: ${rule.gridPadding.top}px;
          --grid-padding-right: ${rule.gridPadding.right}px;
          --grid-padding-bottom: ${rule.gridPadding.bottom}px;
          --grid-padding-left: ${rule.gridPadding.left}px;
        }
      `
    })
  }, [])
}
