import copyToClipboard from 'copy-to-clipboard'

import { safeClassName, useObservable } from 'some-utils/npm/react'
import { DivSwitch } from 'some-utils/npm/react/components'

import * as UI from 'state/ui'
import { useResponsive } from 'state/layout'
import { ovvoFooterOpeningObs, toggleOvvoFooter } from 'state/ovvo-footer'
import { LocationIcon, MailIcon } from 'assets/svg'
import { IconWrapper, Spacing } from 'view/components'
import { Circle } from './svg'
import { DASH_SPACING } from '../HUD'
import { openSnackbar } from 'view/snackbar'
import { companyInfos, globalData } from 'data'
import { LocaleButton } from '../../components/LocaleButton'

const BottomLeftContent = () => {
  const mailClick = () => {
    const companyInfo = companyInfos.find(v => v.attributes.isMainCompany) ?? companyInfos[0]
    if (companyInfo) {
      copyToClipboard(companyInfo.attributes.companyEmails)
      openSnackbar(globalData.attributes.CopyToClipboard, 2)
    } else {
      console.error('No email available!')
    }
  }
  return (
    <div className='flex row center' style={{ transform: 'translateX(8px)' }}>
      <LocaleButton locale='fr' />
      <LocaleButton locale='en' />
      <IconWrapper onClick={mailClick}>
        <MailIcon />
      </IconWrapper>
      <IconWrapper onClick={toggleOvvoFooter} className='ovvo-footer-button'>
        <LocationIcon />
      </IconWrapper>
      <Spacing size={DASH_SPACING} />
      <div className='Dash' />
    </div>
  )
}

export const BottomLeft = () => {
  const { left, bottom } = useResponsive().gridPadding
  const darkTheme = useObservable(ovvoFooterOpeningObs) && 'dark-theme'
  return (
    <div
      className={safeClassName('BottomMenu left', darkTheme)}
      style={{
        left: `${left}px`,
        bottom: `${bottom / 2}px`,
        transform: `translate(-16px, 50%)`,
        pointerEvents: 'none',
      }}
      onPointerEnter={() => UI.hudOpeningHoverObs.setValueWithDelay(true, 0)}
      onPointerLeave={() => UI.hudOpeningHoverObs.setValueWithDelay(false, 2)}
    >

      <DivSwitch
        style={{ pointerEvents: 'none' }}
        index={useObservable(UI.hudOpening) ? 0 : 1}
        items={[
          BottomLeftContent,
          Circle,
        ]}
      />
    </div>
  )
}
