import { ReactMarkdown, ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import { safeClassName } from 'some-utils/npm/react'
import './Markdown.css'
import { useEffect, useRef } from 'react'

export const markdownReplace = (str: string) => {
  return (str
    .replace(/<u>(.*?)<\/u>/, (m, $1) => {
      return `*${$1}*`
    })
  )
}

export const Markdown = ({
  className,
  content = '# Some title\n Some content.',
  ...props
}: Partial<{
  content: string
}> & Partial<ReactMarkdownOptions>) => {

  // Use target "_blank":
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const div = ref.current!
    for (const a of div.querySelectorAll('a')) {
      a.target = '_blank'
    }
  })

  return (
    <div ref={ref}>
      <ReactMarkdown {...props} className={safeClassName('Markdown', className)}>
        {content ? markdownReplace(content) : ''}
      </ReactMarkdown>
    </div>
  )
}
