import { BufferAttribute, Mesh, PlaneGeometry } from 'three'
import { useEffects } from 'some-utils/npm/react'
import { lerp } from 'some-utils/math'
import { ObservableNumber } from 'some-utils/observables'
import { layoutObs } from 'state/layout'
import { scrollObs } from '../scroll'
import { material } from './material'
import { useMemo } from 'react'
import { SCROLL_SPEED, UV_V0 } from './config'

export const Part3 = ({
  alphaObs,
}: Partial<{
  alphaObs: ObservableNumber
}>) => {

  const geometry = useMemo(() => {
    const geometry = new PlaneGeometry()
    const uv = geometry.getAttribute('uv') as BufferAttribute
    const uvArray = uv.array as Float32Array
    for (let i = 0; i < uv.count; i++) {
      const y = uvArray[i * 2 + 1]
      uvArray[i * 2 + 1] = lerp(0, UV_V0, y)
    }
    return geometry
  }, [])

  const { ref } = useEffects<Mesh>(function* (mesh) {
    yield alphaObs?.onChange(alpha => {
      mesh.visible = alpha > 0
    })

    yield layoutObs.withValue(layout => {
      const width = Math.max(layout.threeSize.width, 14)
      const ratio = 1400 / 1000
      mesh.scale.set(width, width / ratio, 1)
    })

    yield scrollObs.withStepValue(.5, scroll => {
      const { size, threeSize } = layoutObs.value
      mesh.position.y = (scroll / size.height - 4.2 / SCROLL_SPEED) * threeSize.height * SCROLL_SPEED
    })
  }, [])

  return (
    <mesh
      ref={ref}
      geometry={geometry}
      material={material}
    />
  )
}
