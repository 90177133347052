import { MeshBasicMaterial, VideoTexture } from 'three'
import { getTexture } from 'view/gl/utils/getTexture'
import maskPng from './PageStudio_Mask.png'
import './material.css'

const video = document.createElement('video')
video.id = 'StudioPage-video'
video.muted = true
video.autoplay = true
video.loop = true
video.crossOrigin = '*'
video.playsInline = true
video.oncanplay = () => {
  video.play()
}

Object.assign(window, { video })

const videoTexture = new VideoTexture(video)

export const playVideo = (url: string) => {
  // document.body.append(video)
  video.src = url
  const destroy = () => {
    video.pause()
  }
  return { destroy }
}

export const material = new MeshBasicMaterial({
  transparent: true,
  // color: '#24222c',
  map: videoTexture,
  alphaMap: getTexture(maskPng),
})
