import { LayoutColumnValue, strapi } from 'config'
import { CSSProperties } from 'react'
import { useLayout, useResponsive } from 'state/layout'

export const useLayoutCase = (layoutCase?: strapi.ColumnCase | null, layoutCaseFallback?: strapi.ColumnCase): strapi.BlockLayout => {
  const { columnsCount } = useResponsive()
  const safeLayoutCase = { ...strapi.defaultColumnCase, ...layoutCaseFallback, ...layoutCase }
  return safeLayoutCase[columnsCount]
}

const resolveParams = (
  columnsCount: number,
  params?: strapi.ColumnCase | strapi.BlockLayoutTuple,
): strapi.BlockLayoutTuple => {
  if (Array.isArray(params)) {
    return params
  } else {
    const layoutCase = { ...strapi.defaultColumnCase, ...params }
    const { columnStart, columnWidth } = layoutCase[columnsCount as LayoutColumnValue]
    return [columnStart, columnWidth]
  }
}

const useLeftRight = (
  params?: strapi.ColumnCase | strapi.BlockLayoutTuple,
): [left: string, right: string] => {
  const layout = useLayout()
  const { columnsCount, gridPadding } = layout.responsive
  const [columnStart, columnWidth] = resolveParams(layout.responsive.columnsCount, params)
  if (typeof columnStart === 'number') {
    return [
      `${gridPadding.left + layout.columnWidth * columnStart}px`,
      `${gridPadding.right + layout.columnWidth * (columnsCount - columnStart - columnWidth)}px`,
    ]
  } else {
    return [
      `calc(${gridPadding.left}px + ${columnStart})`,
      `calc(${gridPadding.right + layout.columnWidth * (columnsCount - columnWidth)}px - ${columnStart})`
    ]
  }
}

export const useHorizontalPadding = (params?: strapi.ColumnCase | strapi.BlockLayoutTuple) => {
  const [paddingLeft, paddingRight] = useLeftRight(params)
  return {
    paddingLeft,
    paddingRight,
  }
}

export const useHorizontalMargin = (params?: strapi.ColumnCase | strapi.BlockLayoutTuple) => {
  const [marginLeft, marginRight] = useLeftRight(params)
  return {
    marginLeft,
    marginRight,
    width: `calc(100% - (${marginLeft} + ${marginRight}))`,
  } as CSSProperties
}
