import { server, strapi } from 'config'
import React from 'react'
import { safeClassName, useObservable, usePromise } from 'some-utils/npm/react'
import { location, setSearch } from 'some-utils/router'
import { useResponsive } from 'state/layout'
import { LightboxData, getLightboxData, lightboxNext, lightboxPrevious, setLightbox } from 'state/lightbox/lightbox'
import { CursorMoveOverDiv, OvvoLogo2023, Spacing } from 'view/components'
import { cursorClassName } from 'view/cursor'
import { MediaCaption } from 'view/project/contents/MediaCaption'
import { ContentCenter } from './ContentCenter'
import { ReactComponent as LightboxBackSvg } from './Lightbox-Back.svg'
import { ReactComponent as LightboxNextComboSvg } from './Lightbox-NextCombo.svg'
import './Lightbox.css'
import { MediaWithCache } from './MediaWithCache'

const LighboxBackButton: React.FC = () => {
  const { right, top } = useResponsive().gridPadding
  return (
    <CursorMoveOverDiv
      parallax={7}
      cursor='None'
      className='LighboxBackButton'
      onClick={() => setSearch('')}
      style={{
        right: `${right}px`,
        top: `${top / 2}px`,
        transform: `translate(50%, -50%)`,
      }}
    >
      <LightboxBackSvg />
    </CursorMoveOverDiv>
  )
}

const NavButton = ({
  onClick,
  className = '',
}: Partial<{
  className: string
  onClick: () => void
}>) => {
  return (
    <CursorMoveOverDiv
      cursor='None'
      className={`NavButton ${className}`}
      onClick={onClick}
      onStart={div => {
        const hand = div.querySelector('#hand') as HTMLDivElement
        hand.style.setProperty('display', 'none')
      }}
      onEnter={div => {
        const hand = div.querySelector('#hand') as HTMLDivElement
        hand.style.removeProperty('display')
      }}
      onLeave={div => {
        const hand = div.querySelector('#hand') as HTMLDivElement
        hand.style.setProperty('display', 'none')
      }}
    >
      <LightboxNextComboSvg />
    </CursorMoveOverDiv>

  )
}

const fetchMediaData = (mediaIds: number[]) => Promise.all(
  mediaIds
    .map(id => `${server}/api/upload/files/${id}`)
    .map(url => window.fetch(url))
    .map(async response => (await response).json() as Promise<strapi.MediaAttributes>)
)

const useMediaData = (mediaIds: number[]) => {
  const data = usePromise(() => fetchMediaData(mediaIds), [mediaIds.join(',')])
  return data
}

const LightboxDiv: React.FC<{
  data: LightboxData
}> = ({
  data
}) => {
    const { top, right, left, bottom } = useResponsive().gridPadding
    const { currentMediaId, mediaIds } = data
    const singleMode = mediaIds.length === 0
    const medias = useMediaData(singleMode ? [currentMediaId] : mediaIds)
    const index = medias?.findIndex(media => media.id === currentMediaId) ?? -1
    const media = medias ? (singleMode ? medias[0] : medias[index]) : null
    return (
      <div className='Lightbox'>
        <div
          className={safeClassName('Background absolute-fill', cursorClassName('Close'))}
          onClick={() => setLightbox(null)}
        />

        <Spacing size={top} />

        <div className='flex row click-through' style={{ flex: '1' }}>

          <div
            className='Lateral flex row center click-through'
            style={{ flex: `0 0 ${left}px` }}
          >
            {singleMode === false && (
              <NavButton className='Previous' onClick={lightboxPrevious} />
            )}
          </div>

          <div
            className='MediaWrapper click-through'
            style={{ flex: '1' }}
          >
            {media === null ? (
              "loading"
            ) : (
              <>
                <ContentCenter
                  className='ImageWrapper click-through'
                  childAspect={media.width / media.height}
                  sizeMode='contain'
                  childKey={media.url}
                >
                  <MediaWithCache data={data} medias={medias!} />
                </ContentCenter>
              </>
            )}
          </div>

          <div
            className='Lateral flex row center click-through'
            style={{ flex: `0 0 ${right}px` }}
          >
            {singleMode === false && (
              <NavButton onClick={lightboxNext} />
            )}
          </div>

        </div>

        <footer
          style={{
            flex: `0 0 ${bottom}px`,
            padding: `32px ${right}px 0 ${left}px`,
          }}
        >
          <div className='Line' />
          <MediaCaption caption={media ? media.caption : '...'} />
        </footer>
        <OvvoLogo2023 style={{ position: 'fixed' }} />
        <LighboxBackButton />
      </div>
    )
  }

export const Lightbox: React.FC = () => {

  useObservable(location.search)
  const data = getLightboxData()

  if (data) {
    return (
      <LightboxDiv
        data={data}
      />
    )
  }

  return null
}