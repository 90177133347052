import { glsl_easings, glsl_iq_noise, glsl_sdf2d, glsl_utils } from 'some-utils/shaders/glsl'


export const vertexShader = /* glsl */ `
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`

export const fragmentShader = /* glsl */ `
  ${glsl_iq_noise}
  ${glsl_utils}
  ${glsl_sdf2d}
  ${glsl_easings}
  float drect(vec2 p) {
    float x = abs(p.x);
    float y = abs(p.y);
    return max(x, y);
  }
  varying vec2 vUv;
  uniform vec4 uColor;
  uniform float uVisibility;
  uniform sampler2D uMap;

  void organicMask() {
    vec2 p = vUv - 0.5;
    float dr = drect((vUv - 0.5) * 2.0);
    float dc = length((vUv - 0.5) * 2.0);
    float dx = mix(dc
      + 0.1 * iq_noise3(vec3(vUv * 10.0, 1.0))
      + 1.2 * iq_noise3(vec3(vUv * 5.0, 1.0)) * (1.0 - uVisibility)
      + 1.2 * iq_noise3(vec3(vUv * 7.0 + 2.0, 1.0)) * (1.0 - uVisibility)
      , dr, dr);
    if (dx > uVisibility) discard;
    gl_FragColor = texture2D(uMap, vUv);
  }

  void simpleFade() {
    gl_FragColor = texture2D(uMap, vUv);
    gl_FragColor.a *= uVisibility;
  }

  void fadeWithBorderFadeout() {
    if (uVisibility < 0.001) discard;
    vec2 p = (vUv - 0.5) * 2.0;
    float threshold = mix(0.5, 1.0, uVisibility);
    float d = sdRoundedBox(p, vec2(threshold), vec4(threshold)) / threshold;
    gl_FragColor = texture2D(uMap, vUv);
    float a = (1.0 - mix(d, 0.0, uVisibility)) * uVisibility;
    a = easeInout4(a);
    gl_FragColor.a *= a;
  }

  void main() {
    // organicMask();
    // simpleFade();
    fadeWithBorderFadeout();
  }
`
