import { SVGProps } from 'react'

export function NextArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18" height="18" viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M7.456 1.28L8.928 0L17.536 8.544L8.928 17.12L7.456 15.84L13.824 9.504H0V7.552H13.792L7.456 1.28Z" fill="currentColor" />
    </svg>
  )
}
