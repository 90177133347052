import { CSSProperties, useState } from 'react'

import { useEffects, useObservable } from 'some-utils/npm/react'
import { DivSwitch } from 'some-utils/npm/react/components'
import { getHash, getPathname, getSearch, location } from 'some-utils/router'

import { useDefaultTheme } from 'AppContext'
import { ReactComponent as Grid2x2Svg } from 'assets/svg/Grid2x2.svg'
import { ReactComponent as ThreeColumnsSvg } from 'assets/svg/ThreeColumns.svg'
import { allProjects, api, getTags, globalData } from 'data'
import { layoutObs, useResponsive } from 'state/layout'
import { navigate, routeObs, staticRoutes } from 'state/navigation'
import { useSetHudOpeningTrue } from 'state/ui'
import { DocumentTitle } from 'utils/document-title'
import { matchColumnCount } from 'utils/matchColumnCount'
import { Tags } from 'view/components'
import { SearchInput } from './components'
import { PixelView, SliderView } from './views'

import './SearchProject.css'

// const SliderOrPixelButton = () => {
//   const routeIsProjects = useObservable(routeObs) === staticRoutes.projects
//   const togglePixelRoute = routeIsProjects
//     ? () => navigate(staticRoutes.projectsPixel)
//     : () => navigate(staticRoutes.projects)
//   return (
//     <DivSwitch
//       className='SliderOrPixelButton button flex column center'
//       onClick={togglePixelRoute}
//       index={routeIsProjects ? 0 : 1}
//       items={[
//         ThreeColumnsSvg,
//         Grid2x2Svg,
//       ]}
//     />
//   )
// }

const HeaderComboBar = ({ projects }: { projects: api.Project[] }) => {
  const { gridPadding, columnsCount } = useResponsive()
  const top = matchColumnCount({
    '1': '248px',
    '3': '230px',
    '*': `${gridPadding.top + gridPadding.bottom / 2}px`,
  }, columnsCount)
  const pixel = useObservable(routeObs) === staticRoutes.searchProjectsPixel
  const togglePixelRoute = pixel
    ? () => navigate(staticRoutes.searchProjects)
    : () => navigate(staticRoutes.searchProjectsPixel)

  const [pixelColor, slideColor] = pixel
    ? ['var(--ui-link-hover)', 'var(--ui-color-dim)']
    : ['var(--ui-color-dim)', 'var(--ui-link-hover)']
  return (
    <div className='HeaderComboBar flex row align-center gutter-16 ui' style={{ top }}>
      <div
        className='Label ui'
        style={{ textAlign: 'right', color: pixelColor }}
        onClick={togglePixelRoute}
      >
        {'vue mosaïque'}
      </div>

      <Grid2x2Svg
        className='button'
        onClick={togglePixelRoute}
        opacity={pixel ? 1 : .8}
        fill={pixelColor} />

      <div className='ProjectCount'>
        {projects.length}
      </div>

      <ThreeColumnsSvg
        className='button'
        onClick={togglePixelRoute}
        opacity={pixel ? .8 : 1}
        fill={slideColor} />

      <div
        className='Label ui'
        style={{ textAlign: 'left', color: slideColor }}
        onClick={togglePixelRoute}
      >
        {'vue liste'}
      </div>
    </div>
  )
}

export const SearchProject = () => {
  useSetHudOpeningTrue() // force HUD opening
  useDefaultTheme()

  const {
    baseFontSize,
    responsive: { gridPadding, columnsCount },
  } = useObservable(layoutObs)
  const tagsStyle: CSSProperties = {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: matchColumnCount({
      '1': `240px`,
      '*': `${gridPadding.top}px`,
    }, columnsCount),
    paddingTop: matchColumnCount({
      '1': `70px`,
      '*': '0',
    }, columnsCount),
    // marginLeft: `${gridPadding.left}px`,
    // marginRight: `${gridPadding.right}px`,
    // marginBottom: matchColumnCount({
    //   '1': `120px`,
    //   '*': `${gridPadding.bottom / 2}px`,
    // }, columnsCount),
    // marginTop: matchColumnCount({
    //   '*': 'unset',
    //   '1': '60px',
    // }, columnsCount),
    // padding: matchColumnCount({
    //   '*': '0 96px',
    //   '1-3': '0',
    // }, columnsCount),
    // paddingBottom: '18px',
  }

  const getProjects = () => {
    const hashTags = getHash().split(',').filter(value => !!value)
    const search = getSearch()
    return [...allProjects.values()]
      .filter(project => project.isVisible())
      .filter(project => (
        project.containsTags(hashTags) && project.matchSearch(search)
      ))
  }

  const [state, setState] = useState(() => ({
    projects: getProjects(),
  }))

  useEffects(function* () {
    const update = () => {
      const routeIsProjects = getPathname().includes(staticRoutes.searchProjects)
      if (routeIsProjects) {
        setState({
          projects: getProjects(),
        })
      }
    }
    yield location.hash.onChange(update)
    yield location.search.onChange(update)
  }, [])

  const { projects } = state

  // NOTE: This is quite verbose, it decides whether or not to use the pixel view.
  const [pixelView, setPixelView] = useState(routeObs.value === staticRoutes.searchProjectsPixel)
  useEffects(function* () {
    yield routeObs.onChange(route => {
      switch (route) {
        case staticRoutes.searchProjects: {
          setPixelView(false)
          break
        }
        case staticRoutes.searchProjectsPixel: {
          setPixelView(true)
          break
        }
      }
    })
  }, [])

  return (
    <div className='SearchProject absolute-fill flex column dark-theme'>
      <DocumentTitle title={globalData.attributes.MenuNameProject} />
      <div className='Header flex column center' style={tagsStyle}>
        <Tags
          selectable
          className='center'
          tags={getTags()}
          style={{
            paddingBottom: '16px',
            transform: matchColumnCount({
              '1': 'translateY(-40%)',
              '*': 'translateY(-16px)',
            }, columnsCount),
            paddingTop: columnsCount === 3 ? '40px' : '0'
          }}
        />

        <HeaderComboBar
          projects={state.projects} />
      </div>

      <SearchInput />

      <DivSwitch
        index={pixelView ? 1 : 0}
        items={[
          SliderView,
          PixelView,
        ]}
        style={{ fontSize: `${baseFontSize}em` }}
        itemProps={{ projects }}
      />

    </div>
  )
}
