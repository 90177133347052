import React from 'react'
import { safeClassName, useRefComplexEffects } from 'some-utils/npm/react'
import { cursorClassName, CursorType } from 'view/cursor'
import { handleFrame, handlePointer } from 'some-utils/dom/handle-events'

/**
 * A little wrapper to make objects slightly move when rolling over (as if the 
 * object was magnetized).
 */
export const CursorMoveOverDiv: React.FC<{
  parallax?: number
  parallaxX?: number
  parallaxY?: number
  cursor: CursorType
  onStart?: (div: HTMLDivElement) => void
  onEnter?: (div: HTMLDivElement) => void
  onLeave?: (div: HTMLDivElement) => void
} & React.HTMLProps<HTMLDivElement>> = ({
  parallax = 16,
  parallaxX = parallax,
  parallaxY = parallax,
  cursor,
  onStart,
  onEnter,
  onLeave,
  className,
  children,
  style,
  ...props
}) => {
    const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {
      let tx = 0
      let ty = 0
      let over = false
      let rect = new DOMRect()
      onStart?.(div)
      yield handlePointer(div, {
        onEnter: () => {
          over = true
          rect = div.getBoundingClientRect()
          onEnter?.(div)
        },
        onLeave: () => {
          over = false
          onLeave?.(div)
        },
        onMoveOver: (event) => {
          const x = -.5 + (event.x - rect.x) / rect.width
          const y = -.5 + (event.y - rect.y) / rect.height
          tx = parallaxX * x
          ty = parallaxY * y
        },
      })
      yield handleFrame(() => {
        const previousTransform = style?.transform ?? ''
        div.style.transform = `translate(${tx.toFixed(1)}px, ${ty.toFixed(1)}px) ${previousTransform}`
        if (over === false) {
          tx += -tx / 5
          ty += -ty / 5
        }
      })
    }, [])

    return (
      <div
        ref={ref}
        style={style}
        className={safeClassName('MoveOverDiv', cursorClassName(cursor), className)}
        {...props}
      >
        {children}
      </div>
    )
  }
