import { Object3D } from 'three'

Object.assign(Object3D.prototype, {

  setPosition({ 
    x = this.position.x, 
    y = this.position.y, 
    z = this.position.z,
    scalar = 1,
  } = {}) {
    if (typeof arguments[0] === 'number') {
      [x, y = y, z = z] = arguments
    }
    if (Array.isArray(arguments[0])) {
      [x, y = y, z = z] = arguments[0]
    }
    this.position.set(x * scalar, y * scalar, z * scalar)
    return this
  },

  setRotation({
    useDegree = false,
    x = this.rotation.x * (useDegree ? 180 / Math.PI : 1), 
    y = this.rotation.y * (useDegree ? 180 / Math.PI : 1), 
    z = this.rotation.z * (useDegree ? 180 / Math.PI : 1),
    order = this.rotation.order,
    scalar = 1,
  }) {
    if (typeof arguments[0] === 'number') {
      [x, y = y, z = z, order = order] = arguments
    }
    if (Array.isArray(arguments[0])) {
      [x, y = y, z = z, order = order] = arguments[0]
    }
    scalar *= useDegree ? Math.PI / 180 : 1
    this.rotation.set(x * scalar, y * scalar, z * scalar, order)
    return this
  },

  setScale({ 
    x = this.scale.x, 
    y = this.scale.y, 
    z = this.scale.z,
    scalar = 1,
  } = {}) {
    if (typeof arguments[0] === 'number') {
      [x, y = y, z = z] = arguments
    }
    if (Array.isArray(arguments[0])) {
      [x, y = y, z = z] = arguments[0]
    }
    this.scale.set(x * scalar, y * scalar, z * scalar)
    return this
  },

  setUniformScale(x) {
    this.scale.set(x, x, x)
    return this
  },

  setParent(parent) {
    parent.add(this)
    return this
  },

  set({
    position,
    rotation,
    scale,
    uniformScale,
    parent,
  } = {}) {
    if (position) { 
      this.setPosition(position)
    }
    if (rotation) { 
      this.setRotation(rotation)
    }
    if (scale) { 
      this.setScale(scale)
    }
    if (uniformScale !== undefined) { 
      this.setUniformScale(uniformScale)
    }
    if (parent) {
      parent.add(this)
    }
    return this
  },
})
