import React from 'react'
import { useHorizontalPadding } from '../project/contents/utils/misc'

type Props = {
  columnStart?: number
  columnWidth?: number
} & React.HTMLAttributes<HTMLDivElement>

export const HorizontalPadding = ({
  columnStart = 0,
  columnWidth = 5,
  children,
  style,
  ...props
}: Props) => {
  const padding = useHorizontalPadding([columnStart, columnWidth])
  return (
    <div {...props}
      style={{ ...style, ...padding }}>
      {children}
    </div>
  )
}
