import * as svg from './svg'

import { safeClassName, useObservable } from 'some-utils/npm/react'

import { ovvoLogoOpening } from 'state/ui'
import { unifiedSearchState } from 'state/unified-search'

import './MobilePermanentSearch.css'

export function MobilePermanentSearch() {
  const logoOpening = useObservable(ovvoLogoOpening)
  return (
    <div
      className={safeClassName('MobilePermanentSearch', logoOpening && 'hidden')}
      onClick={() => {
        unifiedSearchState.visibleObs.setTrue()
      }}>
      <svg.Search />
    </div>
  )
}
