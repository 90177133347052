import * as THREE from 'three'
import { glsl_utils } from 'some-utils/shaders/glsl'

const vertexShader = /* glsl */ `
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
}
`

const fragmentShader = /* glsl */ `
  ${glsl_utils}
  uniform vec3 uColor;
  uniform float uOpacity;
  varying vec2 vUv;
  void main() {
    float d = length(vUv - 0.5) - 0.4;
    gl_FragColor.rgb = uColor;
    gl_FragColor.a = uOpacity * clamp01(1.0 - d * 10.0);
  }
`

export const dotMaterial = new THREE.ShaderMaterial({
  transparent: true,
  vertexShader,
  fragmentShader,
  uniforms: {
    uOpacity: { value: 0.25 },
    uColor: { value: new THREE.Color('white') },
  },
})

export const dashMaterial = new THREE.ShaderMaterial({
  transparent: true,
  vertexShader,
  fragmentShader: /* glsl */ `
    uniform vec3 uColor;
    uniform float uOpacity;
    void main() {
      gl_FragColor.rgb = uColor;
      gl_FragColor.a = uOpacity;
    }
  `,
  uniforms: {
    uOpacity: { value: 0.25 },
    uColor: { value: new THREE.Color('white') },
  },
})
