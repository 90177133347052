import { useMemo } from 'react'
import { useRefComplexEffects } from 'some-utils/npm/react'
import { handlePointer } from 'some-utils/dom'
import { deepClone } from 'some-utils/object'
import * as types from '../types'
import { NumberInput } from './NumberInput'
import { getNumberInputProps } from './utils'
import './Property.css'
import { CheckedSvg, SquareSvg } from './svg'
import { createColorXplr } from '@jniac/color-xplr'

type ValueProps = {
  property: types.Property
  onChange: (value: any) => void
}

type Value = (props: ValueProps) => JSX.Element

const Number: Value = ({ property, onChange }) => {
  return (
    <NumberInput
      {...getNumberInputProps(property)}
      onChange={value => onChange(value)}
    />
  )
}

const Boolean: Value = ({ property, onChange }) => {
  return (
    <div className='Checkbox'>
      <input
        type='checkbox'
        checked={property.value}
        onChange={event => {
          const value = (event.target as HTMLInputElement).checked
          onChange(value)
        }}
      />
      <div className='SvgWrapper absolute-fill flex row center'>
        {property.value ? <CheckedSvg /> : <SquareSvg />}
      </div>
    </div>
  )
}

const Color: Value = ({ property, onChange }) => {
  return (
    <div className='Color flex row center gutter-4'>
      <div className='ColorLabel'>
        {property.value}
      </div>
      <div className='Swatch'>
        <div style={{ backgroundColor: property.value }}>
          <input
            type="color"
            value={property.value}
            onClick={event => {
              event.preventDefault()
              createColorXplr({
                color: property.value,
                modal: { source: event.target as HTMLElement },
                onChange: ({ hex }) => {
                  onChange(hex)
                },
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

const Vector: Value = ({ property, onChange }) => {
  const clone = useMemo(() => deepClone(property.value), [property])
  const keys = Object.keys(property.value)
  return (
    <>
      {keys.map(key => (
        <div key={key} className='flex row center'>
          <div className='Key'>
            {key}
          </div>
          <NumberInput
            {...getNumberInputProps(property, key)}
            onChange={value => {
              clone[key] = value
              onChange(clone)
            }}
          />
        </div>
      ))}
    </>
  )
}


const NotImplemented: Value = ({ property }) => (
  <div>Not Impl. ({types.getValueType(property.value)})</div>
)

const Values: Record<types.ValueType, Value> = {
  String: NotImplemented,
  Color,
  Boolean,
  Vector,
  Number,
}

export const Property = ({
  property,
  onChange,
}: {
  property: types.Property
  onChange: (value: any) => void
}) => {

  const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {
    yield handlePointer(div, {
      onOver: () => div.classList.add('highlight'),
      onOut: () => div.classList.remove('highlight'),
    })
  }, [])

  const Value = Values[types.getValueType(property.value)]

  return (
    <div ref={ref} className='Property flex row gutter-4'>
      <div className='Label'>
        {property.key}
      </div>
      <div className='Value'>
        <Value property={property} onChange={onChange} />
      </div>
    </div>
  )
}
