import { useObservable } from 'some-utils/npm/react'

import { NewsProvider, useNews } from './context'
import { useNewsController } from './controller'
import { NewsFullPage } from './full-page'
import { NewsPreviews } from './previews'
import { SolidFadeIn } from './SolidFadeIn'

import './News.css'

function NewsPage() {
  useNewsController()
  const news = useNews()
  const currentNews = useObservable(news.observables.openedNews)
  return (
    <div className='News absolute-fill flex column' style={{ backgroundColor: 'white' }}>
      {currentNews ? (
        <NewsFullPage
          news={currentNews} />
      ) : (
        <NewsPreviews />
      )}
    </div>
  )
}

export function News() {
  return (
    <NewsProvider>
      <NewsPage />
      <SolidFadeIn />
    </NewsProvider>
  )
} 