import { useThree } from '@react-three/fiber'
import { useMemo } from 'react'
import {
  BufferGeometry,
  Camera,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Scene,
  ShaderMaterial,
  Vector2,
  WebGLRenderTarget,
  WebGLRenderer
} from 'three'

import { Animation } from 'some-utils/Animation'
import { deepLerp, digest } from 'some-utils/math'
import { useEffects } from 'some-utils/npm/react'
import { deepClone } from 'some-utils/object'

import { globalData } from 'data'
import { layoutObs, useLayout } from 'state/layout'
import { is404, routeObs, staticRoutes } from 'state/navigation'
import defautJson from './default.json'
import { fragmentShader, vertexShader } from './shaders'
import { defaultUniforms, getUniformDestination } from './uniforms'

const isVisible = (route: string) => {
  switch (route) {
    case staticRoutes.reels:
    case staticRoutes.searchProjects:
    case staticRoutes.searchProjectsPixel:
    case staticRoutes.studio: {
      return true
    }
    default: {
      return is404(route)
    }
  }
}

const planeGeometry = new PlaneGeometry(1, 1)

/**
 * Create the background using a render target. Therefore, the background can be 
 * used / rendered once.
 */
const createBackgroundRenderTarget = () => {
  const uniforms = deepClone(defaultUniforms)

  const rtMaterial = new ShaderMaterial({
    uniforms,
    vertexShader,
    fragmentShader,
  })
  const rtScene = new Scene()
  const rtMesh = new Mesh(planeGeometry, rtMaterial)
  rtScene.add(rtMesh)
  const rt = new WebGLRenderTarget(1000, 1000)

  const render = (renderer: WebGLRenderer, camera: Camera, sourceMesh: Mesh) => {
    const size = renderer.getDrawingBufferSize(new Vector2())
    size.multiplyScalar(.5) // lower definition since high precision is not required for that image.
    rt.setSize(Math.round(size.x), Math.round(size.y))

    rtMesh.position.copy(sourceMesh.position)
    rtMesh.scale.copy(sourceMesh.scale)
    rtMesh.rotation.copy(sourceMesh.rotation)

    renderer.setRenderTarget(rt)
    renderer.render(rtScene, camera)
    renderer.setRenderTarget(null)
  }

  return {
    uniforms,
    texture: rt.texture,
    render,
  }
}

export const WavyBackground = () => {

  const three = useThree()
  const { threeSize } = useLayout()

  const background = useMemo(createBackgroundRenderTarget, [])

  useEffects(() => {
    background.render(three.gl, three.camera, ref.current!)
  }, [])

  const { ref } = useEffects<Mesh<BufferGeometry, MeshBasicMaterial>>(function* (mesh) {

    background.uniforms.uSize.value.set(layoutObs.value.size.width, layoutObs.value.size.height, layoutObs.value.aspect, 0)
    mesh.scale.set(layoutObs.value.threeSize.width, layoutObs.value.threeSize.height, 1)
    mesh.material.map = background.texture
    background.render(three.gl, three.camera, mesh)

    const json = globalData.attributes.WavyBackgroundSettings
    const {
      duration: transitionDuration,
      ease: transitionEase,
    } = { ...defautJson.global.transition, ...json?.global?.transition }

    yield routeObs.withValue(route => {
      mesh.visible = isVisible(route)
      const from = deepClone(background.uniforms, { ignoredKeys: new Set(['uNoiseMap', 'uSize']) })
      const dest = { ...background.uniforms, ...getUniformDestination(route) }
      const duration = performance.now() < 3e3 ? 0 : transitionDuration
      Animation.duringWithTarget(mesh, { duration, immediate: true }, ({ progress }) => {
        const alpha = Animation.getMemoizedEase(transitionEase as any)(progress)
        deepLerp(from, dest, background.uniforms, alpha)
        background.render(three.gl, three.camera, mesh)
      })
    })

  }, [digest.any(threeSize)])

  return (
    <mesh
      ref={ref}
      geometry={planeGeometry}
    >
      <meshBasicMaterial />
    </mesh>
  )
}

