import * as theatre from '@theatre/core'
import anim from './anim.json'

// Demo is in /extras/ovvo-logo-2023-anim-theatrejs
const project = theatre.getProject('OVVO-LOGO-2023', { state: anim })
const sheet = project.sheet('OVVO')
const object = sheet.object('Studio', {
  'logoCompletion': theatre.types.number(1, { range: [0, 1] }),
  'studio': theatre.types.number(0, { range: [0, 1] }),
  'dot': theatre.types.number(0, { range: [0, 1] }),
  'liveart': theatre.types.number(0, { range: [0, 1] }),
})

const updateSvg = (object: Record<string, number>, svg: SVGSVGElement) => {
  {
    // Logo
    const value = object.logoCompletion
    {
      const element = svg.querySelector('#o1-m') as HTMLElement
      const x = Number.parseFloat(element.getAttributeNS(null, 'x') ?? '0')
      const width = Number.parseFloat(element.getAttributeNS(null, 'width') ?? '0')
      element.style.transform = `translateX(${value * (x + width)}px) scaleX(${1 - value})`
    }
    {
      const element = svg.querySelector('#v1-m') as HTMLElement
      element.style.transform = `translateY(${value * -14}px)`
    }
    {
      const element = svg.querySelector('#v2-m') as HTMLElement
      element.style.transform = `translate(${value * -7}px , ${value * 25}px)`
    }
    {
      const element = svg.querySelector('#o2-m') as HTMLElement
      element.setAttributeNS(null, 'width', `${40 * (1 - value)}`)
    }
  }
  {
    // Studio
    const value = object.studio
    const element = svg.querySelector('#studio') as HTMLElement
    element.style.opacity = value.toFixed(2)
  }
  {
    // Dot
    const value = object.dot
    const element = svg.querySelector('#dot') as HTMLElement
    element.style.opacity = value.toFixed(2)
  }
  {
    // Live Art
    const value = object.liveart
    const element = svg.querySelector('#live-art') as HTMLElement
    element.style.opacity = value.toFixed(2)
  }
}

export {
  object as theatreObject,
  sheet as theatreSheet,
  updateSvg,
}
