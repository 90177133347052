import { isObject } from 'some-utils/object'

/**
 * safeFallback() is for applying fallback on an "unsafe" source value. "unsafe"
 * means that the source may be null, or in a wrong format. Whatever the source
 * could be, the returned value will follow the fallback model.
 * @param unsafeSource
 * @param fallback
 * @returns
 */
export function safeFallback<T extends object>(
  unsafeSource: any,
  fallback: T
): T {
  const dump = (value: any, key: string | number, unsafeSource: any): any => {
    if (isObject(value)) {
      return safeFallback(unsafeSource?.[key], value)
    } else {
      const ok =
        isObject(unsafeSource) &&
        Object.hasOwn(unsafeSource, key) &&
        typeof unsafeSource[key] === typeof value
      return ok ? unsafeSource[key] : value
    }
  }
  if (Array.isArray(fallback)) {
    const result = [] as any[]
    for (const [index, value] of fallback.entries()) {
      result[index] = dump(value, index, unsafeSource)
    }
    return result as T
  } else {
    const result = {} as any
    for (const [key, value] of Object.entries(fallback)) {
      result[key] = dump(value, key, unsafeSource)
    }
    return result as T
  }
}
