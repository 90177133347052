import * as THREE from 'three'
import { deepClone, deepMapValues } from 'some-utils/object'

const isColorString = (str: string) => str in THREE.Color.NAMES || /#([0-9a-f]{3}|[0-9a-f]{6})$/i.test(str)

/**
 * Converts color string to THREE.Color instances.
 */
export const safeSettings = (source: any, debugInfo = '') => deepMapValues(deepClone(source), {

  string: (str, key, path) => {
    
    if (isColorString(str)) {
      return new THREE.Color(str)
    }
    
    if (/#[0-9a-f]+$/i.test(str)) {
      console.warn(`Invalid color format: "${str}" (${path}) ${debugInfo}`)
      return new THREE.Color('magenta')
    }

    if (str === '#fffff') {
      throw new Error(path)
    }

    return str
  },

})
