import { server } from 'config'
import { companyInfos, studiosPage } from 'data'
import { Markdown } from 'view/components'
import './CompanySectionJune23.css'
import { LayoutDiv } from './LayoutDiv'
import defaultDescriptionLayout from './defaultDescriptionLayout.json'

export const CompanySectionJune23 = ({
  info = companyInfos[0],
}) => {
  const imgUrl = server + info.attributes.StudioPageCompanyImage.data?.attributes.url
  return (
    <div className='CompanySectionJune23 flex column center'>
      <LayoutDiv className='flex column center' layout={[studiosPage.attributes.descriptionLayout, defaultDescriptionLayout]}>
        <img src={imgUrl} alt={`${info.attributes.companyName} logo`} />
        <Markdown
          className='Description'
          content={info.attributes.StudioPageCompanyDescription}
        />
      </LayoutDiv>
    </div>
  )
}