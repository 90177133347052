import { defaultMediaSettings, MediaSettings } from 'config'
import { layoutObs } from 'state/layout'
import { Vector3 } from 'three'

export const isImageUrl = (url: string) => /\.(jpe?g|png|webp)$/.test(url)
export const isVideoUrl = (url: string) => /\.(mp4|webm)$/.test(url)

export const ensureMediaSettings = (settings: any) => {
  return {
    ...defaultMediaSettings,
    ...settings,
  } as MediaSettings
}

export const computeScale = (width: number, height: number, settings: MediaSettings) => {
  const aspect = width / height
  const { resizeMode, scale } = settings
  const { aspect: threeAspect } = layoutObs.value
  const w = threeAspect / aspect
  const h = 1
  const ratio = resizeMode === 'cover'
    ? Math.max(w, h)
    : Math.min(h, w)
  return new Vector3(scale * ratio * aspect, scale * ratio, scale)
}

export const getImageSize = (image: HTMLImageElement) => new Promise<{ width: number, height: number }>(resolve => {
  const check = () => {
    if (image.complete) {
      const { naturalWidth: width, naturalHeight: height } = image
      resolve({ width, height })
      image.removeEventListener('load', check)
    }
  }
  image.addEventListener('load', check)
  check()
})

export const getVideoSize = (video: HTMLVideoElement) => new Promise<{ width: number, height: number }>(resolve => {
  const check = () => {
    if (video.readyState > 0) {
      const { videoWidth: width, videoHeight: height } = video
      resolve({ width, height })
      video.removeEventListener('loadeddata', check)
    }
  }
  video.addEventListener('loadeddata', check)
  check()
})
