import * as THREE from 'three'

// https://github.com/mrdoob/three.js/blob/master/src/math/Color.js#L160
const parseColor = (colorString: string, receiver = new THREE.Color()): [THREE.Color, number] => {

  let m = /^((?:rgb|hsl)a?)\(([^)]*)\)/.exec(colorString)

  if (m) {

    // rgb / hsl

    let color
    const name = m[1]
    const components = m[2]

    switch (name) {

      case 'rgb':
      case 'rgba':

        // rgb(255,0,0) rgba(255,0,0,0.5)
        color = /^\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(components)
        if (color) {
          receiver.r = Math.min(255, parseInt(color[1], 10)) / 255
          receiver.g = Math.min(255, parseInt(color[2], 10)) / 255
          receiver.b = Math.min(255, parseInt(color[3], 10)) / 255
          const alpha = color[4] ? parseFloat(color[4]) / 255 : 1
          return [receiver, alpha]
        }

        // rgb(100%,0%,0%) rgba(100%,0%,0%,0.5)
        color = /^\s*(\d+)%\s*,\s*(\d+)%\s*,\s*(\d+)%\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(components)
        if (color) {

          receiver.r = Math.min(100, parseInt(color[1], 10)) / 100
          receiver.g = Math.min(100, parseInt(color[2], 10)) / 100
          receiver.b = Math.min(100, parseInt(color[3], 10)) / 100
          const alpha = color[4] ? parseFloat(color[4]) / 255 : 1
          return [receiver, alpha]
        }

        break

      case 'hsl':
      case 'hsla':
        // #070a07 hsla(120,50%,50%,0.5)
        color = /^\s*(\d*\.?\d+)\s*,\s*(\d+)%\s*,\s*(\d+)%\s*(?:,\s*(\d*\.?\d+)\s*)?$/.exec(components)
        if (color) {
          const h = parseFloat(color[1]) / 360
          const s = parseInt(color[2], 10) / 100
          const l = parseInt(color[3], 10) / 100
          const alpha = color[4] ? parseFloat(color[4]) / 255 : 1
          return [receiver.setHSL(h, s, l), alpha]
        }
        break

    }

  }

  // hex color
  m = /^#([A-Fa-f\d]+)$/.exec(colorString)
  if (m) {
    const hex = m[1]
    const size = hex.length

    // #ff0
    if (size === 3) {
      receiver.r = parseInt(hex.charAt(0) + hex.charAt(0), 16) / 255
      receiver.g = parseInt(hex.charAt(1) + hex.charAt(1), 16) / 255
      receiver.b = parseInt(hex.charAt(2) + hex.charAt(2), 16) / 255
      return [receiver, 1]
    }

    // #ff08
    if (size === 4) {
      receiver.r = parseInt(hex.charAt(0) + hex.charAt(0), 16) / 255
      receiver.g = parseInt(hex.charAt(1) + hex.charAt(1), 16) / 255
      receiver.b = parseInt(hex.charAt(2) + hex.charAt(2), 16) / 255
      const alpha = parseInt(hex.charAt(3) + hex.charAt(3), 16) / 255
      return [receiver, alpha]
    }

    // #ff0000
    if (size === 6) {
      receiver.r = parseInt(hex.charAt(0) + hex.charAt(1), 16) / 255
      receiver.g = parseInt(hex.charAt(2) + hex.charAt(3), 16) / 255
      receiver.b = parseInt(hex.charAt(4) + hex.charAt(5), 16) / 255
      return [receiver, 1]
    }

    // #ff000088
    if (size === 8) {
      receiver.r = parseInt(hex.charAt(0) + hex.charAt(1), 16) / 255
      receiver.g = parseInt(hex.charAt(2) + hex.charAt(3), 16) / 255
      receiver.b = parseInt(hex.charAt(4) + hex.charAt(5), 16) / 255
      const alpha = parseInt(hex.charAt(6) + hex.charAt(7), 16) / 255
      return [receiver, alpha]
    }
  }

  if (colorString && colorString.length > 0) {
    return [receiver.setColorName(colorString), 1]
  }

  throw new Error('Oops.')
}

export const parseColorWithAlpha = (colorString: string): [r: number, g: number, b: number, a: number] => {
  const [color, alpha] = parseColor(colorString)
  return [color.r, color.g, color.b, alpha]
}
