
export type CellInfo = {
  cellCount: number
  cellWidth: number
  cellHeight: number
  cellRatio: number
}

const getCellInfo = (width: number, height: number, gutter: number, col: number, row: number): CellInfo => {
  const cellCount = col * row
  const cellWidth = (width + gutter) / col - gutter
  const cellHeight = (height + gutter) / row - gutter
  const cellRatio = cellWidth / cellHeight
  return {
    cellCount,
    cellWidth,
    cellHeight,
    cellRatio,
  }
}

/**
 * The closest to 1, the higher score.
 * - .5 & 2 have the same score: 2
 * - 0 & Infinity have the same score: 1
 * - 1 - the best ratio - has the best score: Infinity
 */
const computeRatioScore = (ratio: number) => {
  return 1 / (1 - (ratio > 1 ? 1 / ratio : ratio))
}

export type GridInfo = CellInfo & {
  col: number
  row: number
  width: number
  height: number
  gutter: number
  desiredCellCount: number
  solutionIterationIndex: number
  info: string
}

/**
 * Quite tricky (because of the gutter implication). 
 * 
 * The function will compute a first approximation based on given size (width, 
 * height), then will quickly iterate twice (iterationMax params), looking for a 
 * better solution, once by increasing the number of col and row, once by decreasing.
 */
export const findTheBestGrid = ({
  width = 1920,
  height = 1080,
  desiredCellCount = 300,
  gutter = 12,
  iterationMax = 2,
} = {}): GridInfo => {
  const size = Math.sqrt(height * width / desiredCellCount)
  const initialCol = Math.ceil(width / size)
  const initialRow = Math.ceil(height / size)
  const initialSolution = getCellInfo(width, height, gutter, initialCol, initialRow)

  let row = initialRow, col = initialCol
  let solution = initialSolution
  let solutionIterationIndex = 0

  let altRow, altCol
  let altSolution = solution

  // "Up" iteration:
  altRow = initialRow
  altCol = initialCol
  for (let index = 0; index < iterationMax; index++) {
    if (altSolution.cellRatio > 1) {
      altCol++
    } else {
      altRow++
    }
    altSolution = getCellInfo(width, height, gutter, altCol, altRow)
    // console.log(`${index + 1}: ${computeRatioScore(altSolution.cellRatio).toFixed(1)} (${altSolution.cellRatio.toFixed(3)}) ? ${computeRatioScore(solution.cellRatio).toFixed(1)} (${solution.cellRatio.toFixed(3)})`)
    if (computeRatioScore(altSolution.cellRatio) > computeRatioScore(solution.cellRatio)) {
      solution = altSolution
      row = altRow
      col = altCol
      solutionIterationIndex = index + 1
    }
  }

  // "Down" iteration:
  altRow = initialRow
  altCol = initialCol
  for (let index = 0; index < iterationMax; index++) {
    if (altSolution.cellRatio > 1) {
      altRow--
    } else {
      altCol--
    }
    altSolution = getCellInfo(width, height, gutter, altCol, altRow)
    // console.log(`${-(index + 1)}: ${computeRatioScore(altSolution.cellRatio).toFixed(1)} (${altSolution.cellRatio.toFixed(3)}) ? ${computeRatioScore(solution.cellRatio).toFixed(1)} (${solution.cellRatio.toFixed(3)})`)
    if (computeRatioScore(altSolution.cellRatio) > computeRatioScore(solution.cellRatio)) {
      solution = altSolution
      row = altRow
      col = altCol
      solutionIterationIndex = -(index + 1)
    }
  }

  return {
    width,
    height,
    gutter,
    col,
    row,
    ...solution,
    desiredCellCount,
    solutionIterationIndex,
    info: `${solution.cellCount} (${col}x${row}) cr:${solution.cellRatio.toFixed(3)} it:${solutionIterationIndex}`,
  }
}
