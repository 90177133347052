export const defaultVisibility = 'visible'

export type Visibility = typeof defaultVisibility | 'hidden' | 'debug'

export const asVisibility = (value: any): Visibility => {
  switch (typeof value) {
    case 'string': {
      switch (value.toLocaleLowerCase()) {
        case 'hidden': {
          return 'hidden'
        }
        case 'debug': {
          return 'debug'
        }
        default: {
          return 'visible'
        }
      }
    }
    default: {
      return defaultVisibility
    }
  }
}
