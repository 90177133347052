import { server, strapi } from 'config'
import React from 'react'
import { LightboxData } from 'state/lightbox/lightbox'

type Entry = {
  url: string
  media: HTMLImageElement | HTMLVideoElement
  timestamp: number
}

export const MediaWithCache: React.FC<{
  data: LightboxData
  medias: strapi.MediaAttributes[]
  maxCacheEntries?: number
}> = ({
  data,
  medias,
  maxCacheEntries = 5,
}) => {
    const ref = React.useRef<HTMLDivElement>(null)

    const core = React.useMemo(() => {

      const entries = [] as Entry[]

      const capEntries = () => {
        entries.sort((a, b) => b.timestamp - a.timestamp)
        while (entries.length > maxCacheEntries) {
          entries.pop()
        }
      }

      const createVideo = () => {
        const video = document.createElement('video')
        video.controls = true
        return video
      }

      const createEntry = (url: string): Entry => {
        const isVideo = /\.(mp4|webm)$/.test(url)
        const media = isVideo
          ? createVideo()
          : document.createElement('img')
        media.src = `${server}${url}`
        const timestamp = Date.now()
        const entry = { url, media, timestamp }
        entries.push(entry)
        capEntries()
        return entry
      }

      const getEntry = (url: string): Entry => {
        const index = entries.findIndex(entry => entry.url === url)
        if (index === -1) {
          return createEntry(url)
        }
        else {
          const entry = entries[index]
          entry.timestamp = Date.now() // refresh timestamp
          return entry
        }
      }

      return {
        get: (url: string) => getEntry(url).media,
        preload: (url: string) => getEntry(url),
        clear: () => entries.length = 0
      }

    }, [maxCacheEntries])

    // Clear on unmount
    React.useEffect(() => {
      return () => { core.clear() }
    }, [core])

    // Use the cache
    React.useEffect(() => {
      const div = ref.current!
      const media = core.get(medias[data.currentIndex ?? 0].url)
      if (data.nextIndex !== null) {
        core.preload(medias[data.nextIndex].url)
      }
      if (data.previousIndex !== null) {
        core.preload(medias[data.previousIndex].url)
      }
      div.replaceChildren(media)
    })

    return <div ref={ref} className='click-through' />
  }
