import { createContext, useContext, useMemo } from 'react'

import { Observable } from 'some-utils/observables'

import { NewsCategory, NewsFull, NewsPreview } from 'data/api'

function createNewsContext() {
  const newsCategory = new Observable<NewsCategory | null>(null)
  const newsPreviews = new Observable<NewsPreview[]>([])
  const openedNews = new Observable<NewsFull | null>(null)
  // newsCategory.logOnChange('newsCategoryObs')

  async function loadNews(id: string | number) {
    const domain = 'https://ovvo.studio'
    const response = await window.fetch(`${domain}/api/all-news/${id}?populate=*`)
    const data = await response.json()
    openedNews.setValue(data.data)
  }

  return {
    observables: {
      newsCategory,
      newsPreviews,
      openedNews,
    },

    actions: {
      loadNews,
    },
  }
}

export type NewsContext = ReturnType<typeof createNewsContext>

export const ReactNewsContext = createContext<NewsContext>(null!)

export function useNews() {
  return useContext(ReactNewsContext)
}

export function NewsProvider({ children }: { children: React.ReactNode }) {
  const value = useMemo(createNewsContext, [])
  return (
    <ReactNewsContext.Provider value={value}>
      {children}
    </ReactNewsContext.Provider>
  )
}