import { Animation } from 'some-utils/Animation'
import { handleKeyboard, handlePointer } from 'some-utils/dom'
import { clamp01, easings, lerp, limitE, signed } from 'some-utils/math'
import { Message } from 'some-utils/message'
import { time } from 'some-utils/npm/@react-three'
import { Observable } from 'some-utils/observables'
import { FloatVariable } from 'some-utils/variables'
import { handleMouseWheelSwipe } from './handleMouseWheelSwipe'
import { handleSwipe } from './handleSwipe'
import { homeScrollObs, homeScrollTo } from './scroll'

/**
 * The last, the most influence.
 */
const lerpInOrder = (initialValue: number, ...destinations: [number, number][]) => {
  let value = initialValue
  for (const [destination, mix] of destinations) {
    value = lerp(value, destination, mix)
  }
  return value
}

export const bindHomeScroll = function* (element: HTMLElement, influenceObs: Observable<number>) {

  let navigationDestination = 0
  let navigationInfluence = 0

  const goto = (to: number) => {
    const tween = {
      from: homeScrollObs.value,
      to,
    }
    const duration = .3 + Math.abs(tween.to - tween.from) * .2
    Animation.duringWithTarget('NAVIGATION_SCROLL', { duration }, ({ progress }) => {
      const alpha = easings.inout(progress, 3, .25)
      const position = lerp(tween.from, tween.to, alpha)
      navigationDestination = position
    })
    Animation.duringWithTarget('NAVIGATION_INFLUENCE', { duration: 0.2, delay: duration + .5, immediate: true }, ({ progress }) => {
      navigationInfluence = 1 - progress
    })
  }

  yield Message.on('APP', 'LOGO_TAP', ({ props }) => {
    props.catchMessage()
    goto(0)
  })

  // KEYBOARD:

  let jumpDestination = 0
  let jumpInfluence = 0

  const jump = (value: number) => {
    jumpDestination = Math.round(value)
    Animation.duringWithTarget('JUMP_INFLUENCE', .5, ({ progress }) => {
      jumpInfluence = progress < 1 ? 1 : 0
    })
  }

  yield handleKeyboard({
    onDown: [
      [['ArrowDown', 'ArrowUp'], info => {
        info.event.preventDefault()
        const delta = /ArrowUp/.test(info.event.code) ? - 1 : 1
        jump(Math.round(homeScrollObs.value) + delta)
      }],
    ],
  })

  yield Message.on('HOME_SCROLL', 'JUMP', ({ props }) => {
    const value = props?.value
    if (Number.isNaN(value) === false) {
      jump(value)
    }
  })

  let wheelDelta = 0
  let wheelInfluence = 1



  // SWIPE:
  let swipeDestination = 0
  let swipeInfluence = 0

  const swipe = (direction: number) => {
    swipeDestination = Math.round(homeScrollObs.value) + direction
    Animation.duringWithTarget('SWIPE_INFLUENCE', .5, ({ progress }) => {
      swipeInfluence = progress < 1 ? 1 : 0
    })
  }

  yield handleMouseWheelSwipe(element, {
    onVerticalSwipe: ({ direction }) => {
      swipe(direction)
    },
  })

  yield handleSwipe(element, {
    onVerticalSwipe: ({ direction }) => {
      swipe(direction)
    },
  })




  // WHEEL:

  const onWheel = (event: WheelEvent): void => {
    event.stopPropagation()
    event.preventDefault()

    const DOM_DELTA_LINE = 1
    const delta = event.deltaY / (event.deltaMode === DOM_DELTA_LINE ? 16 : 1)
    const height = element.clientHeight
    const limit = height / 10
    const limitedDelta = signed(x => limitE(x, limit), delta)
    wheelDelta += limitedDelta / height * 0.2
  }

  element.addEventListener('wheel', onWheel, { capture: true, passive: false, })
  yield () => element.removeEventListener('wheel', onWheel, { capture: true })

  // TOUCH:

  // let touchDestination = 0
  // let touchInfluence = 0

  // const touch = { start: 0, current: 0, delta: 0 }
  // const onTouchStart = (event: TouchEvent) => {
  //   touch.start = event.touches[0].clientY
  //   touch.current = event.touches[0].clientY
  //   Animation.duringWithTarget('TOUCH_INFLUENCE', 0, () => {
  //     touchInfluence = 1
  //   })
  // }
  // const onTouchMove = (event: TouchEvent) => {
  //   event.preventDefault() // no scroll plz
  //   touch.delta = event.touches[0].clientY - touch.current
  //   touch.current = event.touches[0].clientY
  //   touchDestination += -touch.delta / window.innerHeight
  //   touchDestination = clamp(touchDestination, 0, Data.projects.length)
  // }
  // const onTouchEnd = () => {
  //   touchDestination = Math.round(homeScroll.value
  //     - ((touch.current - touch.start) / window.innerHeight) * 0.75 // intention influence
  //     - (touch.delta / window.innerHeight) * 10 // velocity influence
  //   )
  //   Animation.duringWithTarget('TOUCH_INFLUENCE', 0.5, ({ complete }) => {
  //     touchInfluence = !complete ? 1 : 0
  //   })
  // }

  // window.addEventListener('touchstart', onTouchStart, { capture: true, passive: false })
  // window.addEventListener('touchmove', onTouchMove, { capture: true, passive: false })
  // window.addEventListener('touchend', onTouchEnd, { capture: true, passive: false })
  // yield () => {
  //   window.removeEventListener('touchstart', onTouchStart, { capture: true })
  //   window.removeEventListener('touchmove', onTouchMove, { capture: true })
  //   window.removeEventListener('touchend', onTouchEnd, { capture: true })
  // }



  // DRAG:

  let dragOrigin = 0
  let dragDestination = 0
  let dragInfluence = 0
  const dragDelta = new FloatVariable(0, { size: 4 })

  yield handlePointer(element, {
    onVerticalDragStart: () => {
      dragOrigin = Math.round(homeScrollObs.value)
      dragDestination = homeScrollObs.value
      element.style.setProperty('user-select', 'none', 'important')
      Animation.duringWithTarget('DRAG_INFLUENCE', 0, () => dragInfluence = 1)
    },
    onVerticalDragStop: () => {
      element.style.removeProperty('user-select')
      Animation.duringWithTarget('DRAG_INFLUENCE', { duration: .25, delay: .1 }, ({ progress }) => dragInfluence = 1 - progress)

      // Swipe determination: a tradeoff between distance & speed (delta).
      const distBoost = 2.5 * 2, deltaBoost = 100
      const dist = dragDestination - dragOrigin
      const intention = dragDelta.average * deltaBoost + dist * distBoost
      const swipe = intention < -1 ? -1 : intention > 1 ? 1 : 0
      dragDestination = dragOrigin + swipe
    },
    onVerticalDrag: info => {
      const dragDeltaValue = -info.delta.y / element.clientHeight
      dragDelta.setNewValue(dragDeltaValue)
      dragDestination += dragDeltaValue
    },
  })

  const preventTouch = (event: TouchEvent) => {
    // NOTE: beware of this preventDefault which is very, very high level, and could prevent a lot of desirable functionnalities.
    event.preventDefault() // no scroll plz
  }
  element.addEventListener('touchmove', preventTouch, { capture: true, passive: false })
  yield () => {
    element.removeEventListener('touchmove', preventTouch, { capture: true })
  }


  yield time.onChange(() => {
    // NOTE: this is a trade-off between wheel sensitivity / feedback & jump behaviour
    // Not very satisfied with this, but it works.
    // NB: on macOS wheel events are fired long time after the user last input, 
    // this simulates the behaviour of a physical device (slowing down) but it's
    // in competition with "jump" behaviour:
    // We do not want to have the trailing wheel event acting after a jump. So 
    // we have to deal with a "wheelInfluence" coefficient.
    const scroll = homeScrollObs.value
    const wheelDestination = Math.round(scroll + wheelDelta * 5) // jump is implemented via "round" and delta coef
    const destination = lerpInOrder(wheelDestination,
      [jumpDestination, jumpInfluence],
      [swipeDestination, swipeInfluence],
      // [touchDestination, touchInfluence],
      [dragDestination, dragInfluence],
      [navigationDestination, navigationInfluence]
    )
    const destinationDelta = destination - scroll

    if (Math.abs(destinationDelta) > 0.5) {
      // reset wheelInfluence
      wheelInfluence = 0
      Animation.duringWithTarget('WHEEL_INFLUENCE', { duration: 0.5, delay: 0.9 }, ({ progress }) => {
        wheelInfluence = progress
      })
    }

    let scroll2 = scroll
    scroll2 += influenceObs.value * destinationDelta * 0.6 // the higher the speeder will be the transition 
    scroll2 += influenceObs.value * wheelDelta * clamp01(wheelInfluence) * 2 // feed back boost

    homeScrollTo(scroll2)

    wheelDelta += (0 - wheelDelta) * 0.15
  })
}