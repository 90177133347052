import { ObservableNumber } from 'some-utils/observables'
import { Animation } from 'some-utils/Animation'
import { lerp } from 'some-utils/math'
import { currentRouteIsHome, currentRouteIsProject, routeObs } from 'state/navigation'

/**
 * Here is the "grid" logic.
 * When is it open? Which opacity? On which routes? With which interpolation?
 */
export function* getProps() {

  const openingFromRoute = (defaultValue = 0) => {
    return (
      currentRouteIsProject() ? 1 :
        currentRouteIsHome() ? 0 :
          defaultValue
    )
  }

  const opacityFromRoute = () => {
    return currentRouteIsProject() || currentRouteIsHome() ? 1 : 0
  }

  const opening = new ObservableNumber(openingFromRoute())
  yield opening
  yield routeObs.onChange(() => {
    const start = opening.value
    const end = openingFromRoute(start)
    Animation.duringWithTarget(opening, 1.5, ({ progress }) => {
      if (opening.destroyed) {
        return Animation.BREAK
      }
      opening.value = lerp(start, end, progress)
    })
  })

  const opacity = new ObservableNumber(opacityFromRoute())
  yield opacity
  yield routeObs.onChange(() => {
    const start = opacity.value
    const end = opacityFromRoute()
    Animation.duringWithTarget(opacity, .3, ({ progress }) => {
      if (opacity.destroyed) {
        return Animation.BREAK
      }
      opacity.value = lerp(start, end, progress)
    })
  })

  return { opening, opacity }
}
