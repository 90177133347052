import { removeLocaleFromPath } from 'config'
import { findProject } from 'data'
import { location } from 'some-utils/router'

export const isHome = (path = location.pathname.value) => {
  return removeLocaleFromPath(path).replace(/^\//, '') === ''
}
export const isProject = (path = location.pathname.value) => {
  const pattern = removeLocaleFromPath(path).replace(/^\//, '') // remove heading slash (if so)
  const re = new RegExp(`^${pattern}$`, 'i')
  return !!findProject(project => re.test(project.slug))
}
