import * as theatre from '@theatre/core'
import anim from './anim.json'
import { inverseLerp } from 'some-utils/math'

// Demo is in /extras/ovvo-logo-2023-anim-theatrejs
const project = theatre.getProject('OVVO-LOGO-2023', { state: anim })
const sheet = project.sheet('OVVO')

const animLandscape = sheet.object('Landscape', {
  'deployment': theatre.types.number(0, { range: [0, 1] }),
  'studio': theatre.types.number(0, { range: [0, 1] }),
  'logoCompletion': theatre.types.number(0, { range: [0, 1] }),
  'isotoneCompletion': theatre.types.number(0, { range: [0, 1] }),
})

const animPortrait = sheet.object('Portrait', {
  'isotoneLow': theatre.types.number(1, { range: [0, 1] }),
  'isotoneInline': theatre.types.number(0, { range: [0, 1] }),
  'deployment': theatre.types.number(0, { range: [0, 1] }),
  'studio': theatre.types.number(0, { range: [0, 1] }),
  'logoCompletion': theatre.types.number(0, { range: [0, 1] }),
  'isotoneCompletion': theatre.types.number(0, { range: [0, 1] }),
})

const updateSvg = (object: Record<string, number>, svg: SVGSVGElement, mode: 'landscape' | 'portrait' = 'landscape') => {
  const query = (selector: string) => svg.querySelector(selector) as SVGElement
  const queryAll = (selector: string) => [...svg.querySelectorAll(selector)] as SVGElement[]

  if (mode === 'landscape') {
    // Hide portrait
    query('#low-isotone').style.setProperty('display', 'none')
  } else {    
    query('#low-isotone').style.removeProperty('display')
  }

  {
    // Deployment
    const value = object.deployment
    const tx = (-390 * (1 - value)).toFixed(1)
    query('#isotone').style.transform = `translateX(${tx}px)`
    query('#dot').style.transform = `translateX(${tx}px)`
  }

  {
    // Logo
    const value = object.logoCompletion
    {
      const element = query('#o1-m')
      const x = Number.parseFloat(element.getAttributeNS(null, 'x') ?? '0')
      const width = Number.parseFloat(element.getAttributeNS(null, 'width') ?? '0')
      element.style.transform = `translateX(${value * (x + width)}px) scaleX(${1 - value})`
    }
    {
      const element = query('#v1-m')
      element.style.transform = `translateY(${value * -14}px)`
    }
    {
      const element = query('#v2-m')
      element.style.transform = `translate(${value * -7}px , ${value * 25}px)`
    }
    {
      const element = query('#o2-m')
      const width = 40 * (1 - value)
      element.setAttributeNS(null, 'width', width.toFixed(1))
    }
  }

  {
    // Studio
    const value = object.studio
    const children = [...query('#studio').children] as HTMLElement[]
    for (let i = 0; i < 6; i++) {
      const d = .4
      const t0 = i / 5 * d
      const t1 = 1 - (1 - (i / 5)) * d
      children[5 - i].style.opacity = inverseLerp(t0, t1, value).toFixed(2)
    }
  }

  {
    // Isotone Completion
    const value = object.isotoneCompletion
    const updateO1M = (selector: string) => {
      const element = query(selector)
      const width = 39 * (1 - value)
      element.setAttributeNS(null, 'width', width.toFixed(1))
    }
    const updateO2M = (selector: string) => {
      const element = query(selector)
      const x = Number.parseFloat(element.getAttributeNS(null, 'x') ?? '0')
      const width = Number.parseFloat(element.getAttributeNS(null, 'width') ?? '0')
      element.style.transform = `translateX(${value * (x + width)}px) scaleX(${1 - value})`
    }
    const updateNM = (selector: string) => {
      const element = query(selector)
      const height = Number.parseFloat(element.getAttributeNS(null, 'height') ?? '0')
      const ty = (height - 1) * value
      const transform = `translateY(${ty.toFixed(1)}px)`
      element.style.transform = transform
    }
    updateO1M('#isotone-o1-m')
    updateO2M('#isotone-o2-m')
    updateNM('#isotone-n-m')
    if (mode === 'portrait') {
      updateO1M('#low-isotone-o1-m')      
      updateO2M('#low-isotone-o2-m')
      updateNM('#low-isotone-n-m')
    }
  }

  if (mode === 'portrait') {
    {
      const value = object.isotoneLow
      const element = query('#low-isotone')
      element.setAttributeNS(null, 'opacity', value.toFixed(2))
    }
    {
      const value = object.isotoneInline
      const elements = queryAll('#isotone, #dot')
      for (const element of elements) {
        element.setAttributeNS(null, 'opacity', value.toFixed(2))
      }
    }
  }
}

export {
  animLandscape,
  animPortrait,
  sheet as theatreSheet,
  updateSvg,
}
