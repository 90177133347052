import { Padding } from 'some-utils/geom'
import { ResponsiveBase, ResponsiveRule, solveOrientation, orientations, getOrientation, Size } from './responsive-types'

const bases: ResponsiveBase[] = [
  {
    name: 'mobile',
    max: {
      width: 740,
      height: Infinity,
    },
    hudAlignment: 'inside',
    columnCount: 1,
    gridPadding: new Padding({ horizontal: 32, top: 80, bottom: 32 }),
  },
  {
    name: 'tablet',
    max: {
      width: 1200,
      height: Infinity,
    },
    hudAlignment: 'center',
    columnCount: {
      landscape: 4,
      portrait: 3,
    },
    gridPadding: {
      landscape: new Padding({ horizontal: 76, top: 106, bottom: 92 }),
      portrait: new Padding({ horizontal: 76, top: 140, bottom: 114 }),
    },
  },
  {
    name: 'desktop',
    max: 1360,
    hudAlignment: 'center',
    columnCount: {
      landscape: 4,
      portrait: 4,
    },
    gridPadding: new Padding({ horizontal: 108, vertical: 140 }),
  },
  {
    name: 'desktop wide',
    max: 1500,
    hudAlignment: 'center',
    columnCount: 5,
    gridPadding: new Padding({ horizontal: 108, vertical: 140 }),
  },
  {
    name: 'desktop wide x-wide',
    max: Infinity,
    hudAlignment: 'center',
    columnCount: 5,
    gridPadding: new Padding({ horizontal: 108, vertical: 140 }),
  },
]

export const responsiveRules: Readonly<ResponsiveRule>[] = []

const ensureSize = (arg: Size | number): Size => {
  if (typeof arg === 'number') {
    return {
      width: arg,
      height: arg,
    }
  }
  return arg
}

const incrementSize = (arg: Size | number): Size => {
  const { width, height } = ensureSize(arg)
  return {
    width: width + 1,
    height: height + 1,
  }
}

for (const [index, base] of bases.entries()) {

  const { max, name, hudAlignment } = base
  const columnCount = solveOrientation(base.columnCount)
  const gridPadding = solveOrientation(base.gridPadding)
  const min = index === 0 ? { width: 0, height: 0 } : incrementSize(bases[index - 1].max)

  for (const orientation of orientations) {
    const tokens = [...name.split(' '), orientation]
    responsiveRules.push(Object.freeze({
      screenName: tokens.join('_'),
      tokens,
      min,
      max: ensureSize(max),
      mobile: /mobile/.test(name),
      orientation,
      hudAlignment,
      columnsCount: columnCount[orientation],
      gridPadding: gridPadding[orientation],
    }))
  }
}

export const getCurrentRule = (width: number, height: number) => {
  const orientation = getOrientation(width, height)
  for (const rule of responsiveRules) {
    if (rule.orientation === orientation) {
      if ((width >= rule.min.width || height >= rule.min.height) && (
        width <= rule.max.width && height <= rule.max.height
      )) {
        return rule
      }
    }
  }
  throw new Error('Wah...')
}
