import { ObservableBoolean } from 'some-utils/observables'

type Params = Partial<{
  onVerticalSwipe: (info: { direction: number }) => void
}>

/**
 * This is for handling "mouse wheel swipe", swipe but with the mouse wheel. 
 */
export const handleMouseWheelSwipe = (element: HTMLElement, {
  onVerticalSwipe,
}: Params) => {
  const deltas = [] as number[]
  const scrollObs = new ObservableBoolean(false)
  const state = {
    mounted: true,
    start: 0,
  }
  const now = () => window.performance.now() / 1e3
  scrollObs.onTrue(() => {
    deltas.length = 0
    state.start = now()
  })
  scrollObs.onFalse(() => {
    const duration = now() - state.start
    const total = deltas.reduce((a, b) => a + b, 0)
    const isSwipe = duration < .5 && Math.abs(total) > 200
    if (isSwipe) {
      const direction = total > 0 ? 1 : -1
      onVerticalSwipe?.({ direction })
    }
  })
  let timestamp = 0
  const onWheel = (event: WheelEvent): void => {
    scrollObs.setTrue()
    deltas.push(event.deltaY)
    timestamp = now()
  }
  const frame = () => {
    if (state.mounted === false) {
      return
    }
    window.requestAnimationFrame(frame)
    scrollObs.setValue((now() - timestamp) < .166)
  }
  window.requestAnimationFrame(frame)
  element.addEventListener('wheel', onWheel, { capture: true })
  const destroy = () => {
    state.mounted = false
    element.removeEventListener('wheel', onWheel, { capture: true })
  }
  return { destroy }
}
