import { NewsCategory } from 'data/api'

const mediaData = `{
  data {
    id
    attributes {
      name
      alternativeText
      caption
      mime
      width
      height
      size
      url
    }
  }
}`

const newsPreviewAttributes = `
  NewsCategory
  Date
  Slug
  Title
  Subtitle
  Description
  CoverTheme
  Thumbnail ${mediaData}
`

const newsFullAttributes = `
  ${newsPreviewAttributes}
  Cover ${mediaData}
`

const newsPreviewData = `{
  data {
    id
    attributes {
      ${newsPreviewAttributes}
    }
  }
}
`

const newsFilters = (newsCategory: NewsCategory | null, {
  debug = false,
} = {}) => {
  const debugPrefix = debug ? '#' : '' // Deactivate the filter in debug mode
  switch (newsCategory) {
    case null:
      return `
        filters: {
          and: [
            ${debugPrefix} { Visibility: { in: ["visible"] } }
            { NewsCategory: { notNull: true } }
          ]
        }`
    default:
      return `
          filters: {
            and: [
              ${debugPrefix} { Visibility: { in: ["visible"] } }
              { NewsCategory: { eq: "${newsCategory}" } }
            ]
          }`
  }
}

const newsFullData = `{
  data {
    id
    attributes {
      ${newsFullAttributes}
    }
  }
}
`

export const graphql = {
  newsFilters,
  mediaData,
  newsPreviewAttributes,
  newsFullAttributes,
  newsPreviewData,
  newsFullData,
}
