import { ReactNode, createContext, useContext, useMemo } from 'react'

import { useEffects } from 'some-utils/npm/react'
import { Observable, ObservableNumber } from 'some-utils/observables'

import { Theme, defaultTheme } from 'config'
import { cheatExpose } from 'cheat'

type AppContextType = {
  themeObs: Observable<Theme>
  averageFpsObs: ObservableNumber
  qualityDegradationLevelObs: ObservableNumber
  qualityInfoObs: Observable<string>
}

export const QUALITY_DEGRADATION_MAX = 4

const AppContext = createContext<AppContextType>(null!)

export const useAppContext = () => useContext(AppContext)

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const context = useMemo<AppContextType>(() => {
    return {
      themeObs: new Observable<Theme>(defaultTheme),
      averageFpsObs: new ObservableNumber(0),
      qualityDegradationLevelObs: new ObservableNumber(0, { min: 0, max: QUALITY_DEGRADATION_MAX }),
      qualityInfoObs: new Observable(''),
    }
  }, [])

  cheatExpose({ appContext: context })

  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    yield context.themeObs.withValue((value, { valueOld }) => {
      div.classList.remove(`${valueOld}-theme`)
      div.classList.add(`${value}-theme`)
    })
  }, [])

  return (
    <AppContext.Provider value={context}>
      <div ref={ref} className='App'>
        {children}
      </div>
    </AppContext.Provider>
  )
}

export const useDefaultTheme = () => {
  const { themeObs } = useAppContext()
  useEffects(function () {
    themeObs.setValue(defaultTheme)
  }, [])
}
