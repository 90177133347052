
export const loadFromLocalStorage = () => {
  try {
    const {
      homeScroll = 0, 
      projectScroll = 0,
    } = JSON.parse(localStorage.getItem('scroll') ?? '{}')
    return { homeScroll, projectScroll }
  }
  catch (error) {
    return { homeScroll: 0, projectScroll: 0 }
  }
}

export const saveToLocalStorage = (
  homeScroll: number,
  projectScroll: number,
) => {
  localStorage.setItem('scroll', JSON.stringify({
    homeScroll,
    projectScroll,
  }))
}
