import { useRef } from 'react'

import { handlePointer } from 'some-utils/dom'
import { useEffects, useObservable } from 'some-utils/npm/react'

import { Locale } from 'config'
import { newsCategoriesNames } from 'data'
import { NewsPreview } from 'data/api'
import { useResponsive } from 'state/layout'
import { useLocale } from 'state/navigation'
import { LargeScroller, LargeScrollerPublicState, Scrollbar, ScrollbarPublicState } from 'view/components'
import { Media } from 'view/components/Media'
import { NextArrow } from 'view/components/svg'

import { NewsHeader } from './NewsHeader'
import { useNews } from './context'
import { shorthMonths } from './shorthMonths'

import './previews.css'

export function generateCssGradient({
  hex = '#000000',
  distribution = (t: number) => (1 - t) ** 3,
  reverse = false,
  step = 20,
  alphaBase = .5,
  separator = ',',
  size = 100,
} = {}) {
  return Array.from({ length: step + 1 }, (_, i) => {
    const t = i / step
    const alpha = Math.round(alphaBase * distribution(reverse ? 1 - t : t) * 0xff)
      .toString(16)
      .padStart(2, '0')
    return `${hex}${alpha} ${(size * t).toFixed(1)}%`
  }).join(separator)
}

// console.log(generateCssGradient({
//   size: 50,
//   alphaBase: .85,
//   distribution: t => t ** 5,
//   separator: ',\n',
//   reverse: true,
// }))

function Row({
  newsPreview,
  locale,
}: {
  newsPreview: NewsPreview
  locale: Locale
}) {
  const { ref } = useEffects<HTMLDivElement>(function* (div) {
    yield handlePointer(div, {
      onEnter() {
        div.classList.add('hover')
      },
      onLeave() {
        div.classList.remove('hover')
      },
    })
  }, [])

  const news = useNews()
  const [year, month, day] = newsPreview.attributes.Date.split('-')
  return (
    <div
      ref={ref}
      className='Row flex row'
      data-id={newsPreview.id}
      onClick={() => news.actions.loadNews(newsPreview.id)}
    >
      <div className='Date flex column'>
        <div className='Day'>{day}</div>
        <div className='Month'>{shorthMonths[Number.parseInt(month) - 1][locale].toUpperCase()}</div>
        <div className='Year'>{year.slice(-2)}</div>
      </div>

      <div className='Text'>
        <h2 className='NewsCategory'>{newsCategoriesNames[newsPreview.attributes.NewsCategory]}</h2>
        <div style={{ flex: '0 1 2em' }} />

        <div className='flex column'>
          <h1 className='Title'>{newsPreview.attributes.Title}</h1>
          <div style={{ flex: '0 0 1em' }} />
          <p className='Description'>{newsPreview.attributes.Description}</p>
        </div>

        <div style={{ flex: '1 1 0' }} />

        <button>
          <NextArrow />
        </button>
      </div>

      <div className='Image'>
        <Media
          media={newsPreview.attributes.Thumbnail.data} />
      </div>
    </div>
  )
}

function MobileRow({
  newsPreview,
  locale,
}: {
  newsPreview: NewsPreview
  locale: Locale
}) {
  return (
    <div
      className='MobileRow flex column'
    >
      <div className='Image'>
        <Media
          className='absolute-fill'
          media={newsPreview.attributes.Thumbnail.data} />
        <h2 className={`ui ui-bigger ui-uppercase ${newsPreview.attributes.CoverTheme ?? 'dark'}-theme`}>
          {newsCategoriesNames[newsPreview.attributes.NewsCategory]}
        </h2>
      </div>

      <div className='NewsBody flex column'>
        <div className='Date'>
          {newsPreview.attributes.Date.split('-').reverse().join('.')}
        </div>
        <h1 className='Title'>{newsPreview.attributes.Title}</h1>
        <p className='Description'>{newsPreview.attributes.Description}</p>
        <div style={{ flex: '0 0 3em' }} />
      </div>
    </div>
  )
}

function Rows() {
  const locale = useLocale()
  const news = useNews()
  const newsPreviews = useObservable(news.observables.newsPreviews)
  const { mobile } = useResponsive()
  return (
    <div className='Rows flex-1 flex column'>
      <section className='flex column'>
        {mobile
          ? newsPreviews.map(newsPreview => (
            <MobileRow
              key={newsPreview.id}
              newsPreview={newsPreview}
              locale={locale} />
          ))
          : newsPreviews.map(newsPreview => (
            <Row
              key={newsPreview.id}
              newsPreview={newsPreview}
              locale={locale} />
          ))}
      </section>
    </div>
  )
}

export function NewsPreviews() {
  const scrollbarStateRef = useRef<ScrollbarPublicState>(null)
  const scrollerStateRef = useRef<LargeScrollerPublicState>(null)

  useEffects<HTMLDivElement>(function* () {
    const scrollbar = scrollbarStateRef.current!
    const scroller = scrollerStateRef.current!

    yield scrollbar.onRequestProgress(t => {
      scroller.setProgress(t)
    })
    yield scroller.scrollProgressObs.onChange(progress => {
      scrollbar.setProgress(progress)
    })
  }, [])

  return (
    <div className='NewsPreviews absolute-fill flex column'>
      <Scrollbar
        direction='vertical'
        padding={{ vertical: 16 }}
        stateRef={scrollbarStateRef}
        zIndex={10}
      />

      <NewsHeader />
      <div className='flex-1 overflow-hidden'>
        <LargeScroller stateRef={scrollerStateRef}>
          <Rows />
        </LargeScroller>
      </div>
    </div>
  )
}
