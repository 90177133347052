
export type ValueObject = any // understand here: "anything that is NOT a plain object" (cf safe guard below)

export const isValueObject = (value: any): value is ValueObject => {
  return typeof value === 'object' && value !== null && value.constructor !== Object
}

export type BasicValue = number | string | boolean

export type Value = BasicValue | ValueObject

export const isValue = (value: any): value is Value => {
  const type = typeof value
  return type === 'number' || type === 'string' || type === 'boolean' || isValueObject(value)
}

export type ValueType = 'Number' | 'Vector' | 'Color' | 'String' | 'Boolean'

export type ValueFormat = 'Int' | `F${number}`

export type PropertyMeta = Partial<{
  type: ValueType
  min: any
  max: any
  size: number
  integer: boolean
  step: number
  stepped: boolean
  format: ValueFormat
  hidden: boolean
  priority: number
  debug: boolean
}>

export type ValueMapper = {
  condition: (value: any, key: string) => boolean
  transform: (value: any) => any
  restore: (value: any, originalValue: any) => any
}

export type Property<SourceValue = any, InnerValue extends Value = any> = {
  key: string
  type: ValueType
  mapper: ValueMapper
  readonly value: Value & InnerValue
  getLiveInspectorValue: () => InnerValue
  setLiveInspectorValue: (newValue: InnerValue) => void
  extractValue: () => SourceValue
  updateValue: (value: SourceValue) => void
} & PropertyMeta

export const isColorString = (value: string) => (
  value.startsWith('#')
  && /^#[0-9a-f]+$/i.test(value)
  && (
    value.length === 4
    || value.length === 5
    || value.length === 7
    || value.length === 9
  )
)

export const getValueType = (value: any): ValueType => {
  const type = typeof value

  if (type === 'number') {
    return 'Number'
  }

  if (type === 'string') {
    if (isColorString(value)) {
      return 'Color'
    }
    return 'String'
  }

  if (type === 'object') {
    return 'Vector'
  }

  if (type === 'boolean') {
    return 'Boolean'
  }

  throw new Error(`Cannot determine 'ValueType' of ${JSON.stringify(value)}`)
}
