import { CSSProperties, useRef } from 'react'
import { useChildrenBounds } from 'some-utils/npm/react'
import { strapi } from 'config'
import { useResponsive } from 'state/layout'
import { MediaSingle } from './MediaSingle'
import { Text } from './Text'
import './GridComposer.css'

type GridEntry = {
  content: strapi.Text | strapi.MediaSingle
  rawEntry: strapi.GridComposerEntry
}

type GridData = {
  entries: GridEntry[]
  props: Omit<strapi.GridComposer['gridLayout'], 'entries'>
}

type LayoutDirection = 'horizontal' | 'vertical'

const parse = (rawData: strapi.GridComposer): GridData => {
  const entries = [] as GridEntry[]
  const { entries: rawEntries, ...props } = rawData.gridLayout
  for (const rawEntry of rawEntries) {
    const { selector } = rawEntry
    const [key, rawIndex] = selector.split('-')
    if (key !== 'mediaSingles' && key !== 'texts') {
      throw new Error(`"gridLayout" Error: Invalid key ("${key}"). Key should be "mediaSingles" | "texts"`)
    }
    const array = rawData[key]
    if (array.length > 0) {
      // NOTE: index is not zero indexed!
      const index = Number.parseInt(rawIndex)
      if (index < 1 || index > array.length) {
        throw new Error(`"gridLayout" Error: Invalid index (${index}). Index should be >= 1 & <= ${array.length}`)
      }
      const content = array[index - 1]
      entries.push({ content, rawEntry })
    }
  }
  return { entries, props }
}

const determineLayoutDirection = (entries: GridEntry[], columnsCount: number): LayoutDirection => {
  for (const entry of entries) {
    const { columns } = entry.rawEntry
    if (!columns) {
      // No layout options! Content are distributed vertically.  
      return 'vertical'
    }
    const columnCases = Object.keys(columns).map(v => Number.parseInt(v))
    if (columnCases.includes(columnsCount) === false) {
      // No layout option for the current column count! Content are distributed vertically.  
      return 'vertical'
    }
  }
  // It's ok.
  return 'horizontal'
}

const Vertical = ({ data }: { data: GridData }) => {
  const sortedEntries = [...data.entries].sort((a, b) => ((b.rawEntry.vertical?.priority ?? 0) - (a.rawEntry.vertical?.priority ?? 0)))
  const { gutter = '32px' } = data.props.global?.vertical ?? {}
  const style = { '--gutter': gutter } as CSSProperties
  return (
    <div className='GridComposer vertical flex column gutter' style={style}>
      {sortedEntries.map(({ content, rawEntry: layout }) => (strapi.isText(content)
        ? <Text key={layout.selector} content={content} />
        : <MediaSingle key={layout.selector} content={content} />
      ))}
    </div>
  )
}

const Horizontal = ({ data }: { data: GridData }) => {
  const ref = useRef<HTMLDivElement>(null)
  useChildrenBounds(ref, ':scope > div', allBounds => {
    const div = ref.current!
    const height = Math.max(...allBounds.map(b => b.height))
    div.style.height = `${height}px`
  })
  return (
    <div ref={ref} className='GridComposer horizontal'>
      {data.entries.map(({ content, rawEntry }) => {
        const overridedContent = { ...content } as strapi.Text | strapi.MediaSingle
        const { selector, columns } = rawEntry
        const style = { ...rawEntry.horizontal } as CSSProperties
        if (columns) {
          overridedContent.layout = { columns }
        }
        return (strapi.isText(overridedContent)
          ? <Text key={selector} style={style} content={overridedContent} />
          : <MediaSingle key={selector} content={overridedContent} />
        )
      })}
    </div>
  )
}

export const GridComposer = ({
  content,
}: {
  content: strapi.GridComposer
}) => {
  const data = parse(content)
  const { columnsCount } = useResponsive()
  const direction = determineLayoutDirection(data.entries, columnsCount)
  return (direction === 'vertical'
    ? <Vertical data={data} />
    : <Horizontal data={data} />
  )
} 