
type ColumnLayout<T extends object = any> = {
  1: T
  3: T
  4: T
  5: T
}
const processColumnLayoutDeclaration = <T extends object = any>(columns: any, defaultValue?: T) => {
  if (!columns || typeof columns !== 'object') {
    console.error(columns)
    throw new Error(`Invalid "columns" value (cf console).`)
  }
  const layout: ColumnLayout<T> = {
    1: { ...defaultValue } as T,
    3: { ...defaultValue } as T,
    4: { ...defaultValue } as T,
    5: { ...defaultValue } as T,
  }
  // NOTE: f***ing important to sort the keys here!
  // Otherwise wildcard may override more specific values.
  const keys = (Object.keys(columns) as string[]).sort((a, b) => {
    if (a === '*') { return -1 }
    if (b === '*') { return 1 }
    if (a.includes('-')) {
      if (b.includes('-')) { return a < b ? -1 : 1 }
      else { return -1 }
    }
    if (b.includes('-')) { return 1 }
    return a < b ? -1 : 1
  })
  for (const key of keys) {
    let start = -1, end = -1
    if (key === '*') {
      start = 1
      end = 5
    } else if (/^[1345]$/.test(key)) {
      start = Number.parseInt(key)
      end = start
    } else if (/^[1345]-[1345]$/.test(key)) {
      const [x, y] = key.split('-')
      start = Number.parseInt(x)
      end = Number.parseInt(y)
      if (end < start) {
        console.warn(`Invalid key here, the second index must be greater or equal to the first one. Received: "${key}"`)
        end = start
      }
    } else {
      console.warn(`Invalid key: "${key}". Ignored.`)
    }
    if (start !== -1) {
      // It's a regular "column" key, let's apply the values to the right layout case.
      for (const x of [1, 3, 4, 5] as const) {
        if (x >= start && x <= end) {
          Object.assign(layout[x], columns[key as keyof typeof columns])
        }
      }
    }
  }
  return layout
}

/**
 * "Column Layout Declaration" may cover different usages. This methods resolve
 * declarations options to one normalized result.
 * 
 * Declaration usages:
 * - "one-for-all", eg:
 * ```
 * const d = {
 *   layout: { foo: 1, bar: 2 },
 * }
 * ```
 * - "complex-case-with-overrides", eg:
 * ```
 * const d = {
 *   layout: {
 *     columns: {
 *       "*": { foo: 1, bar: 2 },
 *       "1": { foo: 2 },
 *       "3-5": { foo: 3 },
 *       "1-3": { bar: 4 },
 *     },
 *   },
 * }
 * ```
 */
export const resolveColumnLayoutDeclaration = <T extends object = any>(data: T, defaultValue?: T): ColumnLayout<T> => {
  if (data && typeof data === 'object' && 'columns' in data) {
    return processColumnLayoutDeclaration<T>((data as any).columns as any, defaultValue)
  } else {
    const layout: ColumnLayout<T> = {
      1: { ...defaultValue, ...data },
      3: { ...defaultValue, ...data },
      4: { ...defaultValue, ...data },
      5: { ...defaultValue, ...data },
    }
    return layout
  }
}
